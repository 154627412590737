import { Injectable } from '@angular/core';
import { ConfigService, StorageService } from 'src/app/services';
import { SettleRes } from '../paypay-flow/paypay.model';

//
const LIMIT_DAYS = 14;

//
export interface CarInfo {
  inTrans: number;
  src: string;
  np: string[];
  ticket: string;
  current: boolean;
  isAddictive?: boolean;
}

export interface FeeRate {
  title: string;
  count: number;
  amount: number;
  date: number;
  icon: string;
  purchasePrice: number;
  additionalCharge: number;
  additionalDiscountAmount: number;
  discountApplyFlg: boolean,
  displayOrder: number;
  discountType: number;
  discountInfo: number;
  last: boolean;
}

export interface PassCard {
  no: string;
  type: number;
  date1: number;
  date2: number;
  status: number;
  name: string;
}

//
@Injectable({
  providedIn: 'root'
})
export class PayModel {
  parking: string;

  // for QR
  qrUrl: string;
  qrNg: boolean;

  // for query
  query_type: number; // 0: last, 1: np4, 2: time
  np: string[];
  np4: string;
  date: string;
  time: string;

  // car
  car: CarInfo;
  carList: CarInfo[];

  // page
  page: number;
  maxPage: number;
  total: number;

  // fee
  parkingFee: number;
  parkingTime: number;
  fee: number;
  type: number; // 0:Yen, 1:Hour
  paidOut: number;
  passCard: PassCard;
  feeList: FeeRate[];
  totalDiscount: number;

  // QR
  qrReadId: number;

  // credit, paypay, linepay
  pay: string;
  dealId: string;

  // veritrans
  //veritrans: Veritrans;
  transactionId: string;

  //
  settle: SettleRes;

  //
  completed: boolean;

  //
  url4qr: string;

  //
  count = 0;

  // redirectUrl: string;
  deepLink: string;

  // 未精算常習者
  unPaidCarFlg: number;

  // 未精算車両有無
  dcbUnPaidOutDataExistFlg: number;

    //事業所情報
    issuerName: string;
    registNumber: string;
    issuerAddress: string;
    issuerTel: string;
    taxRate: number;
  
    inTrans: number;

  //
  constructor(
    private _conf: ConfigService,
    private _storage: StorageService
  ) {
    this.loadStorage();

    // if (deepEq(this.parking, this._conf.parking))
    //   this.reset();
  }

  //
  resetAll = () => {
    //this.parking = this._conf.parking;
    // this.redirectUrl = `${location.origin}/${this._conf.parking}/redirect`;

    this.query_type = null;
    this.np = null;
    this.np4 = null;
    this.date = null;
    this.time = null;

    this.carList = null;
    this.page = null;
    this.maxPage = null;
    this.total = null;

    this.unPaidCarFlg = 0;
    this.dcbUnPaidOutDataExistFlg = 0;

    this.reset();
  }

  private reset = () => {
    this.completed = false;
    this.count = 0;

    this.pay = null;
    this.dealId = null;
    this.deepLink = null;

    this.car = {
      inTrans: null,
      src: null,
      np: null,
      ticket: null,
      current: null
    };

    this.parkingFee = null;
    this.parkingTime = null;

    this.fee = null;
    this.type = null;
    this.paidOut = null;
    this.passCard = null;
    this.feeList = null;
    this.totalDiscount = null;

    this.qrReadId = null;

    this.url4qr = null;
    this.settle = null;

    this.issuerName = null;
    this.registNumber = null;
    this.issuerAddress = null;
    this.issuerTel = null;
    this.taxRate = 0;

    //this.resetCredit();
  }

  // resetCredit = () => {
  //   this.veritrans = {
  //     _cardNumber: null,
  //     _cardExpire: null,
  //     _securityCode: null,
  //     reqCardNumber: null,
  //     cardToken: null,
  //   };
  //   this.transactionId = null;

  //   this.saveStorage();
  // }

  //
  checkPassCard = () => {
    if (!this.passCard || this.passCard.status === 0)
      return 0;

    if (new Date() < new Date(this.passCard.date1))
      return 0;

    if (this.passCard.status === 2)
      return 4;

    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    const rest = (new Date(this.passCard.date2).getTime() - dt.getTime()) / (1000 * 60 * 60 * 24);
    if (rest < 0)
      return 3;
    else if (rest < LIMIT_DAYS)
      return 2;

    return 1;
  }

  //
  setCar = (i: number) => {
    this.reset();
    this.car = this.carList[i];
  }

  bCar = () => !!this.car.ticket;

  // mapCarList = (list: ICarInfo[]) => list ? list.map(info => ({
  //   inTrans: info.inTrans,
  //   src: info.filePath,
  //   np: info.numberPlateInfoList && !!info.numberPlateInfoList[3] ? info.numberPlateInfoList : null,
  //   ticket: info.parkingTicket,
  //   current: info.currentFlg,
  //   // isAddictive: info.isAddictive,
  // })) : [];

  // setCarList = (list: ICarInfo[], maxPage: number, total: number) => {
  //   this.reset();

  //   this.carList = this.mapCarList(list);
  //   this.maxPage = maxPage;
  //   this.total = total;
  //   this.saveStorage();
  // }

  // addCarList = (list: ICarInfo[], maxPage: number, total: number) => {
  //   this.carList = [...this.carList, ...this.mapCarList(list)];
  //   this.maxPage = maxPage;
  //   this.total = total;
  //   this.saveStorage();
  // }

  // setFee = (fee: number, list: Discount[]) => {
  setFee = (body: SettleRes) => {
    this.count++;
    // const changed = this.fee !== body.paymentFee;

    // this.parkingFee = body.parkingFee;
    // this.parkingTime = body.parkingTime;
    // this.fee = body.paymentFee;
    // this.type = body.discountDisplayType;
    // this.paidOut = body.paidOut;
    // this.deepLink = body.settlementUrl;
    // this.dealId = body.transactionId;
    // this.unPaidCarFlg = body.unPaidCarFlg;
    // this.dcbUnPaidOutDataExistFlg = body.dcbUnPaidOutDataExistFlg;
    // this.issuerName = body.issuerName;
    // this.registNumber = body.registNumber;
    // this.issuerAddress = body.issuerAddress;
    // this.issuerTel = body.issuerTel;
    // this.taxRate = body.taxRate;
    // this.inTrans = body.inTrans;

    // const pass = body.passcardInfo;
    // this.passCard = pass ? {
    //   no: pass.passcardNo,
    //   type: pass.passcardType,
    //   date1: pass.passcardValidFrom,
    //   date2: pass.passcardValidTo,
    //   status: pass.passcardStatus,
    //   name: pass.passcardTypeName
    // } : null;

    // const list = body.discountInfoList;
    // this.feeList = list && list.length ?
    //   list.map(d => ({
    //     title: this.getTitle(d.discountName, d.discountType, d.discountInfo),
    //     count: d.discountCount,
    //     amount: d.discountAmount,
    //     date: d.discountRegistDate,
    //     purchasePrice: d.purchasePrice,
    //     additionalCharge: d.additionalCharge,
    //     additionalDiscountAmount: d.additionalDiscountAmount,
    //     discountApplyFlg: d.discountApplyFlg,
    //     displayOrder: d.displayOrder,
    //     discountType: d.discountType,
    //     discountInfo: d.discountInfo,
    //     icon: this.getIcon(d.discountType, d.discountInfo),
    //     last: false
    //   }))
    //     .filter(fee => !!fee.icon)
    //     .sort((d1, d2) => d1.displayOrder - d2.displayOrder) : null;

    // // total, last
    // this.totalDiscount = 0;
    // if (this.feeList) {
    //   if (this.feeList.length) {
    //     let m = 0;
    //     let max = 0;

    //     this.feeList.forEach((f, i) => {
    //       if (f.discountApplyFlg)
    //         this.totalDiscount += f.amount;
    //       if (max < f.date) {
    //         m = i;
    //         max = f.date;
    //       }
    //     });

    //     this.feeList[m].last = true;
    //   }
    //   else
    //     this.feeList = null;
    // }

    this.saveStorage();

    // return changed;
  }

  // showDiscount = () => this._conf.canDiscount && (this.parkingFee || this.paidOut !== 99);

  // t: 1, 2, 3, 6, 50, 101, 102, 103, 106 割引種別
  // i: 0, 2, 3, 4, 5, 6, 7, 100, 101 店割引時の割引種類
  private getIcon = (t: number, i: number) => {
    enum ICO {
      SHOP = 'shopping_icon',
      BUILD = 'build_icon',
      CARD = 'card_icon',
      QR = 'qr_icon',
      FIRST = 'first_icon'
    };

    let icon = null as ICO;
    if (t === 2 || t === 102)
      icon = {
        0: ICO.QR, 2: ICO.BUILD, 3: ICO.CARD,
        4: ICO.FIRST, 5: ICO.FIRST, 6: ICO.FIRST, 7: ICO.FIRST, 100: ICO.FIRST, 101: ICO.FIRST
      }[i];
    else
      // icon = { 1: ICO.QR, 6: ICO.SHOP, 50: ICO.FIRST, 101: ICO.QR, 106: ICO.SHOP }[t];
      icon = { 1: ICO.QR, 3:ICO.QR, 6: ICO.SHOP, 50: ICO.FIRST, 101: ICO.QR, 103: ICO.QR, 106: ICO.SHOP }[t];

    if (!icon)
      console.log(t, i);

    return icon ? icon : null;
  }

  //  t: QRグループ判別(1: 買い上げ金額 2: サービス券) t:割引種別 i:店割引時の割引種類
  private getTitle = (title: string, t: number, i: number) => {
    if (title)
      return title;

    enum T1 {
      SHOP = 'お買物割引',
      BUILD = '施設割引',
      CARD = '会員カード割引',
      QR = 'QRサービス券',
      FIRST = '初回無料',
    };

    enum T2 {
      QR = 'QR割引券',
      BUILD = '施設割引',
      CARD = '会員カード割引',
      FIRST = '初回無料',
      CAR = '車種割引',
      LOC = '発券位置割引',
      AUTH = '認証機割引',
      FAIL = 'システム障害時割引',
      EXT = '出庫延長割引',
    };

    if (t === 2 || t === 102)
      title = {
        0: T2.QR, 2: T2.BUILD, 3: T2.CARD,
        4: T2.FIRST, 5: T2.CAR, 6: T2.LOC, 7: T2.AUTH, 100: T2.FAIL, 101: T2.EXT,
      }[i];
    else
      title = { 1: T1.QR, 3: T1.QR, 6: T1.SHOP, 50: T1.FIRST, 101: T1.QR, 103: T1.QR, 106: T1.SHOP }[t];

    return title ? title : null;
  }

  // //
  // query = (n: number) => {
  //   const qt = this.query_type;

  //   if (qt === 0)
  //     return this.query_last(this.np, n);
  //   else if (qt === 1)
  //     return this.query_np4(n);
  //   else if (qt === 2)
  //     return this.query_time(n);
  // }

  // query_last = (np: string[], n: number): LastCarReq => {
  //   this.query_type = 0;
  //   this.np = np;
  //   this.page = n;

  //   return {
  //     businessAreaCode: +this._conf.parking,
  //     numberPlateInfoList: np,
  //     pageNo: n
  //   };
  // }

  // query_np4 = (n: number): SearchAutoReq => {
  //   this.query_type = 1;
  //   this.page = n;

  //   return {
  //     businessAreaCode: +this._conf.parking,
  //     plateLicense: this.np4,
  //     pageNo: n
  //   };
  // }

  // query_time = (n: number): SearchInTransReq => {
  //   this.query_type = 2;
  //   this.page = n;

  //   const conf = this._conf;

  //   const d = this.date.split('-');
  //   const t = this.time.split(':');
  //   const dt = new Date(+d[0], +d[1] - 1, +d[2], +t[0], +t[1]).getTime();

  //   const time1 = dt - conf.timespan;
  //   const time2 = dt + conf.timespan + (59 * 1000);

  //   return {
  //     businessAreaCode: +conf.parking,
  //     dateTimeSearchRangeStart: time1,
  //     dateTimeSearchRangeEnd: time2,
  //     pageNo: n
  //   };
  // }

  //
  apply = () => {
    this.qrUrl = null;
    this.completed = true;
    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('pay', this);
  }

  loadStorage = () => {
    this._storage.load('pay', this);
  }

}
