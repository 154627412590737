import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplyStatus, NOSHOWORDER, weekDays } from '../../passcard.model';
import { PasscardContract } from '../../models/passcard-contract';
import { PasscardApply } from '../../models/apply-info';
import { PaymentType } from '../../pay/pay.model';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {
  private _passcardApply: PasscardApply;
  status2 = 0;
  ApplyStatus = ApplyStatus;
  statusClass = 'status-idle';
  statusText = '';
  tips = '';

  /**
   * １：定期更新後フラグ
   */
  @Input() extraFlag: number = 0;

  @Input() clickable = false;

  @Input()
  set reservation(val: PasscardApply) {
    this._passcardApply = val;

    if (this.setStatus()) return;

    switch (val?.status) {
      case ApplyStatus.WaitingFree:
        this.statusClass = 'status-queuing';
        this.statusText = '順番待ち';
        this.tips = '順番待ちです';
        break;
      case ApplyStatus.WaitingModify:
        this.statusClass = 'status-busy';
        this.statusText = '申請中';
        this.tips = '申請の修正が必要です';
        break;
      case ApplyStatus.WaitingReApply:
        this.statusClass = 'status-idle';
        this.statusText = '申請受付中';
        this.tips = '順番となりました';
        break;
      case ApplyStatus.WaitingPayAfterIssued:
        this.statusClass = 'status-busy';
        this.statusText = 'お手続き待ち';
        this.tips = 'お手続き待ち';
        break;
      case ApplyStatus.WaitingICRegist:
        this.statusClass = 'status-busy';
        this.statusText = 'お手続き待ち';
        this.tips = ApplyStatus[val.status].name;
        break;
      case ApplyStatus.WaitingPay:
        this.statusClass = 'status-busy';
        this.statusText = 'お手続き待ち';
        switch (this._passcardApply.passcardPaymentType) {
          case PaymentType.Terminal:
            this.tips = '定期更新機支払い待ち';
            break;
          case PaymentType.PayPay:
            this.tips = 'PayPay支払い待ち';
            break;
          case PaymentType.Cvs:
            this.tips = 'コンビニ支払い待ち';
            break;
        }
        break;
      default:
        this.statusClass = 'status-busy';
        this.statusText = '申請中';
        this.tips = '申請中';
        break;
    }

    switch (this._passcardApply.passcardAvailable) {
      case 1:
        switch (val.status) {
          case ApplyStatus.WaitingRefresh:
            this.status2 = 1;
          default:
            this.statusText = '利用可';
            this.statusClass = 'status-idle';
            break;
        }
        break;
      case 2:
      case 3:
        this.status2 = 4;
        this.statusText = this._passcardApply.passcardAvailable == 2 ? '解約済み' : '期限切れ';
        this.statusClass = 'status-disabled2';
        break;
    }
  }
  
  @Output() cardClick = new EventEmitter();

  get PasscardAvailable() {
    return this.reservation?.status >= 30 || this._passcardApply.passcardAvailable >= 1;
  }

  get reservation(): PasscardApply{
    return this._passcardApply;
  }

  get order() {
    return this.reservation?.currentNum ?? NOSHOWORDER;
  }

  get contract(): PasscardContract {
    return this.reservation?.status >= 30 || this.reservation.passcardAvailable >= 1 ? this.reservation : null;
  }

  get TermYear() {
    if (!this.contract?.passcardValidTo) return '';

    const term = new Date(this.contract.passcardValidTo);
    return term.getFullYear();
  }

  get TermMMDD() {
    if (!this.contract?.passcardValidTo) return '';

    const term = new Date(this.contract.passcardValidTo);
    return `${term.getMonth() + 1}月${term.getDate()}日`
  }

  get TermWeekDay() {
    if (!this.contract?.passcardValidTo) return '';

    const term = new Date(this.contract.passcardValidTo);
    return `${weekDays[term.getDay()]}`
  }

  isShowOrder() {
    return this.order != NOSHOWORDER;
  }

  onCardClick() {
    if (this.clickable) {
      this.cardClick.emit();
    }
  }

  private setStatus(): boolean {
    if (this.extraFlag == 1) {
      this.statusText = '更新後定期';
      this.statusClass = 'status-disabled';
      this.status2 = 3;
      return true;
    }

    const { status,
      passcardPaymentType,
      systemDate, passcardUpdateFrom, passcardUpdateTo, passcardAutoCancelDelayTerm,
      passcardValidFrom, passcardValidTo } = this._passcardApply;

    if (status == ApplyStatus.WaitingPay && passcardPaymentType == PaymentType.UnSelect) {
      this.statusClass = 'status-idle';
      this.statusText = '申請受付中';
      this.tips = '申請待ち';
      return true;
    }

    if (!this.PasscardAvailable) return false;
    
    const sysDate = new Date(systemDate);
    const validFrom = new Date(passcardValidFrom);
    const validTo = new Date(passcardValidTo);
    const updateFrom = new Date(passcardUpdateFrom);
    const udateTo = new Date(passcardUpdateTo);
    sysDate.setHours(0, 0, 0, 0);
    validFrom.setHours(0, 0, 0, 0);
    validTo.setHours(0, 0, 0, 0);
    updateFrom.setHours(0, 0, 0, 0);
    udateTo.setHours(0, 0, 0, 0);
    this.status2 = sysDate >= updateFrom && sysDate <= udateTo ? 2 : 3;
    if (sysDate < validFrom) {
      this.statusText = '定期期間外';
      this.statusClass = 'status-queuing';
    }
    else if (sysDate <= validTo) {
      this.statusText = '利用可';
      this.statusClass = 'status-idle';
    }
    else {
      this.statusText = '期限切れ'
      this.statusClass = 'status-error';
    }

    return true;
  }
}
