<!-- <div>PayPay内部の処理を待っています</div>
<div>画面を開いたままお待ちください</div> -->
<!-- <div>{{query}}</div> -->
<div class="container">
  <div class="main-wrap">
    <div class="main-contents">
      <div class="text-message text-complete">PayPay内部の処理を待っています。<br>画面を開いたまましばらくお待ちください。</div>
    </div>
  </div>
</div>
