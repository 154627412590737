<block title="宛名設定">
    <div class="receipt-note">
        <p>利用明細書の宛名を設定します。一度設定した宛名変更できません。</p>
        <p>宛名を変更する場合は「変更」を押してください。</p>
        <p>表示している内容でよろしければ「利用明細書表示」を押してください。</p>
        <p>※一度設定した宛名は変更できません。宛名が未定の場合は「戻る」を押し、今は利用明細書を表示しないようにしてください。</p>
    </div>
    <br>
    <div class="tableCtn tableCtn-confirm">
        <p-single label="宛名" [value]="name + '　様'" />
    </div>
    <br>
    <div class="buttonBoxVertical">
        <button type="button" class="cmn-btn mr15 bck-btn" (click)="onBack()">戻る</button>
        <loading-button label="利用明細表示"
            (apply)="onIssue()"
            [disabled]="api.Disabled"
            [loading]="api.Disabled"
            [class]="'cmn-btn mr15'"
            [lClass]="'button-box-loading'" />
        <button type="button" class="cmn-btn mr15" (click)="onChange()">変更</button>
    </div>
</block>