import { Observable, Subject, } from 'rxjs';
import { ApiService } from '.';
import { Injectable } from '@angular/core';
import { HttpResponse, } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { StorageService } from './storage.service';

// お知らせ情報取得時リクエスト
export interface NoticeInfoRequest {
    displayPage: number;        // 呼び出し元画面(1: マイページ,2: TOPページ（定期情報画面）)
    siteNoticeInfoNo: number;   // サイトお知らせ番号
}
// お知らせ情報取得時レスポンス
export interface NoticeInfoResponse {
    resultCode: number;             // 処理結果
    noticeInfoList: NoticeInfo[];   // お知らせ情報
}
// お知らせ情報
export interface NoticeInfo {
    siteNoticeStart?: number;   // サイト掲載開始日
    title: string;              // お知らせタイトル
    messageList?: string[];    // お知らせメッセージリスト
    linkTitle: String;          // リンクタイトル
    urlLink: String;            // URLリンク
}

@Injectable({
    providedIn: 'root'
})
export class NoticeInfoService {

    // 緊急のお知らせ表示最大件数
    readonly NOTICE_MAX_COUNT = 5;

    // 選択されたお知らせ保存用ローカルストレージキー
    public static LOCAL_STORAGE_KEY_NOTICEINFO = 'selectNoticeInformation';
    // 緊急のお知らせ保存用ローカルストレージキー
    public static LOCAL_STORAGE_KEY_EMERGENCY_NOTICEINFO = 'emergencyNoticeInformation';
    // 通常のお知らせ保存用ローカルストレージキー
    public static LOCAL_STORAGE_KEY_NORMAL_NOTICEINFO = 'normalNoticeInformation';

    constructor(
        private api: ApiService,
        private strageService: StorageService,
    ) {
    }


    /**
     * お知らせ情報を取得する
     * @param displayPage 
     * @param siteNoticeInfoNo 
     */
    getNoticeInformation(): Observable<void> {
        // セッションの緊急のお知らせを削除
        this.strageService.remove(NoticeInfoService.LOCAL_STORAGE_KEY_EMERGENCY_NOTICEINFO);
        // 緊急のお知らせを取得
        const httpresponse$: Subject<HttpResponse<NoticeInfoResponse>> =
            this.api.post<NoticeInfoResponse>('/noticeInfo/list', null, 1);
        return httpresponse$.pipe(map(response => {
            if (response.body && response.body.resultCode === 0) {
                // 正常終了
                if (response.body.noticeInfoList && response.body.noticeInfoList.length > 0) {
                    // セッションに保存
                    this.strageService.save(NoticeInfoService.LOCAL_STORAGE_KEY_EMERGENCY_NOTICEINFO, response.body);
                }
            }
        }));
    }

    /**
     * 詳細画面のURLを返す
     * @returns 詳細画面のURL
     */
    getNoticeDetailPath(): string {
        let url = window.location.href;
        // 2個前のスラッシュで切る
        url = url.substring(0, url.lastIndexOf('/'));
        url = url.substring(0, url.lastIndexOf('/'));
        url = url + '/notice-info/detail';
        return url;
    }
}