<h2>お支払い詳細</h2>
<block title="お支払い情報1">
  <p-pay-fees [paidDetail]="feeDetail" totalLbl="合計"></p-pay-fees>
  <br>
  <div class="tableCtn">
    <p-single label="お支払い日時" [value]="paidDetail.paymentDate | date: 'yyyy/MM/dd HH:mm'" />
    <p-single *ngFor="let paymethod of paymentMethodsDetails"
      [label]="paymethod.contentsName" [value]="paymethod.contents" />
  </div>
</block>
<ng-container>
  <div class="buttonBoxVertical">
    <loading-button class="six" label="ご利用明細発行"
      (apply)="onReceiptIssue()"
      [disabled]="api.Disabled"
      [loading]="api.Disabled"
      [class]="'cmn-btn six'"
      [lClass]="'button-box-loading'" />
  </div>
  <br>
</ng-container>
<block title="お支払い情報2">
  <div class="tableCtn">
    <p-single label="お支払い内容" [value]="PCN[paidDetail.paymentContentsCode]" />
    <p-single label="駐車場" [value]="paidDetail.parkingAreaName" />
    <p-single label="受付No." [value]="paidDetail.receiptNo" />
  </div>
</block>
<div class="buttonBoxVertical">
  <button type="button" class="cmn-btn bck-btn six" (click)="onBack()">戻る</button>
</div>
