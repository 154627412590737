import { Component, OnInit, inject } from '@angular/core';
import { NavigateService } from 'src/app/services';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { PasscardApply } from '../models/apply-info';

@Component({
  selector: 'past-passcards',
  templateUrl: './past-passcards.component.html'
})
export class PastPasscardsComponent implements OnInit {
  initialized = false;
  private _navi = inject(NavigateService);
  api = inject(PasscardApiProxy);

  conditionsPeriod: number;
  conditionsPeriods: {code: number, name: string}[] = [];
  reservations: PasscardApply[] = [];

  ngOnInit(): void {
    const nowYear = new Date().getFullYear();
    this.conditionsPeriod = nowYear;
    for (let i = 0; i < 4; ++i) {
      this.conditionsPeriods.push({ code: nowYear - i, name: `${nowYear - i}年` });
    }

    this.api.getPasscardPastInfo()
      .then(x => {
        this.reservations = x
        this.initialized = true;
      });
  }

  /**
   *　カードクリック
   * @param reservation 申請情報
   */
  onCardClick(reservation: PasscardApply) {
      this._navi.navigate(`/passcard/contract`, false, { contract: reservation });
  }

  onChangePeriod() {
    this.api.getPasscardPastInfo(this.conditionsPeriod)
      .then(x => {
        this.reservations = x
      });
  }
}

