<ng-container *ngIf="reservation">
  <p-info kind="danger">
    <p>以下の申請内容を取り消します。</p>
    <p>内容を確認し、「取消」を押してください。</p>
  </p-info>
  <h2>申請取消</h2>
  <apply-info [reservation]="reservation"></apply-info>
  <ng-container *ngIf="!IsComplete">
    <section class="checkWrapper">
      <label [ngClass]="{'checkBoxArea':isConfirmed,'checkBoxAreaNot':!isConfirmed}">
        <input [(ngModel)]="isConfirmed" type="checkbox" class="unsubscribeConfirm_check">
        <div class="checkboxMeg">取消内容について確認しました</div>
      </label>
    </section>
    <br>
    <div *ngIf="!IsComplete" class="buttonBoxVertical">
      <button type="button" (click)="onBack()" class="cmn-btn mr15 bck-btn">戻る</button>
      <loading-button label="取消"
        (apply)="onCancel()"
        [disabled]="!isConfirmed ||api.Disabled "
        [loading]="api.Disabled"
        [lClass]="'button-box-loading'"
        [class]="'cmn-btn cncl-btn'" />
    </div>
    <br>
  </ng-container>
</ng-container>