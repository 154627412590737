<div class="tableCtn">
  <div class="tableLine">
    <div class="tableCell_l">お支払い先コンビニ</div>
    <div class="tableCell_r">
      <div class="tdCtn"></div>
    </div>
  </div>
  <p-single label="払込番号" [value]="model.section" />
  <div class="tableLine">
    <div class="tableCell_l">払込票</div>
    <div class="tableCell_r">
      <div class="tdCtn"><a href="#">コチラからご確認ください</a></div>
    </div>
  </div>
  <p-single label="お支払い期限" [value]="LastPayTime" />
  <div class="tableLine">
    <div class="tableCell_l">ご請求額</div>
    <div class="tableCell_r">
      <div class="tdCtn">{{payRequest.payDetail.paymentAmount | currency:'JPY'}}</div>
    </div>
  </div>
</div>
<div>
  <p><a href="">※お支払い方法の詳細はコチラ</a></p>
  <br>
  <div class="comment-list">
    <div>お支払い期限を過ぎますと、定期券の申請は自動的に削除されます</div>
    <div>コンビニ支払いが完了したタイミングでお支払いが確定します</div>
    <div>すぐにデータは反映されない旨</div>
  </div>
</div>
<br>
<pay-request [model]="payRequest"></pay-request>