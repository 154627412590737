<div class="infoText">
  <div class="infoLine">
    <div>
      <img class="info-icon" src="./assets/img/info.svg" alt="">
    </div>
    <div class="meg" *ngIf="value1 || value2">
      {{value1}}<br>
      {{value2}}<br>
    </div>
    <ng-content></ng-content>
  </div>
</div>