<p-info>
  <p>駐輪場にて以下のQRコード、認証番号を定期更新機に入力し、お支払いを進めてください。</p>
</p-info>
<h2>定期更新機支払い情報</h2>
<div class="tableCtn">
  <div class="tableLine">
    <div class="tableCell_l">ご請求額</div>
    <div class="tableCell_r">
      <div class="tdCtn">{{payRequest.payDetail.paymentAmount| currency:'JPY'}}</div>
    </div>
  </div>
  <p-single label="お支払い期限" [value]="paymentTerm" />
</div>
<br>
<p><a href="{{paymentInfo.paymentTerminalDocumentUrl}}" target="pay-2" class="passcard-link">※お支払い方法の詳細はコチラ</a></p>
<br>  
<div class="comment-list">
  <div>お支払い期限を過ぎますと、定期券の申請は自動的に削除されます</div>
</div>
<br>
<div class="qrcode-cert">
  <div>
    <div><h3 class="lable-with-help">QRコード<i class="icon help" (click)="helpQr = !helpQr"></i></h3></div>
    <div *ngIf="helpQr" class="qr-help">
      <p>こちらのQRコードを定期更新機に読み込ませてICカード登録が行えます。</p>
    </div>
    <div class="qrcode"></div>
  </div>
  <div>
    <div><h3 class="lable-with-help">認証番号<i class="icon help" (click)="helpAuth = !helpAuth"></i></h3></div>
    <div *ngIf="helpAuth" class="qr-help">
      <p>QRコードがご利用できない場合は、認証番号を使用してICカード登録が行えます。</p>
    </div>
    <div><span><b>{{authKey}}</b></span></div>
  </div>
</div>
<br>
<pay-request [model]="payRequest"></pay-request>
<br>
<div class="buttonBoxVertical">
  <loading-button label="定期券情報に戻る"
    (apply)="onBack()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn bck-btn six'" />
</div>
