<block title="現在の定期情報" *ngIf="wiz.passcard">
  <div class="card-container">
    <card *ngIf="contract" [reservation]="contract"></card>
  </div>
  <div *ngIf="passChangeEnabled">
    <div class="buttonBoxVertical" *ngIf="wiz.status != 11">
      <loading-button label="定期の種類を変更する"
        (apply)="onChangePasscard()"
        [disabled]="btnChangePasscardDisabled || api.Disabled"
        [loading]="api.Disabled"
        [class]="'cmn-btn bck-btn'"
        [lClass]="'button-box-loading'" />
    </div>
  </div>
</block>
<block title="更新内容" *ngIf="wiz.status == 11 else input;">
  <div class="tableCtn">
    <p-single label="更新月数" [value]="monthNumDisp" />
    <p-single label="更新後の定期期間" [value]="fixedPeriod" />
    <p-single label="更新後の更新可能期間" [value]="updatablePeriod" />
  </div>
</block>
<ng-template #input>
  <block title="更新月数" *ngIf="wiz.passcard">
    <div class="passcard-list">
      <div class="passcard passcard-normal" *ngFor="let item of paymentDivisionList; let i = index"
        [ngClass]="{ 'passcard-selected': paymentDivision && item.monthNum == paymentDivision.monthNum}"
        (click)="onSetPasscardMonth(item)">
        <div class="input">
          <div class="radio-wrapper"><input type="radio" name="passcardMonth"
            [checked]="paymentDivision && item.monthNum == paymentDivision.monthNum"
              value="1" id="passcardMonth{{i}}" /></div>
          <div><label class="radio-caption" for="passcardMonth{{i}}">
              {{item.monthNum}}ヵ月　{{item.unitPrice | currency:'JPY'}}
            </label></div>
        </div>
      </div>
    </div>
  </block>
  <br>

  <block title="定期期間" *ngIf="wiz.passcard">
    <div class="tableCtn">
      <p-single label="定期期間" [value]="fixedPeriod" />
      <p-single label="更新可能期間" [value]="updatablePeriod" />
    </div>
  </block>
  <br>
  <block  title="証明書等登録" *ngIf="showCertificateUpload">
    <p-info>
      <p>定期期間終了日が次回証明書等アップロード確認月を超過するため、証明書等の再提出が必要です。</p>
      <p>※証明書等の登録については<a href="{{applyParams.certificateURL}}" class="passcard-link" target="certificate-ref">コチラ</a>をご確認ください。</p>
    </p-info>  
    <i-my-files [certificateNextCheckDate]="certificateNextCheckDate" [certificateTypeList]="certificateTypeList" [model]="myFilesModel" [message]="imageCheckResult"></i-my-files>
  </block>
</ng-template>
<div *ngIf="!wiz.complete" class="buttonBox">
  <loading-button class="six" label="次へ"
    (apply)="onNext()"
    [disabled]="NextDisabled"
    [loading]="api.Disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn six'" />
</div>