import { ChangeDetectorRef, Component, ViewChild, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { PasscardApplyApi, PaymentDivision, PeriodCalcParams } from 'src/app/passcard/models/passcard';
import { addDay, calcUpdatablePeriod, formatPeriod, getInputImageUploadModel, getPasscardValidEndDay, getStorageKey } from 'src/app/passcard/passcard.model';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { RefreshComponent } from '../../refresh.component';
import { CurrencyPipe } from '@angular/common';
import { CertificateType } from 'src/app/shared/certificate-file';
import { IMyFilesComponent, MyFilesModel, EmptyImageUpload } from 'src/app/shared/i-my-files/i-my-files.component';
import { PasscardChangeService } from 'src/app/passcard/passcard-change/passcard-change.service';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';
import {
  CertificateImageService,
  DownloadTemporaryImageFileRequest,
  DownloadTemporaryImageFileResponse,
} from '../../../../services/certificateImage.service';
import { CertificateImage } from '../../../../shared/certificate-file';
import { ReceptionMode } from 'src/app/passcard/passcard.model';

@Component({
  selector: 'refresh01',
  templateUrl: './refresh01.component.html',
  providers: [CurrencyPipe]
})
export class Refresh01Component {
  private readonly _route = inject(ActivatedRoute);
  private readonly _storage = inject(StorageService);
  private readonly _currencyPipe = inject(CurrencyPipe);
  private _cdRef = inject(ChangeDetectorRef);
  readonly wiz = inject(RefreshComponent);

  paymentDivisionList: PaymentDivision[];
  validPeriod: number[];
  validUpdatablePeriod: number [];
  applyParams: PasscardApplyApi;
  fixedPeriod: string = '';
  updatablePeriod = '';
  paymentDivision: PaymentDivision | null = null;

  monthNumDisp: string;
  btnChangePasscardDisabled = false;
  showCertificateUpload = false;
  certificateNextCheckDate: number;
  certificateTypeList: CertificateType[];
  myFilesModel: MyFilesModel = {
    certificateType: undefined,
    year: undefined,
    month: undefined,
    images: []
  };
  imageDownloadFinish: Subject<number> = new Subject<number>();

  // 定期種別変更抑止（機能解放時に削除）
  passChangeEnabled = false;

  @ViewChild(IMyFilesComponent) fileUploader: IMyFilesComponent;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private apiService: ApiService,
    private certificateImageService: CertificateImageService
  ) {
  }

  get NextDisabled() {
    const certificateUploadError = this.fileUploader?.error ?? false;
    return this.api.error || this.api.Disabled || !this.paymentDivision || certificateUploadError;
  }

  ngOnInit(): void {
    this._route.data.pipe(map(({ data }) => data)).subscribe(x => {
      this.applyParams = x;
      if (!this.wiz.passcard) return;

      this.paymentDivisionList = this.wiz.passcard.paymentDivisionList ?? [];
      const { status, dcParkingNo, passcardApply } = this.wiz;
      this.btnChangePasscardDisabled = status != 11 && (this.applyParams.passcardTypeList?.length ?? 0) <= 1;
      this.certificateNextCheckDate = passcardApply.certificateNextCheckDate;
      this._storage.load(getStorageKey(`refresh-step-1`, status, dcParkingNo), this)

      const { passcardValidFrom, passcardValidTo } = passcardApply;
      const monthNum = status == 11 ? this.applyParams.selectedMonthNum : this.paymentDivision?.monthNum;
      if (monthNum) {
        this.paymentDivision ??= this.paymentDivisionList.find(x => x.monthNum == monthNum);
        const price = this.paymentDivision.unitPrice;
        this.monthNumDisp = `${monthNum}ヵ月(${this._currencyPipe.transform(price, 'JPY')})`;
        const [fixedPeriod, changePeriod] = this.calcPeriod(monthNum, passcardValidFrom, passcardValidTo, x);
        if (fixedPeriod) {
          this.validPeriod = fixedPeriod;
          this.fixedPeriod = formatPeriod(fixedPeriod);
          this.updatablePeriod = formatPeriod(changePeriod);
          this.validUpdatablePeriod = changePeriod;
        }
      }

      if (this.wiz.passcard.certificateTypeNoList?.length) {
        this.certificateTypeList = getInputImageUploadModel(this.wiz.passcard.certificateTypeNoList, this.applyParams.certificateTypeList)
        if (this.myFilesModel?.certificateType == undefined) {
          setTimeout(() => this.myFilesModel = {
            certificateType: this.certificateTypeList[0]?.certificateType,
            year: undefined,
            month: undefined,
            images: []
          }, 0);
        }

        this.checkCertificateExpire();
      }

      // 画像一時ファイルがある場合は取得
      if (this.myFilesModel && this.myFilesModel.images.length > 0) {
        // ダウンロード終了監視用データ作成
        const imageList: { imageNum: number; finish: boolean }[] = [];
        this.myFilesModel.images.forEach((image) => {
          imageList.push({ imageNum: image.certificateImageNum, finish: false });
        });

        // 画像ダウンロード終了監視
        const subscripttion = this.imageDownloadFinish.subscribe((imageNumber) => {
          if (imageNumber === -1) {
            // エラー発生
            this.apiService.loadingOff();
            subscripttion.unsubscribe();
          } else {
            // 終わったものを終了に
            const image = imageList.find((image) => image.imageNum === imageNumber);
            if (image) {
              // 完了を設定
              image.finish = true;
            }

            // 全部終わったか(未完了のものがないか)
            if (!imageList.some((image) => image.finish === false)) {
              this.apiService.loadingOff();
              subscripttion.unsubscribe();
            }
          }
        });

        // 画像ダウンロード実施
        this.apiService.loadingOn();
        this.downLoadImage(this.myFilesModel.images);
      }
    });
  }

  /**
 * 画像データをダウンロードする
 * @param certicateImages ダウンロードするイメージデータ
 */
  private downLoadImage(certicateImages: CertificateImage[]) {
    certicateImages.forEach((certicateImage) => {
      if (certicateImage.fileSize > 0) {
        // 画像がアップロードされている場合
        const request: DownloadTemporaryImageFileRequest = {
          certificateImageNum: certicateImage.certificateImageNum,
          fileName: certicateImage.fileName,
          objectName: certicateImage.objectName,
        };
        // アップロード済みの画像データがある場合はダウンロードする
        this.certificateImageService.downLoadTemporaryImageFile(request).subscribe(
          (response: DownloadTemporaryImageFileResponse) => {
            if (response.resultCode === 0) {
              // 正常終了
              certicateImage.certificateImageData = CertificateImageService.IMAGE_HEADER + response.certificateImageData;
              this.imageDownloadFinish.next(certicateImage.certificateImageNum);
            } else {
              // 異常終了
              this.imageDownloadFinish.next(-1);
            }
          });
      } else {
        // 画像がアップロードされていない場合
        this.imageDownloadFinish.next(certicateImage.certificateImageNum);
      }
    });
  }

  get contract() {
    return this.wiz.passcardApply;
  }

  onSetPasscardMonth(item: PaymentDivision) {
    if (this.paymentDivision?.monthNum != item.monthNum) {
      this.paymentDivision = item;
      const { passcardValidFrom, passcardValidTo } = this.wiz.passcardApply;
      const [fixedPeriod, changePeriod] = this.calcPeriod(item.monthNum, passcardValidFrom, passcardValidTo, this.applyParams);

      this.validPeriod = fixedPeriod;
      this.fixedPeriod = formatPeriod(fixedPeriod);
      this.validUpdatablePeriod = changePeriod;
      this.updatablePeriod = formatPeriod(changePeriod);
      this.checkCertificateExpire();
      if (this.showCertificateUpload) {
        setTimeout(() => this.myFilesModel = {
          ...EmptyImageUpload,
          images: [],
          certificateType: this.certificateTypeList[0].certificateType
        }, 0);
      }

      this._cdRef.detectChanges();
    }
  }

  async onNext() {
    const { unitPrice, monthNum } = this.paymentDivision;
    if (this.wiz.passcard.receptionMode === ReceptionMode.Manual && (this.wiz.status === 6 || this.wiz.status === 10)) {
      // 手動承認の場合
      if (this.showCertificateUpload) {
        // 証明書が必要な場合は手動承認扱い
        this.wiz.status = 10;
      } else {
        // 証明書が必要ない場合は、自動承認と同じ扱い
        this.wiz.status = 6;
      }
    }
    this.saveModel();
    if (this.wiz.status == 10) {
      // 手動承認で証明書が必要な場合は確認画面へ遷移
      this._navi.navigateByUrl('/passcard/refresh/step/3');
      return;
    }

    const { paymentTerm, terminalPaymentTerm } = await this.api.getPaymentTerm(this.wiz.status, monthNum);
    const {
      paymentMethodPaidMachineQR,
      paymentMethodCreditCard,
      paymentMethodPayPay,
      paymentMethodConvenienceStore,
    } = this.applyParams;
    this._storage.save('payment-params', {
      paymentTerm, terminalPaymentTerm,
      paymentMethodPaidMachineQR, paymentMethodCreditCard, paymentMethodPayPay, paymentMethodConvenienceStore,
      commission: unitPrice
    });

    this._navi.navigateByUrl('/passcard/refresh/step/2');
  }

  onChangePasscard() {
    PasscardChangeService.clear(this._storage, this.wiz.passcardApply.dcParkingNo, 6);
    const oldPasscardType = this.wiz.passcard.passcardType;
    const applyParams = { ...this.applyParams, passcardTypeList: this.applyParams.passcardTypeList.filter(x => x.passcardType != oldPasscardType) };
    this._navi.navigate('/passcard/change', false, {
      passcardApply: this.wiz.passcardApply,
      applyParams
    });
  }

  private calcPeriod(passcardMonth: number,
    passcardValidFrom: number,
    passcardValidTo: number,
    params: PeriodCalcParams): number[][] {
    if (passcardMonth < 1) return [];
    const ret = [];
    const end = getPasscardValidEndDay(new Date(addDay(new Date(passcardValidTo), 1)), passcardMonth);
    ret[0] = [passcardValidFrom, end];
    const { passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay } = params;
    ret[1] = calcUpdatablePeriod(new Date(end), passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay);
    return ret;
  }

  private checkCertificateExpire() {
    if (!this.wiz.passcard.certificateTypeNoList?.length || !this.paymentDivision) return;

    this.showCertificateUpload = this.applyParams.certificateNextCheckDate && this.applyParams.certificateNextCheckDate <= this.validPeriod[1];
    this.wiz.saveState();
  }

  saveModel() {
    const { paymentDivision } = this;
    const { dcParkingNo } = this.wiz.passcardApply;

    const myFilesModel: any = {};
    if (this.wiz.status != 11 && this.showCertificateUpload) {
      myFilesModel.myFilesModel = this.myFilesModel;
      myFilesModel.certificateTypeSetting = this.certificateTypeList.find(x => x.certificateType == this.myFilesModel.certificateType);
      // 画像の実データはクリア(容量の問題)
      if (myFilesModel.myFilesModel && myFilesModel.myFilesModel.images) {
        myFilesModel.myFilesModel.images.forEach((image) => {
          image.certificateImageData = '';
        });
      }
    }

    this._storage.save(getStorageKey(`refresh-step-1`, this.wiz.status, dcParkingNo), {
      paymentDivision,
      validPeriod: this.validPeriod,
      updatablePeriod: this.updatablePeriod,
      validUpdatablePeriod: this.validUpdatablePeriod,
      ...myFilesModel
    });
  }
}
