import { Component, Input } from '@angular/core';
import { PasscardContract } from '../../models/passcard-contract';
import { AppModel } from 'src/app/app.model';

/**
 * 定期契約情報
 */
@Component({
  selector: 'contract-detail',
  templateUrl: './contract-detail.component.html'
})
export class ContractDetailComponent {
  @Input() contract: PasscardContract | null = null;
  constructor(public app: AppModel) {
  }
}
