<h2>証明書等再提出</h2>
<t-error [message]="error"></t-error>
<p-info>
  <p>定期更新機で定期更新を行う方で、証明書等のアップロードを行う必要がある場合、こちらのページからアップロードしてください。</p>
  <p>※アップロードする証明書等の詳細については<a href="{{certificateURL}}" class="passcard-link" target="certificate-ref">コチラ</a>をご確認ください。
  </p>
</p-info>

<i-my-files
  [certificateNextCheckDate]="certificateNextCheckDate"
  [certificateTypeList]="certificateTypeList"
  [model]="myFilesModel"
  [message]="imageCheckResult"></i-my-files>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="onBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button (apply)="onApply()" [disabled]="!myFiles || myFiles.error" [loading]="api.disabled" [label]="'確認'"
    [class]="'cmn-btn'" [lClass]="'button-box-loading'">
  </loading-button>
</div>