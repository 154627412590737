import { Component, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { PayFeeDetail } from 'src/app/passcard/common/p-pay-fees/p-pay-fees.component';
import { ApplyStatus, formatPeriod, getStorageKey } from 'src/app/passcard/passcard.model';
import { PasscardContract } from 'src/app/passcard/models/passcard-contract';
import { PaymentType } from 'src/app/passcard/pay/pay.model';
import { ParkingInfo } from 'src/app/passcard/models/apply-info';
import { RefreshComponent } from '../../refresh.component';
import { getApplyPaymentItems } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';

@Component({
  selector: 'refresh03',
  templateUrl: './refresh03.component.html'
})
export class Refresh03Component {
  private readonly _wiz = inject(RefreshComponent);
  private readonly _storage = inject(StorageService);
  private _cach01: any;
  private _cach02: any;
  private _cach03: any;

  paidDetail: PayFeeDetail;
  contract: PasscardContract;
  passcardMonth: number;
  paymentAmount: number;
  fixedPeriod = '';
  updatablePeriod = '';
  parking: ParkingInfo;
  passcardCancelDocumentUrl = '';

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService) {
    this._wiz.processing = 2;
    this._wiz.saveState();
    this.passcardCancelDocumentUrl = this.Cach01.passcardCancelDocumentUrl;
    const { parkingName, parkingAddress } = this._wiz.passcardApply;
    this.parking = {
      dcParkingNo: 0,
      parkingName: parkingName,
      parkingAddress: parkingAddress
    };

    this.contract = { ...this._wiz.passcardApply };
    this.contract.status = ApplyStatus.AfterRefreshed;
    const { monthNum, unitPrice } = this.Cach01.paymentDivision;
    this.passcardMonth = monthNum;
    this.paymentAmount = unitPrice;
    this.fixedPeriod = formatPeriod(this.Cach01.validPeriod);
    this.updatablePeriod = this.Cach01.updatablePeriod;
    this.contract.passcardValidTo = this.Cach01.validPeriod[1];

    this.paidDetail = {
      paymentFeeDetails: [
        { contents: '定期代', Fee: this.paymentAmount }
      ],
      paymentAmount: this.paymentAmount
    };

    if (this.payInfo.method == PaymentType.Cvs) {
      this.paidDetail.paymentFeeDetails.push({ contents: 'コンビニ支払い手数料', Fee: this.payInfo.cvsPay.payCommission });
      this.paidDetail.paymentAmount += this.payInfo.cvsPay.payCommission;
    }
  }

  get IsComplete() {
    return this._wiz.complete;
  }

  get dcParkingNo(): number {
    return this._wiz.passcardApply.dcParkingNo;
  }

  get Cach01() {
    const key = getStorageKey(`refresh-step-1`, 6, this.dcParkingNo);
    this._cach01 ??= this._storage.load(key, null);
    return this._cach01;
  }

  get Cach02() {
    const key = getStorageKey('payment-method', 6, this.dcParkingNo);
    this._cach02 ??= this._storage.load(key, null);
    return this._cach02;
  }

  get Cach03() {
    const key = getStorageKey('refresh-step-3', 6, this.dcParkingNo);
    this._cach03 ??= this._storage.load(key, null);
    return this._cach03;
  }

  get payInfo() {
    return this.Cach02;
  }

  onReInput(kb: number) {
    this._navi.navigateByUrl(`/passcard/refresh/step/${kb}`);
  }

  /**
 * 申請を行う
 */
  async onApply() {
    const applyInfo: any = {};
    Object.assign(applyInfo, getApplyPaymentItems(this._storage, 6, this.dcParkingNo));
    const { paymentTerm, terminalPaymentTerm } = this.payInfo;
    switch (this.payInfo.method) {
      case PaymentType.Terminal:
        applyInfo.terminalPaymentTerm = terminalPaymentTerm;
        break;
      case PaymentType.PayPay:
      case PaymentType.Cvs:
          applyInfo.paymentTerm = paymentTerm;
        break;
    }

    const { paymentDivision, receptionMode } = this.Cach01;
    const [passcardValidFrom, passcardValidTo] = this.Cach01.validPeriod;
    applyInfo.passcardValidFrom = passcardValidFrom;
    applyInfo.passcardValidTo = passcardValidTo;
    applyInfo.paymentAmount = paymentDivision.unitPrice;

    const { receiptNo, passcardApplyNo } = await this.api.passcardApply({
      status: 6,
      passcardType: this._wiz.passcardApply.passcardType,
      passcardApplyNo: null,
      dcParkingNo: this.dcParkingNo,
      passcardMonth: paymentDivision.monthNum,
      receptionMode,
      passPaymentDivision: paymentDivision.passPaymentDivision,
      ...applyInfo,
    });
    if (receiptNo) {
      this._wiz.maxProcessed = 2;
      this._wiz.complete = true;
      this._wiz.passcardApplyNo = passcardApplyNo;
      this._wiz.saveState();

      let message2 = '';
      let message3 = ''
      switch (this.payInfo.method) {
        case PaymentType.PayPay:
          message2 = '続けてPayPay支払いのお手続きをお願い致します。';
          break;
        case PaymentType.Cvs:
          message2 = '続けてコンビニで支払いのお手続きをお願い致します。';
          break;
      }
      this._navi.navigate(`/passcard/refresh/step/4`, true, {
        message1: '定期更新申請が完了しました。',
        message2,
        message3,
        receiptNo
      });
    }
  }
}
