<div class="applyinfo-cap">
  <h3>申請情報</h3>
  <div class="apply-status">
    <div class="parking-status"
    [ngClass]="getStatusClass(passcardApplyResult)"
    >{{passcardApplyResult}}</div>
  </div>
</div>
<div class="tableCtn">
  <p-single label="現在の状態" value="{{applyState}}" />
  <p-single label="申請内容" value="{{applyContent}}" />
  <p-single label="申請日時" value="{{detail.passcardApplyDate | datetime }}" />
  <p-single label="駐車場" value="{{parkingAreaName}}" />
  <p-single [label]="passcardNameCaption" value="{{detail.passcardTypeName}}" />
  <div class="tableLine" *ngFor="let item of appliedDetails">
    <div class="tableCell_l">{{item.caption}}</div>
    <div class="tableCell_r">
      <div class="tdCtn">
        <p>{{item.dispStr}}</p>
        <p *ngIf="item.dispStr2">{{item.dispStr2}}</p>
      </div>
    </div>
  </div>
</div>
