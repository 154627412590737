<section>
  <div class="tableCtn">
    <p-single label="申請済みの証明書等有効期限" *ngIf="certificateNextCheckDate" [value]="certificateNextCheckDate | date: 'yyyy年MM月まで'" />
    <div class="tableLine">
      <div class="tableCell_l">証明書等</div>
      <div class="tableCell_r my-confirm-files">
        <div>{{ certificateType.certificateTypeName }}</div>
        <div class="upload-block" *ngFor="let item of model.images | filterImage">
          <div>
            <div>{{ item.certificateImageName }}</div>
            <div class="img-preview">
              <img [src]="item.certificateImageData" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <p-single
      *ngIf="certiExpire"
      [label]="certificateType.certificateExpireName"
      [value]="certiExpire"
    /> -->
  </div>
</section>
