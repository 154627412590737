import { Routes } from '@angular/router';

import { CompleteComponent } from './complete/complete.component';
import { ConfigComponent } from './config/config.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { CertificateUploadComponent } from './certificate-upload.component';

//
export const certiUploadRoutes: Routes = [
  {
    path: 'passcard/certificate-upload',
    component: CertificateUploadComponent,
    children:[
      {
        path: 'complete',
        component: CompleteComponent
      }, {
        path: 'config',
        component: ConfigComponent
      }, {
        path: 'confirm',
        component: ConfirmComponent
      }
    ]
  }
];

//
export const certiUploadComponents = [
  CertificateUploadComponent,
  CompleteComponent,
  ConfigComponent,
  ConfirmComponent
]
