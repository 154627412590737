import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PayFeeItem } from '../../pay/pay.model';

export interface PayFeeDetail {
  paymentFeeDetails: PayFeeItem[];
  paymentAmount: number;
}

@Component({
  selector: 'p-pay-fees',
  templateUrl: './p-pay-fees.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PPayFeesComponent {
  private _totalStr = '';
  symbol = '';
  totalStr = '';
  @Input() paidDetail: PayFeeDetail;
  @Input() totalLbl = 'ご請求額';

  get PaymentAmount() {
    if (this.paidDetail && !this._totalStr) {
      this._totalStr = `${this.paidDetail.paymentAmount}`.replace(/\B(?=(\d{3}){0,3}(?!\d))/g, ',');
    }

    return this._totalStr;
  }
}