<ng-container *ngIf="tipVisible">
    <p-info *ngIf="status == 1 || status == 3 || status == 8; else busytip">
        <p>以下の内容で定期申請を行います。</p>
        <p>内容を確認し、「申請」を押してください。</p>
    </p-info>
    <ng-template #busytip>
        <p-info kind="warning" *ngIf="status == 7 || status == 9; else busy">
            <p>内容を確認し、「申請」を押してください。</p>
            <p>申請後、係員が申請内容の確認を行います。</p>
            <p>申請内容が確認出来次第、支払いのお手続きをお願い致します。</p>
        </p-info>
        <ng-template #busy>
            <p-info kind="warning">
                <p>空き待ち登録となります。順番となるまで定期券はご利用いただけません。</p>
                <p>内容を確認し、「申請」を押してください。</p>
            </p-info>
        </ng-template>
    </ng-template>
</ng-container>
<h2>{{parkingName | async}}</h2>
<flow-box *ngIf="showFlowBoxes"
    [steps]="['定期選択', 'お支払い方法', '確認', '完了']"
    [processing]="processing" />
<router-outlet></router-outlet>

<ng-container *ngIf="processing == 3">
    <div class="buttonBoxVertical complete-btns">
        <loading-button *ngIf="status == 1 || status == 3 || status == 8"
          label="{{PayMethodLabel}}情報を表示する"
          (apply)="onToPayDetail()"
          [disabled]="api.Disabled"
          [loading]="api.Disabled"
          [lClass]="'button-box-loading'"
          [class]="'cmn-btn six'" />
        <loading-button label="申請状況を確認する"
          (apply)="onToConfirm()"
          [disabled]="api.Disabled"
          [loading]="api.Disabled"
          [lClass]="'button-box-loading'"
          [class]="'cmn-btn bck-btn2 six'" />
      </div>    
</ng-container>
  