<p-info *ngIf="tip == 2">
  <p>順番となりました。</p>
  <p>定期券の申請を続けてください。</p>
</p-info>
<p-info kind="warning" *ngIf="tip == 4">
  <p>申請が差し戻されました。差し戻し理由を確認し、登録内容の修正をお願いいたします。</p>
  <p>申請内容の修正は、本画面の「申請を修正する」ボタンから行えます。</p>
</p-info>

<p-info *ngIf="passcardApplyResult=='申請取消' && ((status > 30 && status < 40) || status == 51)&& detail.denialReason">
  <p>申請が差し戻しされました。差し戻し理由を確認し、初めから申請をやり直してください。</p>
</p-info>
<p-info *ngIf="tip == 99">
  <p>現在申請中です。申請が受理されるまでしばらくお待ちください。</p>
</p-info>
<p-info *ngIf="tip == 100">
  <p>申請内容確認が完了しました。「定期申請へ」から申請を続けてください。</p>
</p-info>
<p-info *ngIf="tip == 6">
  <p>申請情報を表示します。</p>
  <p>申請を取消する場合は、「申請取消へ」ボタンを押してください。</p>
</p-info>
<p-info *ngIf="tip == 5">
  <p>{{PT[detail.passcardPaymentType]}}支払い待ちです。「{{PT[detail.passcardPaymentType]}}支払い情報」から支払い情報が確認できます。</p>
</p-info>
<p-info *ngIf="tip == 8">
  <p>ICカード登録待ちです。「ICカード登録情報」から登録情報が確認できます。</p>
</p-info>
<h2>申請詳細</h2>

<ng-container *ngIf="tip == 2 || tip == 100">
  <div class="tableCtn" *ngIf="detail.reApplyPeriod">
    <p-single label="申請期限" [value]="detail.reApplyPeriod | datetime" />
  </div>
  <br>
  <div class="buttonBoxVertical">
    <loading-button label="定期申請へ"
      (apply)="onReApply()"
      [disabled]="api.Disabled"
      [loading]="api.Disabled"
      [class]="'cmn-btn six'"
      [lClass]="'button-box-loading'" />
  </div>
</ng-container>

<ng-container *ngIf="tip == 11">
  <div class="tableCtn">
    <p-single label="申請期限" [value]="detail.reApplyPeriod | datetime" />
  </div>
  <br>
</ng-container>

<ng-container *ngIf="denialInfo">
  <div class="tableCtn">
    <p-single label="差し戻し理由" [value]="denialInfo.reason" />
    <p-single label="修正期限" *ngIf="denialInfo.term" value="{{denialInfo.term | datetime}}" />
  </div>
  <br>
</ng-container>

<div class="buttonBoxVertical" *ngIf="tip == 4">
  <loading-button label="申請を修正する"
    (apply)="onCorrect()"
    [disabled]="api.Disabled"
    [loading]="api.Disabled"
    [class]="'cmn-btn six'"
    [lClass]="'button-box-loading'" />
</div>

<block title="ICカード登録情報" *ngIf="tip == 8">
  <div class="buttonBoxVertical">
    <loading-button label="ICカード登録情報"
      (apply)="onToRegistIC()"
      [disabled]="api.Disabled"
      [loading]="api.Disabled"
      [class]="'cmn-btn six'"
      [lClass]="'button-box-loading'" />
  </div>
</block>

<block title="ご請求明細" *ngIf="feeDetail && feeDetail.paymentAmount !== 0">
  <p-pay-fees [paidDetail]="feeDetail"></p-pay-fees>
  <br>
  <div class="tableCtn">
    <p-single label="お支払い期限" *ngIf="(tip == 5 || tip == 12 ) && detail.paymentTerm" [value]="detail.paymentTerm | datetime" />
    <p-single label="お支払い日時" *ngIf="tip != 5 && tip !== 12 && detail.paymentDate" [value]="detail.paymentDate | datetime" />
    <p-single label="お支払い方法" value="{{PT[detail.passcardPaymentType]}}で支払う" />
    <p-single label="クレジットカード番号" *ngIf="detail.passcardPaymentType === 1" [value]="detail.creditCardNo"/>
  </div>
  <br>
  <div class="buttonBoxVertical">
    <loading-button *ngIf="tip == 5" label="{{PT[detail.passcardPaymentType]}}支払い情報"
      (apply)="onPaymentDetail()"
      [disabled]="api.Disabled"
      [loading]="api.Disabled"
      [class]="'cmn-btn six'"
      [lClass]="'button-box-loading'" />
      <loading-button *ngIf="receiptIssueBtnVisible" label="利用明細発行"
      (apply)="onIssuePayReceipt()"
      [disabled]="api.Disabled"
      [loading]="api.Disabled"
      [class]="'cmn-btn six'"
      [lClass]="'button-box-loading'" />
  </div>
</block>
<apply-details
  [passcardApplyResult]="passcardApplyResult"
  [parkingAreaName]="parkingAreaName"
  [detail]="detail"></apply-details>

<div class="buttonBoxVertical">
  <button (click)="onBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button *ngIf="passcardApplyResult == '申請中' && (status < 3 || tip == 4 || tip ==100)" label="申請取消へ"
    (apply)="onCancel()"
    [disabled]="api.Disabled"
    [loading]="api.Disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn'" />
</div>
