<h2>PayPay支払い情報</h2>
<div class="apply-status">
  <div><i class="icon check-circle"></i></div>
  <div>
    <p>PayPay支払いが完了しました</p>
</div>
</div>
<br>
<div class="buttonBox" *ngIf="app.login">
  <loading-button (apply)="onBack()" [label]="'定期券情報に戻る'"
    [class]="'cmn-btn bck-btn2 six'" [lClass]="'button-box-loading'" />
</div>