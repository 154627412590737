<block title="現在の定期情報">
  <div class="card-container">
    <card *ngIf="contract" [reservation]="contract"></card>
  </div>
</block>
<br>
<block title="更新月数">
  <div class="passcard-list">
    <div class="passcard passcard-normal" *ngFor="let item of paymentDivisionList; let i = index"
      [ngClass]="{ 'passcard-selected': paymentDivision && item.monthNum == paymentDivision.monthNum}"
      (click)="onSetPasscardMonth(item)">
      <div class="input">
        <div class="radio-wrapper"><input type="radio" name="passcardMonth"
          [checked]="paymentDivision && item.monthNum == paymentDivision.monthNum"
            value="1" id="passcardMonth{{i}}" /></div>
        <div><label class="radio-caption" for="passcardMonth{{i}}">
            {{item.monthNum}}ヵ月　{{item.unitPrice | currency:'JPY'}}
          </label></div>
      </div>
    </div>
  </div>
</block>
<br>

<block title="定期期間">
  <div class="tableCtn">
    <p-single label="定期期間" [value]="fixedPeriod" />
    <p-single label="更新可能期間" [value]="updatablePeriod" />
  </div>
</block>
<br>

<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button class="six" label="次へ"
    (apply)="onNext()"
    [disabled]="api.error || api.Disabled || !paymentDivision"
    [loading]="api.Disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn six'" />
</div>