import { Component, Input } from '@angular/core';
import { PasscardContract } from '../../models/passcard-contract';
import { PayRequestInfo } from '../pay.model';
import { Location } from '@angular/common';

@Component({
  selector: 'cc-pay',
  templateUrl: './creditcard-payinfo.component.html'
})
export class CreditCardPayInfoComponent {
  payRequest: PayRequestInfo;
  @Input() model!: PasscardContract;

  constructor(location: Location) {
    const state = location.getState();
    this.payRequest = state['payRequest'];
  }

  get LastPayTime() {
    return '2023/09/30(土) 23:59';
  }

}
