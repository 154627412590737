<h2>PayPay支払い情報</h2>
<br>
<div class="apply-status" *ngIf="!isCompleted; else payComplete">
  <div class="text-complete">PayPayを開いています。<br>しばらくお待ちください。</div>
</div>
<ng-template #payComplete>
  <div class="apply-status">
    <div><i class="icon check-circle"></i></div>
    <div>
      <p>PayPay支払いが完了しました</p>
    </div>
  </div>
  <div class="buttonBoxVertical">
    <loading-button label="定期券情報に戻る"
      (apply)="onToPasscards()"
      [disabled]="api.Disabled"
      [loading]="api.Disabled"
      [lClass]="'button-box-loading'"
      [class]="'cmn-btn bck-btn2 six'" />
  </div>  
</ng-template>