import { Injectable, inject } from "@angular/core";
import { StorageService } from "src/app/services";

@Injectable()
export class ApplyCancelModel {
    private _storage = inject(StorageService);
    complete = false;

    constructor() {
        this._storage.load('passcard-apply-cancel', this);
    }

    static reset(storage: StorageService) {
        storage.remove('passcard-apply-cancel');
    }

    save() {
        this._storage.save('passcard-apply-cancel', this);
    }
}