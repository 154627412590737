import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { NOSHOWORDER, ApplyStatus, formatDate as formatDate2 } from '../../passcard.model';
import { formatDate } from '@angular/common';
import { PaymentType } from '../../pay/pay.model';
import { PasscardApply } from '../../models/apply-info';
import { BehaviorSubject } from 'rxjs';

export const TipKind = Object.freeze({
  Unset: -1, '-1': '未指定',
  Applying: 0, '0': '申請中',
  ChangeReceipting: 1, '1': '更新受付中',
  Expired: 2, '2': '期限切れ',
  ManualReApply: 8, '8': '(手動承認)承認後の定期申請',
  ManualInTurn: 9, '9': '(手動承認)空き待ちだった順番が来た定期申請'
});

@Component({
  selector: 'status-tip',
  templateUrl: './status-tip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusTipComponent {
  ApplyStatus = ApplyStatus;
  private _applyInfo: PasscardApply;
  private _tipKind: number = TipKind.Unset;

  clickable = 'clickable'
  applying = new BehaviorSubject<number>(-1);
  mainMessage = [];
  subMessage = [];
  tipClass = '';
  iconClass = '';
  extra: string | null = null;

  constructor(@Inject(LOCALE_ID) private _locale: string){
  }

  @Input()
  set applyInfo(val: PasscardApply) {
    this._applyInfo = val;
    this.getTipDetail();
    this.applying.next(this._tipKind);
  }

  get status() {
    return this._applyInfo?.status;
  }

  get order() {
    return this._applyInfo.currentNum;
  }

  get MainMsgClass() {
    const { status, passcardPaymentType } = this._applyInfo;
    if (status == ApplyStatus.WaitingPay && passcardPaymentType == PaymentType.UnSelect) {
      return 'tip-autowrap';
    }

    return this.status == ApplyStatus.WaitingReApply ? 'tip-autowrap' : null;
  }

  isShowOrder() {
    return this.order && this.order != NOSHOWORDER;
  }

  private getTipDetail() {
    this.clickable = this.status != 0 ? 'clickable' : '';
    if (this.setTipDetail()) return;

    switch (this.status) {
      case ApplyStatus.WaitingICRegist:
      case ApplyStatus.WaitingICRegistForLost:
        this.mainMessage = [`ICカード登録待ちです。`];
        this.subMessage = ['詳細はこちらを押してください。'];
        this.iconClass = 'Suica';
        this.tipClass = 'tips-color-yellow color-chocolate';  
        break;
      case ApplyStatus.WaitingPay:
      case ApplyStatus.WaitingPayForRefresh:
      case ApplyStatus.WaitingPayForLost:
        switch (this._applyInfo.passcardPaymentType) {
          case PaymentType.Terminal:
            this.mainMessage = [`定期更新機支払い待ちです。`];
            this.iconClass = 'exclamation';
            break;
          case PaymentType.PayPay:
            this.mainMessage = [`PayPay支払い待ちです。`];
            this.iconClass = 'exclamation';
            break;
          case PaymentType.Cvs:
            this.mainMessage = [`コンビニ支払い待ちです。`];
            this.iconClass = 'shop';
            break;
        }

        this.subMessage = ['詳細はこちらを押してください。'];
        this.tipClass = 'tips-color-yellow color-chocolate';
        break;
      case ApplyStatus.WaitingFree:
        this.mainMessage = ['順番待ちです。'];
        this.subMessage = ['順番となるまでお待ちください。', '申請を取り消す場合は、こちらからお手続きできます。'];
        this.iconClass = 'hourglass';
        this.tipClass = 'tips-color-blue2 color-blue2';
        break;
      case ApplyStatus.WaitingReApply:
        this.mainMessage = ['順番となりました。', '定期券の申請を続けてください。'];
        this.subMessage = ['こちらから申請処理を続けてください。', ''];
        if (this._applyInfo.reApplyPeriod) {
          const reApplyPeriod = formatDate(this._applyInfo.reApplyPeriod, 'yyyy/MM/dd', this._locale);
          this.extra = `${reApplyPeriod}まで`;
          this.subMessage[1] = 'にお手続きください。';
        }

        this.iconClass = 'bell';
        this.tipClass = 'tips-color-green color-green';
        break;
      case ApplyStatus.WaitingPayAfterIssued:
        this.mainMessage = ['定期券受け取り後', '駐輪場でお手続きが必要です。'];
        this.subMessage = ['お受け取りになった定期券を持参し、駐輪場でお手続きお願いします。', '詳細はこちらを押してください。'];
        this.iconClass = 'exclamation';
        this.tipClass = 'tips-color-yellow color-chocolate';
        break;
      case ApplyStatus.WaitingModify:
        this.mainMessage = ['申請の修正が必要です。'];
        this.subMessage = ['申請が受け付けられませんでした、', 'こちらから内容をご確認お願い致します。'];
        this.iconClass = 'exclamation';
        this.tipClass = 'tips-color-pink';
        break;
      default:
        this.mainMessage = ['現在申請中です。'];
        this.subMessage = ['申請が受理されるまでしばらくお待ちください。', '申請情報は、こちらから確認できます。'];
        this.iconClass = 'note-ok';
        this.tipClass = 'tips-color-blue';
        this._tipKind = TipKind.Applying;
        return true;
    }

    return false;
  }

  private setTipDetail(): boolean {
    const { status,
      passcardAvailable,
      passcardPaymentType,
      passcardAutoCancelDelayTerm,
      systemDate, passcardUpdateFrom, passcardUpdateTo, passcardValidFrom, passcardValidTo } = this._applyInfo;
    
    if ((status == ApplyStatus.WaitingPay || status == ApplyStatus.WaitingPayForRefresh)
      && passcardPaymentType == PaymentType.UnSelect) {
      this.mainMessage = ['申請内容確認が完了しました。', '定期券の申請を続けてください。'];
      this.subMessage = ['こちらから申請処理を続けてください。', ''];
      let reApplyPeriod = this._applyInfo.reApplyPeriod;
      if (status == ApplyStatus.WaitingPayForRefresh) {
        reApplyPeriod = this._applyInfo.passcardUpdateTo;
      }

      if (reApplyPeriod) {
        const reApplyPeriodStr = formatDate(reApplyPeriod, 'yyyy/MM/dd', this._locale);
        this.extra = `${reApplyPeriodStr}まで`;
        this.subMessage[1] = 'にお手続きください。';
      }

      this.iconClass = 'bell';
      this.tipClass = 'tips-color-green color-green';
      this._tipKind = TipKind.ManualReApply;
      return true;
    }

    if (passcardAvailable != 1
      || status == ApplyStatus.WaitingPayForRefresh
      || status == ApplyStatus.WaitingPayForLost
      || status == ApplyStatus.WaitingICRegistForLost
      || status == 32 || status == 51     // 申請中
    ) return false;

    const sysDate = new Date(systemDate);
    const validFrom = new Date(passcardValidFrom);
    const validTo = new Date(passcardValidTo);
    const updateFrom = new Date(passcardUpdateFrom);
    const udateTo = new Date(passcardUpdateTo);
    sysDate.setHours(0, 0, 0, 0);
    validFrom.setHours(0, 0, 0, 0);
    validTo.setHours(0, 0, 0, 0);
    updateFrom.setHours(0, 0, 0, 0);
    udateTo.setHours(0, 0, 0, 0);
    // 利用可(更新猶予期間)
    if (sysDate > validTo && updateFrom <= sysDate && sysDate <= udateTo) {
      this.mainMessage = ['定期の有効期限が切れました。'];
      this.subMessage = [
        'こちらから定期更新が行えます。',
        `${formatDate2(passcardAutoCancelDelayTerm)}以降、自動的に解約となります。`
      ];
      this.iconClass = 'exclamation';
      this.tipClass = 'tips-color-pink';
      this._tipKind = TipKind.Expired;
      return true;
    }

    // 利用可(更新可能期間)
    if ( sysDate >= validFrom && sysDate <= validTo
      && sysDate >= updateFrom && sysDate <= udateTo) {
      this.mainMessage = [`定期更新受付中です。`];
      this.subMessage = ['こちらから定期更新が行えます。'];
      this.iconClass = 'refresh';
      this.tipClass = 'tips-color-yellow color-chocolate';
      this._tipKind = TipKind.ChangeReceipting;
      return true;
    }
    
    return false;
  }
}
