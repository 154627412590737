import { Injectable } from "@angular/core";
import { ApiService, NavigateService } from "../services";
import { ApiResult, ApiReturn, PasscardApplyResultApi, PasscardApply3dResultApi } from "./passcard.model";
import { UserInfo } from "./models/user-info";
import { PasscardPaidInfo } from "./pay/pay.model";
import { PasscardApplyApi } from "./models/passcard";
import { ApiResultGetPasscardApply, PasscardApply } from "./models/apply-info";
import { PaymentReceipt } from "./pay/models/payment-receipt.model";
import { CreditInitInfo } from "./common/pay-creditcard/pay-creditcard.component";
import { PaymentInfo } from "./pay/models/payinfo.model";
import { CvsInfo } from "./pay/models/CvsInfo.model";
import { PaymentDetail } from "./pay/models/PaymentDetail.model";
import { ApplyListItem } from "./apply-list/apply-list.model";
import { PasscardApplyDetail } from "./models/apply-details";

export const passcardmemberapi = Object.freeze({
    noticeInfoList: '/noticeInfo/list',
    passcardApply: '/passcardApplyEc_v2',
    passcardApply3d: '/paymentCreditCard/createCreditAuthStartURL',
    passcardReApplyInfo: '/passcardReApplyInfo',
    passcardApplyInfo: '/passcardApplyInfo',
    passcardPastInfo: '/passcardPastInfo',
    deliveryInfo: '/deliveryInfo',
    passcardTypeList: '/passcardTypeList',
    myPageCorrectConfirmation: '/myPageCorrectConfirmation',
    passcardApplyCancel: '/passcardApplyCancelEc',
    passcardCancel: '/passcardCancel',
    paymentList: '/paymentHistory/paymentList',
    applyList: '/passcardApplyHistory/passcardApplyList',
    passcardApplyDetail: '/passcardApplyHistory/passcardApplyDetails',
    receiptAddressName: '/paymentHistory/receiptAddressName',
    paymentReceipt: '/paymentHistory/receipt',
    paymentDetails: '/paymentHistory/paymentDetails',
    creditCardInfo: '/paymentCreditCard/creditCardInfo_v2',    // #32
    inputLimitRegist: '/paymentCreditCard/inputLimitRegist',
    creditCardAuth: '/paymentCreditCard/auth_v2',              // #33
    paymentCvsList: '/paymentCvs/searchPaymentCvsList', // 5(1)
    deliveryInfoUpdate: '/deliveryInfoUpdate',
    publishedDocumentInfo: '/publishedDocumentInfo',
    ICCardRegistInfo: '/ICCardRegistInfo',
    paymentTerm: '/paymentTerm',
});

@Injectable({
    providedIn: 'root'
})
export class PasscardApiProxy {
    private _error = '';

    constructor(private _navi: NavigateService,
        private _api: ApiService) {
    }

    get error() {
        return this._error;
    }

    set error(val: string) {
        this._error = val;
        if (val != '') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    get Disabled() {
        return this._api.disabled;
    }

    set Disabled(val: boolean) {
        this._api.disabled = val;
    }

    /**
     * 支払い情報を取得する
     * @param payMethod 
     * 0: 定期更新機
     * 2: PayPay
     * 3: コンビニ
     * @param passcardApplyNo 
     */
    async getPaymentInfo(payMethod: number, passcardApplyNo: number): Promise<PaymentInfo> {
        let url = '';
        switch (payMethod) {
            case 0:
                url = '/paymentTerminal/searchPaymentTerminalInfo';
                break;
            case 2:
                url = '/paymentPayPay/searchInfo';
                break;
            case 3:
                url = '/paymentCvs/searchPaymentCvsInfo';
                break;
        }

        return <PaymentInfo>await this.post<ApiReturn>(url, { passcardApplyNo }); 
    }

    async getCreditCardInfo(status: number): Promise<CreditInitInfo> {
        return <CreditInitInfo><any>await this.post<ApiReturn>(passcardmemberapi.creditCardInfo, { status: +status }); 
    }
 
    async registInputLimit() {
        await this.post<ApiReturn>(passcardmemberapi.inputLimitRegist, {}); 
    }
 
    async getCvsInfo(status: number): Promise<CvsInfo> {
        const cvsInfo = <CvsInfo>await this.post<ApiReturn>(passcardmemberapi.paymentCvsList, { status: +status });
        cvsInfo.cvsList = (cvsInfo.cvsList??[]).sort((a, b) => (a.dspIndex ?? 0) - (b.dspIndex ?? 0));
        return cvsInfo;
    }

    async getPaymentTerm(status: number ,selectTargetMonth: number, passcardValidFrom?: number): Promise<{ paymentTerm: number, terminalPaymentTerm: number }> {
        passcardValidFrom ??= null;
        const {paymentTerm, terminalPaymentTerm } = await this.post<ApiReturn>(passcardmemberapi.paymentTerm, { status, selectTargetMonth, passcardValidFrom}); 
        return {paymentTerm, terminalPaymentTerm };
    }
    
    async authCreditCardInfo(req: any): Promise<string> {
        const result = await this.post<ApiReturn>(passcardmemberapi.creditCardAuth, req);
        return result['transactionId'];
    }

    async getPasscardApplyInfo(): Promise<ApiResultGetPasscardApply> {
        return await this.get<ApiResultGetPasscardApply>(passcardmemberapi.passcardApplyInfo);
    }
    async passcardApply(passcardApply: any, isCorrect: boolean = false): Promise<PasscardApplyResultApi> {
        const apiUrl = isCorrect ? '/passcardApplyCorrectConfirmation' : passcardmemberapi.passcardApply;
        return await this.post<PasscardApplyResultApi>(apiUrl, passcardApply);
    }

    async passcardApply3Dsecure(passcardApply: any): Promise<PasscardApply3dResultApi> {
        return await this.post<PasscardApply3dResultApi>(passcardmemberapi.passcardApply3d, passcardApply, false);
    }

    async getReceipt(processDt: number, processMs: number): Promise<ReadonlyArray<PaymentReceipt>> {
        const ret = await this.post<ApiReturn>(passcardmemberapi.paymentReceipt, {processDt, processMs});
        return ret['receiptList'];
    }

    async getPaymentDetails(processDt: number, processMs: number) {
        return await this.post<PaymentDetail>(passcardmemberapi.paymentDetails, {processDt, processMs});
    }

    async getPasscardApplyDetail(cond: { passcardApplyNo: number, processDt: number, processMs: number }) {
        return await this.post<PasscardApplyDetail>(passcardmemberapi.passcardApplyDetail, cond);
    }

    async setReceiptName(processDt: number, processMs: number, receiptAddressName: string) {
        await this.post<ApiReturn>(passcardmemberapi.receiptAddressName, {processDt, processMs, receiptAddressName});
    }

    async passcardTypeList(passcardApplyNo?: number) {
        let result: PasscardApplyApi = null;
        if (passcardApplyNo != null && passcardApplyNo != undefined) {
            result = await this.post<PasscardApplyApi>(passcardmemberapi.passcardReApplyInfo, { passcardApplyNo });
        }
        else {
            result = await this.get<PasscardApplyApi>(passcardmemberapi.passcardTypeList);
        }

        if (!result.passcardApplyPossible || result.resultCode != 0)
        {
            this.error = result.mainMessage;
        }
        result.passcardTypeList ??= [];
        return result;
    }

    async getPaymentList(pageModel: { page: number, pages: number, countPerPage: number }, conditionsPeriod?: number): Promise<PasscardPaidInfo[]> {
        const result = await this.post<ApiReturn>(passcardmemberapi.paymentList, {pageNo: pageModel.page, conditionsPeriod});
        const paymentList = result.paymentList ?? [];
        pageModel.pages = (((result['totalNumber'] ?? 0) - 1) / pageModel.countPerPage) | 0;
        pageModel.pages += 1;
        return paymentList;
    }

    async getApplyHistory(pageModel: { page: number, pages: number, countPerPage: number }, conditionsPeriod?: number): Promise<ApplyListItem[]> {
        const result = await this.post<ApiReturn>(passcardmemberapi.applyList, {pageNo: pageModel.page, conditionsPeriod});
        const applyList = result.passcardApplyList ?? [];
        pageModel.pages = (((result['totalNumber'] ?? 0) - 1) / pageModel.countPerPage) | 0;
        pageModel.pages += 1;
        return applyList;
    }

    async getPasscardPastInfo(selectYear?: number): Promise<PasscardApply[]> {
        const result = await this.post<ApiReturn>(passcardmemberapi.passcardPastInfo, {selectYear});
        return result.passcardPastList ?? [];
    }

    /**
     * 申請キャンセル
     * @param passcardNo 
     */
    async passcardApplyCancel(passcardApplyNo: any): Promise<void> {
        await this.post<ApiResult>(passcardmemberapi.passcardApplyCancel, { passcardApplyNo });
    }

    /**
     * 解約
     * @param passcardNo 
     */
    async passcardCancel(passcardNo: any): Promise<void> {
        await this.post<ApiResult>(passcardmemberapi.passcardCancel, { passcardNo });
    }

    deliveryInfo() {
        return this.get<UserInfo>(passcardmemberapi.deliveryInfo);
    }

    async myPageCorrectConfirmation(passcardApplyNo: any): Promise<string> {
        const result = await this.post<ApiResult>(passcardmemberapi.myPageCorrectConfirmation, { passcardApplyNo });
        if (result.resultCode == 0) {
            return <string>result['receiptNo'];
        }

        this.error = result.mainMessage;
        return '';
    }

    async deliveryInfoUpdate(param: any) {
        const result = await this.post<ApiResult>(passcardmemberapi.deliveryInfoUpdate, param);
        if (result.resultCode == 0) {
            return <string>result['receiptNo'];
        }

        this.error = result.mainMessage;
        return '';
    }

    async getPublishedDocumentInfo(parkingCode: string): Promise<{ passcardApplyTermsServiceURL: string, passcardApplyNotesDOCList: string[] }> {
        const result = await this.post<ApiResult>(passcardmemberapi.publishedDocumentInfo, { parkingCode });
        return {
            passcardApplyTermsServiceURL: result['passcardApplyTermsServiceURL'],
            passcardApplyNotesDOCList: result['passcardApplyNotesDOCList']
        }
    }

    private post<T>(url: string, param: any, reject4: boolean = true) {
        this.error = '';
        return new Promise<T>((resolve, reject) => {
            this._api.disabled = true;
            this._api.post<T>(url, param, 1).subscribe(res => {
                this._api.disabled = false;
                const result = <ApiResult>res.body;
                if (result.resultCode == 0 || (!reject4 && result.resultCode == 4)) {
                    if (result.resultCode == 4) this.error = result.mainMessage;
                    resolve(res.body);
                    return;
                }

                if (result.resultCode == 4) {
                    this.error = result.mainMessage;
                    reject(this.error)
                    return;
                }

                this._navi.navigateByUrl('/error', true);
            });
        });
    }

    private get<T>(url: string): Promise<T> {
        this.error = '';
        return new Promise<T>((resolve, reject) => {
            this._api.disabled = true;
            this._api.get<T>(url, 1).subscribe(res => {
                this._api.disabled = false;
                const result = <ApiResult>res.body;
                if (result.resultCode == 0) {
                    resolve(res.body);
                    return;
                }

                if (result.resultCode == 4) {
                    this.error = result.mainMessage;
                    reject(this.error)
                    return;
                }

                this._navi.navigateByUrl('/error', true);
            });
        });
    }
}