<h2>修正申請</h2>
<div class="apply-status">
  <div><i class="icon check-circle"></i></div>
  <div>
    <p>定期券の修正申請が完了しました。</p>
    <p>申請が完了するまでお待ちください。</p>
    <br>
    <p>受付No.{{receiptNo}}</p>
</div>
</div>
<br>
<div class="buttonBox">
  <loading-button (apply)="onConfirmApply()" [disabled]="api.Disabled" [loading]="api.Disabled" [label]="'申請状況を確認する'"
    [class]="'cmn-btn bck-btn2 six'" [lClass]="'button-box-loading'" />
</div>