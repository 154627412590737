import { Component, Input } from '@angular/core';
import { Passcard } from '../../models/passcard';
import { PasscardStatusText } from '../../passcard.model';

@Component({
  selector: 'passcard-info',
  templateUrl: './passcard-info.component.html'
})
export class PasscardInfoComponent {
  @Input() model: Passcard = { status: 0, name: '', no: 0, unitPrice: 0 };
  @Input() showStatus = true;

  get StatusText() {
    return PasscardStatusText[this.model.status];
  }
}
