<div #archor>
  <footer #footer>
    <div class="footer">
      <div>
        <ul class="footer-link">
          <li><a href="{{app.publishedDocumentInfo.termsServiceURL}}" target="term-of-service">サイト利用規約 </a></li>
          <li>|</li>
          <li><a href="{{app.publishedDocumentInfo.privacyPolicyURL}}" target="privacy">プライバシーポリシー</a></li>
          <li>|</li>
          <li><a href="{{app.publishedDocumentInfo.managementCompanyInfoURL}}" target="company">運営会社情報</a></li>
          <li>|</li>
          <li><a href="{{app.publishedDocumentInfo.siteUseURL}}" target="site-use">ご利用にあたって</a></li>
          <li>|</li>
          <li><a href="{{app.publishedDocumentInfo.specifiedCommercialTransactionsURL}}" target="specified-commercial-transactions">特定商取引法に基づく表記</a></li>
        </ul>
      </div>
      <div><span>Copyright(c) Amano Corporation. All Rights Reserved.</span></div>
    </div>
  </footer>
</div>