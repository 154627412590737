<h2>PayPay支払い情報</h2>
<br>
<div class="buttonBox">
  <loading-button label="PayPay支払いを実行する"
    (apply)="onExePaypay()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [class]="'cmn-btn six'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>
<br>
<div class="tableCtn">
  <p-single label="決済状況" [value]="paymentInfo.settlementStatus" />
  <div class="tableLine">
    <div class="tableCell_l">ご請求額</div>
    <div class="tableCell_r">
      <div class="tdCtn">{{payRequest.payDetail.paymentAmount | currency:'JPY'}}</div>
    </div>
  </div>
  <p-single label="お支払い期限" [value]="paymentTerm" />
</div>
<br>
<div>
  <p><a href="{{paymentInfo.paymentPaypayDocumentUrl}}" target="pay-2" class="passcard-link">※お支払い方法の詳細はコチラ</a></p>
  <br>
  <div>
  <div class="comment-list">
    <div>お支払い期限を過ぎますと、定期券の申請は自動的に削除されます</div>
    <div class="error-text">PayPayでお支払い完了後、本サイトの決済完了ページに自動的に移動します。PayPayお支払い完了後は、アプリや画面を閉じずにお待ちください</div>
    <div>PayPayでお支払い完了後、本サイトに移動できない場合、10分程度でお支払いが完了しますので、時間をおい
      て定期情報ページをご確認ください</div>
  </div>
</div>
</div>
<br>
<pay-request [model]="payRequest"></pay-request>
<br>
<div class="buttonBoxVertical">
  <loading-button label="定期券情報に戻る"
    (apply)="onBack()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn bck-btn six'" />
</div>