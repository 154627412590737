import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, NavigateService } from 'src/app/services';
import { ErrorModel } from 'src/app/error/error.model';
import { PaypayReceiptReq, PaypayReceiptRes, SettleRes } from '../paypay.model';
import { PayModel } from '../../models/pay.model';

type Pay = LinePay | PayPay;

interface LinePay {
  pay: 'linepay';
  orderId: string;
  command: string;
  linepayOrderId: string;
  vResultCode: string;
  mstatus: string;
  vAuthInfo: string;
  authParams: string;
}

interface PayPay {
  pay: 'paypay';
  settle: SettleRes;
}

@Component({
  selector: 'redirect-success',
  templateUrl: './success.component.html',
})
export class SuccessComponent implements OnInit {
  query: string;
  dealId: string;

  constructor(
    private route: ActivatedRoute,
    private model: PayModel,
    private api: ApiService,
    private navi: NavigateService,
    private error: ErrorModel,
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(
      (params: Pay) => {
        this.query = JSON.stringify(params, null, ' ');
        if (params.pay === 'paypay') {
          // call API
          console.log(`paypay_orderID: ${this.model.dealId}`);
          this.getreceipt();
        } else if (params.pay === 'linepay') {
          console.log(`linepay_orderID: ${params.linepayOrderId}`);
        }
      }
    );
  }

  getreceipt() {
    const req: PaypayReceiptReq = {
      transactionId: this.model.dealId,
    };
    this.api.post<PaypayReceiptRes>('/usageDetails', req, 1).subscribe(response => {
      this.api.enabled();
      if (response.body.resultCode === 0) {
        this.api.enabled();
        var res = response.body;
        // API次第 /conplete で表示したいもの(receipt.component参照)を全て詰める
        this.setReceipt(res);
        // this.navi.navigateByUrl('complete');
      }
      else {
        // todo error処理
        console.log(`receipt_can't_get resultCode: ${res.resultCode}`);
        this.error.status = res.resultCode;
        this.navi.navigateByUrl('error');
      }
    });
  }

  setReceipt(response: PaypayReceiptRes) {
    // this.model.resetAll();
    this.model.pay = 'paypay';
    // this.model.car = {
    //   inTrans: response.inTrans,
    //   src: null,
    //   np: response.numberPlateInfoList,
    //   ticket: null,
    //   current: null,
    // };
    this.model.settle = {
      resultCode: response.resultCode,
      mainMessage: response.mainMessage,
      parkingFee: response.parkingFee,
      parkingTime: response.parkingTime,
      paymentFee: response.paymentFee,
      inTrans: response.inTrans,
      outTrans: response.outTrans,
      paidTrans: response.paidTrans,
      merchantName: response.merchantName,
      transactionId: response.transactionId,
      discountDisplayType: response.discountDisplayType,
      discountInfoList: response.discountInfoList,
      feeRate: response.feeRate,
      inOutId: response.inOutId,
      displayParkingTicket: response.displayParkingTicket,
      previousReceipt: response.previousReceipt,
      paidOut: response.paidOut,
      passcardInfo: response.passcardInfo,
      usageStatementHeaderInfoList: response.usageStatementHeaderInfoList,
      issuerName: response.issuerName,
      registNumber: response.registNumber,
      issuerAddress: response.issuerAddress,
      issuerTel: response.issuerTel,
      taxRate: response.taxRate
    };
    // this.model.parkingFee = response.parkingFee;
    // this.model.parkingTime = response.parkingTime,
    // this.model.passCard = {
    //   no: response.passcardInfo.passcardNo,
    //   type: response.passcardInfo.passcardType,
    //   name: response.passcardInfo.passcardTypeName,
    //   date1: response.passcardInfo.passcardValidFrom,
    //   date2: response.passcardInfo.passcardValidTo,
    //   status: response.passcardInfo.passcardStatus,
    // };
    
    // this.model.setFee(response);

    this.afterSet();
  }

  afterSet() {
    console.log(this.model.settle); // 確認用
    // this.navi.navigateByUrl('complete');
  }
}
