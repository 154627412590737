import { Component, inject } from '@angular/core';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { ActivatedRoute } from '@angular/router';
import { StorageKey3dsecure, StorageKeyApplyNo , ApplyType } from '../passcard.model';
import { NavigateService, StorageService } from 'src/app/services';

// ベリトランス3Dセキュア認証後に遷移する画面
@Component({
  selector: 'redirect3DSecure　',
  templateUrl: './redirect3dsecure.component.html'
})
export class Redirect3DSecureComponent {
  private readonly _storage = inject(StorageService);

  constructor(
    public api: PasscardApiProxy,
    private route: ActivatedRoute,
    private _navi: NavigateService,
  ) {
  }

  ngOnInit() {
    // 3DSecure後のAPI呼び出し
    this.route.queryParams.subscribe((async (params) => {
      const requestId = params.RequestId;
      const orderId = params.OrderId;
      let obj: any;
      const storageData = this._storage.load(StorageKey3dsecure, obj);
      this._storage.remove(StorageKey3dsecure);
      // 結果取得API呼び出し
      const req = {
        ...storageData.apiRequest,
        '3dSecureRequestId': requestId,
        '3dSecureOrderID': orderId
      }
      // req['3dSecureRequestId'] = requestId;
      // req['3dSecureOrderID'] = orderId;
      // 結果取得
      await this.api.passcardApply(req).then(
        result =>{
          const receiptNo = result.receiptNo;

          let url = '';
          let message1 = '';
          let message2 = '';
          let message3 = '';
          let strageKey = '';
          switch (storageData.applyType) {
            case ApplyType.New:
              // 新規申請の場合
              url = '/passcard/new/step/4';
              message1 = '定期券の新規申請が完了しました。';
              message2 = '続けてICカードの登録を定期更新機にて';
              message3 = 'お手続きをお願い致します。'
              strageKey = 'apply-wiz';
              break;
            case ApplyType.Refresh:
              // 更新申請の場合
              url = '/passcard/refresh/step/4';
              message1 = '定期更新申請が完了しました。';
              strageKey = 'refresh-wiz';
              break;
            case ApplyType.Lost:
              // 紛失申請の場合
              url = '/passcard/lost/step/4';
              message1 = '定期券の紛失申請が完了しました。';
              message2 = '続けてICカードの登録を定期更新機にて';
              message3 = 'お手続きをお願い致します。'
              strageKey = 'lost-wiz';
              break;
          }
          // 申請番号をストレージに保存
          let tmpObj;
          let applyInfo;
          applyInfo = this._storage.load(strageKey , tmpObj);
          applyInfo.passcardApplyNo = result.passcardApplyNo;
          this._storage.save(strageKey , applyInfo);

          const state = { message1, message2, message3, receiptNo, };
          this._navi.navigate(url, true, state);
        },error =>{
          let url = '';
          console.log(error);
          switch (storageData.applyType) {
            case ApplyType.New:
              url = '/passcard/new/step/3';
              break;
            case ApplyType.Refresh:
              url = '/passcard/refresh/step/3';
              break;
            case ApplyType.Lost:
              url = '/passcard/lost/step/3';
              break;
          }
          this._navi.navigate(url, true);
        }
      );
    }));
  }
}
