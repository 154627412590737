import { Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NavigateService } from 'src/app/services';

@Component({
  selector: 'proc-succ',
  templateUrl: './proc-success.component.html'
})
export class ProcSuccessComponent {
  private readonly _navi = inject(NavigateService);
  showBackToApplyList = false;
  message1: string;
  message2: string;
  message3: string;
  receiptNo: string;
  title: string;
  constructor(location: Location) {
    const state = location.getState();
    this.message1 = state['message1'];
    this.message2 = state['message2'];
    this.message3 = state['message3'];
    this.receiptNo = state['receiptNo'];
    this.title = state['title'];
    this.showBackToApplyList = state['showBackToApplyList'];
  }

  onToPasscardApplyInfo() {
    this._navi.navigateByUrl('/passcard/list');
  }
}
