import { Component, Input, ViewChild } from '@angular/core';
import { PasscardContract } from '../../models/passcard-contract';
import { AppModel } from 'src/app/app.model';
import { ModalComponent } from '../../common/modal/modal.component';
import { NavigateService } from 'src/app/services';
import { LostService } from '../../lost/lost.service';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { PasscardApply } from '../../models/apply-info';
import { Location } from '@angular/common';

/**
 * 定期解約
 */
@Component({
  selector: 'passcard-cancel',
  templateUrl: './passcard-cancel.component.html'
})
export class PasscardCancelComponent {
  private _passcardApply: PasscardApply;
  isConfirmed = false;

  @Input() contract: PasscardContract | null = null;

  @ViewChild('cancelConfirm') cancelConfirm!: ModalComponent

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private _lostService: LostService,
    l: Location) {
    const navigateState = l.getState();
    this._passcardApply = <PasscardApply>navigateState?.['contract'];
    this.contract = navigateState?.['contract'];
  }

  get IsComplete() {
    return this._lostService.cancelComplete;
  }

  onDlgClose() {
    this.cancelConfirm.closeModal();
  }

  /**
   * 解約を行う
   */
  onDlgCancel() {
    this.api.passcardCancel(this._passcardApply.passcardApplyNo)
      .then(() => {
        this.cancelConfirm.closeModal();
        this._lostService.cancelComplete = true;
        this._navi.navigate('passcard/passcard/canceled', true, {
          title: '定期解約',
          message1: '定期券の解約を承りました。',
          message2: 'またのご利用お待ちしております。'
        });
      })
      .catch(() => {
        this.cancelConfirm.closeModal();
      });
  }

  onBack() {
    this._navi.goBack();
  }

  onCancelApply() {
    this.cancelConfirm.showModal();
  }
}
