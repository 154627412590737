<div class="credit-cards">
  <div>
    <div class="logo-cvs" [style.maxWidth.px]="maxWidth" [style.aspectRatio]="maxWidth/65">
      <div *ngFor="let op of cvsList" class="conveni" [ngClass]="cvsImages[op.cvsCode].cls"></div>
    </div>
  </div>
  <div>
    <div class="tableCtn">
      <div class="tableLine">
        <div class="tableCell_l">お支払い先コンビニ</div>
        <div class="tableCell_r">
          <div class="selectBox">
            <select title="コンビニ" [(ngModel)]="PayModel.cvsCode" (change)="onSelected()" [ngClass]="{'error_border_r':msgCvs.length}">
              <option value="-1">--</option>
              <option *ngFor="let op of cvsList" [value]="op.cvsCode">{{op.cvsName}}</option>
            </select>
          </div>
          <p-error [messages]="msgCvs"></p-error>
      </div>
      </div>
      <div class="tableLine">
        <div class="tableCell_l">コンビニ支払い手数料</div>
        <div class="tableCell_r">
          <div class="tdCtn">{{fee | currency:'JPY'}}</div>
        </div>
      </div>
      <p-single label="お支払い期限" [value]="paymentTerm" />
    </div>
  </div>
</div>