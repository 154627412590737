import { Component, DestroyRef, inject } from '@angular/core';
import { PasscardStatus, getStorageKey } from '../passcard.model';
import { AppModel } from 'src/app/app.model';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { ApiService, NavigateService, StorageService } from 'src/app/services';
import { PaymentType } from '../pay/pay.model';
import { Passcard } from '../models/passcard';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

export interface IWizard {
  processing: number;
  complete: boolean;
}

const STEPS = Object.freeze({
  "0": '定期選択',
  "1": 'お支払い方法',
  "2": '確認',
  "3": '完了'
});

@Component({
  selector: 'apply',
  templateUrl: './apply.component.html'
})
export class ApplyComponent implements IWizard {
  TS = PasscardStatus;
  private readonly _storage = inject(StorageService);
  private readonly _navi = inject(NavigateService);
  private readonly _route = inject(ActivatedRoute);
  private readonly _router = inject(Router);
  private readonly _apiProxy = inject(PasscardApiProxy);
  public readonly api = inject(ApiService);

  /**
   * 1：申込可の定期券を申請する場合
   * 2：空き待ちの定期券を申請する場合
   * 3：申請受付中の定期券を申請する場合（空き待ちだった順番が来た定期券を申請する場合）
   * 4：紛失の申請を行う場合
   * 5：シール再発行の申請を行う場合
   * 6：定期更新の申請を行う場合
   * 7：(手動承認)承認前の定期申請を行う場合
   * 8：(手動承認)承認後の定期申請を行う場合
   * 9：(手動承認)承認前の申請受付中の定期券を申請する場合（空き待ちだった順番が来た定期券を申請する場合）
   */
  status: number;
  paramsReApply: any;
  passcardApplyNo: number | null = null;
  steps = [STEPS[0], STEPS[1], STEPS[2], STEPS[3]];
  paymentType: number | null = null;

  parkingName: string;
  parkingAddress: string;
  completeMsg = ['', '']
  receptionNo: string | null = null;
  btnPhoneMode = false;
  currentProc: number = 0;
  app = inject(AppModel);
  processing = 0;
  complete = false;
  applyBtnCaption = '申請';

  passcard: Passcard | null = null;
  paymethodLable: string = '';
  
  constructor() {
    this.paramsReApply = this._storage.load(`passcard-applyinfo`, null);
    const { parkingName, parkingAddress } = this._storage.load('apply-parkinginfo', null);
    this.parkingName = parkingName;
    this.parkingAddress = parkingAddress;

    this._storage.load('apply-wiz', this);

    if (this.isCorrect || this.status == 9) {
      this.steps = [STEPS[0], STEPS[2], STEPS[3]];
      this.applyBtnCaption = this.isCorrect ? '申請修正': '申請';
    }

    const subs = inject(Router).events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { url } = <NavigationEnd>event;
        if (url.endsWith('/step/4')) {
          this.processing = 3;
        }
        else if (url.includes('/step/3')) {
          this.processing = 2;
        }
        else if (url.includes('/step/2')) {
          this.processing = 1;
        }
        else {
          this.processing = 0;
        }

        this.saveState();
      }
    });

    inject(DestroyRef).onDestroy(() => subs.unsubscribe());
  }

  navigate(url: string, state?: any) {
    if (this._navi.isLocked()) return;

    this._router.navigate([url], { relativeTo: this._route, state });
    this.api?.enabled(0);
  }

  saveState(): void {
    const { passcardApplyNo, processing, complete, passcard } = this;
    this._storage.save('apply-wiz', {
      status: this.status,
      passcard,
      passcardApplyNo,
      processing,
      complete,
      paymentType: this.paymentType,
    });
  }

  get tipVisible() {
    return this.processing == 2;
  }

  get showFlowBoxes() {
    const status = this.status;
    return this.processing < 2
      || status == 1 || status == 3 || status == 8;
  }

  get PayMethodLabel() {
    return PaymentType[this.paymentType];
  }

  get currentStep() {
    if (!this.isCorrect) return this.processing;
    return this.processing > 1 ? this.processing - 1 : 0;
  }

  /**
   * 申請修正かどうかフラグ
   */
  get isCorrect(): boolean {
    return this.paramsReApply?.['action'] == 'correct';
  }

  getStorageKey(base: string): string {
    return getStorageKey(base, this.status, this.paramsReApply?.dcParkingNo??null);
  }

  async onToPayDetail() {
    let to = 'ic-register';
    switch (this.paymentType) {
      case PaymentType.Terminal:
        to = 'terminal-pay';
        break;
      case PaymentType.CreditCard:
        to = `ic-register/${this.passcardApplyNo}`;
        break;
      case PaymentType.PayPay:
        to = '2pay-pay';
        break;
      case PaymentType.Cvs:
        to = 'conveni-pay';
        break;
    }

    let paymentInfo = null;
    if (this.paymentType != 1) {
      paymentInfo = await this._apiProxy.getPaymentInfo(this.paymentType, this.passcardApplyNo);
    }

    this._navi.navigate(`/passcard/${to}`, false, { passcardApplyNo: this.passcardApplyNo, paymentInfo });
  }

  /**
  * 申請状況を確認する
  */
  onToConfirm() {
    this._navi.navigateByUrl('/passcard/list');
  }
}
