<div class="card-container">
  <br>
  <card [reservation]="reservation"></card>
</div>
<br>
<ng-content></ng-content>
<notice-info [parkingNoticeInformation]="noticeInfo" *ngIf="noticeInfoShow"></notice-info>
<br>

<place-info [parkingInfo]="reservation"></place-info>
<br>