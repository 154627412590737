import { Component, Input } from '@angular/core';
import { PasscardStatus, PasscardStatusText } from '../../passcard.model';
import { Passcard } from '../../models/passcard';

@Component({
  selector: 'passcard-status',
  template: `
  <div class=passcard-status>
    <div class="parking-status"
      [ngClass]="{
        'status-stop': passcard.status == PasscardStatus.NoVacancies || passcard.status == PasscardStatus.StopApply,
        'status-busy': passcard.status == PasscardStatus.WaitingVacancies,
        'status-idle': passcard.status == PasscardStatus.Available
        }" *ngIf="showStatus">{{StatusText}}</div>
    <div class="font-small" *ngIf="showWaitNum">{{StausTextExtra}}</div>
  </div>
  `})
export class PasscardStatusComponent {
  PasscardStatus = PasscardStatus;
  @Input() passcard: Passcard;
  @Input() showWaitNum = true;
  @Input() showStatus = true;

  get StatusText() {
    return PasscardStatusText[this.passcard.status];
  }

  get StausTextExtra() {
    if (this.passcard.certificateTypeNoList?.length) return '※証明書等アップロードが必要です';
    return '';
  }
}
