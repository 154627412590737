import { Location } from '@angular/common';
import { Component, OnDestroy, inject } from '@angular/core';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { NavigateService } from 'src/app/services';
import { createReceiptPdf, downloadDetailPdf } from '../createpdf';

@Component({
  selector: 'receipt-change-confirm',
  templateUrl: './change-confirm.component.html',
})
export class ReceiptChangeConfirmComponent implements OnDestroy {
  private readonly _nav = inject(NavigateService);
  cacheData: any;
  name : string;
  processDt: number;
  processMs: number;

  constructor(location: Location,
    public api: PasscardApiProxy) {
    const state = location.getState();
    this.cacheData = state['cacheState'];
    this.name = state['name'];
    this.processDt = state['processDt']; 
    this.processMs = state['processMs']; 
  }
  ngOnDestroy(): void {
    this.api.error = '';
  }

  onBack(){
    this._nav.navigate('/payment/receipt/changing', false, { name: this.name, processDt: this.processDt, processMs: this.processMs, cacheState: this.cacheData  });
  }

  async onIssue() {
    downloadDetailPdf(async () => {
      const { processDt, processMs, name } = this;
      await this.api.setReceiptName(processDt, processMs, name);
      const receipt = await this.api.getReceipt(processDt, processMs);
      this.api.Disabled = true;
      const objUrl = await createReceiptPdf(receipt);
      this.api.Disabled = false;
      const paymentDetail = await this.api.getPaymentDetails(processDt, processMs);
      paymentDetail.processDt = processDt;
      paymentDetail.processMs = processMs;
      paymentDetail.objUrl = objUrl;
      this._nav.navigate(`/payment/detail`, false, paymentDetail);
      return objUrl;
    });
  }
}
