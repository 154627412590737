<div class="card-container">
  <br>
  <card [reservation]="reservation"></card>
</div>
<br>
<ng-content></ng-content>
<block title="定期情報">
  <passcard-info [model]="passcardInfo"></passcard-info>
  <br>
  <div class="tableCtn" *ngIf="periodVisible">
    <p-single label="定期期間" [value]="ValidPeriod" />
    <p-single label="更新可能期間" [value]="UpdatablePeriod" />
  </div>
  <br>
</block>

<notice-info [parkingNoticeInformation]="noticeInfo" *ngIf="noticeInfoShow"></notice-info>
<br>

<place-info [parkingInfo]="reservation"></place-info>
<br>