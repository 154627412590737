import { Component } from '@angular/core';
import { Delivery, deliveryToDeliveryInput, getDefaultDelivery } from '../../passcard.model';
import { Location } from '@angular/common';
import { DeliveryEditService } from './delivery-edit.service';
import { NavigateService } from 'src/app/services';

@Component({
  selector: 'delivery-edit',
  templateUrl: './delivery-edit.component.html'
})
export class DeliveryEditComponent {
  constructor(
    editService: DeliveryEditService,
    navi: NavigateService,
    location: Location) {
    if (editService.passcardApply) return;

    const navigateState = location.getState();
    editService.passcardApply = navigateState?.['applyInfo'];
    editService.myPage = navigateState?.['myPage'];
    if (!editService.passcardApply || !editService.passcardApply.deliveryZipCode || !editService.myPage) {
      navi.navigateByUrl('/error', true);
    }

    editService.deliveryInputKind = editService.passcardApply.deliveryType ?? 0;
    if (editService.deliveryInputKind == 1) {
      editService.delivery = <Delivery><any>editService.passcardApply;
      editService.inputedDelivery = deliveryToDeliveryInput(editService.delivery);
    }
    else {
      editService.inputedDelivery = getDefaultDelivery();
    }
  }
}
