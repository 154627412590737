import { PayFeeDetail } from "../common/p-pay-fees/p-pay-fees.component";
import { CvsInfo } from "./models/CvsInfo.model";

export interface PasscardPaidInfo {
  /**
   * 処理日時(long)
   * yyyyMMddHHmmss形式
   */
  processDt: number;

  /**
   * 処理日時のミリ秒(int)
   */
  processMs: number;
  
  /**
   * 支払種別
   */
  paymentContentsCode: number;

  /**
   * お支払い日時
   */
  paymentDate: string;

  /**
   * 駐車場
   */
  parkingAreaName: string;

  /**
   * 支払金額
   */
  paymentFee: number;


  paymentFeeDetails: PayFeeItem[];

  /**
   * 支払い内容(String)
   */
  paymentContents: string;
}

export interface PayFeeItem {
  contents: string;
  Fee: number;
}

export interface PayRequestInfo {
  paymentContentsName: string;
  parkingAreaName: string;
  receptionNo: string;
  payDetail: PayFeeDetail;
}


export interface PaidDetail extends PasscardPaidInfo {
}

export const PaymentContentName = Object.freeze({
  New: 1, "1": "新規定期契約",
  Refresh: 2, "2": "定期更新",
  ReIssueLost: 3, "3": "紛失再発行",
  ReIssueSeal: 4, "4": "シール再発行",
});

export const PasscardPaidKind = Object.freeze({
  NewContract: 0, '0': '新規定期契約',
  Reresh: 1, '1': '定期更新手数料',
  Lost: 2, '2': '紛失手数料',
});

export interface CreditCard {
  no: string;
  expire: string;
  securityCode?: string;
  errorLimit?: string;
}

export interface CreditCardPay {
  cardUse: number; // 0: 登録したカード情報を使用する 1: カード情報入力する
  transactionId?: string;
  creditCardNo?: string;
  tokenApiKey?: string;
  creditInputInvalidTime?: number;
  creditInputCountLimit?: number;
  veriCount?: number;

  "0"?: CreditCard;
  "1"?: CreditCard;
}

export const CeditDefault = { no: '', expire: '' };

export interface CvsPay {
  cvsCode?: string;
  cvsName?: string;
  payCommission?: number;
  payExpire?: number;
  cvsInfo?: CvsInfo;
}

export const PaymentType = Object.freeze({

  /**
   * 更新機支払い
   */
  Terminal: 0, '0': '定期更新機支払い',

  /**
   * クレジットカード支払い
   */
  CreditCard: 1, '1': 'ICカード登録',

  /**
   * PayPay支払い
   */
  PayPay: 2, '2': 'PayPay支払い',

  /**
   * コンビニ支払い
   */
  Cvs: 3, '3': 'コンビニ支払い',

  UnSelect: 99, '99': '未選択'
});

export interface PayInfomation {
  error?: boolean;
  method: number;
  paymentTerm?: number;
  terminalPaymentTerm?: number;
  creditPay?: CreditCardPay;
  cvsPay?: CvsPay
}
