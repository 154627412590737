import { Location } from '@angular/common';
import { Component, OnDestroy, inject } from '@angular/core';
import { NavigateService } from 'src/app/services';
import { createReceiptPdf, downloadDetailPdf } from '../createpdf';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';

@Component({
  selector: 'receipt-change-tip',
  templateUrl: './change-tip.component.html',
})
export class ReceiptChangeTipComponent implements OnDestroy {
  private readonly _nav = inject(NavigateService);
  readonly api = inject(PasscardApiProxy);
  cacheData: any;
  name: string;
  processDt: number;
  processMs: number;

  constructor(location: Location) {
    const state = location.getState();
    this.cacheData = state['cacheState'];
    this.name = this.cacheData['nameFurigana'];
    this.processDt = state['processDt']; 
    this.processMs = state['processMs']; 
  }
  ngOnDestroy(): void {
    this.api.error = '';
  }

  onBack(){
    this._nav.navigate('payment/detail', false, this.cacheData);
  }

  async onIssue() {
    downloadDetailPdf(async () => {
      const { processDt, processMs } = this;
      await this.api.setReceiptName(processDt, processMs, this.name);
      const receipt = await this.api.getReceipt(processDt, processMs);
      this.api.Disabled = true;
      const objUrl = await createReceiptPdf(receipt);
      this.api.Disabled = false;
      const paymentDetail = await this.api.getPaymentDetails(this.processDt, this.processMs);
      paymentDetail.processDt = this.processDt;
      paymentDetail.processMs = this.processMs;
      paymentDetail.objUrl = objUrl;
      this._nav.navigate(`/payment/detail`, false, paymentDetail);
      return objUrl;
    });
  }

  onChange() {
    this._nav.navigate('/payment/receipt/changing', false, { name: this.name, processDt: this.processDt, processMs: this.processMs, cacheState: this.cacheData });
  }
}
