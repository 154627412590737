<h2 *ngIf="title">{{title}}</h2>
<div class="apply-status">
  <div><i class="icon check-circle"></i></div>
  <div>
    <p>{{message1}}</p>
    <p *ngIf="message2">{{message2}}</p>
    <p *ngIf="message3">{{message3}}</p>
    <ng-container *ngIf="receiptNo">
      <br>
      <p>受付No.{{receiptNo}}</p>
    </ng-container>
  </div>
</div>
<br>
<div class="buttonBoxVertical" *ngIf="showBackToApplyList">
  <loading-button label="定期券情報に戻る"
    (apply)="onToPasscardApplyInfo()"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn bck-btn six'" />
</div>