<div class="passcard readonly passcard-normal">
  <div class="radio-wrapper input2">
    <input type="radio" style="visibility: hidden;">
    <div>
      <div><passcard-status [passcard]="model" [showStatus]="showStatus"></passcard-status></div>
      <br>
      <p>{{model.name}}</p>
      <br>
      <div class="cost font-small">
        <p>1ヵ月あたり{{model.unitPrice | currency:'JPY'}}</p>
        <ng-container *ngIf="model.annotation">
          <br>
          <p class="annotation">{{model.annotation}}</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>