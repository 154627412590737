<block title="定期選択">
  <passcard-info *ngIf="isReApply && passcardSelected; else list"
    [model]="passcardSelected"
    [showStatus]="false"></passcard-info>
  <ng-template #list>
    <p-info *ngIf="passcards?.length && applyParams.parkingDetailsHelpUrl">
      <p>定期券の詳細については<a href="{{applyParams.parkingDetailsHelpUrl}}" class="passcard-link" target="certificate-ref">コチラ</a>をご確認ください。</p>
    </p-info>
    <br>
    <div class="passcard-list">
      <div class="passcard input passcard-normal" *ngFor="let passcard of passcards; let i=index;"
        [ngClass]="{
          'passcard-disabled': passcard.status == PasscardStatus.NoVacancies || passcard.status == PasscardStatus.StopApply ,
          'passcard-selected': passcardSelected && passcardSelected.passcardType == passcard.passcardType
        }"
        (click)="onPasscardChange(passcard)">
        <div class="radio-wrapper input2">
          <input type="radio" name="passcard" id="passcard{{i}}"
            [disabled]="passcard.status === PasscardStatus.NoVacancies || passcard.status === PasscardStatus.StopApply"
            [checked]="passcardSelected && passcardSelected.passcardType == passcard.passcardType" [value]="i" />
          <div>
            <div>
              <passcard-status [passcard]="passcard"></passcard-status>
            </div>
            <br>
            <p>{{passcard.name}}</p>
            <br>
            <div class="cost font-small">
              <p>1ヵ月あたり{{passcard.unitPrice | currency:'JPY'}}</p>
              <ng-container *ngIf="passcard.annotation">
                <br>
                <p class="annotation">{{passcard.annotation}}</p>
              </ng-container>  
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</block>
<ng-container  *ngIf="isReApply && !showSelect">
  <br>
  <div class="tableCtn">
    <p-single label="契約月数" [value]="selectedInfo.passcardMonth" />
    <p-single label="使用開始日" [value]="selectedInfo.applyUseStart" />
    <p-single label="定期期間" [value]="selectedInfo.validPeriod" />
    <p-single label="更新可能期間" [value]="selectedInfo.updatablePeriod" />
  </div>  
</ng-container>
<br>
<ng-container *ngIf="showSelect">
  <block title="契約月数">
    <div class="passcard-list">
      <div class="passcard passcard-normal" *ngFor="let item of passcardSelected.paymentDivisionList; let i = index"
        [ngClass]="{ 'passcard-selected': item.monthNum == model.passcardMonth}"
        (click)="onSetPasscardMonth(item.monthNum)">
        <div class="input">
          <div class="radio-wrapper"><input type="radio" name="passcardMonth"
            [checked]="item.monthNum == model.passcardMonth"
              value="1" id="passcardMonth{{i}}" /></div>
          <div><label class="radio-caption" for="passcardMonth{{i}}">
              {{item.monthNum}}ヵ月　{{item.unitPrice | currency:'JPY'}}
            </label></div>
        </div>
      </div>
    </div>
  </block>
  <br>
  <block title="定期期間">
    <ng-container *ngIf="applyUseStartMethod == 0">
      <div class="passcard-list">
        <div class="passcard passcard-normal" *ngIf="applySelectMonthType % 2 == 0"
          [ngClass]="{'passcard-selected': model.selectTargetMonth == PERIODKIND.CurrentMonth }"
          (click)="onPeriodChange(PERIODKIND.CurrentMonth)">
          <div class="input">
            <div class="radio-wrapper"><input type="radio" name="period"
              [checked]="model.selectTargetMonth == PERIODKIND.CurrentMonth"
                value="{{PERIODKIND.NextMonth}}" id="period1" /></div>
            <div><label class="radio-caption" for="period1">当月から</label></div>
          </div>
        </div>
        <div class="passcard passcard-normal" *ngIf="applySelectMonthType > 0"
          [ngClass]="{'passcard-selected': model.selectTargetMonth == PERIODKIND.NextMonth }"
          (click)="onPeriodChange(PERIODKIND.NextMonth)">
          <div class="input">
            <div class="radio-wrapper"><input type="radio" name="period" [checked]="model.selectTargetMonth == PERIODKIND.NextMonth"
                value="{{PERIODKIND.NextMonth}}" id="period2" /></div>
            <div><label class="radio-caption" for="period2">翌月から</label></div>
          </div>
        </div>
      </div>
      <br>
    </ng-container>
    <div class="tableCtn">
      <div class="tableLine" *ngIf="applyUseStartMethod == 1">
        <div class="tableCell_l">使用開始日</div>
        <div class="tableCell_r">
          <i-date-select
          [(ngModel)]="dateSelectModel.current"
          [minDate]="dateSelectModel.min"
          [maxDate]="dateSelectModel.max"
          [error]="msgDateSelect.length"
          (change)="onStartDateChanged($event)"
          ></i-date-select>
          <p-error [messages]="msgDateSelect"></p-error>
        </div>
      </div>
      <p-single label="定期期間" [value]="fixedPeriod" />
      <p-single label="更新可能期間" [value]="updatablePeriod" />
    </div>
  </block>
  <br>
</ng-container>

<ng-container *ngIf="passcardSelected && status != 2 && (cardPurchaseChoicesBuy || cardPurchaseChoicesMyCardUse)">
  <block title="ICカード購入">
    <div class="passcard-list">
      <div *ngIf="cardPurchaseChoicesMyCardUse" class="passcard passcard-normal"
        [ngClass]="{ 'passcard-selected': model.buyICCard == 0 }" (click)="model.buyICCard = 0">
        <div class="input">
          <div class="radio-wrapper"><input type="radio" name="card" [checked]="model.buyICCard == 0"
              (change)="model.buyICCard = 0" value="0" id="buy1" #buy1 /></div>
          <div><label class="radio-caption" for="buy1">
              <div>お手持ちの交通系ICカードを定期券として利用する</div>
              <div>(発行手数料：{{applyNewIssueCommission | currency:'JPY'}})</div>
            </label></div>
        </div>
      </div>
      <div *ngIf="cardPurchaseChoicesBuy" class="passcard passcard-normal"
        [ngClass]="{ 'passcard-selected': model.buyICCard == 1 }" (click)="model.buyICCard = 1">
        <div class="input">
          <div class="radio-wrapper"><input type="radio" name="card" [checked]="model.buyICCard == 1"
              (change)="model.buyICCard = 1" value="1" id="buy2" #buy2 /></div>
          <div><label class="radio-caption" for="buy2">
              <div>駐輪場専用のICカードを購入して定期券として利用する</div>
              <div>(発行手数料：{{issueFee | currency:'JPY'}})</div>
            </label></div>
        </div>
      </div>
    </div>
  </block>
  <br>
</ng-container>
<block  title="証明書等登録" *ngIf="showCertificateUpload">
  <p-info>
    <p>申請を行う定期に必要な証明書等をアップロードしてください。</p>
    <p *ngIf="certificateURL">※アップロードする証明書等の詳細については<a href="{{certificateURL}}" class="passcard-link" target="certificate-ref">コチラ</a>をご確認ください。</p>
  </p-info>
  <i-my-files [initValue]="initCertificate" [certificateTypeList]="certificateTypeList" [model]="myFilesModel" [message]="imageCheckResult"></i-my-files>
</block>

<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button class="six" label="次へ"
    (apply)="onNext()"
    [disabled]="NextDisabled"
    [loading]="api.Disabled"
    [class]="'cmn-btn six'"
    [lClass]="'button-box-loading'" />
</div>