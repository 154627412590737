import { Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { CeditDefault, PayInfomation, PaymentType } from '../../pay/pay.model';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { StorageService, ValidateService } from 'src/app/services';
import { PayByCreditcardComponent } from '../pay-creditcard/pay-creditcard.component';
import { formatDate, getStorageKey } from '../../passcard.model';

@Component({
  selector: 'pm-sel',
  templateUrl: './paymethod-select.component.html'
})
export class PaymethodSelectComponent implements OnDestroy {
  private _dcParkingNo: number | null = null;
  paymentTerm = '';
  terminalPaymentTerm = '';

  private _storage = inject(StorageService);
  api = inject(PasscardApiProxy);
  valid = inject(ValidateService);

  PayMethod = PaymentType;
  commission = 0;

  usableFlags: number[] = [];
  status: number;
  @Input() set Params(params: { status: number, dcParkingNo: number | null}) {
    if (!params) return;

    const { status, dcParkingNo } = params;
    this._dcParkingNo = dcParkingNo;
    this.status = status;
    const key = getStorageKey('payment-method', status, dcParkingNo);
    const {
      paymentTerm, terminalPaymentTerm,
      paymentMethodPaidMachineQR, paymentMethodCreditCard, paymentMethodPayPay, paymentMethodConvenienceStore,
      commission
    } = this._storage.load('payment-params', null);
    this.paymentTerm = this.formatTerm(paymentTerm);
    this.terminalPaymentTerm = this.formatTerm(terminalPaymentTerm);
    this.commission = commission;
    this.usableFlags = [paymentMethodPaidMachineQR, paymentMethodCreditCard, paymentMethodPayPay, paymentMethodConvenienceStore];

    const { method, cvsPay } = this._storage.load(key, null) ?? { method: -1 };
    this.model.method = method;
    this.model.creditPay = { cardUse: -1 };
    if (cvsPay) {
      this.model.cvsPay = {
        cvsCode: cvsPay.cvsCode
      }
    }

    this.model.paymentTerm = paymentTerm;
    this.model.terminalPaymentTerm = terminalPaymentTerm;
    if (this.model.method == PaymentType.CreditCard && this.model.creditPay.cardUse == -1) {
      this.onCreditSelected();
    }
    else if (this.model.method == PaymentType.Cvs) {
      this.OnCvsSelected();
    }
  }

  model: PayInfomation = { method: -1 };

  @ViewChild('pcc') creditInput!: PayByCreditcardComponent;

  ngOnDestroy(): void {
    this.api.error = '';
  }

  onMethodSelected(method: number) {
    if (this.model.method != method) {
      this.api.error = '';
      this.model.error = false;
      switch (method) {
        case PaymentType.CreditCard:
          this.onCreditSelected();
          break;
        case PaymentType.Cvs:
          this.OnCvsSelected();
          break;
      }
    }

    this.model.method = method;
  }

  async validate(): Promise<boolean> {
    if (this.model.error) return false;

    if (!this.creditInput) return true

    return this.creditInput.veritrans();
  }

  async OnCvsSelected() {
    this.model.cvsPay ??= {};
    if (!this.model.cvsPay.cvsInfo) {
      this.model.cvsPay.cvsInfo = await this.api.getCvsInfo(this.status);
    }

    const { feeType, fee, feeRate, minimumFee } = this.model.cvsPay.cvsInfo;
    const cvsCode = this.model.cvsPay.cvsCode;
    if (cvsCode) {
      this.model.cvsPay.cvsName = this.model.cvsPay.cvsInfo.cvsList.find(x => x.cvsCode == cvsCode)?.cvsName;
    }

    //1：金額指定、2：料率(1~100%)
    this.model.cvsPay.payCommission = fee;
    if (feeType == 2) {
      this.model.cvsPay.payCommission = this.commission * feeRate | 0;
      if (this.model.cvsPay.payCommission < minimumFee) {
        this.model.cvsPay.payCommission = minimumFee;
      }
    }
  }

  private formatTerm(src: number) {
    const dateStr = formatDate(src);
    const date = new Date(src);
    const hours = `${date.getHours()}`.padStart(2, '0');
    const minutes = `${date.getMinutes()}`.padStart(2, '0');
    return `${dateStr} ${hours}:${minutes}`;
  }

  async onCreditSelected() {
    this.model.creditPay ??= { cardUse: -1, '0': { ...CeditDefault }, '1': { ...CeditDefault } };
    this.model.creditPay.veriCount ??= 0;
    this.model.method = PaymentType.CreditCard;
    const creditInfo = await this.api.getCreditCardInfo(this.status);
    if (creditInfo.creditCardNo && creditInfo.creditExpirationDate) {
      const expire = new Date(creditInfo.creditExpirationDate);
      this.model.creditPay[0].no = creditInfo.creditCardNo;
      this.model.creditPay[0].expire = `${expire.getMonth() + 1}/${expire.getFullYear() % 100}`;
      this.model.creditPay.cardUse = 0;
    }
    else {
      this.model.creditPay.cardUse = 1;
    }

    this.model.creditPay.tokenApiKey = creditInfo.tokenApiKey;
    this.model.creditPay.creditInputInvalidTime = creditInfo.creditInputInvalidTime;
    this.model.creditPay.creditInputCountLimit = creditInfo.creditInputCountLimit;
  }

  saveModel() {
    const saveInfo: any = {
      method: this.model.method,
      paymentTerm: this.model.paymentTerm,
      terminalPaymentTerm: this.model.terminalPaymentTerm
    };

    if (this.model.method == PaymentType.Cvs) {
      const { cvsCode, cvsName, payCommission } = this.model.cvsPay;
      saveInfo.cvsPay = {
        cvsCode,
        cvsName,
        payCommission
      };
    }
    else if (this.model.method == PaymentType.CreditCard) {
      const { creditCardNo, transactionId, cardUse } = this.model.creditPay;
      saveInfo.creditPay = {
        creditCardNo: cardUse == 0 ? this.model.creditPay[this.model.creditPay.cardUse].no : creditCardNo,
        expire: this.model.creditPay[this.model.creditPay.cardUse].expire,
        transactionId
      }
    }

    this._storage.save(getStorageKey('payment-method', this.status, this._dcParkingNo), saveInfo);
  }
}

export function getApplyPaymentItems(storage: StorageService, status: number, dcParkingNo: number): any {
  let applyInfo: any = {};
  const payInfo = storage.load(getStorageKey('payment-method', status, dcParkingNo), null);
  applyInfo.paymentMethod = payInfo.method;
  switch (payInfo.method) {
    case PaymentType.CreditCard:
      const creditPay = payInfo.creditPay;
      applyInfo.tokenId = creditPay.transactionId;
      applyInfo.creditCardNo = creditPay.creditCardNo;
      const mmyy = creditPay.expire.split('/')
      applyInfo.creditExpirationDateYear = +mmyy[1];
      applyInfo.creditExpirationDateMonth = +mmyy[0];
      break;
    case PaymentType.Cvs:
      applyInfo.convenienceCode = payInfo.cvsPay.cvsCode;
      applyInfo.applyConvenienceCommission = payInfo.cvsPay.payCommission;
      break;
  }

  return applyInfo;
}