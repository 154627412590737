import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { formatPeriod } from '../../passcard.model';
import { PasscardContract } from '../../models/passcard-contract';

@Component({
  selector: 'contract-info',
  templateUrl: './contract-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContractInfoComponent {
  @Input() model!: PasscardContract;

  get PasscardNo() {
    return this.model['passcardNo'];
  }

  get ParkingPlace() {
    return this.model['parkingName'];
  }

  get TicketKind() {
    return this.model['name'];
  }

  get ValidPeriod() {
    const { passcardValidFrom, passcardValidTo } = this.model;
    return formatPeriod([passcardValidFrom, passcardValidTo]);
  }

  get UpdatablePeriod() {
    const { passcardUpdateFrom, passcardUpdateTo } = this.model;
    return formatPeriod([passcardUpdateFrom, passcardUpdateTo]);
  }
}
