<section>
  <div class="tableCtn">
    <p-single label="申請済みの証明書等有効期限" *ngIf="certificateNextCheckDate" [value]="certificateNextCheckDate | date: 'yyyy年MM月まで'" />
    <div class="tableLine">
      <div class="tableCell_l">証明書等</div>
      <div class="tableCell_r my-confirm-files">
        <div class="selectBox">
          <select [(ngModel)]="model.certificateType" (change)="onFilesKindChange()">
              <option *ngFor="let item of certificateTypeList" [ngValue]="item.certificateType">{{item.certificateTypeName}}</option>
          </select>
        </div>
        <div *ngFor="let item of images">
          <div class="upload-block">
            <span class="require" *ngIf="item.imageSetting == 1">必須</span><br/>
            <div>
              <div>{{ item.certificateImageName }}</div>
              <div class="img-preview"  *ngIf="item.certificateImageData">
                <div class="remove-img" (click)="onFileCancel(item)"><i class="icon cross"></i></div>
                <img [src]="item.certificateImageData" (error)="onImageError(item)" (load)="onloadImage(item)"/>
              </div>
              <div class="file-upload-btn">
                <input type="file" accept=".png,.jpg,.jpeg" [id]="item.certificateImageNum" (change)="onSelectFile(item, $event)" #upload />
                <label for="{{item.certificateImageNum}}" class="cmn-btn bck-btn icon-btn"><i class="btn-title icon camera">画像を選択する</i></label>
              </div>
            </div>
          </div>
          <p-error [messages]="item.validatorMessage"></p-error>
        </div>
      </div>
    </div>
  </div>
</section>