<h2>メール通知設定変更確認</h2>
<t-info [value1]="'以下の内容で更新します。'" [value2]="'内容を確認し、「確定」ボタンを押してください。'"></t-info>


<section>
  <div class="tableCtn tableCtn-confirm">
    <div class="tableLine">
      <div class="tableCell_l">
        <p>メール通知設定<br>通知する項目のみ表示</p>
      </div>

      <div *ngIf="!hasNotify()" class="tableCell_r">
        <p class="mailNotify-contents">メール通知項目はありません</p>
      </div>

      <div *ngIf="hasNotify()" class="tableCell_r">
        <p *ngIf="model.updateNotice" class="mailNotify-contents">更新期間通知</p>
      </div>
    </div>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [label]="'確定'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>