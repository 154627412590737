import { Apply01Component } from './apply/steps/step01/apply01.component';
import { Apply02Component } from './apply/steps/step02/apply02.component';
import { Apply03Component } from './apply/steps/step03/apply03.component';
import { ApplyComponent } from './apply/apply.component';
import { Routes } from '@angular/router';
import { ApplyGuideComponent } from './apply-guide/apply-guide.component';
import { PassCardComponent } from './passcard.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ContractComponent } from './contract/contract.component';
import { ChangeConfirmComponent } from './change-confirm/change-confirm.component';
import { LostComponent } from './lost/lost.component';
import { Lost03Component } from './lost/steps/lost03/lost03.component';
import { Lost02Component } from './lost/steps/lost02/lost02.component';
import { Lost01Component } from './lost/steps/lost01/lost01.component';
import { ApplyInfoComponent } from './reservation/apply-info/apply-info.component';
import { ApplyCancelComponent } from './reservation/apply-cancel/apply-cancel.component';
import { PasscardCancelComponent } from './contract/passcard-cancel/passcard-cancel.component';
import { ContractDetailComponent } from './contract/contract-detail/contract-detail.component';
import { CvsPayInfoComponent } from './pay/cvs-payinfo/cvs-payinfo.component';
import { PayRequestComponent } from './pay/pay-request/pay-request.component';
import { TerminalPayInfoComponent } from './pay/terminal-payinfo/terminal-payinfo.component';
import { PaypayPayinfoComponent } from './pay/paypay-payinfo/paypay-payinfo.component';
import { CreditCardPayInfoComponent } from './pay/creditcard-payinfo/creditcard-payinfo.component';
import { RefreshComponent } from './refresh/refresh.component';
import { SealReissueComponent } from './seal-reissue/seal-reissue.component';
import { Refresh01Component } from './refresh/steps/refresh01/refresh01.component';
import { Refresh02Component } from './refresh/steps/refresh02/refresh02.component';
import { Refresh03Component } from './refresh/steps/refresh03/refresh03.component';
import { SealReissue01Component } from './seal-reissue/steps/seal-reissue01/seal-reissue01.component';
import { SealReissue02Component } from './seal-reissue/steps/seal-reissue02/seal-reissue02.component';
import { SealReissue03Component } from './seal-reissue/steps/seal-reissue03/seal-reissue03.component';
import { ICRegisterComponent, ICRegisterResolver } from './ic-register/ic-register.component';
import { ApplySuccessResolver, PasscardTypesResolver } from './apply/apply.service';
import { ProcSuccessComponent } from './proc-success/proc-success.component';
import { LostApplySuccessResolver } from './lost/lost.service';
import { SealReissueSuccessResolver } from './seal-reissue/seal-reissue.service';
import { RefreshSuccessResolver } from './refresh/refresh.service';
import { AppliesResolver, ReservationsComponent } from './reservations/reservations.component';
import { Paypaycomponent } from './pay/paypay-flow/paypay/paypay.component';
import { PayListComponent } from './pay/pay-list/pay-list.component';
import { PayDetailComponent } from './pay/pay-detail/pay-detail.component';
import { redirectComponents } from './pay/paypay-flow/redirect/redirect.routing';
import { PastPasscardsComponent } from './past-passcards/past-passcards.component';
import { EditDeliveryComponent } from './reservation/delivery-edit/edit-delivery/edit-delivery.component';
import { DeliveryConfirmComponent } from './reservation/delivery-edit/changed-confirm/changed-confirm.component';
import { DeliveryEditComponent } from './reservation/delivery-edit/delivery-edit.component';
import { ChangeCompleteComponent } from './change-confirm/complete/complete.component';
import { Pay2CompleteComponent } from './pay/pay2-complete/pay2-complete.component';
import { ApplyListComponent } from './apply-list/apply-list.component';
import { PasscardChangeComponent } from './passcard-change/passcard-change.component';
import { PasscardChange01Component } from './passcard-change/steps/step01/passcard-change01.component';
import { PasscardChange02Component } from './passcard-change/steps/step02/passcard-change02.component';
import { PasscardChange03Component } from './passcard-change/steps/step03/passcard-change03.component';
import { ApplyListComponents, applyListRoutes } from './apply-list';
import { passcardCommonComponents } from './common';
import { ApplyDetailComponent } from './apply-list/apply-detail/apply-detail.component';
import { certiUploadComponents, certiUploadRoutes } from './certificate-upload';
import { receiptIssueComponents, receiptIssueRoutes } from './pay/receipt-issue';
import { NoticeInfoComponent } from './common/notice-info/notice-info.component';
import { Redirect3DSecureComponent} from './Redirect3DSecure/redirect3dsecure.component';

export const passcardComponents = [
  ...passcardCommonComponents,
  ApplyGuideComponent,
  ApplyComponent,
  Apply01Component,
  Apply02Component,
  Apply03Component,
  Lost01Component,
  Lost02Component,
  Lost03Component,
  Refresh01Component,
  Refresh02Component,
  Refresh03Component,
  SealReissue01Component,
  SealReissue02Component,
  SealReissue03Component,
  ChangeConfirmComponent,
  ChangeCompleteComponent,
  LostComponent,
  SealReissueComponent,
  RefreshComponent,
  ContractComponent,
  ReservationComponent,
  ReservationsComponent,
  PassCardComponent,
  ApplyInfoComponent,
  ApplyCancelComponent,
  PasscardCancelComponent,
  ContractDetailComponent,
  CvsPayInfoComponent,
  PayRequestComponent,
  TerminalPayInfoComponent,
  PaypayPayinfoComponent,
  Pay2CompleteComponent,
  CreditCardPayInfoComponent,
  ICRegisterComponent,
  PayListComponent,
  PayDetailComponent,
  ProcSuccessComponent,
  Paypaycomponent,
  PastPasscardsComponent,
  EditDeliveryComponent,
  DeliveryConfirmComponent,
  DeliveryEditComponent,
  PasscardChangeComponent,
  PasscardChange01Component,
  PasscardChange02Component,
  PasscardChange03Component,
  NoticeInfoComponent,
  Redirect3DSecureComponent,
  ...receiptIssueComponents,
  ...redirectComponents,
  ...ApplyListComponents,
  ...certiUploadComponents,
];


export const ticketRoutes: Routes = [
  { path: '', component: ApplyGuideComponent },
  { path: 'guide', component: ApplyGuideComponent },
  { path: 'passcard/paypay', component: Paypaycomponent },
  {
    path: 'passcard/new', component: ApplyComponent, children: [
      { path: '', redirectTo: 'step/1', pathMatch: 'full' },
      { path: 'step/1', component: Apply01Component, data: { status: 1 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/2', component: Apply02Component },
      { path: 'step/3', component: Apply03Component },
      { path: 'step/4', component: ProcSuccessComponent, resolve: { data: ApplySuccessResolver } },
    ]
  },
  {
    path: 'passcard/reapply', component: ApplyComponent, children: [
      { path: '', redirectTo: 'step/1', pathMatch: 'full' },
      { path: 'step/1', component: Apply01Component, data: { status: 3 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/2', component: Apply02Component },
      { path: 'step/3', component: Apply03Component },
      { path: 'step/4', component: ProcSuccessComponent, resolve: { data: ApplySuccessResolver } },
    ]
  },
  {
    path: 'passcard/correct', component: ApplyComponent, children: [
      { path: '', redirectTo: 'step/1', pathMatch: 'full' },
      { path: 'step/1', component: Apply01Component, data: { status: 3 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/2', component: Apply02Component },
      { path: 'step/3', component: Apply03Component },
      { path: 'step/4', component: ProcSuccessComponent, resolve: { data: ApplySuccessResolver } },
    ]
  },
  { path: 'passcard/list', component: ReservationsComponent, resolve: { data: AppliesResolver } },
  { path: 'passcard/list-auto', component: ReservationsComponent, data: { auto: true }, resolve: { data: AppliesResolver } },
  { path: 'passcard/past-passcards', component: PastPasscardsComponent },
  { path: 'passcard/apply/canceled', component: ProcSuccessComponent },
  { path: 'passcard/apply/cancel', component: ApplyCancelComponent },
  { path: 'passcard/rejection', component: ChangeConfirmComponent },
  { path: 'passcard/rejection/ok', component: ChangeCompleteComponent },
  { path: 'passcard/terminal-pay', component: TerminalPayInfoComponent },
  { path: 'passcard/conveni-pay', component: CvsPayInfoComponent },
  { path: 'passcard/2pay-pay', component: PaypayPayinfoComponent },
  { path: 'passcard/2pay/complete', component: Pay2CompleteComponent },
  { path: 'passcard/ic-register/:passcardApplyNo', component: ICRegisterComponent, resolve: { data: ICRegisterResolver } },
  {
    path: 'passcard/lost', component: LostComponent, children: [
      { path: '', component: Lost01Component, data: { status: 4 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/1', component: Lost01Component, data: { status: 4 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/2', component: Lost02Component },
      { path: 'step/3', component: Lost03Component },
      { path: 'step/4', component: ProcSuccessComponent, resolve: { data: LostApplySuccessResolver } },
    ]
  }, {
    path: 'passcard/seal-reissue', component: SealReissueComponent, children: [
      { path: '', component: SealReissue01Component, data: { status: 5 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/1', component: SealReissue01Component, data: { status: 5 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/2', component: SealReissue02Component },
      { path: 'step/3', component: SealReissue03Component },
      { path: 'step/4', component: ProcSuccessComponent, resolve: { data: SealReissueSuccessResolver } },
    ]
  }, {
    path: 'passcard/refresh', component: RefreshComponent, children: [
      { path: '', component: Refresh01Component, data: { status: 6 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/1', component: Refresh01Component, data: { status: 6 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/2', component: Refresh02Component },
      { path: 'step/3', component: Refresh03Component },
      { path: 'step/4', component: ProcSuccessComponent, resolve: { data: RefreshSuccessResolver } },
    ]
  }, {
    path: 'passcard/change', component: PasscardChangeComponent, children: [
      { path: '', redirectTo: 'step/1', pathMatch: 'full' },
      { path: 'step/1', component: PasscardChange01Component },
      { path: 'step/2', component: PasscardChange02Component },
      { path: 'step/3', component: PasscardChange03Component },
      { path: 'step/4', component: ProcSuccessComponent },
    ]
  },
  { path: 'passcard/contract', component: ContractComponent },
  { path: 'passcard/passcard/cancel', component: PasscardCancelComponent },
  { path: 'passcard/passcard/canceled', component: ProcSuccessComponent },
  { path: 'passcard/applyInfo', component: ReservationComponent },
  { path: 'payment/list', component: PayListComponent },
  { path: 'payment/detail', component: PayDetailComponent },
  { path: 'passcard/apply-history/list', component: ApplyListComponent },
  { path: 'passcard/apply-history/detail', component: ApplyDetailComponent },
  { path: 'passcard/emv3dsecure', component: Redirect3DSecureComponent },
  ...receiptIssueRoutes,
  ...applyListRoutes,
  ...certiUploadRoutes,
];
