<pm-sel [Params]="{ status: wiz.status, dcParkingNo: wiz.dcParkingNo }"></pm-sel>
<br>
<div *ngIf="!wiz.complete" class="buttonBoxVertical">
  <button type="button" class="cmn-btn mr15 bck-btn" (click)="onBack()">戻る</button>
  <loading-button label="次へ"
    (apply)="onNext()"
    [disabled]="nextDisabled"
    [loading]="api.Disabled"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'" />
</div>