import { Component, OnInit } from '@angular/core';

import { ApiService, ConfigService, DeviceService, KeyService, NavigateService, RouterService, StorageService } from './services';
import { printCss } from './shared/color';
import { Router, NavigationEnd } from '@angular/router';

import { Date9 } from './shared/time9';
import { AppModel } from './app.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  constructor(
    public app: AppModel,
    public api: ApiService,
    public conf: ConfigService,
    private device: DeviceService,
    private key: KeyService,
    private navi: NavigateService,
    private routerWatch: RouterService,
    private router: Router,
    private storage: StorageService
  ) { }

  ngOnInit() {
    var c = Date9();
    console.log(c.format());
    console.log(c);
    console.log(c.year(), c.month(), c.date(), c.hour(), c.minute(), c.day());

    this.printCar();

    // IEでのキーボード操作を抑止
    this.key.blockBS();

    if (this.app.login) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (event.url.startsWith('/?parking-code')
              || event.url.startsWith('/login')
              || event.url.startsWith('/registration/confirm')) {
            this.app.login = false;
          }
        }
      });
    }

    // ルーティングを監視
    this.routerWatch.setWatcher();

    // スマホ以外のbodyにpseudoを追加
    this.device.pseudoIfPC();

    //
    window.onpopstate = this.onPopstate;

    //
    // printCss();

    // error reload -> top
    if (location.pathname.endsWith('/error')) {
      this.navi.navigateByUrl('/', true);
    }

    this.conf.setParking();
    // 駐車場情報取得
    if (this.conf.parkingCode) {
      this.api.post('/parkingName', { parkingCode: this.conf.parkingCode }, 1)
        .subscribe(res => {
          if (res.body['resultCode'] == 0) {
            const { parkingName, address1, address2 } = <any>res.body;
            this.app.ParkingPlace = { id: 0, name: parkingName, address: `${address1 ?? ''}${address2 ?? ''}` };
          } else {
            this.navi.navigateByUrl('/error', true);
          }
        });
    }

    // footer link url　取得
    this.api.post('/publishedDocumentInfo', { parkingCode: this.conf.parkingCode }, 1)
    .subscribe(resp => {
      const result = resp.body;
      if (result['resultCode'] == 0) {
        this.app.publishedDocumentInfo = {
          termsServiceURL: result['termsServiceURL'],
          privacyPolicyURL: result['privacyPolicyURL'],
          managementCompanyInfoURL: result['managementCompanyInfoURL'],
          siteUseURL: result['siteUseURL'],
          specifiedCommercialTransactionsURL: result['specifiedCommercialTransactionsURL'],
          passcardApplyTermsServiceURL: result['passcardApplyTermsServiceURL']??'',
          passcardApplyNotesDOCList: result['passcardApplyNotesDOCList']??[],
          applySiteTopLinkName: result['applySiteTopLinkName'],
          applySiteTopLinkURL: result['applySiteTopLinkURL'],
        };
      } else {
        this.navi.navigateByUrl('/error', true);
      }
    });

    // 駐車場コードがないURLで来た場合、ログイン画面に遷移させる
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/'){
          // 駐車場コードをセッションから削除
          if (this.storage._load('parkingCode'))
          {
            this.storage.remove('parkingCode');
          }
          this.app.login = false;
          this.navi.navigateByUrl('/login', true);
        }

      }
    });

  }

  private onPopstate = () => {
    // console.log('pop:', history.length, history.state);
  }

  private printCar = () => {
    console.log(`
         _______
        //  ||\\ \\
  _____//___||_\\ \\___
  )  _          _    \\
  |_/ \\________/ \\___|
____\\_/________\\_/_____
    `);
  }

}
