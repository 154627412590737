import { Component, OnInit } from '@angular/core';
import { ApiService, ConfigService, NavigateService, StorageService, NoticeInfoService } from '../services';

import { AppModel } from '../app.model';
import { MypageModel } from '../mypage/mypage.model';
import { ErrorModel } from '../error/error.model';

import { LoginReq, LoginRes } from './login.interface';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';


interface AutoLoginReq {
  parkingCode: number;
  agreementStatus: number;
}

interface AutoLoginRes {
  resultCode: number;
  loginStatusCode: number;
}

//
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  mail: string;
  passwd: string;
  auto: boolean;
  error: string;
  messages_m: string[];
  messages_p: string[];

  // 利用規約同意の不具合対応
  agreementStatus: string;

  //
  constructor(
    public api: ApiService,
    private app: AppModel,
    private conf: ConfigService,
    private mypage: MypageModel,
    private errorModel: ErrorModel,
    private navi: NavigateService,
    private _storage: StorageService,
    public passApi: PasscardApiProxy,
    private noticeService: NoticeInfoService,
  ) { }

  ngOnInit() {
    if (!this.app.login)
    this.autoLoginApi();
   }

   // 自動ログイン機能
   private autoLoginApi() {
    //this.api.disabled = true;
    
    // 自動ログイン中処理中はログイン画面を表示しない
    document.getElementById('container').style.display ="none";

    // 利用規約同意の不具合対応
    if (this._storage._load('agreementStatus') != null 
		  && this._storage._load('agreementStatus') != undefined) {
      this.agreementStatus = this._storage._load('agreementStatus');
    } else {
      this.agreementStatus = '0';
    }

    const req: AutoLoginReq = {
      parkingCode: Number(this._storage._load('parkingCode')),
      agreementStatus: Number(this.agreementStatus)
    };
    this.api.post<AutoLoginRes>('/autoLogin', req, 1).subscribe(
      res => this.autoLogin(res.body)
    );
  }

  private autoLogin(body: AutoLoginRes) {
    if (body.resultCode === 0 && body.loginStatusCode === 1) {
      this.loginNext()
    } else {
      document.getElementById('container').style.display ="block";
    }
  }

  //
  private afterLogin = (body: LoginRes) => {
    if (body.resultCode === 0) {
      this.loginNext()
      //
    } else if (body.resultCode === 1 || body.resultCode === 2) {
      this.api.enabled();
      this.navi.navigateByUrl('/error', true);
    }
    else {
      this.api.enabled();
      this.error = body.mainMessage;
      this.messages_m = body.validatorMessageMailAddressList;
      this.messages_p = body.validatorMessagePasswordList;
    }
  }

  // ログイン成功時の処理
  private loginNext() {
    this.errorModel.reset();
    this.app.login = true;
    this.app.saveStorage();
    this.mypage.getMypage();
    // 2023.11.02 modified
    //this.navi.navigateByUrl('/passcard', true);

    // 駐車場コードをセッションから削除
    if (this._storage._load('parkingCode'))
    {
      const parkingCode = this._storage._load('parkingCode')
      this._storage.remove('parkingCode');

      // 利用規約同意の不具合対応
      //this.navi.navigateByUrl('/passcard/new', true);
      if (this._storage._load('agreementStatus') != null
         && this._storage._load('agreementStatus') != undefined
         && this._storage._load('agreementStatus') === '1') {
          this.navi.navigateByUrl('/passcard/new', true);
      } else {
        //this.navi.navigateByUrl('/terms?parking-code=' + parkingCode , true);
        this.navi.navigateByUrl('/passcard/list-auto', true);
      }

    } else {
      this.navi.navigateByUrl('/passcard/list-auto', true);
    }

    // 緊急のお知らせ取得
    this.noticeService.getNoticeInformation().subscribe();
  }

  onMail = () => {
    this.error = null;
    this.messages_m = null;
  }

  onPasswd = () => {
    this.error = null;
    this.messages_p = null;
  }

  onApply = () => {
    this.api.disabled = true;
    const c = this.conf;
    const req: LoginReq = {
      mailAddress: this.mail,
      password: this.passwd,
      autoLogin: !!this.auto,
      parkingCode: Number(this._storage._load('parkingCode')),
      agreementStatus: Number(this.agreementStatus)
    };

    this.api.post<LoginRes>('/login', req, 1).subscribe(
      res => this.afterLogin(res.body)
    );
  }

  //
  onPasswdReissue = (evt: Event) => {
    evt.preventDefault();
    this.navi.navigateByUrl('/passwd-reissue/mail');
  }

  onRegistration = (evt: Event) => {
    evt.preventDefault();

    // 会員登録完了後の画面遷移先切替対応
    const url_path = location.pathname;
    this._storage._save('transitionSourceURL', url_path);

    this.navi.navigateByUrl('/registration/mail');
  }

}
