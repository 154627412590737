import { Component, Input } from '@angular/core';
import { PasscardStatus } from '../../passcard.model';
import { Passcard } from '../../models/passcard';

@Component({
  selector: 'passcard-info',
  templateUrl: './passcard-info.component.html'
})
export class PasscardInfoComponent {
  @Input() model: Passcard = { status: 0, name: '', no: 0, unitPrice: 0 };
  @Input() showStatus = true;

  get StatusText() {
    return this.model.status == PasscardStatus.Busy ? '△空き待ち' : '〇申込可';
  }
}
