<div class="nav">
  <div (click)="onTopBtn1Click()">
    <label class="parking-button" [class.active]="active == 0" title="{{btn1Caption}}">
      <i class="icon" [ngClass]="btn1Icon"></i>
      <div>{{btn1Caption}}</div>
    </label>
  </div>
  <div *ngIf="app.login" (click)="onApplyInfoClick()">
    <label class="parking-button" [class.active]="active == 1" title="申請情報">
      <i class="icon note-simple"
        [class.navi-pay-sel]="active == 1"
        [class.note-simple]="active != 1"
        ></i>
      <div>申請情報</div>
    </label>
  </div>
  <div (click)="onTopBtn2Click()">
    <label class="parking-button" [class.active]="active == 2" title="{{btn2Caption}}">
      <i class="icon" [ngClass]="btn2Icon"></i>
      <div>{{btn2Caption}}</div>
    </label>
  </div>
</div>
