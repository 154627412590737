import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, NavigateService } from 'src/app/services';

@Component({
  selector: 'receipt-issue',
  templateUrl: './receipt-issue.component.html'
})
export class ReceiptIssueComponent {
  private readonly _navi = inject(NavigateService);
  public readonly api = inject(ApiService);
  private readonly _route = inject(ActivatedRoute);
  private readonly _router = inject(Router);

  navigate(url: string, state?: any) {
    if (this._navi.isLocked()) return;

    this._router.navigate([url], { relativeTo: this._route, state });
    this.api?.enabled(0);
  }
}
