import { Location } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NavigateService, ValidateService } from 'src/app/services';

const nameCaption = '宛名';

@Component({
  selector: 'receipt-changing',
  templateUrl: './changing.component.html',
})
export class ReceiptChangingComponent {
  private readonly _nav = inject(NavigateService);
  valid = inject(ValidateService);

  cacheData: any;
  message: string[] = [];
  messages = [];
  oldName: string;
  name: string;
  processDt: string;
  processMs: string;

  constructor(location: Location) {
    const state = location.getState();
    this.cacheData = state['cacheState'];
    this.name = state['name'];
    this.oldName = this.name;
    this.processDt = state['processDt']; 
    this.processMs = state['processMs']; 
  }

  ngDoCheck() {
    this.validation();
  }

  get error() {
    return !!this.message.length
  }

  validation() {
    const msg = this.valid;
    this.message = msg.validRequired(this.name, nameCaption);
    if (this.message.length == 0) {
      msg.pushSetname(this.message, this.name, nameCaption);
    }

    if (this.message.length == 0) {
      msg.pushSurrogate(this.message, this.name, nameCaption);     
    }
    
    return this.message && !this.message.length;
  }

  onChange = () => this.messages = null;

  onConfirm() {
    this._nav.navigate('/payment/receipt/confirm', false, { name: this.name, processDt: this.processDt, processMs: this.processMs, cacheState: this.cacheData });
  }

  onBack() {
    this._nav.navigate('/payment/receipt/tip', false, { name: this.oldName, processDt: this.processDt, processMs: this.processMs, cacheState: this.cacheData });  
  }
}
