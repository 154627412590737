<ng-container *ngIf="reservation">
    <div class="passcard-app-title"><h2>定期情報</h2><div><a class="passcard-link" routerLink="/passcard/past-passcards">過去の定期</a></div></div>
    <status-tip (click)="onStatusTipClick()" [status]="reservation.status" [applyInfo]="reservation" #statusTip></status-tip>
    <apply-info [tipKind]="statusTip.applying | async" [reservation]="reservation">
        <div class="buttonBoxVertical">
            <loading-button class="six" label="申請中の情報を表示する"
                (apply)="onApplyDetail()"
                [icon]="true"
                [disabled]="NextDisabled"
                [loading]="api.Disabled"
                [class]="api.Disabled ? 'cmn-btn' : 'cmn-btn icon'"
                [lClass]="'button-box-loading'"><i class="icon left sign"></i><span>申請中の情報を表示する</span></loading-button>
        </div>        
        <br>

        <div class="buttons">
            <div class="large-button apply-button" *ngIf="reservation.status == ApplyStatus.WaitingICRegist" (click)="onICRegister()">
                <label title="ICカード登録">
                    <div><i class="icon Suica"></i></div>
                  <div>ICカード登録</div>
                </label>
            </div>
            <div class="large-button apply-button" *ngIf="PayButtonVisible" (click)="onPay()">
                <label title="お支払い">
                  <div><i class="icon money-circle"></i></div>
                  <div>お支払い</div>
                </label>
            </div>
            <div class="large-button apply-button"
                *ngIf="reservation.status == ApplyStatus.WaitingModify" (click)="onModifyConfirm()">
                <label title="修正確認">
                    <div><i class="icon note-pen"></i></div>
                    <div>修正確認</div>
                </label>
            </div>
            <div class="large-button apply-button"
                *ngIf="applyBtnVisible" (click)="onReApply()">
                <label title="定期申請">
                    <div><i class="icon note-pen"></i></div>
                    <div>定期申請</div>
                </label>
            </div>
            <div class="large-button" *ngIf="cancelBtnVisible" (click)="onCancelConfirm()">
                <label title="申請取消">
                    <div><i class="icon cancel"></i></div>
                    <div>申請取消</div>
                </label>
            </div>
        </div>
        <br>
    </apply-info>
</ng-container>