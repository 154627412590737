import { Component, Input } from '@angular/core';

@Component({
  selector: 'p-info',
  templateUrl: './infomation.component.html'
})
export class InfomationComponent {
  /**
   * ヒント: "proposal"
   * 警告: "warning"
   * 危険: "danger"
   */
  @Input() kind = "proposal";
}