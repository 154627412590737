<div class="tips {{tipClass}} {{clickable}}" [ngClass]="{'cursor-pointer': !!clickable}">
  <div><i class="icon" [ngClass]="iconClass"></i></div>
  <div>
    <ng-container [ngSwitch]="status">
      <ng-template [ngSwitchCase]="ApplyStatus.WaitingFree">
        <div>順番待ちです。</div>
      </ng-template>
      <ng-template ngSwitchDefault>
        <div [ngClass]="MainMsgClass">
          <div *ngFor="let mainMsg of mainMessage">{{mainMsg}}</div>
        </div>
      </ng-template>
    </ng-container>
    <div class="sub-tip">
      <p>{{subMessage[0]}}</p>
      <p *ngIf="subMessage[1]"><span *ngIf="extra"><b>{{extra}}</b></span>{{subMessage[1]}}</p>
    </div>
  </div>
  <div *ngIf="!!clickable"><span><b>></b></span></div>
</div>