<div class="wrapper">
  <app-header [showLink]="app.publishedDocumentInfo.applySiteTopLinkName && app.publishedDocumentInfo.applySiteTopLinkURL" *ngIf="viewHeader()" id="header"></app-header>
  <div class="emg-notice" *ngIf="viewNoticeList()">
    <div>
      <div *ngFor="let notice of getNoticeInfoList(); let i=index;">
        <i class="icon exclamation-circle"></i>
        <a (click)="onDetail(i)" [state]="notice">{{notice.title}}</a>
      </div>
    </div>
    <div>
      <div (click)="onNoticeExpand()">全{{noticeCount}}件<i class="icon"
        [class.triangle-down]="!noticeExpanded"
        [class.triangle-up]="noticeExpanded"
        ></i></div>  
    </div>
  </div>
  <app-navi *ngIf="viewNavi()" class="navi"></app-navi>
  
  <div id="containerWrap">
    <router-outlet></router-outlet>
  </div>

  <app-footer *ngIf="viewFooter()" id="footer"></app-footer>
</div>
