import { Injectable } from '@angular/core';

const storage = localStorage;
//const storage = sessionStorage;

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  // for api-token
  _load = (key: string) => {
    return storage.getItem(key);
  }

  _save = (key: string, str: string) => {
    storage.setItem(key, str);
  }

  // for storage model
  private keys = (obj: any) => {
    const keys = [];

    Object.getOwnPropertyNames(obj).forEach(n => {
      const t = typeof obj[n];
      if ((t !== 'function') && (!n.startsWith('_')))
        keys.push(n);
    });

    return keys;
  }

  private rm_keys = (obj: any) => {
    if (obj instanceof Object)
      for (const key in obj) {
        if (key.startsWith('_'))
          obj[key] = undefined;
        else
          this.rm_keys(obj[key]);
      }
  }

  load = (key: string, obj: Object) => {
    const s = storage.getItem(key);
    if (!s)
      return null;

    const o = JSON.parse(s)
    if (!!obj && !!o)
      for (const k of this.keys(o))
        obj[k] = o[k];

    return o;
  }

  save = (key: string, obj: Object) => {
    let s = null;

    if (!!obj) {
      const o = {};
      for (const k of this.keys(obj))
        o[k] = obj[k];

      // this.rm_keys(o);
      s = JSON.stringify(o);
    }

    storage.setItem(key, s);
  }

  remove = (key: string) => {
    storage.removeItem(key);
  }

  logout = () => {
    // claer storage
    for (let i = storage.length - 1; 0 <= i; i--) {
      const key = storage.key(i);
      if (!key.startsWith('_'))
        this.remove(key);
    }

    // clear cookies
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
      const pair = cookie.split('=');
      if (pair[0])
        document.cookie = pair[0] + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    });
  }
}
