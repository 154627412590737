import { Injectable } from '@angular/core';

//
declare var dcMemberCompanyNo: number;

//
@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  // config
  use_etc: boolean;
  //
  parkingCode: string;

  //
  memberCategory: number;
  reserveRegist: number;
  reserveCompany: number;
  memberCompany: number;

  businessArea: number;
  areas: { value: number; text: string; dcParkingNo: number }[];
  spaces: number[];

  paypay_interval = 2;
  paypay_limit = 5;

  //
  constructor() {
    //
    //this.memberCompany = dcMemberCompanyNo;
  }

  setAreaSpace = (i: number) => {
  }

  setSpace = (n: number) => {
    this.setAreaSpace(this.spaces.findIndex(v => v === n));
  }

  getParkingNo = (v: number) => {
    return undefined;
  }

  // 駐車場コード対応
  setParking = async () => {
    const base = document.querySelector('base').href;
    const path = document.URL.substring(base.length);
    const s = path.split('=');
    //this.parkingCode = s[1];
    if (s[1]) {
      // クエリパラメータで駐車場コードが渡ってきた場合

      if (path.match('passcard/2pay/complete')) {
        const s2 = s[1].split('?');
        this.parkingCode = s2[0];
      }else if(path.startsWith('passcard/emv3dsecure')){
        console.log('a');
        return this.parkingCode;
      } else {
        this.parkingCode = s[1];
      }
      // this.parkingCode = s[1];
    } else {
      if (path.match('registration/confirm')) {
        const s2 = path.split('/');
        if (s2[4]) {
          // 確認メールのリンクURLで駐車場コードが渡ってきた場合
          this.parkingCode = s2[4];
        }
      } else if (path.match('passwd-reissue/confirm')) {
        const s3 = path.split('/');
        if (s3[4]) {
          // パスワード変更の確認メールのリンクURLで駐車場コードが渡ってきた場合
          this.parkingCode = s3[4];
        }
      }
    }
    return this.parkingCode;
  }
}
