import { Component, inject } from '@angular/core';
import { PasscardStatus, ReceptionMode, getStorageKey } from '../passcard.model';
import { AppModel } from 'src/app/app.model';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { NavigateService, StorageService } from 'src/app/services';
import { PaymentType } from '../pay/pay.model';
import { Passcard } from '../models/passcard';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

export interface IWizard {
  maxProcessed: number;
  processing: number;
  complete: boolean;
}

@Component({
  selector: 'apply',
  templateUrl: './apply.component.html'
})
export class ApplyComponent implements IWizard {
  TS = PasscardStatus;
  private _storage = inject(StorageService);
  paramsReApply: any;
  passcardApplyNo: number | null = null;
  paymentType: number | null = null;

  parkingName = new BehaviorSubject('');
  parkingAddress: string;
  completeMsg = ['', '']
  receptionNo: string | null = null;
  btnPhoneMode = false;
  currentProc: number = 0;
  app = inject(AppModel);
  maxProcessed = 0;
  processing = 0;
  complete = false;

  passcard: Passcard | null = null;
  paymethodLable: string = '';
  
  constructor(
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    router: Router,
    l: Location) {
    const state = l.getState();
    const dcParkingNo = state?.['dcParkingNo'] ?? null;
    if (dcParkingNo != null) {
      this.paramsReApply = this._storage.load(`passcard-reapply-${dcParkingNo}`, null);
      
      this.parkingName.next(this.paramsReApply.parkingName);
      this.parkingAddress = this.paramsReApply.parkingAddress;
    }

    const status = !this.paramsReApply ? 1 : (state?.['status'] ?? this.paramsReApply.applyStatus);
    const cache01Key = getStorageKey(`passcard-apply-1`, status, dcParkingNo);

    this._storage.load('apply-wiz', this);
    const cache01 = this._storage.load(cache01Key, null);
    if (!this.parkingName.getValue() && cache01?.parkingName) {
      this.parkingName.next(cache01.parkingName);
      this.parkingAddress = cache01.parkingAddress;
    }

    this.passcard = cache01?.passcard ?? null;
    const cache03Key = this.getStorageKey(`passcard-apply-complete`);
    const cache02Key = this.getStorageKey(`payment-method`);
    this.passcardApplyNo = this._storage.load(cache03Key, null)?.passcardApplyNo ?? null;
    this.paymentType = this._storage.load(cache02Key, null)?.method ?? null;
    this.complete = this.passcardApplyNo != null;
    this.maxProcessed = this.complete ? 3 : this.maxProcessed;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { url } = <NavigationEnd>event;
        if (url.includes('/passcard/new/step/4')) {
          this.processing = 3;
        }
        else if (url.includes('/passcard/new/step/3')) {
          this.processing = 2;
        }
        else if (url.includes('/passcard/new/step/2')) {
          this.processing = 1;
        }
        else {
          this.processing = 0;
        }
        if (url.includes('/passcard/new/')) {
          this.saveState();
        }
      }
    });
  }

  saveState(): void {
    const { maxProcessed, processing, complete } = this;
    this._storage.save('apply-wiz', {
      processing,
      maxProcessed,
      complete,
    });
  }

  get tipVisible() {
    return this.processing == 2;
  }

  get showFlowBoxes() {
    const status = this.status;
    return this.processing < 2
      || status == 1 || status == 3 || status == 8;
  }

  get PayMethodLabel() {
    return PaymentType[this.paymentType];
  }

  /**
   * 1：申込可の定期券を申請する場合
   * 2：空き待ちの定期券を申請する場合
   * 3：申請受付中の定期券を申請する場合（空き待ちだった順番が来た定期券を申請する場合）
   * 4：紛失の申請を行う場合
   * 5：シール再発行の申請を行う場合
   * 6：定期更新の申請を行う場合
   * 7：(手動承認)承認前の定期申請を行う場合
   * 8：(手動承認)承認後の定期申請を行う場合
   * 9：(手動承認)承認前の申請受付中の定期券を申請する場合（空き待ちだった順番が来た定期券を申請する場合）
   */
  get status() {
    if (!this.passcard) return 1;
    let status = 1;
    const receptionMode = this.passcard.receptionMode;
    if (this.paramsReApply) {
      status = this.paramsReApply.applyStatus == 8 ? 8 : (receptionMode == ReceptionMode.Auto ? 3 : this.paramsReApply.applyStatus);
    }
    else {
      status = this.passcard.status == PasscardStatus.Busy ? 2 : (receptionMode == ReceptionMode.Auto ? 1 : 7);
    }

    return status;
  }

  getStorageKey(base: string): string {
    return getStorageKey(base, this.status, this.paramsReApply?.dcParkingNo??null);
  }

  async onToPayDetail() {
    let to = 'ic-register';
    switch (this.paymentType) {
      case PaymentType.Terminal:
        to = 'terminal-pay';
        break;
      case PaymentType.CreditCard:
        to = `ic-register/${this.passcardApplyNo}`;
        break;
      case PaymentType.PayPay:
        to = '2pay-pay';
        break;
      case PaymentType.Cvs:
        to = 'conveni-pay';
        break;
    }

    let paymentInfo = null;
    if (this.paymentType != 1) {
      paymentInfo = await this.api.getPaymentInfo(this.paymentType, this.passcardApplyNo);
    }

    this._navi.navigate(`/passcard/${to}`, false, { passcardApplyNo: this.passcardApplyNo, paymentInfo });
  }

  /**
  * 申請状況を確認する
  */
  onToConfirm() {
    this._navi.navigateByUrl('/passcard/list');
  }
}
