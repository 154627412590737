import { Component, Input } from '@angular/core';
import { MyFilesModel } from '../i-my-files/i-my-files.component';
import { CertificateType } from '../certificate-file';

//
@Component({
  selector: 'p-my-files',
  templateUrl: './p-my-files.component.html',
})
export class PMyFilesComponent {
  private _model: MyFilesModel;
  certiExpire: string;

  @Input() certificateNextCheckDate?: number;
  @Input() set model(val: MyFilesModel) {
    this._model = val;
    // const { year, month } = val;
    // if (year && month) {
    //   this.certiExpire = `${year}(令和${+year - 2018})年 ${+month}月`
    // }
  }

  @Input() certificateType: CertificateType;

  get model() {
    return this._model;
  }
}