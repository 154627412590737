<h2>申請詳細</h2>
<p-info kind="warning">
  <p>申請が差し戻されました。差し戻し理由を確認し、登録内容の修正をお願いいたします。</p>
  <p>申請内容の修正は、本画面の「申請を修正する」ボタンから行えます。</p>
</p-info>
<div class="tableCtn">
  <p-single label="差し戻し理由" [value]="RejectReason" />
  <p-single label="修正期限" [value]="LastChangableTime" />
</div>
<br>
<div class="buttonBoxVertical">
  <loading-button label="申請を修正する"
    (apply)="onCorrect()"
    [disabled]="api.Disabled"
    [loading]="api.Disabled"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'" />
</div>
<br>
<apply-details></apply-details>
<br>

<div class="buttonBoxVertical">
  <button (click)="onBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button label="申請を削除する"
    (apply)="onDelete()"
    [disabled]="api.Disabled"
    [loading]="api.Disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn'" />
</div>

<popup-modal #changeConfirm>
  <div class="cancel-constract-modal">
    <div><i class="icon info"></i></div>
    <div>修正申請を実行します。</div>
    <div class="comment">
      <div>この操作は取消ができません。</div>
      <div>修正申請を実行すると、再度定期申請が行われます。</div>  
    </div>
    <div>
      <div *ngIf="!api.Disabled">
        <button type="button" class="cmn-btn bck-btn" (click)="onDlgClose()">閉じる</button>
      </div>
      <div>
        <loading-button label="修正確定"
          [disabled]="api.Disabled"
          [loading]="api.Disabled"
          [lClass]="'button-box-loading'"
          [class]="'cmn-btn dcd-btn'" />
      </div>
    </div>
  </div>
</popup-modal>