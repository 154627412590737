import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PayModel } from '../../models/pay.model';

@Component({
  selector: 'redirect-cancel',
  templateUrl: './cancel.component.html',
})
export class CancelComponent implements OnInit {
  query: string;

  constructor(
    private route: ActivatedRoute,
    private pay: PayModel
  ) { }

  ngOnInit() {
    this.pay.resetAll();

    this.route.queryParams.subscribe(
      params => this.query = JSON.stringify(params, null, ' '));
  }

}
