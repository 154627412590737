import { Injectable, inject } from "@angular/core";
import { PasscardContract } from "../models/passcard-contract";
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { Observable, from } from "rxjs";
import { ApplyModel } from "../apply/apply.service";
import { PasscardApplyApi } from "../models/passcard";
import { CeditDefault } from "../pay/pay.model";

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  applyParams: PasscardApplyApi;
  processing = 0;
  maxProcessed = 0;
  passcardApply: PasscardContract;
  inputCache: ApplyModel = {
    passcardApplyNo: null,
    passcardMonth: 0,
    buyICCard: -1,   // -1:選択されない 0:購入しない、1:購入する
    payMethod: { method: -1, creditPay:{ cardUse:-1, '0': {...CeditDefault}, '1': {...CeditDefault} }, cvsPay: {}},
    selectTargetMonth: 0,
  };
  
  applyModel: ApplyModel = {
    passcardApplyNo: null,
    passcardMonth: 0,
    buyICCard: -1,
    payMethod: { method: -1 }
  }

  reissueFee: number = 0;
  complete = false;
  cancelComplete = false;

  reset() {
    this.complete = false;
    this.processing = 0;
    this.maxProcessed = 0;
    this.reissueFee = 0;
    this.inputCache = {
      passcardApplyNo: null,
      passcardMonth: 0,
      buyICCard: -1,   // -1:選択されない 0:購入しない、1:購入する
      payMethod: { method: -1, creditPay:{ cardUse:-1, '0': {...CeditDefault}, '1': {...CeditDefault} }, cvsPay: {}},
      selectTargetMonth: 0,
    };
    this.applyModel = {
      passcardApplyNo: null,
      passcardMonth: 0,
      buyICCard: -1,
      payMethod: { method: -1 }
    }
  }
}

export const RefreshSuccessResolver: ResolveFn<number> = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  service: RefreshService = inject(RefreshService)
): Observable<number> => {
  service.complete = true;
  service.processing = 3;
  service.maxProcessed = 3;
  return from([0]);
}
