<block title="ご請求明細" *ngIf="this.Cach02">
  <p-pay-fees [paidDetail]="paidDetail"></p-pay-fees>
  <br>
</block>
<block title="定期情報">
  <div class="tableCtn">
    <p-single label="駐車場" [value]="wiz.passcardApply.parkingName" />
    <p-single label="現在の定期の種類" [value]="Cach01.beforePasscardTypeName" />
    <p-single label="更新後の定期の種類" [value]="Cach01.passcard.name" />
    <p-single label="更新月数" value="{{Cach01.passcardMonth}}ヵ月({{passcardFee | currency:'JPY'}})" />
    <p-single label="更新後の定期期間" [value]="fixedPeriod" />
    <p-single label="更新後の更新可能期間" [value]="updatablePeriod" />
  </div>
</block>

<div class="button-wrapper">
  <button type="button" class="cmn-btn bck-btn" (click)="onReInput(1)">定期情報を再選択する</button>
</div>
<block title="証明書等登録" *ngIf="showCertificateUpload">
  <p-my-files [certificateType]="certificateTypeSetting" [model]="myFilesModel"></p-my-files>
</block>
<br>
<place-info [parkingInfo]="parking"></place-info>
<br>
<ng-container *ngIf="this.Cach02">
  <block title="お支払い情報">
    <p-pay-method [payInfo]="Cach02"></p-pay-method>
  </block>
  <br>
  <div *ngIf="!wiz.complete" class="button-wrapper">
    <button type="button" class="cmn-btn bck-btn" (click)="onReInput(2)">お支払い情報入力に戻る</button>
  </div>
  <br>
</ng-container>
<div *ngIf="!wiz.complete" class="buttonBox">
  <loading-button class="six" label="申請"
    (apply)="onApply()"
    [disabled]="api.Disabled"
    [loading]="api.Disabled"
    [class]="'cmn-btn dcd-btn six'"
    [lClass]="'button-box-loading'" />
</div>