import { Component, inject } from '@angular/core';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { Location } from '@angular/common';
import { NavigateService } from 'src/app/services';
import { AppModel } from 'src/app/app.model';

@Component({
  selector: 'pay2-complete',
  templateUrl: './pay2-complete.component.html'
})
export class Pay2CompleteComponent {
  private readonly _navi = inject(NavigateService);

  constructor(public app: AppModel){}

  onBack() {
    this._navi.navigateByUrl('/passcard/list');
  }
}
