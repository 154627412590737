import { Component, Input, inject } from '@angular/core';
import { CVS, formatDate } from '../../passcard.model';
import { PayInfomation } from '../../pay/pay.model';
import { ValidateService } from 'src/app/services';
import { CvsInfo } from '../../pay/models/CvsInfo.model';

/**
 * コンビニ支払い
 */
@Component({
  selector: 'pay-cvs',
  templateUrl: './pay-cvs.component.html'
})
export class PayByCvsComponent {
  msgCvs: string[] = [];
  valid = inject(ValidateService);
  cvsImages = CVS;
  private _paymentTerm: string;
  
  @Input() cvsInfo: Readonly<CvsInfo>;
  @Input() model: PayInfomation;

  ngDoCheck() {
    const error = this.validation();
    if (error != this.model.error) {
      setTimeout(() => this.model.error = error);
    }
  }

  get paymentTerm() {
    if (!this._paymentTerm && this.model.paymentTerm) {
      const dateStr = formatDate(this.model.paymentTerm);
      const date = new Date(this.model.paymentTerm);
      const hours = `${date.getHours()}`.padStart(2, '0');
      const minutes = `${date.getMinutes()}`.padStart(2, '0');
      this._paymentTerm = `${dateStr} ${hours}:${minutes}`;      
    }

    return this._paymentTerm;
  }

  get maxWidth() {
    let width = 0;
    this.cvsList.forEach(x => width += CVS[x.cvsCode].width);
    width += (this.cvsList.length - 1) * 4;
    return width;
  }

  get cvsList() {
    return this.cvsInfo?.cvsList ?? [];
  }

  get fee() {
    return this.model.cvsPay.payCommission;
  }

  get PayModel() {
    this.model.cvsPay.cvsCode ??= '-1';
    return this.model.cvsPay;
  }

  get PayExpire() {
    return formatDate(this.PayModel?.payExpire);
  }

  validation(): boolean {
    this.msgCvs = this.PayModel.cvsCode == '-1' ? ['お支払い先コンビニを入力してください。'] : [];
    return this.msgCvs.length > 0;
  }

  onSelected() {
    const cvsCode = this.PayModel.cvsCode;
    this.model.cvsPay.cvsName = this.cvsInfo.cvsList.find(x => x.cvsCode == cvsCode)?.cvsName;
  }
}
