<button (click)="onClick()" [disabled]="disabled || loading" [ngClass]="getClass()" type="button">
  <div *ngIf="loading" class="ballsWaveG-wrap">
    <div class="ballsWaveG ballsWaveG-1"></div>
    <div class="ballsWaveG ballsWaveG-2"></div>
    <div class="ballsWaveG ballsWaveG-3"></div>
    <div class="ballsWaveG ballsWaveG-4"></div>
    <div class="ballsWaveG ballsWaveG-5"></div>
    <div class="ballsWaveG ballsWaveG-6"></div>
    <div class="ballsWaveG ballsWaveG-7"></div>
    <div class="ballsWaveG ballsWaveG-8"></div>
    <div class="ballsWaveG ballsWaveG-9"></div>
    <div class="ballsWaveG ballsWaveG-10"></div>
  </div> <ng-container *ngIf="loading; else normal; let label, icon">LOADING</ng-container>
</button>
<ng-template #normal>
  <ng-container *ngIf="!icon; else content">
    {{ label }}
  </ng-container>
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</ng-template>