import { Injectable, inject } from '@angular/core';
import { Passcard, PasscardApplyApi } from '../models/passcard';
import { StorageService } from 'src/app/services';
import { PayInfomation } from '../pay/pay.model';
import { PasscardApply } from '../models/apply-info';
import { getStorageKey } from '../passcard.model';

export interface ApplyModel {
    passcard?: Passcard;
    dcParkingNo?: number;
    passcardApplyNo?: number;
    passcardMonth: number;
    buyICCard: number;
    payMethod: PayInfomation;
    selectTargetMonth?: number;
    passcardValidFrom?: number;
    passcardValidTo?: number;
}

/**
 * 定期券予約サービス
 */
@Injectable()
export class PasscardChangeService {
    static key = 'passcard-change-wiz';
    private readonly _storage = inject(StorageService);
    passcardApply: PasscardApply;
    applyParams: PasscardApplyApi;
    Cach01: any;
    status: number;
    processing = 0;
    maxProcessed = 0;
    complete = false;

    constructor() {
       this._storage.load(PasscardChangeService.key, this);
       this.processing ??= 0;
       this.maxProcessed ??= 0;
       this.complete ??= false;
    }

    saveState() {
        this._storage.save(PasscardChangeService.key, this);
    }

    static clear(storage: StorageService, dcParkingNo: number, status: number = 6) {
        storage.remove(PasscardChangeService.key);
        storage.remove(getStorageKey('payment-method', status, dcParkingNo));
        storage.remove('passcard-change-2');
        storage.remove('passcard-change-3');
    }
}
