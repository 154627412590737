import { CertificateType, ImageCheckReq } from '../shared/certificate-file';
import { Time9 } from '../shared/time9';
import { UserInfo } from './models/user-info';

export enum PasscardStatus {
  NoVacancies = -1,
  StopApply = 0,
  Available = 1,
  WaitingVacancies = 2,
}

export const PasscardStatusText = Object.freeze({
  '-1': '×空き無し',
  '0': '×受付停止',
  '1': '〇申込可',
  '2': '△空き待ち'
});

export const CVS = Object.freeze({
  Seven11: '00007', '00007': { name: 'セブン-イレブン', cls: 'seven11', width: 65 },
  Family: '10002', '10002': { name: 'ファミリーマート', cls: 'family', width: 65 },
  Lawson: '10001', '10001': { name: 'ローソン', cls: 'lawson', width: 65 },
  MiniStop: '10005', '10005': { name: 'ミニストップ', cls: 'ministop', width: 65 },
  Seco: '10008', '10008': { name: 'セイコーマート', cls: 'seico', width: 117 },
});

/**
 * 配送先情報
 */
export interface DeliveryInfo {
  /**
   * 郵便番号(3桁-4桁)
   */
  postcode: string[];

  /**
   * 住所1
   */
  address1: string;

  /**
   * 住所2
   */
  address2: string;

  /**
   * 宛名
   */
  userName: string;

  /**
   * 電話番号
   */
  phone: string;

  byUserZip?: string;
  byUserAddress1?: string;
  byUserAddress2?: string;
  byUserTel?: string;
}

export function deliveryInputToMyPage(delivery: DeliveryInfo): UserInfo {
  return {
    memberShipZipCode: delivery.postcode.join('-'),
    memberShipAddress1: delivery.address1,
    memberShipAddress2: delivery.address2,
    memberShipName: delivery.userName,
    memberShipTel: delivery.phone,
  };
}

export function deliveryInputToDelivery(delivery: DeliveryInfo) {
  return {
    deliveryZipCode: delivery.postcode.join('-'),
    deliveryAddress1: delivery.address1,
    deliveryAddress2: delivery.address2,
    deliveryName: delivery.userName,
    deliveryTel: delivery.phone,
  };
}


export function deliveryToDeliveryInput(delivery: Delivery) {
  return {
    postcode: delivery.deliveryZipCode.split('-'),
    address1: delivery.deliveryAddress1,
    address2: delivery.deliveryAddress2,
    userName: delivery.deliveryName,
    phone: delivery.deliveryTel,
    byUserZip: '2',
    byUserAddress1: '2',
    byUserAddress2: '2',
    byUserTel: '2'
  };
}

export function myPageToDeliveryInput(mypage: UserInfo): DeliveryInfo {
  return {
    postcode: mypage.memberShipZipCode.split('-'),
    address1: mypage.memberShipAddress1,
    address2: mypage.memberShipAddress2,
    userName: mypage.memberShipName,
    phone: mypage.memberShipTel,
    byUserZip: '2',
    byUserAddress1: '2',
    byUserAddress2: '2',
    byUserTel: '2'
  };
}

export const ApplyStatus = Object.freeze(
  {
    /**
     * なし
     */
    None: 0, '0': { name: 'なし' },

    /**
     * 順番待ち
     */
    WaitingFree: 1, '1': { name: '空き待ち', icon: 'hourglass', fill: 'tips-color-blue2' },

    /**
     * 再申請待ち
     */
    WaitingReApply: 2, '2': { name: '再申請待ち', icon: 'bell', fill: 'tips-color-green' },

    /**
     * 申請確認待ち
     */
    WaitingConfirm: 3, '3': { name: '申請確認待ち' },

    /**
     * 申請修正待ち
     */
    WaitingModify: 4, '4': { name: '申請修正待ち', icon: 'exclamation', fill: 'tips-color-pink' },

    /**
     * 定期発行前支払い待ち
     */
    WaitingPay: 5, '5': { name: '定期発行前支払い待ち' },

    /**
     * 定期発行待ち
     */
    WaitingIssue: 6, '6': { name: '定期発行待ち' },

    /**
     * 定期発送待ち
     */
    WaitingDelivery: 7, '7': { name: '定期発送待ち' },

    /**
     * ICカード登録待ち
     */
    WaitingICRegist: 8, '8': { name: 'ICカード登録待ち', icon: 'Suica', fill: 'tips-color-yellow' },

    /**
     * 定期発行後支払い待ち
     */
    WaitingPayAfterIssued: 9, '9': { name: '定期発行後支払い待ち' },

    /**
     * PayPay支払い待ち
     */
    WaitingPayByPaypy: 10, '10': { name: 'PayPay支払い待ち', icon: 'exclamation', fill: 'tips-color-yellow' },

    /**
     * 定期更新機支払い待ち
     */
    WaitingPayByTerminal: 11, '11': { name: '定期更新機支払い待ち', icon: 'exclamation', fill: 'tips-color-yellow' },

    /**
     * コンビニ支払い待ち
     */
    WaitingPayByConveni: 12, '12': { name: 'コンビニ支払い待ち', icon: 'shop', fill: 'tips-color-yellow' },

    /**
     * 利用可
     */
    Avaliable: 30, '30': { name: '利用可' },

    /**
     * 定期更新支払い待ち
     */
    WaitingPayForRefresh: 31, '31': { name: '定期更新支払い待ち' },

    /**
     * 定期更新確認待ち
     */
    WaitingConfirmForRefresh: 32, '32': { name: '定期更新確認待ち' },

    /**
     * 紛失定期再発行前支払い待ち
     */
    WaitingPayForLost: 41, '41': { name: '紛失定期再発行前支払い待ち' },

    /**
     * 紛失定期再発行待ち
     */
    WaitingReissueForLost: 42, '42': { name: '紛失定期再発行待ち' },

    /**
     * 紛失定期発送待ち
     */
    WaitingDeliveryFoReissue: 43, '43': { name: '紛失定期発送待ち' },

    /**
     * 紛失定期ICカード登録待ち
     */
    WaitingICRegistForLost: 44, '44': { name: '紛失定期ICカード登録待ち' },

    /**
     * 紛失定期再発行後支払い待ち
     */
    WaitingPayAfterReissue: 45, '45': { name: '紛失定期再発行後支払い待ち' },

    /**
     * 証明書等再提出確認待ち
     */
    waitingConfirmForUpload: 51, '51': { name: '証明書等再提出確認待ち' },

    /**
     * 解約予定
     */
    AutoCancellation: 100, '100': { name: '解約予定' },

    /**
     * 定期更新受付中
     * システム年月日が定期更新可能期間内の場合
     */
    WaitingRefresh: 101, '101': { name: '定期更新受付中', icon: 'refresh', fill: 'tips-color-yellow', color: 'chocolate' },

    /**
     * 更新後定期
     */
    AfterRefreshed: 102, '102': { name: '更新後定期' }
  }
);

export const ReceptionMode = Object.freeze({
  Manual: 0, '0': '手動承認',
  Auto: 1, '1': '自動承認'
});

export interface ApiReturn {
  resultCode: number;
  mainMessage?: string;
  [key: string]: any;
}

export const PeriodKind = Object.freeze({
  CurrentMonth: 1, '1': '当月から',
  NextMonth: 2, '2': '翌月から'
});

export const ApplyKind = Object.freeze({
  New: 0, '0': '新規',
  ReApply: 1, '1': '再申請',
  Lost: 2, '2': '紛失',
});

export const StorageKey3dsecure = 'threed-secure-info';
export const StorageKeyApplyNo = 'apply-no';
export const ApplyType = {
  New: '0', // 新規申請
  Refresh: '1', // 更新申請
  Lost: '2', // 紛失申請
};

export interface PeriodInfo {
  kind: number,
  period: string[],
  allow: string[],
}

export const weekDays: ReadonlyArray<string> = ['日', '月', '火', '水', '木', '金', '土']

export function formatDate(dateStr: number | undefined) {
  if (!dateStr) return '';

  const date = new Time9(dateStr);
  return date.getYMD() + '(' + weekDays[date.getDay()] + ')';
}

export function formatPeriod(period: (number | undefined)[]) {
  if (!period[0] && !period[1]) return '';

  return `${formatDate(period[0])} ～ ${formatDate(period[1])}`
}

export interface Delivery {
  deliveryZipCode: string;      // 配送先郵便番号(String),
  deliveryAddress1: string;     // 配送先住所1 (String),
  deliveryAddress2: string;     // 配送先住所2(String),
  deliveryName: string;         // 配送先宛名(String),
  deliveryTel: string;          // 配送先電話番号(String),
}
export interface ApiResult {
  resultCode: number;
  mainMessage?: string;
}

export interface PasscardApplyResultApi extends ApiResult {
  receiptNo?: string;
  passcardApplyNo: number;
}

export interface PasscardApply3dResultApi{
  resultCode: number;
  mainMessage: string;
  authStartUrl: string;
}

export function getDefaultDelivery(): DeliveryInfo {
  return {
    postcode: ['', ''],
    address1: '',
    address2: '',
    userName: '',
    phone: '',
    byUserZip: '2',
    byUserAddress1: '2',
    byUserAddress2: '2',
    byUserTel: '2'
  }
}

export const NOSHOWORDER = 999999;

export function addMonth(now: Date, addMonth: number): number {
  let targetDate = 1;
  const target = new Date(now.getFullYear(), now.getMonth() + addMonth, targetDate);
  const maxDays = new Date(target.getFullYear(), target.getMonth() + 1, 0).getDate();

  targetDate = now.getDate();
  if (now.getDate() > maxDays) {
    targetDate = maxDays;
  }

  return target.setDate(targetDate);
}

export function addDay(src: Date, days: number): number {
  return new Date(src.getFullYear(), src.getMonth(), src.getDate() + days).getTime();
}

export function getPasscardValidEndDay(passcardValidFrom: Date, monthNum: number): number {
  const temp = new Date(addMonth(passcardValidFrom, monthNum));
  if (temp.getDate() < passcardValidFrom.getDate()) {
    return temp.getTime();
  }

  return addDay(temp, -1);
}

export function calcUpdatablePeriod(base: Date,
  passcardUpdatePeriod: number,
  passcardUpdateStartDay: number,
  passcardUpdateEndDay: number): number[] {

  const endDate: Date = new Date(new Date(base.getTime()).setDate(base.getDate() + passcardUpdateEndDay));
  // 時刻を設定
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  const updatablePeriod: number[] = [];
  updatablePeriod[1] = endDate.getTime();
  if (passcardUpdatePeriod == 0) {
    updatablePeriod[0] = base.setDate(base.getDate() - passcardUpdateStartDay);
  } else {
    // 有効終了月の指定日
    let start = new Date(base.getFullYear(), base.getMonth(), passcardUpdateStartDay);
    if (start.getTime() > updatablePeriod[1]) {
      start = new Date(addMonth(start, -1));
      if (new Date(start).getMonth() == base.getMonth()) {
        start = new Date(base.getFullYear(), base.getMonth(), 0);
      }
    }

    updatablePeriod[0] = start.getTime();
  }

  return updatablePeriod;
}

export function getPeridStart(systemDate: number) {
  const dateNow = systemDate ? new Date(systemDate) : new Date();
  const year = dateNow.getFullYear();
  const m = dateNow.getMonth();
  const currMonth = Date.UTC(year, m, 1);
  const nextMonth = Date.UTC(year, m + 1, 1);
  return [currMonth, nextMonth];
}

/**
 * 1：申込可の定期券を申請する場合
 * 2：空き待ちの定期券を申請する場合
 * 3：申請受付中の定期券を申請する場合（空き待ちだった順番が来た定期券を申請する場合）
 * 4：紛失の申請を行う場合
 * 5：シール再発行の申請を行う場合
 * 6：定期更新の申請を行う場合
 * 7：(手動承認)承認前の定期申請を行う場合
 * 8：(手動承認)承認後の定期申請を行う場合
 * 9：(手動承認)承認前の申請受付中の定期券を申請する場合（空き待ちだった順番が来た定期券を申請する場合）
 */
export function getStorageKey(base: string, status: number, dcParkingNo: number | null): string {
  const strParkingNo = dcParkingNo == null ? 'NPK' : `PK${dcParkingNo}`;
  switch (status) {
    case 1:
    case 2:
    case 7:
      return `${base}-${strParkingNo}-1`;
    case 3:
    case 8:
      return `${base}-${strParkingNo}-2`;
    case 9:
      return `${base}-${strParkingNo}-3`;
    case 4:
    case 5:
    case 6:
    case 10:
    case 11:
      return `${base}-${strParkingNo}-${status}`;
    default:
      return base;
  }
}

export function getInputImageUploadModel(certificateTypeNos: number[], settings: CertificateType[]) {

  // 使用するものだけに絞る
  const retSettings = settings.filter((setting) => certificateTypeNos.some((x) => x === setting.certificateType));

  retSettings.forEach((setting) => {
    // 表示させるものだけに絞る
    setting.certificateImageSettingList =
      setting.certificateImageSettingList.filter((x) => x.imageSetting !== 0);
    // インデックスを振る
    setting.certificateImageSettingList.forEach((x, index) => {
      x.certificateImageNum = index + 1;
    });
  });
  return retSettings;
}