import { Component, OnInit, inject } from '@angular/core';
import { generateQrcode } from '../../shared/qrcode';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { ApiService, NavigateService } from 'src/app/services';
import { ActivatedRoute, ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ApiReturn } from '../passcard.model';

interface ICRegisterInfo {
  registICCardUrl: string;
  qrCode?: string;
  authKey?: string;
}

/**
 * ICカード登録
 */
@Component({
  selector: 'ic-reg',
  templateUrl: './ic-register.component.html'
})
export class ICRegisterComponent implements OnInit {
  private readonly _navi = inject(NavigateService);
  api = inject(ApiService);
  private readonly _route: ActivatedRoute = inject(ActivatedRoute);
  helpQr = false;
  helpAuth = false;
  authKey: string;
  registICCardUrl: string;

  ngOnInit(): void {
    this._route.data.pipe(map(({ data }) => data)).subscribe(x => {
      this.authKey = `${x.authKey.substring(0, 4)}-${x.authKey.substring(4)}`;
      this.registICCardUrl = x.registICCardUrl;
      generateQrcode(x.qrCode, '.qrcode');
    });
  }


  onBack() {
    this._navi.navigateByUrl('/passcard/list');    
  }
}

export const ICRegisterResolver: ResolveFn<ICRegisterInfo> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  navi = inject(NavigateService),
  apiProxy = inject(PasscardApiProxy),
  api = inject(ApiService),
): Observable<ICRegisterInfo> => {
  const passcardApplyNo = route.paramMap.get('passcardApplyNo');
  if (!route.paramMap.has('passcardApplyNo')) {
    navi.navigateByUrl('/error', true);
    return EMPTY;
  }

  return api.post<ApiReturn>('/ICCardRegistInfo', { passcardApplyNo }, 1).pipe(
    catchError(() => {
        navi.navigateByUrl('/error', true);
        return EMPTY;  
    }),
    map(({ body }) => {
      setTimeout(() => apiProxy.error = body.mainMessage);
      if (body.resultCode != 0 && body.resultCode != 4) {
        navi.navigateByUrl('/error', true);
      }
      else {
        return {
          registICCardUrl: <string>body.registICCardUrl,
          authKey: <string>body.authKey,
          qrCode: <string>body.qrCode
        };
      }
    }),
    take(1)
  )
}
