<div class="card-container">
  <card *ngIf="contract" [reservation]="contract"></card>
</div>
<br>
<block title="ICカード再発行" *ngIf="cardPurchaseChoicesBuy || cardPurchaseChoicesMyCardUse">
  <ng-container *ngIf="cardPurchaseChoicesBuy">
    <p-info>
      <p>新しいICカードをお手持ちでない場合は、</p>
      <p>「駐輪場専用のICカードを購入して定期券として利用する」を選択してください。</p>
    </p-info>
    <br>
  </ng-container>
  <div class="passcard-list">
    <div *ngIf="cardPurchaseChoicesMyCardUse" class="passcard passcard-normal" [ngClass]="{ 'passcard-selected': buyICCard == 0 }" (click)="buyICCard = 0">
      <div class="input">
        <div class="radio-wrapper"><input type="radio" name="card" [checked]="buyICCard == 0"
            value="0" id="buy1" #buy1 /></div>
        <div><label class="radio-caption" for="buy1">
            <div>お手持ちの交通系ICカードを定期券として利用する</div>
            <div>(再発行手数料：{{applyLostIssueCommission | currency:'JPY'}})</div>
          </label></div>
      </div>
    </div>
    <div *ngIf="cardPurchaseChoicesBuy" class="passcard passcard-normal" [ngClass]="{ 'passcard-selected': buyICCard == 1 }" (click)="buyICCard = 1">
      <div class="input">
        <div class="radio-wrapper"><input type="radio" name="card" [checked]="buyICCard == 1"
            value="1" id="buy2" #buy2 /></div>
        <div><label class="radio-caption" for="buy2">
            <div>駐輪場専用のICカードを購入して定期券として利用する</div>
            <div>(再発行手数料：{{applyCardIssueCommission + applyLostIssueCommission | currency:'JPY'}})</div>
          </label></div>
      </div>
    </div>
  </div>
  <br>
</block>

<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button class="six" label="次へ"
    (apply)="onNext()"
    [disabled]="api.error || api.Disabled || (buyICCard == -1 && (cardPurchaseChoicesBuy || cardPurchaseChoicesMyCardUse))"
    [loading]="api.Disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn six'" />
</div>