import { Component, DestroyRef, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { Location } from '@angular/common';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { NavigateService, StorageService } from 'src/app/services';
import { PayFeeDetail } from '../../common/p-pay-fees/p-pay-fees.component';
import { ChangeConfirmService } from '../../change-confirm/change-confirm.service';
import { PasscardApply } from '../../models/apply-info';
import { PasscardApplyDetail } from '../../models/apply-details';
import { ApplyStatus } from '../../passcard.model';
import { PasscardApplyService } from '../../apply/apply.service';
import { PaymentType } from '../../pay/pay.model';
import { ApplyCancelModel } from '../../reservation/apply-cancel/apply-cancel.model';
import { RefreshService } from '../../refresh/refresh.service';
import { ReceiptIssueModel } from '../../pay/receipt-issue/receipt-issue.model';

const paymentMeta: ReadonlyArray<any>= [
  { item: 'newIssueCommission', title: '新規発行手数料' },
  { item: 'reIssueCommission', title: '再発行手数料' },
  { item: 'buyICCardCommission', title: 'ICカード購入手数料' },
  { item: 'convenienceCommission', title: 'コンビニ支払い手数料' },
];


@Component({
  selector: 'apply-detail',
  templateUrl: './apply-detail.component.html',
  providers: [ ChangeConfirmService, ReceiptIssueModel ]
})
export class ApplyDetailComponent {
  PT = Object.freeze({
    "0": '定期更新機',
    "1": 'クレジットカード',
    "2": 'PayPay',
    "3": 'コンビニ'
  });

  private readonly _storage = inject(StorageService);
  private readonly _navi = inject(NavigateService);
  private readonly _service = inject(ChangeConfirmService);
  private readonly _applyService = inject(PasscardApplyService);
  private readonly _refreshService = inject(RefreshService);
  private readonly _receiptIssueModel = inject(ReceiptIssueModel);

  private _applyInfo: PasscardApply;
  private _naviState: any;

  readonly api = inject(PasscardApiProxy);
  denialInfo: { reason: string, term: number };
  passcardApplyNo: number;
  passcardApplyResult: string;
  parkingAreaName: string;

  status: number;
  tip = -1;

  detail: PasscardApplyDetail;
  feeDetail: PayFeeDetail;

  constructor(public app: AppModel,
    location: Location) {
    this._naviState = <any>location.getState();
    const { passcardApplyNo, reservation, applyDetail, passcardApplyResult, parkingAreaName } = this._naviState;
    this.passcardApplyNo = passcardApplyNo;
    this.detail = applyDetail;
    this.status = applyDetail.status;
    this.passcardApplyResult = passcardApplyResult;
    this.parkingAreaName = parkingAreaName;
    this._applyInfo = reservation;
    this.setDenialInfo();
    this.setPaymentInfo();
    if (passcardApplyResult == '申請中') {
      this.tip = 0;
      switch (this.status) {
        case ApplyStatus.WaitingFree:
          this.tip = 6;
          break;
        case ApplyStatus.WaitingReApply:
        case ApplyStatus.WaitingModify:
          this.tip = this.status
          break;
        case ApplyStatus.WaitingConfirm:
        case ApplyStatus.WaitingConfirmForRefresh:
        case ApplyStatus.waitingConfirmForUpload:
          this.tip = 99;  // 現在申請中です。申請が受理されるまでしばらくお待ちください。
          break;
        case ApplyStatus.WaitingICRegist:
        case ApplyStatus.WaitingICRegistForLost:
          this.tip = 8;
          break;
        case ApplyStatus.WaitingPay:
        case ApplyStatus.WaitingPayForRefresh:
          if (this.detail.passcardPaymentType == 99) this.tip = 100;
          else 
          this.tip = 5;
          if(this.detail.passcardPaymentType === PaymentType.Terminal){
            this.detail.paymentTerm = this.detail.terminalPaymentTerm;
          }
          break;
        case ApplyStatus.WaitingPayForLost:
          this.tip = 5;  // XXXX支払い待ちです。「XXXX支払い情報」から支払い情報が確認できます。
          if(this.detail.passcardPaymentType === PaymentType.Terminal){
            this.detail.paymentTerm = this.detail.terminalPaymentTerm;
          }
          break;
      }
    }

    if (passcardApplyResult == '期限切れ') {
      switch (this.status) {
        
        case ApplyStatus.WaitingPay:
        case ApplyStatus.WaitingPayForRefresh:
        case ApplyStatus.WaitingPayForLost:
          if (this.detail.passcardPaymentType == 99){
            this.tip = 11;
          }else{
            this.tip = 12;
          }
          break;
        case ApplyStatus.WaitingReApply:
          this.tip = 11;
          break;
      }
    }

    inject(DestroyRef).onDestroy(() => this._receiptIssueModel.clear());
  }

  get receiptIssueBtnVisible() {
    return this.tip != 5 && this.detail.paymentDate && (this.passcardApplyResult == '申請中' ||  this.passcardApplyResult == '完了');
  }

  onBack() {
    if (this._applyInfo) {
      if (this._applyInfo.status < 30 && this._applyInfo.passcardAvailable != 1) {
        this._navi.navigate(`/passcard/applyInfo`, false, { reservation: this._applyInfo });
      } else {
        this._navi.navigate(`/passcard/contract`, false, { contract: this._applyInfo });
      }
      return;
    }

    this._navi.navigateByUrl('passcard/apply-history/list');
  }

  async onReApply() {
    const {
      status,
      passcardPaymentType,
      dcParkingNo,
      parkingName,
      parkingAddress,
      passcardType,
      passcardMonth,
      selectTargetMonth,
      passcardValidFrom,
      passcardValidTo,
      buyICCard,
      no,
    } = this.detail;
    if (status == 2 || status == 5) {
      this._applyService.reset(dcParkingNo);
      const applyStatus = status == ApplyStatus.WaitingPay && passcardPaymentType == PaymentType.UnSelect ? 8 : 9;
      this._storage.save(`passcard-applyinfo`, {
        applyStatus,
        passcardApplyNo: this.passcardApplyNo,
        passcardType,
        passcardMonth,
        selectTargetMonth,
        passcardValidFrom,
        passcardValidTo,
        buyICCard,
        dcParkingNo,
        parkingName: parkingName ?? this.parkingAreaName,
        parkingAddress,
        no,
       });
      
      this._navi.navigateByUrl(`/passcard/reapply`);
      return;  
    }

    if (!this._applyInfo) {
      const { passcardApplyList } = await this.api.getPasscardApplyInfo();
      this._applyInfo = passcardApplyList.find(x => x.passcardApplyNo == this.passcardApplyNo);
    }

    this._refreshService.reset();
    this.prepareRefresh(11);
    this._navi.navigate('/passcard/refresh', false, { contract: this._applyInfo , status: status });  
  }

  /**
   * 「申請を修正する」ボタンクリック
   * 新規申請差し戻し修正
   */
  async onCorrect() {
    this._service.prepare(<any>{
      ...this.detail,
      passcardApplyNo: this.passcardApplyNo,
      parkingName: this.parkingAreaName
    });
    this._service.doCorrect();
  }
   /**
    *　お支払いへ
    */
   async onPaymentDetail() {
    const paymentType = this.detail.passcardPaymentType;
    let to = '';
    switch (paymentType) {
      case PaymentType.Terminal:
        to = 'terminal-pay';
        break;
      case PaymentType.PayPay:
        to = '2pay-pay';
        break;
      case PaymentType.Cvs:
        to = 'conveni-pay';
        break;
    }

    const paymentInfo = await this.api.getPaymentInfo(paymentType, this.passcardApplyNo);
    this._navi.navigate(`/passcard/${to}`, false, { passcardApplyNo: this.passcardApplyNo, paymentInfo });
  }

  /**
   * 
   * @param status 
   *   6: 更新処理（通常）
   *  11: 更新処理(証明書アプロード後)
   */
  private prepareRefresh(status: number) {
    const { passcardType, passcardApplyNo, dcParkingNo, parkingName, parkingAddress, passcardExtended, name,
      passcardValidFrom, passcardValidTo, no
     } = this._applyInfo;
    this._storage.remove(`payment-params`);
    this._storage.remove('refresh-wiz');
    this._storage.save('passcard-applyinfo', {
      status,
      passcardApplyNo,
      passcardType,
      parkingName,
      parkingAddress,
      name,
      passcardExtended,
      dcParkingNo,
      passcardValidFrom,
      passcardValidTo,
      no,
    });
  }

  onToRegistIC() {
    this._navi.navigateByUrl(`/passcard/ic-register/${this.passcardApplyNo}`);
  }

  async onIssuePayReceipt() {
    const { paymentProcessDt: processDt, paymentProcessMs: processMs, receiptAddressNameFlg } = this.detail;
    this._receiptIssueModel.processDt = processDt;
    this._receiptIssueModel.processMs = processMs;
    if (receiptAddressNameFlg !== 0) {
       this._receiptIssueModel.issueOnly();
       return;
    }

    this._receiptIssueModel.nameFurigana = this.detail.nameFurigana;
    this._receiptIssueModel.src = {
      url: 'passcard/apply-history/detail',
      state: this._naviState,
      refreshField: 'applyDetail'
    };
    this._receiptIssueModel.saveModel();
    this._navi.navigateByUrl('/passcard/apply-history/receipt');
  }

  onCancel() {
    const applyDetail = this.detail;
    ApplyCancelModel.reset(this._storage);
    this._navi.navigate(`/passcard/apply/cancel`, false, {
      proc: 1,
      passcardApplyNo: this.passcardApplyNo,
      parkingAreaName: this.parkingAreaName,
      applyDetail,
    });
  }

  private setDenialInfo() {
    if (!this.detail.denialReason
      || this.passcardApplyResult == '完了'
      || (this.passcardApplyResult == '申請中' && this.status != 4)
      || (this.passcardApplyResult == '期限切れ' && this.status != 4)
    ) return;

    this.denialInfo = {
      reason: this.detail.denialReason,
      term: this.detail.correctLimitDate
    };
  }

  private setPaymentInfo() {
    const {
      status,
      passcardPaymentType,
      passcardAmount,
      paymentFee: paymentAmount,
     } = this.detail;
    if (this.passcardApplyResult == '申請取消'
      || status > 50
      || status <= 4
      || (passcardPaymentType ?? 99) == 99
      || (
      this.passcardApplyResult == '申請中'
      && status != ApplyStatus.WaitingPay
      && status != ApplyStatus.WaitingICRegist
      && status != ApplyStatus.WaitingICRegistForLost
      && status != ApplyStatus.WaitingPayForRefresh
      && status != ApplyStatus.WaitingPayForLost
      )) return;

    if (!this.PT[passcardPaymentType]) return;

    const paymentContentsCode = status < 30 ? 1 : status < 40 ? 2 : 3;
    
    this.feeDetail = {
      paymentFeeDetails: [],
      paymentAmount
    };

    if (paymentContentsCode <= 2
      && !!passcardAmount) {
      //const content1 = PaymentContentName[paymentContentsCode];
      this.feeDetail.paymentFeeDetails.push({
        contents: '定期代', //`${content1}(${this.detail.passcardMonth}か月)`,
        Fee: passcardAmount
      });
    }

    paymentMeta.forEach(item => {
      const fee = this.detail[item.item];
      if (!!fee) {
        this.feeDetail.paymentFeeDetails.push({ contents: item.title, Fee: fee });
      }
    });
  }
}
