import { Routes } from "@angular/router";
import { ApplyListComponent } from "./apply-list.component";
import { ApplyDetailComponent } from "./apply-detail/apply-detail.component";

export const ApplyListComponents = [
  ApplyListComponent,
  ApplyDetailComponent,
];


export const applyListRoutes: Routes = [
  { path: 'passcard/apply-list', component: ApplyListComponent },
  { path: 'passcard/apply-detail', component: ApplyDetailComponent },
];
