import { Component } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService } from 'src/app/services';
import { PasscardBookingService } from '../../passcard-booking.service';
import { Location } from '@angular/common';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { PasscardApply } from '../../models/apply-info';

@Component({
  selector: 'apply-cancel',
  templateUrl: './apply-cancel.component.html'
})
export class ApplyCancelComponent {
  private _isConfirmed = false;

  reservation: PasscardApply | null = null;

  constructor(
    public app: AppModel,
    public api: PasscardApiProxy,
    private _cacheService: PasscardBookingService,
    private _navi: NavigateService,
    location: Location) {
    const navigateState = location.getState();
    this.reservation = navigateState?.['reservation'];
  }

  get IsComplete() {
    return this._cacheService.cancelComplete;
  }

  get isConfirmed() {
    return this._isConfirmed;
  }

  set isConfirmed(val: boolean) {
    this._isConfirmed = val;
  }

  onBack() {
    this._navi.goBack();
  }

  onCancel() {
    this.api.passcardApplyCancel(this.reservation.passcardApplyNo).then(x => {
      this._cacheService.cancelComplete = true;
      this._navi.navigate('passcard/apply/canceled', true, {
        title: '定期情報',
        message1: '定期券の新規申請を取り消しました。',
        message2: 'またのご利用お待ちしております。'
      });
    });
  }
}
