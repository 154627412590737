<block title="定期選択" *ngIf="wiz.status != 11; else reApply">
  <div class="tableCtn">
    <p-single label="駐車場" [value]="wiz.passcardApply.parkingName" />
    <p-single label="現在の定期の種類" [value]="applyParams.currentPasscardTypeName" />
    <p-single label="更新後の定期の種類" *ngIf="wiz.status == 11" [value]="model.passcard.name" />
  </div>
  <br>
  <div class="passcard-list">
    <div class="passcard passcard-normal" *ngFor="let passcard of applyParams.passcardTypeList; let i=index;"
      [ngClass]="{ 'passcard-selected': passcardSelected && passcardSelected.passcardType == passcard.passcardType}"
      (click)="onPasscardChange(passcard)">
      <div class="radio-wrapper input2">
        <input type="radio" name="passcard" id="passcard{{i}}"
          [checked]="passcardSelected && passcardSelected.passcardType == passcard.passcardType" [value]="i" />
        <div>
          <div>
            <passcard-status [showStatus]="false" [passcard]="passcard"></passcard-status>
          </div>
          <br>
          <p>{{passcard.name}}</p>
          <br>
          <div class="cost font-small">
            <p>1ヵ月あたり{{passcard.unitPrice | currency:'JPY'}}</p>
            <ng-container *ngIf="passcard.annotation">
              <br>
              <p class="annotation">{{passcard.annotation}}</p>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <p>※変更可能な定期の種類のみ表示しています。上記以外の定期契約をご希望の場合は、契約中の定期を解約後、ご希望の定期を新規申請してください。</p>
</block>
<ng-template #reApply>
  <block title="定期情報">
    <div class="tableCtn">
      <p-single label="駐車場" [value]="wiz.passcardApply.parkingName" />
      <p-single label="現在の定期の種類" [value]="wiz.passcardApply.name" />
      <p-single label="更新後の定期の種類" [value]="model.passcard.name" />
      <p-single label="更新月数" value="{{paymentDivision.monthNum}}ヵ月({{paymentDivision.unitPrice | currency:'JPY'}})" />
      <p-single label="更新後の定期期間" [value]="fixedPeriod" />
      <p-single label="更新後の更新可能期間" [value]="updatablePeriod" />
    </div>
  </block>  
</ng-template>
<br>
<ng-container *ngIf="wiz.status != 11 && passcardSelected">
  <block title="更新月数">
    <div class="passcard-list">
      <div class="passcard passcard-normal" *ngFor="let item of passcardSelected.paymentDivisionList; let i = index"
        [ngClass]="{ 'passcard-selected': item.monthNum == model.passcardMonth}" (click)="onSetPasscardMonth(item)">
        <div class="input">
          <div class="radio-wrapper"><input type="radio" name="passcardMonth"
              [checked]="item.monthNum == model.passcardMonth" value="1" id="passcardMonth{{i}}" /></div>
          <div><label class="radio-caption" for="passcardMonth{{i}}">
              {{item.monthNum}}ヵ月　{{item.unitPrice | currency:'JPY'}}
            </label></div>
        </div>
      </div>
    </div>
  </block>
  <br>
  <block title="定期期間">
    <div class="tableCtn">
      <p-single label="定期期間" [value]="fixedPeriod" />
      <p-single label="更新可能期間" [value]="updatablePeriod" />
    </div>
  </block>
  <br>
  <block title="証明書等登録" *ngIf="showCertificateUpload">
    <i-my-files [certificateTypeList]="certificateTypeList" [model]="myFilesModel"
      [message]="imageCheckResult"></i-my-files>
  </block>
</ng-container>
<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button class="six" label="次へ" (apply)="onNext()" [disabled]="NextDisabled" [loading]="api.Disabled"
    [class]="'cmn-btn six'" [lClass]="'button-box-loading'" />
</div>