import { Component, ViewChild, inject } from '@angular/core';
import { Location } from '@angular/common';
import { NOSHOWORDER, ApplyStatus } from '../passcard.model';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { PasscardBookingService } from '../passcard-booking.service';
import { PasscardApply } from '../models/apply-info';
import { PasscardApplyService } from '../apply/apply.service';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { PaymentType } from '../pay/pay.model';
import { StatusTipComponent } from '../common/status-tip/status-tip.component';

@Component({
  selector: 'passcard-reservation',
  templateUrl: './reservation.component.html'
})
export class ReservationComponent {
  private _storage = inject(StorageService);
  ApplyStatus = ApplyStatus;
  reservation: PasscardApply | null = null;

  @ViewChild(StatusTipComponent) statusTip: StatusTipComponent;

  constructor(
    public app: AppModel,
    public api: PasscardApiProxy,
    private _cacheService: PasscardBookingService,
    private _applyService: PasscardApplyService,
    private _navi: NavigateService,
    location: Location) {
    const navigateState = location.getState();
    this.reservation = navigateState?.['reservation'];
    if (this.reservation) {
      this.reservation.currentNum ??= NOSHOWORDER;
    }
  }

  get PayButtonVisible() {
    const status = this.reservation?.status;
    return status == ApplyStatus.WaitingPay
      && (this.reservation.passcardPaymentType == PaymentType.Terminal
      //|| this.reservation.passcardPaymentType == PaymentType.CreditCard
      || this.reservation.passcardPaymentType == PaymentType.PayPay
      || this.reservation.passcardPaymentType == PaymentType.Cvs);
  }

  get applyBtnVisible() {
    const { status, passcardPaymentType } = this.reservation;
    return status == ApplyStatus.WaitingReApply
    || (status == ApplyStatus.WaitingPay && passcardPaymentType == PaymentType.UnSelect);
  }

  get cancelBtnVisible() {
    const { status, passcardPaymentType } = this.reservation;
    return status == ApplyStatus.WaitingFree
    || status == ApplyStatus.WaitingReApply
    || status == ApplyStatus.WaitingModify
    || (status == ApplyStatus.WaitingPay && passcardPaymentType == PaymentType.UnSelect);
  }

  /**
   * チップクリック
   */
  onStatusTipClick() {
    if (this.statusTip.applying.getValue() == 8) {
      this.onReApply();
      return;
    }

    switch (this.reservation.status) {
      case ApplyStatus.WaitingFree:
        this.onCancelConfirm();
        break;
      case ApplyStatus.WaitingReApply:
        this.onReApply();
        break;
      case ApplyStatus.WaitingModify:
        this.onModifyConfirm();
        break;
      case ApplyStatus.WaitingICRegist:
        this.onICRegister();
        break;
      case ApplyStatus.WaitingPay:
        this.onPay();
        break;
    }
  }

  /**
   * お支払い
   */
  async onPay() {
    let payMethod = 0;
    let urlNav = '/passcard/terminal-pay';
    switch (this.reservation.passcardPaymentType) {
      case PaymentType.Terminal:
        urlNav = `/passcard/terminal-pay`;
        payMethod = 0;
        break;
      case PaymentType.PayPay:
        urlNav = `/passcard/2pay-pay`;
        payMethod = 2;
        break;
      case PaymentType.Cvs:
        urlNav = `/passcard/conveni-pay`;
        payMethod = 3;
        break;
    }

    const paymentInfo = await this.api.getPaymentInfo(payMethod, this.reservation.passcardApplyNo);
    this._navi.navigate(urlNav, false, { passcardApplyNo: this.reservation.passcardApplyNo, reservation: this.reservation, paymentInfo });
  }

  /**
   * ICカード登録
   */
  onICRegister() {
    this._navi.navigate(`/passcard/ic-register/${this.reservation.passcardApplyNo}`, false, this.reservation);
  }

  /**
 * 申請取消確認
 */
  onCancelConfirm() {
    this._cacheService.cancelComplete = false;
    this._navi.navigate(`/passcard/apply/cancel`, false, { reservation: this.reservation });
  }

  /**
   * 修正確認
   */
  onModifyConfirm() {
    this._cacheService.changeComplete = false;
    this._navi.navigate(`/passcard/rejection`,
      false,
      this.reservation);
  }

  /**
   * 再申請
   */
  onReApply() {
    const { status, passcardPaymentType, passcardApplyNo, dcParkingNo, parkingName, parkingAddress, passcardType } = this.reservation;
    this._applyService.reset(dcParkingNo);
    const applyStatus = status == ApplyStatus.WaitingPay && passcardPaymentType == PaymentType.UnSelect ? 8 : 9;
    this._storage.save(`passcard-reapply-${dcParkingNo}`, {
      applyStatus,
      passcardApplyNo,
      passcardType,
      dcParkingNo,
      parkingName,
      parkingAddress,
     });
    
    this._navi.navigate(`/passcard/new/reApply/${dcParkingNo}`,
      false,
      { dcParkingNo });
  }
}
