<h2>申請一覧</h2>
<div class="row">
  <div class="selectBox">
    <select title="期間" [(ngModel)]="conditionsPeriod" (change)="onChangePeriod()">
      <option *ngFor="let item of conditionsPeriods" value="{{item.code}}">{{item.name}}</option>
    </select>
  </div>
</div>
<ng-container *ngIf="applyList$ | async as applyList">
  <ng-container *ngIf="applyList.length; else noApplyInfo">
    <div class="apply-list">
      <div class="apply-item" *ngFor="let applyItem of applyList" (click)="onToDetail(applyItem)">
        <div class="status"><i class="icon"
          [class.certi-card]="applyItem.passcardApplyContentsCode == 1"
          [class.refresh]="applyItem.passcardApplyContentsCode == 2"
          [class.lost]="applyItem.passcardApplyContentsCode == 3"
          [class.resume]="applyItem.passcardApplyContentsCode == 5"
          ></i>
          </div>
        <div class="detail">
          <div>{{applyItem.passcardApplyDate | datetime2}}</div>
          <div class="apply-status">
            <div class="parking-status" [ngClass]="getStatusClass(applyItem.passcardApplyResult)">{{applyItem.passcardApplyResult}}</div>
          </div>
          <div class="apply-name">{{PCN[applyItem.passcardApplyContentsCode]}}</div>
          <div class="parking-area">{{applyItem.parkingAreaName}}</div>
          <div class="currency">{{ getPaymentFee(applyItem.paymentFee) }}</div>
        </div>
      </div>
    </div>
    <br>
    <app-pagination
      *ngIf="1 < pageModel.pages"
      [pages]="pageModel.pages"
      [page]="pageModel.page"
      [popup]="!api.Disabled"
      (onPage)="onPage($event)"
      ></app-pagination>
  </ng-container>
  <ng-template #noApplyInfo>
    <div class="no-apply">
      <div>申請情報はありません</div>
    </div>
  </ng-template>
</ng-container>
