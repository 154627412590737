import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorModel } from 'src/app/error/error.model';
import { ApiService, NavigateService } from 'src/app/services';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { PaypayReceiptReq, PaypayReceiptRes, PaypayStateReq, PaypayStateRes } from '../paypay.model';
import { PayModel } from '../../models/pay.model';

interface Params {
  transactionId: string;
  parkingNo: number;
}

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
})
export class RedirectComponent implements OnInit {

  query: string;
  isAlreadySent: boolean;  // 2回だけstatus確認をするため
  isCompleted: boolean; // response返ってくる前に2回目のループ入るパターン対策

  constructor(
    private route: ActivatedRoute,
    private navi: NavigateService,
    private api: ApiService,
    private error: ErrorModel,
    private model: PayModel,
    private local: LocalStorageService,
  ) { }

  ngOnInit() {
    // 別セッションになるためリセット不要かも
    // this.pay.resetAll();
    this.route.queryParams.subscribe(async (params: Params) => {
      // for confirm
      this.query = JSON.stringify(params, null, ' ');
      this.model.dealId = params.transactionId;

      // ポーリングページなどでusageDetailsを既に投げている場合の対処
      if (!this.local._load('paypay')) {
        this.error.status = 992;
        this.navi.navigateByUrl('error', true);
      } else {
        // paypay側の処理が終わっていない時のためpolling(とりあえず2回)
        var count = 0;
        do {
          //  this.api.setDisabled();
          // request間の時間を置く
          await Promise.all([this.confirmPaypayState(), this.api.sleep(1500)]);
          count++;
        } while (count < 2 && !this.isCompleted);
      }
    });
  }

  // confirm paypay's state
  confirmPaypayState() {
    const req: PaypayStateReq = {
      transactionId: this.model.dealId,
    };
    this.api.post<PaypayStateRes>('/paymentStatusConfirmation', req, 1).subscribe(res => {
      if (res.body.resultCode === 0 && res.body.paymentStatus === 'COMPLETED') {
        this.isCompleted = true;
        // API側でテーブル操作中に別のリクエストを投げないためディレイをかける
        // this.navi.navigateByUrl('redirect/success?pay=paypay');
        this.api.sleep(1000).then(() => this.getReceipt());
      } else if (res.body.resultCode === 7 && this.isAlreadySent) {
        this.error.status = res.body.resultCode;
        this.api.enabled();
        this.navi.navigateByUrl('error', true);
      } else {
        if (this.isAlreadySent) {
          this.api.enabled();
          this.navi.navigateByUrl('redirect/error2', true);
        } else {
          this.isAlreadySent = true;
        }
      }
    });
  }

  getReceipt() {
    const req: PaypayReceiptReq = {
      transactionId: this.model.dealId,
    };
    this.api.post<PaypayReceiptRes>('/usageDetails', req, 1).subscribe(response => {
      var res = response.body;
      if (res.resultCode === 0) {
        // バッティング回避
        this.local.remove('paypay');
        // API次第 /complete で表示したいもの(receipt.component参照)を全て詰める
        this.setReceipt(res);
      }
      else {
        this.api.enabled();
        console.log(`receipt_can't_get resultCode: ${res.resultCode}`);
        this.error.status = res.resultCode;
        this.navi.navigateByUrl('error', true);
      }
    });
  }

  setReceipt(response: PaypayReceiptRes) {
    // this.model.resetAll();
    this.model.pay = 'paypay';
    // this.model.car = {
    //   inTrans: response.inTrans,
    //   src: null,
    //   np: response.numberPlateInfoList,
    //   ticket: null,
    //   current: null,
    // };
    this.model.settle = {
      resultCode: response.resultCode,
      mainMessage: response.mainMessage,
      parkingFee: response.parkingFee,
      parkingTime: response.parkingTime,
      paymentFee: response.paymentFee,
      inTrans: response.inTrans,
      outTrans: response.outTrans,
      paidTrans: response.paidTrans,
      merchantName: response.merchantName,
      transactionId: response.transactionId,
      discountDisplayType: response.discountDisplayType,
      discountInfoList: response.discountInfoList,
      feeRate: response.feeRate,
      inOutId: response.inOutId,
      displayParkingTicket: response.displayParkingTicket,
      previousReceipt: response.previousReceipt,
      paidOut: response.paidOut,
      passcardInfo: response.passcardInfo,
      usageStatementHeaderInfoList: response.usageStatementHeaderInfoList,
      issuerName: response.issuerName,
      registNumber: response.registNumber,
      issuerAddress: response.issuerAddress,
      issuerTel: response.issuerTel,
      taxRate: response.taxRate
    };
    // this.model.parkingFee = response.parkingFee;
    // this.model.parkingTime = response.parkingTime,
    // this.model.passCard = {
    //   no: response.passcardInfo.passcardNo,
    //   type: response.passcardInfo.passcardType,
    //   name: response.passcardInfo.passcardTypeName,
    //   date1: response.passcardInfo.passcardValidFrom,
    //   date2: response.passcardInfo.passcardValidTo,
    //   status: response.passcardInfo.passcardStatus,
    // };
    
    // this.model.setFee(response);

    this.afterSet();
  }

  afterSet() {
    // console.log(this.model.settle); // 確認用
    this.api.enabled();
    this.navi.navigateByUrl('complete');
  }

}
