import { Component } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { SealReissueService } from '../../seal-reissue.service';
import { NavigateService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { PayFeeDetail } from 'src/app/passcard/common/p-pay-fees/p-pay-fees.component';
import { PaymentType } from 'src/app/passcard/pay/pay.model';

@Component({
  selector: 'seal-reissue03',
  templateUrl: './seal-reissue03.component.html'
})
export class SealReissue03Component {
  paidDetail: PayFeeDetail;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private _sealService: SealReissueService) {
      this._sealService.processing = 2;
      this.paidDetail = {
        paymentFeeDetails: [
          { contents: '定期代', Fee: 500 },
          { contents: 'コンビニ支払い手数料', Fee: 50 },
          { contents: 'ICカード購入手数料', Fee: 500 },
        ],
        paymentAmount: 550
      }
  }
  
  get IsComplete() {
    return this._sealService.complete;
  }

  get payInfo() {
    return this._sealService.applyModel.payMethod;
  }

  get contract() {
    return this._sealService.passcardApply;
  }

  get Charge() {
    return this._sealService.reissueFee;
  }

  onReInput() {
    this._navi.navigateByUrl(`/passcard/seal-reissue/step/2`);
  }

  /**
 * 申請を行う
 */
  async onApply() {
    this._sealService.maxProcessed = 2;
    const { no, passcardType, passcardExtended, passcardValidFrom } = this._sealService.passcardApply;
    const { payMethod } = this._sealService.applyModel;
    const paymentToken: any = {};
    switch (payMethod.method) {
      case PaymentType.CreditCard:
        paymentToken.tokenId = payMethod.creditPay.transactionId;
        break;
    }

    const { receiptNo, passcardApplyNo } = await this.api.passcardApply({
      passcardApplyNo: null,
      dcParkingNo: this._sealService.passcardApply.dcParkingNo,
      status: 5, // 5：シール再発行の申請を行う場合
      no,
      passcardType,
      extended: passcardExtended,
      passcardMonth: 0,
      passcardValidFrom,
      paymentMethod: payMethod.method,
      ...paymentToken
    });
    
    this._sealService.processing = 3;
    this._navi.navigate(`/passcard/seal-reissue/step/4`, true, {
      message1: '定期シール再発行申請が完了しました。',
      message2: '続けてコンビニ支払いのお手続きをお願い致します。',
      receiptNo
    });
  }
}
