<p-info>
  <p>お手持ちのICカードを定期券として登録するため、</p>
  <p>駐輪場にて、以下のQRコード、認証番号を定期更新機に入力し、</p>
  <p>ICカード登録を進めてください。</p>
</p-info>
<h2>ICカード登録</h2>
<block title="ICカード登録方法">
  <div class="passcard-content">
    <p><a [href]="registICCardUrl" target="ic-register" class="passcard-link" >ICカード登録方法の詳細はコチラ</a></p>
  </div>
</block>
<br>
<br>
<div class="qrcode-cert">
  <div>
    <div><h3 class="lable-with-help">QRコード<i class="icon help" (click)="helpQr = !helpQr"></i></h3></div>
    <div *ngIf="helpQr" class="qr-help">
      <p>こちらのQRコードを定期更新機に読み込ませてICカード登録が行えます。</p>
    </div>
    <div class="qrcode"></div>
  </div>
  <div>
    <div><h3 class="lable-with-help">認証番号<i class="icon help" (click)="helpAuth = !helpAuth"></i></h3></div>
    <div *ngIf="helpAuth" class="qr-help">
      <p>QRコードがご利用できない場合は、認証番号を使用してICカード登録が行えます。</p>
    </div>
    <div><span><b>{{authKey}}</b></span></div>
  </div>
</div>
<br>
<div class="buttonBoxVertical">
  <loading-button label="定期券情報に戻る"
    (apply)="onBack()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn bck-btn six'" />
</div>