import { Component, Inject, LOCALE_ID, ViewChild, inject } from '@angular/core';
import { CurrencyPipe, Location } from '@angular/common';
import { ModalComponent } from '../common/modal/modal.component';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { CorrectionReq } from '../models/correct-request';
import { PasscardApply } from '../models/apply-info';
import { ChangeConfirmService } from './change-confirm.service';
import { NavigateService } from 'src/app/services';
import { formatDate } from '../passcard.model';

/**
 * 否認定期修正確認
 */
@Component({
  selector: 'change-confirm',
  templateUrl: './change-confirm.component.html',
  providers: [ ChangeConfirmService, CurrencyPipe ]
})
export class ChangeConfirmComponent {
  private _navi = inject(NavigateService);
  private readonly _service = inject(ChangeConfirmService);
  private readonly _currencyPipe = inject(CurrencyPipe);

  correctionReq: CorrectionReq | null = null;
  passcardApplyNo: number;

  @ViewChild('changeConfirm') changeConfirm!: ModalComponent

  constructor(public api: PasscardApiProxy,
    @Inject(LOCALE_ID) private _locale: string,
    location: Location) {
    this.correctionReq = <CorrectionReq>location.getState();
    this.passcardApplyNo = this.correctionReq['passcardApplyNo'];
    this._service.prepare(<PasscardApply>this.correctionReq);
  }

  get ParkingName() {
    return this.correctionReq['parkingName'];
  }

  get RejectReason() {
    return this.correctionReq?.denialReason;
  }

  get LastChangableTime() {
    if (!this.correctionReq.correctLimitDate) return '無期限';

    return formatDate(this.correctionReq.correctLimitDate);
  }

  get ApplyInfo() {
    return this.correctionReq?.applyInfo;
  }

  get receiptNo() {
    return this.correctionReq?.receiptNo;
  }

  onBack() {
    this._navi.navigate(`/passcard/applyInfo`, false, { reservation: this.correctionReq });
  }

  onDelete() {
  }
  
  /**
   * 「申請を修正する」ボタンクリック
   */
  onCorrect() {    
    this._service.doCorrect();
  }
}
