<div class="passcard-app-title"><h2>過去の定期情報</h2>
  <div><a class="passcard-link" href="/passcard/list">定期情報></a></div>
</div>
<div class="row">
  <div class="selectBox">
    <select title="期間" [(ngModel)]="conditionsPeriod" (change)="onChangePeriod()">
      <option *ngFor="let item of conditionsPeriods" value="{{item.code}}">{{item.name}}</option>
    </select>
  </div>
</div>
<div class="card-list" *ngIf="reservations.length || !initialized; else noReservations">
  <ng-container *ngFor="let reservation of reservations;let isLast=last; let i = index;">
    <div>
      <card [reservation]="reservation" [clickable]="true" (cardClick)="onCardClick(reservation)"></card>
    </div>
    <div class="dummy-card" *ngIf="isLast && i % 2 == 0">&nbsp;</div>
  </ng-container>
</div>
<ng-template #noReservations>
  <div *ngIf="!api.error" class="error-textbox">
    <p class="err-txt-box">過去の定期情報はありません</p>
  </div>
</ng-template>