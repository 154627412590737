import { StatusTipComponent } from './common/status-tip/status-tip.component';
import { CardComponent } from './common/card/card.component';
import { ContractInfoComponent } from './common/contract-info/contract-info.component';
import { Apply01Component } from './apply/steps/step01/apply01.component';
import { Apply02Component } from './apply/steps/step02/apply02.component';
import { Apply03Component } from './apply/steps/step03/apply03.component';
import { PlaceInfoComponent } from './common/place-info/place-info.component';
import { ModalComponent } from './common/modal/modal.component';
import { ApplyComponent } from './apply/apply.component';
import { FlowBoxComponent } from './common/flow-box/flow-box.component';
import { Routes } from '@angular/router';
import { ApplyGuideComponent } from './apply-guide/apply-guide.component';
import { PassCardComponent } from './passcard.component';
import { PasscardInfoComponent } from './common/passcard-info/passcard-info.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ContractComponent } from './contract/contract.component';
import { ChangeConfirmComponent } from './change-confirm/change-confirm.component';
import { LostComponent } from './lost/lost.component';
import { Lost03Component } from './lost/steps/lost03/lost03.component';
import { Lost02Component } from './lost/steps/lost02/lost02.component';
import { Lost01Component } from './lost/steps/lost01/lost01.component';
import { ApplyInfoComponent } from './reservation/apply-info/apply-info.component';
import { ApplyCancelComponent } from './reservation/apply-cancel/apply-cancel.component';
import { PasscardCancelComponent } from './contract/passcard-cancel/passcard-cancel.component';
import { ContractDetailComponent } from './contract/contract-detail/contract-detail.component';
import { CvsPayInfoComponent } from './pay/cvs-payinfo/cvs-payinfo.component';
import { PayByCreditcardComponent } from './common/pay-creditcard/pay-creditcard.component';
import { PayByCvsComponent } from './common/pay-cvs/pay-cvs.component';
import { BlockComponent } from './common/block/block.component';
import { PaymethodSelectComponent } from './common/paymethod-select/paymethod-select.component';
import { PayRequestComponent } from './pay/pay-request/pay-request.component';
import { TerminalPayInfoComponent } from './pay/terminal-payinfo/terminal-payinfo.component';
import { PaypayPayinfoComponent } from './pay/paypay-payinfo/paypay-payinfo.component';
import { CreditCardPayInfoComponent } from './pay/creditcard-payinfo/creditcard-payinfo.component';
import { RefreshComponent } from './refresh/refresh.component';
import { SealReissueComponent } from './seal-reissue/seal-reissue.component';
import { Refresh01Component } from './refresh/steps/refresh01/refresh01.component';
import { Refresh02Component } from './refresh/steps/refresh02/refresh02.component';
import { Refresh03Component } from './refresh/steps/refresh03/refresh03.component';
import { SealReissue01Component } from './seal-reissue/steps/seal-reissue01/seal-reissue01.component';
import { SealReissue02Component } from './seal-reissue/steps/seal-reissue02/seal-reissue02.component';
import { SealReissue03Component } from './seal-reissue/steps/seal-reissue03/seal-reissue03.component';
import { ICRegisterComponent, ICRegisterResolver } from './ic-register/ic-register.component';
import { PasscardStatusComponent } from './common/passcard-info/passcard-status.component';
import { IDateSelectComponent } from './common/i-dateselect/i-date-select.component';
import { InfomationComponent } from './common/infomation/infomation.component';
import { ApplySuccessResolver, PasscardTypesResolver } from './apply/apply.service';
import { ProcSuccessComponent } from './proc-success/proc-success.component';
import { LostApplySuccessResolver } from './lost/lost.service';
import { SealReissueSuccessResolver } from './seal-reissue/seal-reissue.service';
import { RefreshSuccessResolver } from './refresh/refresh.service';
import { AppliesResolver, ReservationsComponent } from './reservations/reservations.component';
import { PPayMethodComponent } from './common/p-pay-method/p-pay-method.component';
import { PPayFeesComponent } from './common/p-pay-fees/p-pay-fees.component';
import { Paypaycomponent } from './pay/paypay-flow/paypay/paypay.component';
import { PayListComponent } from './pay/pay-list/pay-list.component';
import { PayDetailComponent } from './pay/pay-detail/pay-detail.component';
import { redirectComponents } from './pay/paypay-flow/redirect/redirect.routing';
import { PastPasscardsComponent } from './past-passcards/past-passcards.component';
import { ReceiptIssueComponent } from './pay/receipt-issue/receipt-issue.component';
import { ReceiptChangeTipComponent } from './pay/receipt-issue/change-tip/change-tip.component';
import { ReceiptChangeConfirmComponent } from './pay/receipt-issue/change-confirm/change-confirm.component';
import { ReceiptChangingComponent } from './pay/receipt-issue/changing/changing.component';
import { PaginationComponent } from './common/pagination/pagination.component';
import { DeliveryComponent } from './common/delivery/delivery.component';
import { DeliveryInputComponent } from './common/delivery-input/delivery-input.component';
import { IUsernameComponent } from './common/i-username/i-username.component';
import { EditDeliveryComponent } from './reservation/delivery-edit/edit-delivery/edit-delivery.component';
import { DeliveryConfirmComponent } from './reservation/delivery-edit/changed-confirm/changed-confirm.component';
import { DeliveryEditComponent } from './reservation/delivery-edit/delivery-edit.component';
import { ChangeCompleteComponent } from './change-confirm/complete/complete.component';
import { Pay2CompleteComponent } from './pay/pay2-complete/pay2-complete.component';
import { NoticeInfoComponent } from './common/notice-info/notice-info.component';

export const passcardComponents = [
  ApplyGuideComponent,
  ApplyComponent,
  StatusTipComponent,
  Apply01Component,
  Apply02Component,
  Apply03Component,
  Lost01Component,
  Lost02Component,
  Lost03Component,
  Refresh01Component,
  Refresh02Component,
  Refresh03Component,
  SealReissue01Component,
  SealReissue02Component,
  SealReissue03Component,
  CardComponent,
  PasscardInfoComponent,
  ContractInfoComponent,
  ChangeConfirmComponent,
  ChangeCompleteComponent,
  LostComponent,
  SealReissueComponent,
  RefreshComponent,
  PlaceInfoComponent,
  ModalComponent,
  FlowBoxComponent,
  ContractComponent,
  ReservationComponent,
  ReservationsComponent,
  PassCardComponent,
  ApplyInfoComponent,
  ApplyCancelComponent,
  PasscardCancelComponent,
  ContractDetailComponent,
  CvsPayInfoComponent,
  PayByCreditcardComponent,
  PayByCvsComponent,
  BlockComponent,
  PaymethodSelectComponent,
  PayRequestComponent,
  TerminalPayInfoComponent,
  PaypayPayinfoComponent,
  Pay2CompleteComponent,
  CreditCardPayInfoComponent,
  ICRegisterComponent,
  PayListComponent,
  PayDetailComponent,
  PasscardStatusComponent,
  IDateSelectComponent,
  InfomationComponent,
  ProcSuccessComponent,
  PPayMethodComponent,
  PPayFeesComponent,
  Paypaycomponent,
  PastPasscardsComponent,
  ReceiptIssueComponent,
  ReceiptChangeTipComponent,
  ReceiptChangeConfirmComponent,
  ReceiptChangingComponent,
  PaginationComponent,
  DeliveryComponent,
  IUsernameComponent,
  DeliveryInputComponent,
  EditDeliveryComponent,
  DeliveryConfirmComponent,
  DeliveryEditComponent,
  ...redirectComponents,
  NoticeInfoComponent,
];


export const ticketRoutes: Routes = [
  { path: '', component: ApplyGuideComponent },
  { path: 'guide', component: ApplyGuideComponent },
  { path: 'passcard/paypay', component: Paypaycomponent },
  {
    path: 'passcard/new', component: ApplyComponent, children: [
      { path: 'reApply/:dcParkingNo', component: Apply01Component, data: { status: 3 }, resolve: { data: PasscardTypesResolver } },
      { path: '', component: Apply01Component, data: { status: 1 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/1', component: Apply01Component, data: { status: 1 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/2', component: Apply02Component },
      { path: 'step/3', component: Apply03Component },
      { path: 'step/4', component: ProcSuccessComponent, resolve: { data: ApplySuccessResolver } },
    ]
  },
  { path: 'passcard/list', component: ReservationsComponent, resolve: { data: AppliesResolver } },
  { path: 'passcard/list-auto', component: ReservationsComponent, data: { auto: true }, resolve: { data: AppliesResolver } },
  { path: 'passcard/past-passcards', component: PastPasscardsComponent },
  { path: 'passcard/apply/canceled', component: ProcSuccessComponent },
  { path: 'passcard/apply/cancel', component: ApplyCancelComponent },
  { path: 'passcard/rejection', component: ChangeConfirmComponent },
  { path: 'passcard/rejection/ok', component: ChangeCompleteComponent },
  { path: 'passcard/terminal-pay', component: TerminalPayInfoComponent },
  { path: 'passcard/conveni-pay', component: CvsPayInfoComponent },
  { path: 'passcard/2pay-pay', component: PaypayPayinfoComponent },
  { path: 'passcard/2pay/complete', component: Pay2CompleteComponent },
  { path: 'passcard/ic-register/:passcardApplyNo', component: ICRegisterComponent, resolve: { data: ICRegisterResolver } },
  {
    path: 'passcard/lost', component: LostComponent, children: [
      { path: '', component: Lost01Component, data: { status: 4 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/1', component: Lost01Component, data: { status: 4 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/2', component: Lost02Component },
      { path: 'step/3', component: Lost03Component },
      { path: 'step/4', component: ProcSuccessComponent, resolve: { data: LostApplySuccessResolver } },
    ]
  },{
    path: 'payment/receipt', component: ReceiptIssueComponent, children: [
      { path: '', component: ReceiptChangeTipComponent },
      { path: 'tip', component: ReceiptChangeTipComponent },
      { path: 'changing', component: ReceiptChangingComponent },
      { path: 'confirm', component: ReceiptChangeConfirmComponent },
    ]
  }, {
    path: 'passcard/seal-reissue', component: SealReissueComponent, children: [
      { path: '', component: SealReissue01Component, data: { status: 5 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/1', component: SealReissue01Component, data: { status: 5 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/2', component: SealReissue02Component },
      { path: 'step/3', component: SealReissue03Component },
      { path: 'step/4', component: ProcSuccessComponent, resolve: { data: SealReissueSuccessResolver } },
    ]
  }, {
    path: 'passcard/refresh', component: RefreshComponent, children: [
      { path: '', component: Refresh01Component, data: { status: 6 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/1', component: Refresh01Component, data: { status: 6 }, resolve: { data: PasscardTypesResolver } },
      { path: 'step/2', component: Refresh02Component },
      { path: 'step/3', component: Refresh03Component },
      { path: 'step/4', component: ProcSuccessComponent, resolve: { data: RefreshSuccessResolver } },
    ]
  },
  { path: 'passcard/contract', component: ContractComponent },
  { path: 'passcard/passcard/cancel', component: PasscardCancelComponent },
  { path: 'passcard/passcard/canceled', component: ProcSuccessComponent },
  { path: 'passcard/applyInfo', component: ReservationComponent },
  { path: 'payment/list', component: PayListComponent },
  { path: 'payment/detail', component: PayDetailComponent },
];
