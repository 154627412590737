<block title="ご請求明細">
  <p-pay-fees [paidDetail]="paidDetail"></p-pay-fees>
  <br>
</block>

<block title="更新後の定期情報">
  <div class="card-container">
    <card *ngIf="contract" [extraFlag]="1" [reservation]="contract"></card>
  </div>
  <br>

  <div class="tableCtn">
    <p-single label="更新月数" value="{{passcardMonth}}ヵ月({{paymentAmount | currency:'JPY'}})" />
    <p-single label="更新後の定期期間" [value]="fixedPeriod" />
    <p-single label="更新後の更新可能期間" [value]="updatablePeriod" />
  </div>

  <div *ngIf="!IsComplete" class="button-wrapper">
    <button type="button" class="cmn-btn bck-btn" (click)="onReInput(1)">定期情報を再選択する</button>
  </div>
  <br>
</block>

<place-info [parkingInfo]="parking"></place-info>
<br>

<block title="お支払い情報">
  <p-pay-method [passcardCancelDocumentUrl]="passcardCancelDocumentUrl" [payInfo]="payInfo"></p-pay-method>
</block>
<br>

<div *ngIf="!IsComplete" class="button-wrapper">
  <button type="button" class="cmn-btn bck-btn" (click)="onReInput(2)">お支払い情報入力に戻る</button>
</div>
<br>
<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button class="six" label="申請"
    (apply)="onApply()"
    [disabled]="api.Disabled"
    [loading]="api.Disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn dcd-btn six'" />
</div>