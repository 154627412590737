import { Injectable } from '@angular/core';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorModel {
  status: number;
  message: string;
  messages: string[];

  constructor(
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  //
  set = (message: string, messages: string[]) => {
    if (!message && !messages) {
      this.message = '操作が正しく受け付けられませんでした';
      this.messages = ['誠に申し訳ございませんが、再度最初から操作をお願い致します。'];
    } else {
      this.message = message;
      this.messages = messages;
    }

    this.saveStorage();
  }

  reset = () => {
    this.message = null;
    this.messages = null;

    this.saveStorage();
  }

  //
  saveStorage = () => {
    this._storage.save('error', this);
  }

  loadStorage = () => {
    this._storage.load('error', this);
  }

}
