
<div id="container">
  <h2>ログイン</h2>

  <t-error [message]="error"></t-error>
  <section>
    <div class="tableCtn mb15">
      <div class="tableLine">
        <div class="tableCell_l">ログインID（メールアドレス）</div>
        <div class="tableCell_r">
          <input [(ngModel)]="mail" (keyup)="onMail()" type="email" class="w100" maxlength="256" placeholder="passcard_@pkweb.jp">
          <p-error [messages]="messages_m"></p-error>
        </div>
      </div>
      <div class="tableLine">
        <div class="tableCell_l">パスワード</div>
        <div class="tableCell_r">
          <input [(ngModel)]="passwd" (keyup.enter)="onApply()" (keyup)="onPasswd()" type="password" class="w100" maxlength="64"
            placeholder="パスワードを入力">
            <p-error [messages]="messages_p"></p-error>
          </div>
      </div>
    </div>
  </section>

  <section class="checkWrapper">
    <label [ngClass]="{'checkBoxArea':auto,'checkBoxAreaNot':!auto}">
      <input [(ngModel)]="auto" type="checkbox" class="unsubscribeConfirm_check"><div class="checkboxMeg">次回から自動的にログイン</div>
    </label>
  </section>

  <div class="buttonBox">
    <loading-button
      (apply)="onApply()"
      [disabled]="api.disabled"
      [loading]="api.disabled"
      [label]="'ログイン'"
      [class]="'cmn-btn button-box-color-w-50'"
      [lClass]="'button-box-loading'">
    </loading-button>
  </div>

  <section class="loginMenu">
    <ul>
      <li><a href="#" (click)="onPasswdReissue($event)" class="passcard-link">パスワードを忘れた方はコチラ</a></li>
    </ul>
  </section>

  <h3 class="mb15 mt20">アカウントをお持ちでない方</h3>
  <div class="buttonBox">
    <button type="button" class="cmn-btn bck-btn button-box-color-w-50" (click)="onRegistration($event)">新規会員登録</button>
  </div>

</div>