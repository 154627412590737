import { Injectable, inject } from '@angular/core';
import { StorageService } from '../../services';
import { CertificateImage } from 'src/app/shared/certificate-file';

const key = 'certificate-upload';

@Injectable()
export class CertificateUploadModel {
  private readonly _storage = inject(StorageService);
  certificateType = 0;
  year: number = undefined;
  month : number = undefined;
  images: CertificateImage[];

  completed: boolean;

  constructor(
  ) {
    this._storage.load(key, this);
    this.images ??= [];
  }

  static reset = (storage: StorageService) => {
    storage.save(key, {
      completed: false,
      certificateType: 0,
      images: []
    });
  }

  apply() {
    this.completed = true;
    this.saveStorage();
  }

  saveStorage() {
    // 画像実データを削除
    if (this.images && this.images.length > 0) {
      this.images.forEach((iamge) => {
        iamge.certificateImageData = "";
      });
    }
    this._storage.save(key, this);
  }

  loadStorage(): CertificateUploadModel{
    return this._storage.load(key, this)
  }
}
