import { Component } from '@angular/core';
import { RouterService } from '../services';
import { AppModel } from '../app.model';
import { NavigationEnd, Router } from '@angular/router';
import { NoticeInfoService, NoticeInfo, NoticeInfoResponse } from '../services/notice-info.service';
import { NavigateService, StorageService } from '../services';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})

export class HomeComponent {
  // お知らせ開閉状態
  noticeExpanded = false;
  // お知らせ情報
  noticeInfo: NoticeInfoResponse;
  // お知らせ件数
  noticeCount = 0;

  private _pay2CompleteNoLogin = false;

  constructor(
    public app: AppModel,
    private routerWatch: RouterService,
    private router: Router,
    private noticeService: NoticeInfoService,
    public navi: NavigateService,
    private storageService: StorageService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { url } = <NavigationEnd>event;
        this._pay2CompleteNoLogin = false;
        if (url.includes('/passcard/2pay/complete')) {
          if (!app.login) {
            this._pay2CompleteNoLogin = true;
          }
        }

        // セッションから緊急のお知らせを取得
        this.noticeInfo = this.storageService.load(NoticeInfoService.LOCAL_STORAGE_KEY_EMERGENCY_NOTICEINFO, this.noticeInfo);
        if (this.noticeInfo && this.noticeInfo.noticeInfoList) {
          this.noticeCount = this.noticeInfo.noticeInfoList.length;
        }
      }
    });
  }

  // header
  viewHeader = () => ['app', 'unexpected'].includes(this.routerWatch.mode);

  // navi
  viewNavi = () => !this._pay2CompleteNoLogin && ['app'].includes(this.routerWatch.mode);

  // footer
  viewFooter = () => ['app', 'unexpected'].includes(this.routerWatch.mode);

  /**
   * お知らせリストを表示するかを返す
   * @returns true 表示；false 非表示
   */
  viewNoticeList() {
    return this.noticeCount > 0 && (this.router.url.startsWith('/passcard/applyInfo') || this.router.url.startsWith('/passcard/contract'));
  }

  /**
   * お知らせリストアコーディオン押下時イベント
   */
  onNoticeExpand() {
    this.noticeExpanded = !this.noticeExpanded;
  }

  /**
   * お知らせ詳細画面を開く（お知らせリンククリック）
   * @param id お知らせ番号
   */
  onDetail = (id: number) => {
    // 選択されたお知らせをローカルストレージへ保存
    this.storageService.save(NoticeInfoService.LOCAL_STORAGE_KEY_NOTICEINFO, this.noticeInfo.noticeInfoList[id]);
    window.open(this.noticeService.getNoticeDetailPath(), '_blank');
  }

  /**
   * アコーディオンの開閉状態を考慮してお知らせ一覧を返す
   */
  getNoticeInfoList(): NoticeInfo[] {

    if(!this.noticeInfo || !this.noticeInfo.noticeInfoList || this.noticeInfo.noticeInfoList.length === 0 ){
      this.noticeCount = 0;
      return;
    }else{
      this.noticeCount = this.noticeInfo.noticeInfoList.length;
    }
    if (this.noticeExpanded) {
      // 開くとき
      return this.noticeInfo.noticeInfoList;
    } else {
      // 閉じるとき
      return [this.noticeInfo.noticeInfoList[0]];
    }
  }
}
