import { Routes } from '@angular/router';

import { CancelComponent } from './cancel.component';
import { ErrorComponent } from './error.component';
import { RedirectComponent } from './redirect.component';
import { SuccessComponent } from './success.component';

//
export const routes: Routes = [{
  path: 'cancel',
  // component: CancelComponent,
  component: ErrorComponent,
}, {
  path: 'error2',
  component: ErrorComponent,
}, {
  path: 'success',
  component: SuccessComponent,
}, {
  path: '',
  component: RedirectComponent,
}];

//
export const redirectComponents = [
  CancelComponent,
  ErrorComponent,
  SuccessComponent,
  RedirectComponent,
];
