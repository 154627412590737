import { Component, OnInit, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { LostService } from '../../lost.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { LostComponent } from '../../lost.component';
import { Passcard, PasscardApplyApi } from 'src/app/passcard/models/passcard';
import { getStorageKey } from 'src/app/passcard/passcard.model';

@Component({
  selector: 'lost01',
  templateUrl: './lost01.component.html'
})
export class Lost01Component implements OnInit {
  private readonly _route = inject(ActivatedRoute);
  private readonly _wiz = inject(LostComponent);
  private readonly _storage = inject(StorageService);
  
  buyICCard = -1;
  fee = 0;
  passcard: Passcard;
  applyParams: PasscardApplyApi;
  applyCardIssueCommission: number = 0;
  applyLostIssueCommission: number = 0;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService
  ) {
    this._wiz.processing = 0;
    this._wiz.saveState();
    const { dcParkingNo } = this._wiz.passcardApply;
    this._storage.load(getStorageKey(`lost-step-1`, 4, dcParkingNo), this)
  }

  ngOnInit(): void {
    this._route.data.pipe(map(({ data }) => data)).subscribe(x => {
      this.applyParams = x;
      const passcardType = this._wiz.passcardApply.passcardType;
      this.passcard = x.passcardTypeList.find(x => x.passcardType == passcardType);
      this.applyCardIssueCommission = x?.applyCardIssueCommission;
      this.applyLostIssueCommission = x?.applyLostIssueCommission;
    });
  }

  get IsComplete() {
    return this._wiz.complete;
  }

  get contract() {
    return this._wiz.passcardApply;
  }

  get cardPurchaseChoicesBuy() {
    return this.applyParams.cardPurchaseChoicesBuy;
  }

  get cardPurchaseChoicesMyCardUse() {
    return this.applyParams.cardPurchaseChoicesMyCardUse;
  }

  async onNext() {
    this.fee = this.buyICCard == 0 ? this.applyLostIssueCommission : this.applyCardIssueCommission + this.applyLostIssueCommission;
    if (this.fee) {
      const selectTargetMonth = this._wiz.passcardApply['selectTargetMonth'];
      const { paymentTerm, terminalPaymentTerm } = await this.api.getPaymentTerm(4, selectTargetMonth);
      const {
        paymentMethodPaidMachineQR,
        paymentMethodCreditCard,
        paymentMethodPayPay,
        paymentMethodConvenienceStore,
      } = this.applyParams;
      this._storage.save('payment-params', {
        paymentTerm, terminalPaymentTerm,
        paymentMethodPaidMachineQR, paymentMethodCreditCard, paymentMethodPayPay, paymentMethodConvenienceStore,
        commission: this.fee
      });
    } else {
      // 金額が０円の場合は支払情報を削除する
      this._storage.remove(getStorageKey('payment-method', 4, this._wiz.passcardApply.dcParkingNo));
    }

    this.saveModel();

    const next = this.fee == 0 ? 3 : 2;
    this._navi.navigateByUrl(`/passcard/lost/step/${next}`);
  }

  saveModel() {
    const { buyICCard, fee } = this;
    const { dcParkingNo } = this._wiz.passcardApply;
    const { applyLostIssueCommission, applyCardIssueCommission, passcardCancelDocumentUrl} = this.applyParams;
    this._storage.save(getStorageKey(`lost-step-1`, 4, dcParkingNo), {
      buyICCard,
      fee,
      applyLostIssueCommission,
      applyCardIssueCommission,
      passcardCancelDocumentUrl,
      passcard: this.passcard
    });
  }
}
