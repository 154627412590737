<h2>メール通知設定</h2>

<section>
  <div class="tableCtn">
    <div class="tableLine">
      <div class="tableCell_l">メール通知設定</div>
      <div class="tableCell_r- tableCell_r_mail mailNotify">
        <label [ngClass]="{'mail-box':model.updateNotice,'mail-box-not':!model.updateNotice}">
          <div class="check-line">
            <input [(ngModel)]="model.updateNotice" type="checkbox" class="check-pos">
            <div class="check-text">
              <p class="check-content" [ngClass]="{'check-content-check':model.updateNotice}">更新期間通知</p>
              <p class="mailNotify-comment">定期更新期間となりましたらお知らせします。</p>
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</section>

<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <button (click)="onApply()" class="cmn-btn">確認</button>
</div>