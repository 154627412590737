import { Injectable } from '@angular/core';

import { ConfigService } from './services/config.service';
import { StorageService } from './services/storage.service';

export interface ParkingPlace {
  address: string;
  id: number;
  name: string;
}

export interface PublishedDocumentInfo {
  /**
   * ���p�K��URL(String)
   */
  termsServiceURL: string;

  /**
   * �v���C�o�V�[�|���V�[URL(String)
   */
  privacyPolicyURL: string;

  /**
   * �^�c��Џ��URL(String)
   */
  managementCompanyInfoURL: string;
  
  /**
   * �����p�ɂ�������URL(String)
   */
  siteUseURL?: string;

  /**
   * ���菤����@�Ɋ�Â��\�LURL(String)
   */
  specifiedCommercialTransactionsURL?: string;
  
  passcardApplyTermsServiceURL?: string;
  passcardApplyNotesDOCList?: string[];
  applySiteTopLinkName?: string;
  applySiteTopLinkURL?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppModel {
  login: boolean;
  creditTime = 0;
  locked = false;

  publishedDocumentInfo: PublishedDocumentInfo = {
    termsServiceURL: '',
    privacyPolicyURL: '',
    managementCompanyInfoURL: ''
  };
  
  private _parkingPlace: ParkingPlace = { id: 0, name: '', address: '' };

  constructor(
    private _conf: ConfigService,
    private _storage: StorageService
  ) {
    this.loadStorage();
  }

  get ParkingPlace(): ParkingPlace {
    return this._parkingPlace;
  }

  set ParkingPlace(val: ParkingPlace) {
    this._parkingPlace = val;
  }

  //
  saveStorage = () => {
    this._storage.save('app', this);
  }

  loadStorage = () => {
    this._storage.load('app', this);
  }

  //
  guard = (url: string) => {

    // ignore
    for (const p of ['/mypage/mail/complete/']) {
      if (url.startsWith(p))
        return false;
    }

    // check login
    if (this.login)
      return false;

    for (const p of ['/mypage/']) {
      if (url.startsWith(p))
        return true;
    }

    //
    return false;
  }

}
