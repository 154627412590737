import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { Passcard, PasscardApplyApi } from '../models/passcard';
import { EMPTY, Observable, Subject, from } from 'rxjs';
import { ApiService, NavigateService, StorageService } from 'src/app/services';
import { catchError, map, take } from 'rxjs/operators';
import { AppModel } from 'src/app/app.model';
import { HttpResponse } from '@angular/common/http';
import { PayInfomation, CeditDefault } from '../pay/pay.model';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { ReceptionMode, getStorageKey } from '../passcard.model';
import { PasscardApply } from '../models/apply-info';

export interface ApplyModel {
    passcard?: Passcard;
    dcParkingNo?: number;
    passcardApplyNo?: number;
    passcardMonth: number;
    buyICCard: number;
    payMethod: PayInfomation;
    selectTargetMonth?: number;
    passcardValidFrom?: number;
    passcardValidTo?: number;
}

/**
 * 定期券予約サービス
 */
@Injectable({
    providedIn: 'root'
})
export class PasscardApplyService {
    private _storage = inject(StorageService);
    complete = false;
    processing = 0;
    maxProcessed = 0;
    passcardApply: PasscardApply | null = null;
    applyParams: PasscardApplyApi;
    applyCardIssueCommission: number;
    inputCache: ApplyModel = {
        passcardApplyNo: null,
        passcardMonth: 0,
        buyICCard: -1,   // -1:選択されない 0:購入しない、1:購入する
        payMethod: { method: -1, creditPay:{ cardUse:-1, '0': {...CeditDefault}, '1': {...CeditDefault} }, cvsPay: {}},
        selectTargetMonth: 0,
    };

    applyModel: ApplyModel = {
        passcardApplyNo: null,
        passcardMonth: 0,
        buyICCard: -1,   // -1:選択されない 0:購入しない、1:購入する
        payMethod: { method: -1},
        selectTargetMonth: 0,
    };

    get isReApply() {
        return this.applyStatus > 1;
    }
    
    get applyStatus() {
        return this.passcardApply?.applyStatus ?? 1;
    }

    reset(dcParkingNo?: number) {
        dcParkingNo ??= null;
        this._storage.remove(`payment-params`);
        this._storage.remove('apply-wiz');
        if (dcParkingNo != null) {
            this._storage.remove(`passcard-reapply-${dcParkingNo}`);
        }

        this._storage.remove(getStorageKey('passcard-apply-1', 1, null));
        this._storage.remove(getStorageKey('payment-method', 1, null));
        this._storage.remove(getStorageKey('passcard-apply-complete', 1, null));
        for (let status of [3, 9]) {
          this._storage.remove(getStorageKey('passcard-apply-1', status, dcParkingNo));
          this._storage.remove(getStorageKey('payment-method', status, dcParkingNo));
          this._storage.remove(getStorageKey('passcard-apply-complete', status, dcParkingNo));  
        }  
    }
}

export const PasscardTypesResolver: ResolveFn<PasscardApplyApi> = (
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
    navi = inject(NavigateService),
    api: ApiService = inject(ApiService),
    apiProxy = inject(PasscardApiProxy),
    app = inject(AppModel),
    storage = inject(StorageService),
    service = inject(PasscardApplyService)
): Observable<PasscardApplyApi> => {
    const status = route.data?.status ?? 1;
    let result: Subject<HttpResponse<PasscardApplyApi>> = null;
    let paramsReApply: any = null;
    if (status == 3) {
        const dcParkingNo = route.paramMap.get('dcParkingNo');
        paramsReApply = storage.load(`passcard-reapply-${dcParkingNo}`, null);
        result = api.post<PasscardApplyApi>('/passcardReApplyInfoEc', { passcardApplyNo: paramsReApply.passcardApplyNo }, 1);
    }
    else {
        result = api.post<PasscardApplyApi>('/passcardTypeListEc', { status }, 1)
    }

    return result.pipe(
        catchError(() => {
            navi.navigateByUrl('/error', true);
            return EMPTY;  
        }),
        map(({ body }) => {
            setTimeout(() => apiProxy.error = body.mainMessage);
            body.passcardTypeList ??= [];
            if (body.resultCode != 0 && body.resultCode != 4) {
                navi.navigateByUrl('/error', true);
            }

            if (body.resultCode == 0
                && status == 3) {
                const applyStatus = paramsReApply.applyStatus;
                if (applyStatus == 9) {
                    const passcard = body.passcardTypeList[0];
                    if (passcard.receptionMode == ReceptionMode.Manual) {
                        const key = getStorageKey('passcard-apply-1', 9, paramsReApply.dcParkingNo);
                        storage.save(key, {
                            passcard
                        });
    
                        navi.navigate('/passcard/new/step/3', false, { status: 9, dcParkingNo: paramsReApply.dcParkingNo });
                    }    
                }
            }

            if (status == 3) {
                body['dcParkingNo'] = paramsReApply.dcParkingNo;
            }

            return body;
        }),
        take(1)
    );
}

export const ApplySuccessResolver: ResolveFn<number> = (
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
    service: PasscardApplyService = inject(PasscardApplyService)
): Observable<number> => {
    service.complete = true;
    service.processing = 3;
    service.maxProcessed = 3;
    return from([0]);
}
