<div class="container">
  <div class="main-wrap">
    <div class="main-contents">
      <div class="complete-mark"><i class="far far fa-times-circle check-out em4"></i></div>
      <div class="text-message text-complete">決済に失敗しました。<br>お手数ですが再度入力をお願いします。</div>
      <div class="button-area">
        <button class="common-button back-button" (click)="onTop()">トップページへ戻る</button>
      </div>
    </div>
  </div>
</div>