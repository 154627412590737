import { Component, inject } from '@angular/core';
import { Location } from '@angular/common';
import { PayRequestInfo } from '../pay.model';
import { ApiService, ConfigService, NavigateService } from 'src/app/services';
import { PayModel } from '../models/pay.model';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { PaymentPayPayInfo, checkValidFeeItem } from '../models/payinfo.model';
import { ApiReturn, formatDate } from '../../passcard.model';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

interface SettlementResp extends ApiReturn {
  paymentFee?: number;
  transactionId?: string;
  settlementUrl?: string;
}

const error_trans = 'PayPay要求回数制限に達しました。';

@Component({
  selector: 'pp-pay',
  templateUrl: './paypay-payinfo.component.html'
})
export class PaypayPayinfoComponent {
  private readonly _navi = inject(NavigateService);
  private readonly _apiProxy = inject(PasscardApiProxy);

  paymentInfo: PaymentPayPayInfo;
  paymentTerm: string;
  payRequest: PayRequestInfo;
  passcardApplyNo: number;
  payModel = inject(PayModel);

  feeChanged = false;
  disabled = false;
  message_s = '';
  redirectUrl = '';
  businessAreaCode = 0;
  veriCount = 0;

  constructor(public api: ApiService,
    public conf: ConfigService,
    l: Location) {
    const state = l.getState();
    this.passcardApplyNo = state['passcardApplyNo'];
    this.paymentInfo = <PaymentPayPayInfo>state['paymentInfo'];
    this.redirectUrl = `${location.origin}/passcard/2pay/complete?parking-code=${conf.parkingCode??''}`;
    if (this.paymentInfo.paymentTerm) {
      const dateStr = formatDate(this.paymentInfo.paymentTerm);
      const date = new Date(this.paymentInfo.paymentTerm);
      const hours = `${date.getHours()}`.padStart(2, '0');
      const minutes = `${date.getMinutes()}`.padStart(2, '0');
      this.paymentTerm = `${dateStr} ${hours}:${minutes}`;
    }

    this.payRequest = {
      paymentContentsName: this.paymentInfo.paymentDetail,
      parkingAreaName: this.paymentInfo.dcParkingName,
      receptionNo: this.paymentInfo.receiptNo,
      payDetail: {
        paymentAmount: 0,
        paymentFeeDetails: []
      }
    };

    this.setFeeDetails();

    if (new Date().getTime() < this.paymentInfo.paypayCallInvalidTime) {
      this._apiProxy.error = error_trans;
    }
  }

  get limit() {
    return this.paymentInfo.paypayCallCountLimit;
  }

  onBack() {
    this._navi.navigateByUrl('/passcard/list');
  }

  onExePaypay() {
    this.postSettle();
  }

  private postSettle() {
    if (this._apiProxy.error == error_trans) return;

    // const bak = LOOPBACK;
    // LOOPBACK = false;
    // const url = this.api.getUrl();
    // this.api.setUrl('http://localhost:5000');
    this.api.disabled = true;
    this.api.post<SettlementResp>('/paymentPayPay/settlement',
      {
        passcardApplyNo: this.passcardApplyNo,
        redirectUrl: this.redirectUrl
      }, 1).pipe(
        catchError(() => {
          this.disabled = false;
          this.errorVeritrans();
          return EMPTY;
        })
      ).subscribe(({ body }) => this.afterSettle(body));

    // LOOPBACK = bak;
    // this.api.setUrl(url);
  }

  private afterSettle(body: SettlementResp) {
    this.api.disabled = false;
    if (body.resultCode !== 0) {
      this.errorVeritrans(body.mainMessage);
      return;
    }

    window.location.href = body.settlementUrl;
  }

  private errorVeritrans(error?: string) {
    const locked = !!this.limit && this.limit <= ++this.veriCount;
    if (locked) {
      this._apiProxy.error = error_trans;
    }
    else {
      this._apiProxy.error = error ? error : 'PayPay要求に失敗しました。';
    }
  }

  private setFeeDetails() {
    const meta = [
      { item: 'passcardAmount', title: '定期代' },
      { item: 'newIssueCommission', title: '新規発行手数料' },
      { item: 'reIssueCommission', title: '再発行手数料' },
      { item: 'buyICCardCommission', title: 'ICカード購入手数料' },
    ];

    meta.forEach(payItem => {
      const fee = checkValidFeeItem(this.paymentInfo, payItem.item);
      if (fee == null) return;

      this.payRequest.payDetail.paymentAmount += fee;
      this.payRequest.payDetail.paymentFeeDetails.push({contents:payItem.title, Fee: fee});  
    });
  }
}
