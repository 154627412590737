import { Component } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { Location } from '@angular/common';
import { SealReissueService } from './seal-reissue.service';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { NavigateService } from 'src/app/services';
import { PaymentType } from '../pay/pay.model';

@Component({
  selector: 'seal-reissue',
  templateUrl: './seal-reissue.component.html'
})
export class SealReissueComponent {
  constructor(
    public app: AppModel,
    public api: PasscardApiProxy,
    public sealService: SealReissueService,
    private _navi: NavigateService,
    location: Location) {
    const navigateState = location.getState();
    sealService.passcardApply = navigateState?.['contract'];
  }

  get PayMethodLabel() {
    return PaymentType[this.sealService.applyModel.payMethod.method];
  }

  onToPayDetail() {
    const payRequest = {
      requestContent: '定期申請料金',
      parkingName: 'アマノ第一駐輪場',
      receiptNo: '37-123456789',
      payDetail: {
        payItems: [
          { name: '定期代', amount: 18000 },
          { name: 'コンビニ支払い手数料', amount: 200 },
          { name: 'ICカード購入手数料', amount: 500 },
        ],
        total: 18700
      }
    };

    let to = 'ic-register';
    switch (this.sealService.applyModel.payMethod.method) {
      case PaymentType.Terminal: to = 'terminal-pay'; break;
      case PaymentType.CreditCard: to = 'ic-register'; break;
      case PaymentType.PayPay: to = '2pay-pay'; break;
      case PaymentType.Cvs: to = 'conveni-pay'; break;
    }

    this._navi.navigate(`/passcard/${to}`, false, { reservation: null, payRequest });
  }

  /**
  * 定期情報ページに戻る
  */
  onBackToContract() {
    this._navi.navigate(`/passcard/contract`, false, { contract: this.sealService.passcardApply });
  }
}
