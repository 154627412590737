import { Component, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { PayFeeDetail } from 'src/app/passcard/common/p-pay-fees/p-pay-fees.component';
import { PaymentType } from 'src/app/passcard/pay/pay.model';
import { ParkingInfo } from 'src/app/passcard/models/apply-info';
import { LostComponent } from '../../lost.component';
import { getStorageKey } from 'src/app/passcard/passcard.model';
import { getApplyPaymentItems } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';

@Component({
  selector: 'lost03',
  templateUrl: './lost03.component.html'
})
export class Lost03Component {
  private readonly _storage = inject(StorageService);
  private readonly _wiz = inject(LostComponent);
  private _cach01: any;
  private _cach02: any;
  private _cach03: any;

  dcParkingNo: number;
  paidDetail: PayFeeDetail;
  parking: ParkingInfo;
  passcardCancelDocumentUrl = '';

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService) {
    this._wiz.processing = 2;
    this._wiz.saveState();
    const { parkingName, parkingAddress } = this._wiz.passcardApply;
    this.parking = {
      dcParkingNo: 0,
      parkingName: parkingName,
      parkingAddress: parkingAddress
    };

    this.dcParkingNo = this._wiz.passcardApply.dcParkingNo;

    this.paidDetail = {
      paymentFeeDetails: [
        { contents: '再発行手数料', Fee: this.Cach01.fee },
      ],
      paymentAmount: this.Cach01.fee
    };

    if (this.Cach01.buyICCard == 1) {
      this.paidDetail.paymentFeeDetails[0].contents = 'ICカード再発行手数料';
    }

    this.passcardCancelDocumentUrl = this.Cach01.passcardCancelDocumentUrl;
    if (this.payInfo?.method == PaymentType.Cvs) {
      this.paidDetail.paymentFeeDetails.push({ contents: 'コンビニ支払い手数料', Fee: this.payInfo.cvsPay.payCommission });
      this.paidDetail.paymentAmount += this.payInfo.cvsPay.payCommission;
    }
  }

  get Cach01() {
    const key = getStorageKey(`lost-step-1`, 4, this.dcParkingNo);
    this._cach01 ??= this._storage.load(key, null);
    return this._cach01;
  }

  get Cach02() {
    const key = getStorageKey('payment-method', 4, this.dcParkingNo);
    this._cach02 ??= this._storage.load(key, null);
    return this._cach02;
  }

  get Cach03() {
    const key = getStorageKey('lost-step-3', 4, this.dcParkingNo);
    this._cach03 ??= this._storage.load(key, null);
    return this._cach03;
  }

  get IsComplete() {
    return this._wiz.complete;
  }

  get payInfo() {
    return this.Cach02;
  }

  get contract() {
    return this._wiz.passcardApply;
  }

  get Charge() {
    return this.Cach01.fee;
  }

  get buyCardContent() {
    return this.Cach01.buyICCard == 1 ?'駐輪場専用のICカードを購入して' : 'お手持ちの交通系ICカードを'
  }

  get showPaymentInfo() {
    return this.Cach01.fee != 0;
  }

  onReInput(kb: number) {
    this._navi.navigateByUrl(`/passcard/lost/step/${kb}`);
  }

  /**
 * 申請を行う
 */
  async onApply() {
    this._wiz.maxProcessed = 2;
    const { dcParkingNo, no, passcardType, passcardExtended, passcardValidFrom, passcardValidTo, passcardMonth } = this._wiz.passcardApply;
    const passcard = this.Cach01.passcard;
    const buyICCard = this.Cach01.buyICCard;
    const applyInfo: any = {};
    if (this.showPaymentInfo) {
      applyInfo.buyICCard = buyICCard;
      Object.assign(applyInfo, getApplyPaymentItems(this._storage, 4, dcParkingNo));
      const { paymentTerm, terminalPaymentTerm } = this.payInfo;
      switch (this.payInfo.method) {
        case PaymentType.Terminal:
          applyInfo.terminalPaymentTerm = terminalPaymentTerm;
          break;
        case PaymentType.PayPay:
        case PaymentType.Cvs:
            applyInfo.paymentTerm = paymentTerm;
          break;
      }

      const { applyLostIssueCommission, applyCardIssueCommission } = this.Cach01;
      if (applyLostIssueCommission) {
        applyInfo.applyLostIssueCommission = applyLostIssueCommission;
      }

      if (buyICCard == 1 && applyCardIssueCommission > 0) {
        applyInfo.applyCardIssueCommission = applyCardIssueCommission;
      }
    }

    const passPaymentDivision = passcard?.paymentDivisionList?.find(x => x.monthNum == passcardMonth)?.passPaymentDivision ?? 0;
    const { receiptNo, passcardApplyNo } = await this.api.passcardApply({
      passcardApplyNo: null,
      dcParkingNo: this.dcParkingNo,
      status: 4, // 4：紛失の申請を行う場合
      no,
      passcardType,
      extended: passcardExtended,
      passPaymentDivision,
      passcardValidFrom,
      passcardValidTo,
      ...applyInfo,
    });

    this._wiz.passcardApplyNo = passcardApplyNo;
    if (this.payInfo) {
      this._wiz.paymentType = this.payInfo.method;
    }
    this._wiz.complete = true;
    this._wiz.processing = 3;
    this._wiz.saveState();
    let message2 = '続けてICカードの登録を定期更新機にて';
    let message3 = '';
    switch (this.payInfo?.method) {
      case PaymentType.Terminal:
        message2 = '続けて駐車場の定期更新機にてお支払いをお願い致します。';
        break;
      case PaymentType.PayPay:
        message2 = '続けてPayPay支払いのお手続きをお願い致します。';
        break;
      case PaymentType.Cvs:
        message2 = '続けてコンビニで支払いのお手続きをお願い致します。';
        break;
      default:
        message3 = 'お手続きをお願い致します。'
    }
    
    const state = {
      message1: '定期券の紛失申請が完了しました。',
      message2,
      message3,
      receiptNo
    };
    this._navi.navigate(`/passcard/lost/step/4`, true, state);
  }
}
