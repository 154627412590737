<block title="ご請求明細">
  <p-pay-fees [paidDetail]="paidDetail"></p-pay-fees>
  <br>
</block>

<block title="定期情報">
  <div class="card-container">
    <card *ngIf="contract" [reservation]="contract"></card>
  </div>
  <br>
</block>

<block title="お支払い情報">
  <p-pay-method [payInfo]="payInfo"></p-pay-method>
</block>
<br>

<div *ngIf="!IsComplete" class="button-wrapper">
  <button type="button" class="cmn-btn bck-btn" (click)="onReInput(2)">お支払い情報入力に戻る</button>
</div>
<br>
<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button class="six" label="申請"
    (click)="onApply()"
    [disabled]="api.Disabled"
    [loading]="api.Disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn dcd-btn six'" />
</div>