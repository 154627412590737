<div class="confirm-info confirm-info-border">
    <div><i class="confirm-img info-img"></i></div>
    <div>
        <p>以下の内容で配送先情報を変更します。</p>
        <p>内容を確認し、「確定」を押してください。</p>
    </div>
</div>
<h2>配送先情報編集</h2>
<h3>定期情報</h3>
<passcard-info [model]="PasscardInfo"></passcard-info>
<br>

<div class="tableCtn">
    <p-single label="定期期間" [value]="ValidPeriod" />
</div>
<br>

<place-info [parkingPlace]="app.ParkingPlace"></place-info>
<br>

<h3>配送先情報</h3>
<div class="comment delivery-tip"><span>※定期券の配送先となるため、</span><span>お間違えの無いようにご入力ください。</span></div>
<br>
<delivery [delivery]="Delivery"></delivery>
<div class="button-wrapper">
    <button type="button" class="cmn-btn bck-btn" (click)="onReInput()">配送先情報入力に戻る</button>
</div>
<br>
<div *ngIf="!IsComplete" class="buttonBox">
    <loading-button (apply)="onApply()" [disabled]="api.Disabled" [loading]="api.Disabled" [label]="'確定'"
      [class]="'cmn-btn dcd-btn six'" [lClass]="'button-box-loading'" />
</div>