import { Component, Input, inject } from '@angular/core';
import { Date9 } from 'src/app/shared/time9';
import { option_numbers } from 'src/app/shared/utils';
import { CeditDefault, PayInfomation } from '../../pay/pay.model';
import { ApiService, UrlCategory, ValidateService } from 'src/app/services';
import { VeritransRes } from 'src/app/shared/veritrans/veritrans.interface';
import { PasscardApiProxy } from '../../passcard.api.proxy';

const LBL_securityCode = 'セキュリティコード';
const LBL_cardNumber = 'クレジットカード番号';

declare var veritrans_url: string;

export interface CreditInitInfo {
  creditCardNo: string;
  creditExpirationDate: number;
  tokenApiKey: string;
  creditInputCountLimit: number;
  creditInputInvalidTime: number;
}

@Component({
  selector: 'pay-cc',
  templateUrl: './pay-creditcard.component.html'
})
export class PayByCreditcardComponent {
  api = inject(ApiService);
  apiProxy = inject(PasscardApiProxy);
  @Input() model: PayInfomation;
  @Input() status: number;

  valid = inject(ValidateService);

  help = false;
  option_y = [];
  option_m = [];
  msgCard: string[] = [];
  msgSecurity: string[] = [];
  msgMonth: string[] = [];
  msgYear: string[] = [];
  msgExpired: string[];

  regitFlag: boolean = false;

  constructor() {
    const dt = Date9();
    const year = dt.year() - 2000;
    this.option_y = option_numbers(year, year + 10);
    this.option_m = option_numbers(1, 12);
  }

  ngDoCheck() {
    const error = this.validation();
    if (error != this.model.error) {
      setTimeout(() => this.model.error = error);
    }
  }

  get CardExpire() {
    this.model.creditPay[1].expire ??= '/';
    return this.model.creditPay[1].expire;
  }

  get cardExpiresMonth(): string {
    return this.CardExpire.split('/')[0];
  }

  set cardExpiresMonth(val: string) {
    let expires = this.CardExpire.split('/');
    expires[0] = val;
    this.model.creditPay[1].expire = expires.join('/');
  }

  get cardExpiresYear(): string {
    return this.CardExpire.split('/')[1];
  }

  set cardExpiresYear(val: string) {
    let expires = this.CardExpire.split('/');
    expires[1] = val;
    this.model.creditPay[1].expire = expires.join('/');
  }

  get PayModel() {
    this.model.creditPay[0] ??= { ...CeditDefault };
    this.model.creditPay[1] ??= { ...CeditDefault };
    return this.model.creditPay;
  }

  get limit() {
    return this.PayModel.creditInputCountLimit;
  }

  async veritrans(): Promise<boolean> {
    if (this.model.error) return false;

    if (this.PayModel.cardUse == 1
      && (new Date().getTime() < this.PayModel.creditInputInvalidTime
      || (!!this.limit && this.model.creditPay.veriCount >= this.limit))) {
      this.apiProxy.error = this.valid.credit.countLimit;
      return false;
    }

    const veritransRes = await this.execVeritrans();
    if (veritransRes?.code == 'success' && veritransRes.status === 'success') {
      const cardExpire = (this.PayModel.cardUse == 1 ? this.CardExpire : this.PayModel[0].expire).split('/');
      const creditExpirationDate = new Date(+`20${cardExpire[1]}`, +cardExpire[0] - 1, 1).getTime();
      this.model.creditPay.transactionId = await this.apiProxy.authCreditCardInfo({
        maskCreditNo: veritransRes.req_card_number,
        creditExpirationDate,
        mdkToken: veritransRes.token,
        registFlg: this.PayModel.cardUse == 0 ? true : this.regitFlag,
        status: +this.status
      });
      this.model.creditPay.creditCardNo = veritransRes.req_card_number;

      return true;
    }

    this.errorVeritrans();
    return false;
  }

  setCardUse(kb: number) {
    if (this.PayModel.cardUse != kb) {
      this.PayModel.cardUse = kb;
      if (kb == 0) {
        this.apiProxy.error = '';
      }
    }
  }

  private async execVeritrans(): Promise<VeritransRes | null> {
    if (this.PayModel.cardUse == 0) {
      return {
        code: 'success',
        status: 'success',
        message: '',
        token: null,
        req_card_number: null,
        token_expire_date: null
      };
    }

    if (!!this.limit && this.limit <= this.PayModel.veriCount) {
      return null;
    }

    const req = {
      token_api_key: this.PayModel.tokenApiKey,
      card_number: this.PayModel[1].no,
      card_expire: this.PayModel[1].expire,
      security_code: this.PayModel[1].securityCode,
      lang: 'ja'
    };

    return new Promise<VeritransRes | null>(resolve => {
      this.api.post<VeritransRes>(veritrans_url, req, UrlCategory.Raw)
        .subscribe(({ body }) => {
          resolve(body);
        },
          _error => {
            resolve(null);
          });
    });
  }

  private errorVeritrans() {
    const locked = !!this.limit && this.limit <= ++this.PayModel.veriCount;
    if (locked) {
      this.apiProxy.registInputLimit();
      this.apiProxy.error = this.valid.credit.countLimit;
    }
    else {
      this.apiProxy.error = this.valid.credit.certErr;
    }
  }

  validation(): boolean {
    if (this.PayModel.cardUse == 0) {
      return false;
    }

    this.msgCard = this.valid.validRequired(this.PayModel[1].no, LBL_cardNumber);
    if (!this.msgCard.length) {
      this.valid.pushCredit(this.msgCard, this.PayModel[1].no, LBL_cardNumber);
    }

    if (this.PayModel[1].no && (16 < this.PayModel[1].no.length)) {
      this.msgCard.push(this.valid.get(this.valid.string.maxLength, LBL_cardNumber, '16'));
    }

    this.msgSecurity = this.valid.validRequired(this.PayModel[1].securityCode, LBL_securityCode);
    this.valid.pushSecurity(this.msgSecurity, this.PayModel[1].securityCode, LBL_securityCode);
    this.msgMonth = this.valid.validRequired(this.cardExpiresMonth, 'month');
    this.msgYear = this.valid.validRequired(this.cardExpiresYear, 'year');
    this.msgExpired = this.msgMonth.length || this.msgYear.length ? ['有効期限を入力してください。'] : [];

    return this.msgSecurity.length > 0
      || this.msgCard.length > 0
      || this.msgExpired.length > 0;
  }
}
