<p-info>
    <p>宛名の変更を行います。</p>
    <p>宛名を入力し、「確認」を押してください。</p>
</p-info>
<br>
<div class="tableCtn">
    <div class="tableLine">
        <div class="tableCell_l">宛名</div>
        <div class="tableCell_r">
            <div class="editname">
                <input [(ngModel)]="model.nameWorking" (keyup)="onChange()"
                    [class.error_border_r]="valid.hasError(message, messages)" placeholder="天野太郎" type="text"
                    maxlength="65">
                <p>様</p>
            </div>
            <p-error [messages]="valid.merge(message, messages)"></p-error>
        </div>
    </div>
</div>
<br>
<div class="buttonBoxVertical">
    <button type="button" class="cmn-btn mr15 bck-btn" (click)="onBack()">戻る</button>
    <button type="button" [disabled]="error" class="cmn-btn" (click)="onConfirm()">確認</button>
</div>