import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'block',
  templateUrl: './block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockComponent {
  private _title = '';

  @HostBinding('attr.title')
  externalTitle = '';

  @Input() set title(val: string) {
    this._title = val;
    this.externalTitle = '';
  }

  get title() {
    return this._title;
  }
}