import { ApiReturn } from "../passcard.model";

export interface PasscardApplyDetail extends ApiReturn {
    /**
     * 申請状況
     */
    status?: number;

    passcardPaymentType?: number;
    
    /**
     * 申請日時
     */
    passcardApplyDate?: number;

    /**
     * 定期の種類
     */
    passcardTypeName?: string;

    /**
     * 定期種別変更後の定期券種別
     */
    changedPasscardType?: number;

    /**
     * 定期種別変更後の定期券種別名称(String)
     */
    changedPasscardTypeName?: string;

    denialReason?: string;
    correctLimitDate?: number;

    /**
     * 契約月数
     */
    passcardMonth?: number;

    passcardAmount?: number;

    /**
     * 使用開始日
     */
    applyUseStart?: number;

    selectTargetMonth?: number;

    /**
     * 定期有効開始
     */
    passcardValidFrom?: number;

    /**
     * 定期有効終了
     */
    passcardValidTo?: number;

    /**
     * 定期更新可能開始
     */
    passcardUpdateFrom?: number;

    /**
     * 定期更新可能終了
     */
    passcardUpdateTo?: number;

    /**
     * ICカード購入
     */
    selectedBuyCard?: number;

    buyICCardCommission?: number;

    /**
     * 受付No.
     */
    receiptNo?: string;

    /**
     * 支払い処理日時(long)
     */
    paymentProcessDt?: number;

    /**
     * 支払い処理日時のミリ秒(int)
     */
    paymentProcessMs?: number;

    /**
     * 氏名（カナ）（String)
     */
    nameFurigana?: string;

    /**
     * 宛名設定フラグ(int)
     */
    receiptAddressNameFlg?: number;

    /**
     * 定期券種別管理番号
     */
    no?: number;
}

export enum AppliedDetailItemType {
    String = 0,
    Status,
    DateTime = 1,
    Date,
    Currency,
    BuyICCard,
    ValidFrom,
    PasscardMonth,
}

export interface AppliedDetailItem {
    caption: string;
    type?: AppliedDetailItemType,
    dispStr?: string,
    dispStr2?: string;
    to?: string;
}

export const AppliedDetailDispMeta = Object.freeze({
    "changedPasscardTypeName": { caption: '更新後の定期の種類', type: AppliedDetailItemType.String, belong: [2] },
    "passcardMonth": { caption: '契約月数', type: AppliedDetailItemType.PasscardMonth, belong: [1, 2] },
    "selectTargetMonth": { caption: '使用開始日', type: AppliedDetailItemType.ValidFrom, belong: [1] },
    "passcardValidFrom": { caption: '定期期間', type: AppliedDetailItemType.Date, to: 'passcardValidTo', belong: [1, 2] },
    "passcardUpdateFrom": { caption: '更新可能期間', type: AppliedDetailItemType.Date, to: 'passcardUpdateTo', belong: [1, 2] },
    "selectedBuyCard": { caption: 'ICカード購入', type: AppliedDetailItemType.BuyICCard, belong: [1, 3] },
    "certificateTypeName": { caption: '証明書等', type: AppliedDetailItemType.String, belong: [5] },
    "receiptNo": { caption: '受付No.', type: AppliedDetailItemType.String, belong: [1, 2, 3, 5] },
});
