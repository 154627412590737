import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { Injectable, inject } from '@angular/core';
import { Passcard, PasscardApplyApi } from '../models/passcard';
import { EMPTY, Observable, Subject, from } from 'rxjs';
import { ApiService, NavigateService, StorageService } from 'src/app/services';
import { catchError, map, take } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { PayInfomation, CeditDefault } from '../pay/pay.model';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { ReceptionMode, getStorageKey } from '../passcard.model';
import { PasscardApply } from '../models/apply-info';
import { PasscardChangeService } from '../passcard-change/passcard-change.service';

export interface ApplyModel {
    passcard?: Passcard;
    dcParkingNo?: number;
    passcardApplyNo?: number;
    passcardMonth: number;
    buyICCard: number;
    payMethod: PayInfomation;
    selectTargetMonth?: number;
    passcardValidFrom?: number;
    passcardValidTo?: number;
}

/**
 * 定期券予約サービス
 */
@Injectable({
    providedIn: 'root'
})
export class PasscardApplyService {
    private _storage = inject(StorageService);
    complete = false;
    processing = 0;
    maxProcessed = 0;
    passcardApply: PasscardApply | null = null;
    applyParams: PasscardApplyApi;
    applyCardIssueCommission: number;
    inputCache: ApplyModel = {
        passcardApplyNo: null,
        passcardMonth: 0,
        buyICCard: -1,   // -1:選択されない 0:購入しない、1:購入する
        payMethod: { method: -1, creditPay:{ cardUse:-1, '0': {...CeditDefault}, '1': {...CeditDefault} }, cvsPay: {}},
        selectTargetMonth: 0,
    };

    applyModel: ApplyModel = {
        passcardApplyNo: null,
        passcardMonth: 0,
        buyICCard: -1,   // -1:選択されない 0:購入しない、1:購入する
        payMethod: { method: -1},
        selectTargetMonth: 0,
    };

    get isReApply() {
        return this.applyStatus > 1;
    }
    
    get applyStatus() {
        return this.passcardApply?.applyStatus ?? 1;
    }

    reset(dcParkingNo?: number) {
        dcParkingNo ??= null;
        this._storage.remove(`payment-params`);
        this._storage.remove('apply-wiz');
        this._storage.remove(`passcard-applyinfo`);
        this._storage.remove(getStorageKey('passcard-apply-1', 1, null));
        this._storage.remove(getStorageKey('payment-method', 1, null));
        for (let status of [3, 9]) {
          this._storage.remove(getStorageKey('passcard-apply-1', status, dcParkingNo));
          this._storage.remove(getStorageKey('payment-method', status, dcParkingNo));
        }  
    }
}

export const PasscardTypesResolver: ResolveFn<PasscardApplyApi> = (
    route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
    navi = inject(NavigateService),
    api: ApiService = inject(ApiService),
    apiProxy = inject(PasscardApiProxy),
    storage = inject(StorageService),
): Observable<PasscardApplyApi> => {
    const status = route.data?.status ?? 1;
    let result: Subject<HttpResponse<PasscardApplyApi>> = null;
    let paramsReApply: any = storage.load(`passcard-applyinfo`, null);
    if (status == 3 || (status == 6 && paramsReApply?.status == 11)) {
        result = api.post<PasscardApplyApi>('/passcardReApplyInfoEc', { passcardApplyNo: paramsReApply.passcardApplyNo }, 1);
    }
    else {
        if (status != 6 && status != 3) {
            storage.remove('passcard-applyinfo');
            paramsReApply = null;
        }

        const oldStatus =  storage.load('apply-wiz', null)?.status;
        if (oldStatus != 1 && oldStatus != 2 && oldStatus != 7) {
            storage.remove('apply-wiz');
        }

        result = api.post<PasscardApplyApi>('/passcardTypeListEc', { status }, 1)
    }

    return result.pipe(
        catchError(() => {
            navi.navigateByUrl('/error', true);
            return EMPTY;  
        }),
        map(({ body }) => {
            setTimeout(() => apiProxy.error = body.mainMessage);
            body.passcardTypeList ??= [];
            if (body.resultCode != 0 && body.resultCode != 4) {
                navi.navigateByUrl('/error', true);
            }

            if (body.resultCode == 0) {
                const { parkingName, address1, address2 } = <any>body;
                const parkingAddressParam = `${address1 ?? ''}${address2 ?? ''}`;
                if (paramsReApply) {
                    const { parkingName, parkingAddress } = paramsReApply;
                    storage.save('apply-parkinginfo', {
                        parkingName,
                        parkingAddress: parkingAddress ?? parkingAddressParam
                    });
                }
                else {
                    storage.save('apply-parkinginfo', {
                        parkingName,
                        parkingAddress: parkingAddressParam
                    });
                }

                switch (status) {
                    case 3:
                        const applyWiz = storage.load('apply-wiz', null);
                        if (!applyWiz) {
                            const passcard = body.passcardTypeList[0];
                            const { applyStatus } = paramsReApply;
                            const status = applyStatus == 8 ? 8: passcard.receptionMode == ReceptionMode.Auto ? 3 : 9;
                            const processing = 0;
                            storage.save('apply-wiz', {
                                passcard,
                                processing,
                                status
                            });
                        }
                        break;
                    case 6:
                        const refreshWiz = storage.load('refresh-wiz', null);
                        if (!refreshWiz) {
                            const { passcardType, dcParkingNo, status: procStatus } = paramsReApply ?? {};
                            const passcard = procStatus == 11 ? body.passcardTypeList[0] : body.passcardTypeList.find(x => x.passcardType == passcardType);
                            let newStatus = procStatus;
                            if (procStatus == 6
                                && passcard?.receptionMode == ReceptionMode.Manual) {
                                newStatus = 10;
                            }

                            const processing = 0;
                            if (newStatus == 11 && passcard.passcardType != passcardType) {

                                // 定期種別変更として判定
                                PasscardChangeService.clear(storage, paramsReApply.dcParkingNo, 11);
                                navi.navigate('/passcard/change', false, {
                                passcardApply: paramsReApply,
                                applyParams: body,
                                status: 11
                                });
                                return;
                            }

                            storage.save('refresh-wiz', { status: newStatus, dcParkingNo, passcard, processing });
                            storage.remove(getStorageKey('refresh-step-1', newStatus, dcParkingNo));
                            storage.remove(getStorageKey('payment-method', newStatus, dcParkingNo));
                        }
                        
                        break;
                }
    
            }

            return body;
        }),
        take(1)
    );
}

export const ApplySuccessResolver: ResolveFn<number> = (
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
    service: PasscardApplyService = inject(PasscardApplyService)
): Observable<number> => {
    service.complete = true;
    service.processing = 3;
    service.maxProcessed = 3;
    return from([0]);
}
