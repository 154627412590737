import { Component, inject } from '@angular/core';
import { PasscardContract } from '../../models/passcard-contract';
import { Location } from '@angular/common';
import { PayRequestInfo } from '../pay.model';
import { ApiService, NavigateService } from 'src/app/services';
import { CVS, formatDate } from '../../passcard.model';
import { PaymentCvsInfo, checkValidFeeItem } from '../models/payinfo.model';

@Component({
  selector: 'cvs-pay',
  templateUrl: './cvs-payinfo.component.html'
})
export class CvsPayInfoComponent {
  private readonly _navi = inject(NavigateService);
  api = inject(ApiService);

  paymentInfo: PaymentCvsInfo;
  paymentTerm: string;
  payRequest: PayRequestInfo;
  model!: PasscardContract;

  constructor(location: Location) {
    const state = location.getState();
    this.model = state['reservation'];
    this.paymentInfo = <PaymentCvsInfo>state['paymentInfo'];

    if (this.paymentInfo.paymentTerm) {
      const dateStr = formatDate(this.paymentInfo.paymentTerm);
      const date = new Date(this.paymentInfo.paymentTerm);
      const hours = `${date.getHours()}`.padStart(2, '0');
      const minutes = `${date.getMinutes()}`.padStart(2, '0');
      this.paymentTerm = `${dateStr} ${hours}:${minutes}`;
    }
    
    this.payRequest = {
      paymentContentsName: this.paymentInfo.paymentDetail,
      parkingAreaName: this.paymentInfo.dcParkingName,
      receptionNo: this.paymentInfo.receiptNo,
      payDetail: {
        paymentAmount: 0,
        paymentFeeDetails: []
      }
    };

    this.setFeeDetails();
  }

  get CvsClass() {
    return CVS[this.paymentInfo.cvsCode]?.cls;
  }

  onBack() {
    this._navi.navigateByUrl('/passcard/list');    
  }

  private setFeeDetails() {
    const meta = [
      { item: 'passcardAmount', title: '定期代' },
      { item: 'newIssueCommission', title: '新規発行手数料' },
      { item: 'reIssueCommission', title: '再発行手数料' },
      { item: 'buyICCardCommission', title: 'ICカード購入手数料' },
      { item: 'convenienceCommission', title: 'コンビニ決済手数料' },
    ];

    meta.forEach(payItem => {
      const fee = checkValidFeeItem(this.paymentInfo, payItem.item);
      if (fee == null) return;

      this.payRequest.payDetail.paymentAmount += fee;
      this.payRequest.payDetail.paymentFeeDetails.push({contents:payItem.title, Fee: fee});
    });
  }
}
