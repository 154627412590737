<h2>新規会員登録</h2>
<!--
<reg-stepbar [step]="2"></reg-stepbar>
-->
<flow-box-registration [steps]="['アドレス入力', 'お客様情報入力', '確認', '完了']" [processing]="1" [processed]="1" />

<div *ngIf="loading" class="error-textbox">
  <p class="err-txt-box">問合せ中...</p>
</div>

<ng-container *ngIf="!loading">

  <t-error [message]="messages.error"></t-error>

  <section>
    <h3>お客様情報</h3>
    <div class="tableCtn">
      <i-fullname
        [model]="model"
        [messages]="messages.fullname"
        [label]="'氏名'"
        [label1]="'氏名（姓）'"
        [label2]="'氏名（名）'"
        (keyup)="onChange()"
        [spanRequire]="true">
      </i-fullname>
      <i-kana
        [model]="model"
        [messages]="messages.kana"
        [label]="'氏名（カナ）'"
        [label1]="'氏名（カナ）（セイ）'"
        [label2]="'氏名（カナ）（メイ）'"
        (keyup)="onChange()"
        [spanRequire]="true">
      </i-kana>
      <i-birthdate 
        [model]="model" 
        [messages]="messages.birthdate"
        [label]="'生年月日'">
      </i-birthdate>
      <p-single 
        [label]="'メールアドレス'" 
        [value]="model.mailAddr">
      </p-single>
      <i-phone [model]="model"
        [label]="'電話番号'"
        [messages]="messages.phone"
        (keyup)="onChange()"
        [spanRequire]="true">
      </i-phone>
      <i-securitynumber
        [model]="model"
        [label]="'防犯登録番号'"
        [messages]="messages.securityNunber"
        (keyup)="onChange()"
        [spanRequire]="true">
      </i-securitynumber>
    </div>
  </section>
  <section>
    <div *ngIf="model.byUserZip!=='0' || model.byUserAddress1!=='0' ||  model.byUserAddress2!=='0'">
      <h3 class="undr-tble">住所</h3>
    </div>
    <span *ngIf="model.byUserZip!=='0' || model.byUserAddress1!=='0' ||  model.byUserAddress2!=='0'">
      <div class="tableCtn">
          <i-address
            [model]="model"
            (keyup)="onChange()"
            [label]="'郵便番号'"
            [label1]="'郵便番号'"
            [label2]="'住所1'"
            [label3]="'住所2'"
            [messages]="messages.zipCode"
            [messages1]="messages.address1"
            [messages2]="messages.address2"
            [spanRequire]="true">
          </i-address>
        </div>
    </span>
  </section>
  <section>
    <div *ngIf="model.byCompanyName !=='0' || model.byCompanyAddress2!=='0'">
      <h3 class="undr-tble">通学/勤務先</h3>
    </div>
    <span *ngIf="model.byCompanyName !=='0'">
      <div class="tableCtn">
        <i-ws-name
          [model]="model"
          (keyup)="onChange()"
          [label]="'通学/勤務先名'"
          [messages]="messages.wsName"
          [spanRequire]="true">
        </i-ws-name>
        <i-ws-kana
          [model]="model"
          (keyup)="onChange()"
          [label]="'通学/勤務先名（カナ）'"
          [messages]="messages.wsKana"
          [spanRequire]="true">
        </i-ws-kana>
        <i-ws-phone 
          [model]="model"
          [label]="'電話番号'"
          [messages]="messages.wsPhone"
          (keyup)="onChange()"
          [spanRequire]="true">
        </i-ws-phone>
        <i-ws-address
          [model]="model"
          (keyup)="onChange()"
          [label]="'郵便番号'"
          [label1]="'郵便番号'"
          [label2]="'住所1'"
          [label3]="'住所2'"
          [messages]="messages.companyZipCode"
          [messages1]="messages.companyAddress1"
          [messages2]="messages.companyAddress2"
          [spanRequire]="true">
        </i-ws-address>
      </div>
    </span>
  </section>
  <section>
    <div *ngIf="model.byCarInfo !=='0'">
      <h3 class="undr-tble">車両情報(クルマ)</h3>
    </div>
    <span *ngIf="model.byCarInfo !=='0'">
      <div class="tableCtn">
        <i-carinfo
          [model]="model"
          [label]="'ナンバープレート'"
          (change)="onChange()"
          (keyup)="onChange()"
          [messages]="messages.NP"
          [okng]="messages.okNg"
          [spanRequire]="true">
        </i-carinfo>
      </div>
    </span>
  </section>
  <section>
    <div *ngIf="model.byApplyMotorcycleNumber!=='0'">
      <h3 class="undr-tble">車両情報(バイク)</h3>
    </div>
    <span *ngIf="model.byApplyMotorcycleNumber!=='0'">
      <div class="tableCtn">
        <i-motorcyclenumber
          [model]="model"
          [label]="'ナンバープレート'"
          (change)="onChange()"
          (keyup)="onChange()"
          [messages]="messages.motorcycleNumber"
          [spanRequire]="true">
        </i-motorcyclenumber>
      </div>
    </span>
  </section>
  <section>
    <h3 class="undr-tble">パスワード</h3>
    <div class="tableCtn">
      <i-passwd2
        [model]="model"
        [messages]="messages.passwd"
        (keyup)="onChange()"
        [label]="'パスワード'"
        [placeholder1]="'パスワードを入力'"
        [placeholder2]="'パスワードを入力'"
        [spanRequire]="true">
      </i-passwd2>
    </div>
    <br>
    ※パスワードは最低８文字で半角英数字<br class="br-sp">（大文字、小文字）、記号が使用できます
  </section>
  <div *ngIf="!model.completed" class="buttonBox">
    <loading-button
      (apply)="onApply()"
      [disabled]="disabled"
      [loading]="api.disabled"
      [label]="'次へ'"
      [class]="'cmn-btn button-box-color-w-50'"
      [lClass]="'button-box-loading'">
    </loading-button>
  </div>
</ng-container>