import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ParkingInfo } from '../../models/apply-info';

@Component({
  selector: 'place-info',
  templateUrl: './place-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlaceInfoComponent {
  isCollapsed = true;

  @Input() parkingInfo: ParkingInfo;

  get name() {
    return this.parkingInfo?.parkingName;
  }

  get address() {
    return this.parkingInfo?.parkingAddress;
  }

  onClick() {
    this.isCollapsed = !this.isCollapsed;
  }
}
