import { Component, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Passcard, PasscardApplyApi, PaymentDivision, PeriodCalcParams } from 'src/app/passcard/models/passcard';
import { addDay, addMonth, calcUpdatablePeriod, formatPeriod, getStorageKey } from 'src/app/passcard/passcard.model';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { RefreshComponent } from '../../refresh.component';

@Component({
  selector: 'refresh01',
  templateUrl: './refresh01.component.html'
})
export class Refresh01Component {
  private readonly _route = inject(ActivatedRoute);
  private readonly _wiz = inject(RefreshComponent);
  private readonly _storage = inject(StorageService);

  receptionMode: number;
  paymentDivisionList: PaymentDivision[];
  validPeriod: number[];

  applyParams: PasscardApplyApi;
  fixedPeriod: string = '';
  updatablePeriod = '';
  paymentDivision: PaymentDivision | null = null;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService
  ) {
    this._wiz.processing = 0;
    this._wiz.saveState();
    const { dcParkingNo } = this._wiz.passcardApply;
    this._storage.load(getStorageKey(`refresh-step-1`, 6, dcParkingNo), this)
  }

  ngOnInit(): void {
    this._route.data.pipe(map(({ data }) => data)).subscribe(x => {
      this.applyParams = x;
      const { passcardValidFrom, passcardValidTo } = this._wiz.passcardApply;
      if (this.paymentDivision) {
        const [ fixedPeriod, changePeriod ] = this.calcPeriod(this.paymentDivision.monthNum, passcardValidFrom, passcardValidTo, x);
        if (fixedPeriod) {
          this.validPeriod = fixedPeriod;
          this.fixedPeriod = formatPeriod(fixedPeriod);
          this.updatablePeriod = formatPeriod(changePeriod);  
        }  
      }
      
      const passcard = x.passcardTypeList
        .find((xPasscard: Passcard) => xPasscard.passcardType == this._wiz.passcardApply.passcardType);
      this.paymentDivisionList = passcard.paymentDivisionList ?? [];
      this.receptionMode = passcard.receptionMode;
    });
  }

  get IsComplete() {
    return this._wiz.complete;
  }

  get contract() {
    return this._wiz.passcardApply;
  }

  onSetPasscardMonth(item: PaymentDivision) {
    if (this.paymentDivision?.monthNum != item.monthNum) {
      this.paymentDivision = item;
      const { passcardValidFrom, passcardValidTo } = this._wiz.passcardApply;
      const [ fixedPeriod, changePeriod ] = this.calcPeriod(item.monthNum, passcardValidFrom, passcardValidTo, this.applyParams);

      this.validPeriod = fixedPeriod;
      this.fixedPeriod = formatPeriod(fixedPeriod);
      this.updatablePeriod = formatPeriod(changePeriod);
    }
  }

  async onNext() {
    this.saveModel();
    const { unitPrice, monthNum } = this.paymentDivision;
    const { paymentTerm, terminalPaymentTerm } = await this.api.getPaymentTerm(6, monthNum);
    const {
      paymentMethodPaidMachineQR,
      paymentMethodCreditCard,
      paymentMethodPayPay,
      paymentMethodConvenienceStore,
    } = this.applyParams;
    this._storage.save('payment-params', {
      paymentTerm, terminalPaymentTerm,
      paymentMethodPaidMachineQR, paymentMethodCreditCard, paymentMethodPayPay, paymentMethodConvenienceStore,
      commission:unitPrice
    });

    this._navi.navigateByUrl('/passcard/refresh/step/2');
  }

  calcPeriod(passcardMonth: number,
    passcardValidFrom: number,
    passcardValidTo: number,
    params: PeriodCalcParams): number[][] {
    if (passcardMonth < 1) return [];
    const ret = [];
    const base = new Date(passcardValidTo);
    const end = addDay(new Date(addMonth(new Date(base.getFullYear(), base.getMonth(), 1), passcardMonth + 1)), -1);
    ret[0] = [passcardValidFrom, end];
    const { passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay } = params;
    ret[1] = calcUpdatablePeriod(new Date(end), passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay);
    return ret;
  }

  saveModel() {
    const { paymentDivision } = this;
    const { dcParkingNo } = this._wiz.passcardApply;
    const { passcardCancelDocumentUrl} = this.applyParams;
    this._storage.save(getStorageKey(`refresh-step-1`, 6, dcParkingNo), {
      receptionMode: this.receptionMode,
      paymentDivision,
      validPeriod: this.validPeriod,
      updatablePeriod: this.updatablePeriod,
      passcardCancelDocumentUrl,
    });
  }

}
