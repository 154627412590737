<p-info kind="warning">
  <p>差し戻し理由を確認し、登録内容の修正をお願いいたします。</p>
  <p>修正が完了したら、本画面の「修正確認」を押してください。</p>
</p-info>

<h2>修正申請</h2>
<div class="tableCtn">
  <p-single label="差し戻し理由" [value]="RejectReason" />
  <p-single label="修正期限" [value]="LastChangableTime" />
</div>
<div class="comment-list">
  <div>登録情報はマイページから修正できます</div>
  <div>修正期限までに、修正申請いただけなかった場合、本申請は自動的に削除されます</div>
</div>
<br>

<h3>申請情報</h3>
<div class="tableCtn">
  <p-single label="申請情報" [value]="ApplyInfo" />
  <p-single label="駐車場" [value]="ParkingName" />
  <p-single label="受付No." [value]="receiptNo" />
</div>
<br>

<div *ngIf="!IsComplete" class="buttonBoxVertical">
  <button (click)="onBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button label="修正確認"
    (apply)="onConfirm()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn'" />
</div>

<popup-modal #changeConfirm>
  <div class="cancel-constract-modal">
    <div><i class="icon info"></i></div>
    <div>修正申請を実行します。</div>
    <div class="comment">
      <div>この操作は取消ができません。</div>
      <div>修正申請を実行すると、再度定期申請が行われます。</div>  
    </div>
    <div>
      <div *ngIf="!api.Disabled">
        <button type="button" class="cmn-btn bck-btn" (click)="onDlgClose()">閉じる</button>
      </div>
      <div>
        <loading-button label="修正確定"
          (apply)="onDlgCorrect()"
          [disabled]="api.Disabled"
          [loading]="api.Disabled"
          [lClass]="'button-box-loading'"
          [class]="'cmn-btn dcd-btn'" />
      </div>
    </div>
  </div>
</popup-modal>