import { Component, inject, OnInit } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { PayFeeDetail } from 'src/app/passcard/common/p-pay-fees/p-pay-fees.component';
import { ApplyStatus, formatPeriod, getStorageKey, StorageKey3dsecure, ApplyType } from 'src/app/passcard/passcard.model';
import { PasscardContract } from 'src/app/passcard/models/passcard-contract';
import { PaymentType } from 'src/app/passcard/pay/pay.model';
import { ParkingInfo } from 'src/app/passcard/models/apply-info';
import { RefreshComponent } from '../../refresh.component';
import { getApplyPaymentItems } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';
import { CertificateType } from 'src/app/shared/certificate-file';
import { MyFilesModel } from 'src/app/shared/i-my-files/i-my-files.component';
import {
  CertificateImageService,
  DownloadTemporaryImageFileRequest,
  DownloadTemporaryImageFileResponse,
} from '../../../../services/certificateImage.service';
import { CertificateImage } from '../../../../shared/certificate-file';
import { Subject } from 'rxjs';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'refresh03',
  templateUrl: './refresh03.component.html'
})
export class Refresh03Component implements OnInit {
  private readonly _storage = inject(StorageService);
  private _cach01: any;
  private _cach02: any;
  private _cach03: any;
  readonly wiz = inject(RefreshComponent);

  paidDetail: PayFeeDetail;
  contract: PasscardContract;
  passcardMonth: number;
  paymentAmount: number;
  fixedPeriod = '';
  updatablePeriod = '';
  parking: ParkingInfo;

  certificateTypeSetting: CertificateType;
  myFilesModel: MyFilesModel;

  imageDownloadFinish: Subject<number> = new Subject<number>();

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private certificateImageService: CertificateImageService,
    private apiService: ApiService
  ) {
    this.wiz.processing = 2;
    this.wiz.saveState();
    const { parkingName, parkingAddress } = this.wiz.passcardApply;
    this.parking = {
      dcParkingNo: 0,
      parkingName: parkingName,
      parkingAddress: parkingAddress
    };

    this.contract = { ...this.wiz.passcardApply };
    this.contract.status = ApplyStatus.AfterRefreshed;
    this.certificateTypeSetting = this.Cach01.certificateTypeSetting;
    this.myFilesModel = this.Cach01.myFilesModel;

    const { monthNum, unitPrice } = this.Cach01.paymentDivision;
    this.passcardMonth = monthNum;
    this.paymentAmount = unitPrice;
    this.fixedPeriod = formatPeriod(this.Cach01.validPeriod);
    this.updatablePeriod = this.Cach01.updatablePeriod;
    this.contract.passcardValidTo = this.Cach01.validPeriod[1];

    const payInfo = this.Cach02;
    if (payInfo) {
      this.paidDetail = {
        paymentFeeDetails: [
          { contents: '定期代', Fee: this.paymentAmount }
        ],
        paymentAmount: this.paymentAmount
      };

      if (payInfo.method == PaymentType.Cvs) {
        this.paidDetail.paymentFeeDetails.push({ contents: 'コンビニ支払い手数料', Fee: payInfo.cvsPay.payCommission });
        this.paidDetail.paymentAmount += payInfo.cvsPay.payCommission;
      }
    }
  }


  ngOnInit() {
    // 画像一時ファイルがある場合は取得
    if (this.myFilesModel && this.myFilesModel.images.length > 0) {
      // ダウンロード終了監視用データ作成
      const imageList: { imageNum: number; finish: boolean }[] = [];
      this.myFilesModel.images.forEach((image) => {
        imageList.push({ imageNum: image.certificateImageNum, finish: false });
      });

      // 画像ダウンロード終了監視
      const subscripttion = this.imageDownloadFinish.subscribe((imageNumber) => {
        if (imageNumber === -1) {
          // エラー発生
          this.apiService.loadingOff();
          subscripttion.unsubscribe();
        } else {
          // 終わったものを終了に
          const image = imageList.find((image) => image.imageNum === imageNumber);
          if (image) {
            // 完了を設定
            image.finish = true;
          }

          // 全部終わったか(未完了のものがないか)
          if (!imageList.some((image) => image.finish === false)) {
            this.apiService.loadingOff();
            subscripttion.unsubscribe();
          }
        }
      });

      // 画像ダウンロード実施
      this.apiService.loadingOn();
      this.downLoadImage(this.myFilesModel.images);
    }
  }

  /**
   * 画像データをダウンロードする
   * @param certicateImages ダウンロードするイメージデータ
   */
  private downLoadImage(certicateImages: CertificateImage[]) {
    certicateImages.forEach((certicateImage) => {
      if (certicateImage.fileSize > 0) {
        // 画像がアップロードされている場合
        const request: DownloadTemporaryImageFileRequest = {
          certificateImageNum: certicateImage.certificateImageNum,
          fileName: certicateImage.fileName,
          objectName: certicateImage.objectName,
        };
        // アップロード済みの画像データがある場合はダウンロードする
        this.certificateImageService.downLoadTemporaryImageFile(request).subscribe(
          (response: DownloadTemporaryImageFileResponse) => {
            if (response.resultCode === 0) {
              // 正常終了
              certicateImage.certificateImageData = CertificateImageService.IMAGE_HEADER + response.certificateImageData;
              this.imageDownloadFinish.next(certicateImage.certificateImageNum);
            } else {
              // 異常終了
              this.imageDownloadFinish.next(-1);
            }
          });
      } else {
        // 画像がアップロードされていない場合
        this.imageDownloadFinish.next(certicateImage.certificateImageNum);
      }
    });
  }


  get Cach01() {
    const key = getStorageKey(`refresh-step-1`, this.wiz.status, this.wiz.dcParkingNo);
    this._cach01 ??= this._storage.load(key, null);
    return this._cach01;
  }

  get Cach02() {
    if (this._cach01.myFilesModel) return null;

    const key = getStorageKey('payment-method', this.wiz.status, this.wiz.dcParkingNo);
    this._cach02 ??= this._storage.load(key, null);
    return this._cach02;
  }

  get Cach03() {
    const key = getStorageKey('refresh-step-3', this.wiz.status, this.wiz.dcParkingNo);
    this._cach03 ??= this._storage.load(key, null);
    return this._cach03;
  }

  get showCertificateUpload() {
    return !!this._cach01.myFilesModel;
  }

  onReInput(kb: number) {
    this._navi.navigateByUrl(`/passcard/refresh/step/${kb}`);
  }

  /**
 * 申請を行う
 */
  async onApply() {
    const applyInfo: any = {};
    const { status, dcParkingNo } = this.wiz;
    if (this.Cach02) {
      Object.assign(applyInfo, getApplyPaymentItems(this._storage, status, dcParkingNo));
      const { paymentTerm, terminalPaymentTerm } = this.Cach02;
      switch (applyInfo.paymentMethod) {
        case PaymentType.Terminal:
          applyInfo.terminalPaymentTerm = terminalPaymentTerm;
          break;
        case PaymentType.PayPay:
        case PaymentType.Cvs:
          applyInfo.paymentTerm = paymentTerm;
          break;
      }
    }

    const { paymentDivision } = this.Cach01;
    const receptionMode = this.wiz.passcard.receptionMode;
    const [passcardValidFrom, passcardValidTo] = this.Cach01.validPeriod;
    const [passcardUpdateFrom, passcardUpdateTo] = this.Cach01.validUpdatablePeriod;
    applyInfo.passcardValidFrom = passcardValidFrom;
    applyInfo.passcardValidTo = passcardValidTo;
    applyInfo.paymentAmount = paymentDivision.unitPrice;
    applyInfo.passcardUpdateFrom = passcardUpdateFrom;
    applyInfo.passcardUpdateTo = passcardUpdateTo;

    const certificateUploadInfo: any = {};
    if (this.showCertificateUpload) {
      const { certificateType, images } = this.myFilesModel;
      certificateUploadInfo.certificateType = certificateType;
      certificateUploadInfo.certificateImageList = images.filter(x => x.fileSize).map(x => {
        const { certificateImageNum, fileName, objectName } = x;
        return {
          certificateImageNum,
          fileName,
          objectName
        }
      });
    }

    const statusApply = this.wiz.status;
    let applyNo = null;
    let paramsReApply: any = this._storage.load(`passcard-applyinfo`, null);
    if(statusApply === 11){
      applyNo = paramsReApply.passcardApplyNo;
    }

    const req ={
      status: statusApply,
      passcardType: this.wiz.passcardApply.passcardType,
      passcardApplyNo: applyNo,
      no: paramsReApply.no , 
      dcParkingNo,
      passcardMonth: paymentDivision.monthNum,
      receptionMode,
      passPaymentDivision: paymentDivision.passPaymentDivision,
      ...applyInfo,
      ...certificateUploadInfo,
    }
    if (applyInfo.paymentAmount > 0 && applyInfo.paymentMethod === PaymentType.CreditCard) {
      // 支払いが発生し、クレジットカード払いの場合 
      let redirectUrl = '';
      if(location.href.startsWith(location.origin + '/web-passcard-ec/')){
        // Expressで起動時
        redirectUrl = `${location.origin}/web-passcard-ec/passcard/emv3dsecure`;
      }else{
        // 通常時
        redirectUrl = `${location.origin}/passcard/emv3dsecure`;
      }
      const request = {
        tokenId: req.tokenId,
        applyCardIssueCommission: req.applyCardIssueCommission,
        applyNewIssueCommission: req.applyNewIssueCommission,
        applyLostIssueCommission: req.applyLostIssueCommission,
        status: req.status,
        paymentAmount: req.paymentAmount,
        redirectionUri: redirectUrl
      }
      // 支払いが発生し、クレジットカード払いの場合 
      const applyResult = await this.api.passcardApply3Dsecure(request);
      if (applyResult.resultCode === 0) {
        if (!applyResult.authStartUrl) {
          this._navi.navigate('/error');
        } else {
          // 申請情報をストレージに保存
          const strageInfo = {
            applyType: ApplyType.Refresh,
            apiRequest: req,
          }
          this._storage.save(StorageKey3dsecure, strageInfo);
          this.wiz.saveState();
          location.href = applyResult.authStartUrl;
        }
      }
    } else {
      const { receiptNo, passcardApplyNo } = await this.api.passcardApply(req);

      this.wiz.complete = true;
      this.wiz.passcardApplyNo = passcardApplyNo;
      this.wiz.saveState();

      let message1 = ''
      let message2 = '';
      let message3 = '';
      if (statusApply == 10) {
        message1 = '定期券の更新申請が完了しました。';
        message2 = '申請内容の確認完了までお待ちください。';
      } else {
        message1 = '定期更新申請が完了しました。'
        switch (applyInfo.paymentMethod) {
          case PaymentType.PayPay:
            message2 = '続けてPayPay支払いのお手続きをお願い致します。';
            break;
          case PaymentType.Cvs:
            message2 = '続けてコンビニで支払いのお手続きをお願い致します。';
            break;
        }
      }

      this._navi.navigate(`/passcard/refresh/step/4`, true, {
        message1,
        message2,
        message3,
        receiptNo
      });
    }
  }
}
