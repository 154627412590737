<p-info kind="warning" *ngIf="sealService.processing == 0">
    <p>定期シールの再発行を行います。</p>
    <p>発行済みの定期シールはご利用できなくなります。</p>
</p-info>
<p-info *ngIf="sealService.processing == 2">
    <p>以下の内容で定期申請を行います。</p>
    <p>内容を確認し、「申請」を押してください。</p>
</p-info>

<h2>シール再発行</h2>
<flow-box
  [steps]="['再発行確認', 'お支払い方法', '確認', '完了']"
  [processing]="sealService.processing"
  [processed]="sealService.maxProcessed" />

<router-outlet></router-outlet>

<ng-container *ngIf="sealService.processing == 3">
    <div class="buttonBoxVertical">
        <button type="button" class="cmn-btn six" (click)="onToPayDetail()">{{PayMethodLabel}}情報を表示する</button>
      </div>
      <div class="buttonBoxVertical">
        <button type="button" class="cmn-btn bck-btn six" (click)="onBackToContract()">申請状況を確認する</button>
      </div>      
</ng-container>