import { Component, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService } from 'src/app/services';
import { ApplyListModel, ApplyListItem } from './apply-list.model';
import { CurrencyPipe, Location } from '@angular/common';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { PaymentContentName } from '../pay/pay.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'apply-list',
  templateUrl: './apply-list.component.html',
  providers: [ ApplyListModel ]
})
export class ApplyListComponent {
  PCN = PaymentContentName;
  readonly api = inject(PasscardApiProxy);
  applyList$ = new Subject<ApplyListItem[]>();

  pageModel = {
    pages: 0,
    countPerPage: 20,
    page: 1,
  }

  conditionsPeriod: number;
  conditionsPeriods: {code: number, name: string}[] = [];

  constructor(public app: AppModel,
    private _navi: NavigateService,
    private viewModel: ApplyListModel,
    location: Location) {
    this.pageModel.page = viewModel.currentPage;
    let nowYear = new Date().getFullYear();
    this.conditionsPeriods.push({ code: nowYear, name: '過去1年間' });
    for (let i = 1; i < 4; ++i) {
      this.conditionsPeriods.push({ code: nowYear - i, name: `${nowYear - i}年` });
    }

    const state = <any>location.getState();
    if (state?.conditionsPeriod) {
      this.pageModel.page = state.currentPage;
      nowYear = state.conditionsPeriod;
    }

    this.conditionsPeriod = viewModel.conditionsPeriod;
    this.getApplyList();
  }

  onChangePeriod() {
    this.pageModel.page = 1;
    this.viewModel.conditionsPeriod = this.conditionsPeriod;
    this.viewModel.saveState();
    this.getApplyList();
  }

  /**
   *　申請詳細へ
   * @param applyItem
   */
  async onToDetail(applyItem: ApplyListItem) {
    const { passcardApplyNo, processDt, processMs} = applyItem;
    const applyDetail = await this.api.getPasscardApplyDetail({passcardApplyNo, processDt, processMs});
    this._navi.navigate(`passcard/apply-history/detail`, false, {
      passcardApplyNo, processDt, processMs,
      passcardApplyResult: applyItem.passcardApplyResult,
      parkingAreaName: applyItem.parkingAreaName,
      applyDetail
    });
  }

  async onPage(page: number) {
    this.viewModel.currentPage = page;
    this.pageModel.page = page;
    this.getApplyList();
    this.viewModel.saveState();
  }

  private async getApplyList() {
    const pageModel = { ...this.pageModel, page: this.pageModel.page };
    const applyList = await this.api.getApplyHistory(pageModel, +this.conditionsPeriod);
    this.applyList$.next(applyList);
    this.pageModel.pages = pageModel.pages
  }

  getStatusClass(status: string) {
    switch (status) {
      case '完了': return 'status-idle';
      case '申請中': return 'status-busy';
      case '申請取消': return 'status-stop';
      case '申請削除': return 'status-stop';
      case '期限切れ': return 'status-stop';
    }
  }

  getPaymentFee(fee: number): string {
    if (fee) {
      return `¥ ${fee.toLocaleString()}`;
    } else {
      return '';
    }
  }
}
