import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PayInfomation, PaymentType } from '../../pay/pay.model';
import { formatDate } from '../../passcard.model';
import { AppModel } from 'src/app/app.model';


@Component({
  selector: 'p-pay-method',
  templateUrl: './p-pay-method.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PPayMethodComponent {
  private _paymentTerm: string;

  @Input() payInfo: PayInfomation;

  constructor(public app: AppModel) {
  }

  get creditNo() {
    return this.payInfo.creditPay.creditCardNo;
  }

  get creditExpire() {
    return this.payInfo.creditPay['expire'];

  }

  get paymentTerm() {
    const paymentTerm = this.payInfo.method == PaymentType.Terminal ?
      this.payInfo.terminalPaymentTerm : this.payInfo.paymentTerm;
    if (!this._paymentTerm && paymentTerm) {
      this._paymentTerm = this.formatTerm(paymentTerm);      
    }

    return this._paymentTerm;
  }

  private formatTerm(src: number) {
    const dateStr = formatDate(src);
    const date = new Date(src);
    const hours = `${date.getHours()}`.padStart(2, '0');
    const minutes = `${date.getMinutes()}`.padStart(2, '0');
    return `${dateStr} ${hours}:${minutes}`;    
  }

}