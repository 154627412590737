<p-info kind="warning" *ngIf="processing == 0">
    <p>定期券ICカードの紛失・破損処理を行います。</p>
    <p>紛失・破損したICカードはご利用できなくなります。</p>
</p-info>

<p-info *ngIf="processing == 2">
    <p>以下の内容で定期申請を行います。</p>
    <p>内容を確認し、「申請」を押してください。</p>
</p-info>
<h2>定期紛失</h2>
<flow-box
    [steps]="['定期選択', 'お支払い方法', '確認', '完了']"
    [processing]="processing"
    [processed]="maxProcessed" />
<router-outlet></router-outlet>

<ng-container *ngIf="processing == 3">
    <div class="buttonBoxVertical complete-btns">
        <loading-button *ngIf="paymentType >= 0 && paymentType != 99  else icRegist"
            label="{{PayMethodLabel}}情報を表示する"
            (apply)="onToPayDetail()"
            [disabled]="api.Disabled"
            [loading]="api.Disabled"
            [lClass]="'button-box-loading'"
            [class]="'cmn-btn six'" />
    <ng-template #icRegist>
        <loading-button label="ICカード登録情報を表示する"
            (apply)="onToRegistIc()"
            [disabled]="api.Disabled"
            [loading]="api.Disabled"
            [lClass]="'button-box-loading'"
            [class]="'cmn-btn six'" />
    </ng-template>
        <loading-button label="定期券情報に戻る"
          (apply)="onBackToContract()"
          [disabled]="api.Disabled"
          [loading]="api.Disabled"
          [lClass]="'button-box-loading'"
          [class]="'cmn-btn bck-btn2 six'" />
    </div>
</ng-container>