import { Component, ViewChild, inject } from '@angular/core';
import { NavigateService } from 'src/app/services';
import { PaymethodSelectComponent } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { LostComponent } from '../../lost.component';

@Component({
  selector: 'lost02',
  templateUrl: './lost02.component.html'
})
export class Lost02Component {
  private readonly _wiz = inject(LostComponent);

  dcParkingNo: number | null;
  api = inject(PasscardApiProxy);

  @ViewChild(PaymethodSelectComponent, { static: true }) payMethodSelect!: PaymethodSelectComponent;

  constructor(
    private _navi: NavigateService) {
    this._wiz.processing = 1;
    this._wiz.saveState();

    this.dcParkingNo = this._wiz.passcardApply.dcParkingNo;
  }

  get IsComplete() {
    return this._wiz.complete;
  }

  get nextDisabled() {
    const payMethod = this.payMethodSelect?.model;
    return this.api.Disabled || !payMethod || payMethod.method == -1 || !!payMethod.error;
  }
 
  onBack() {
    this._navi.navigateByUrl('/passcard/lost/step/1');
  }

  async onNext() {
    if (!await this.payMethodSelect.validate()) return;

    this._wiz.maxProcessed = Math.max(this._wiz.maxProcessed, 2);
    this.payMethodSelect.saveModel();
    this._navi.navigateByUrl('/passcard/lost/step/3');
  }
}
