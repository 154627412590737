import { AfterViewInit, ChangeDetectorRef, Component, ViewChild, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { PaymethodSelectComponent } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { ApplyComponent } from '../../apply.component';

@Component({
  selector: 'apply02',
  templateUrl: './apply02.component.html'
})
export class Apply02Component implements AfterViewInit {
  private _cdRef = inject(ChangeDetectorRef);
  dcParkingNo: number | null;
  api = inject(PasscardApiProxy);
  status: number;
  
  @ViewChild(PaymethodSelectComponent) payMethodSelect!: PaymethodSelectComponent;

  constructor(public app: AppModel,
    public wiz: ApplyComponent) {
    this.dcParkingNo = wiz.paramsReApply?.dcParkingNo ?? null;
    this.status = wiz.status;
  }

  ngAfterViewInit(): void {
    this._cdRef.detectChanges();
  }

  get nextDisabled() {
    const payMethod = this.payMethodSelect?.model;
    return this.api.Disabled || !payMethod || payMethod.method == -1 || !!payMethod.error;
  }
  
  onBack() {
    this.wiz.navigate('step/1');
  }

  async onNext() {
    if (!await this.payMethodSelect.validate()) return;
    this.wiz.paymentType = this.payMethodSelect.model.method;
    this.wiz.saveState();
    this.payMethodSelect.saveModel();

    this.wiz.navigate('step/3');
  }
}
