<p-info *ngIf="processing == 2 && (status != 10)">
    <p>以下の内容で定期申請を行います。</p>
    <p>内容を確認し、「申請」を押してください。</p>
</p-info>
<p-info kind="warning" *ngIf="processing == 2 && (status == 10)">
    <p>内容を確認し、「申請」を押してください。</p>
    <p>申請後、係員が申請内容の確認を行います。</p>
    <p>申請内容が確認出来次第、支払いのお手続きをお願い致します。</p>
</p-info>
<h2>定期更新</h2>
<flow-box *ngIf="status != 10 || processing < 2"
    [steps]="['定期選択', 'お支払い方法', '確認', '完了']"
    [processing]="processing" />
<router-outlet></router-outlet>

<ng-container *ngIf="processing == 3">
    <div class="buttonBoxVertical complete-btns" >
        <loading-button *ngIf="status != 10 && paymentType != 1"
          label="{{PayMethodLabel}}情報を表示する"
          (apply)="onToPayDetail()"
          [disabled]="api.Disabled"
          [loading]="api.Disabled"
          [lClass]="'button-box-loading'"
          [class]="'cmn-btn six'" />
        <loading-button label="申請状況を確認する"
          (apply)="onBackToContract()"
          [disabled]="api.Disabled"
          [loading]="api.Disabled"
          [lClass]="'button-box-loading'"
          [class]="'cmn-btn bck-btn2 six'" />
    </div>
</ng-container>