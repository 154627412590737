<block title="ご請求明細">
  <p-pay-fees [paidDetail]="paidDetail"></p-pay-fees>
  <br>
</block>

<block title="定期情報">
  <div class="card-container">
    <card *ngIf="contract" [reservation]="contract"></card>
  </div>
  <br>

  <div class="tableCtn">
    <div class="tableLine">
      <div class="tableCell_l">ICカード購入</div>
      <div class="tableCell_r">
        <div class="tdCtn">
          <p>{{buyCardContent}}定期券として利用する</p>
          <p>(再発行手数料：{{Charge | currency:'JPY'}})</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!IsComplete" class="button-wrapper">
    <button type="button" class="cmn-btn bck-btn" (click)="onReInput(1)">定期情報入力に戻る</button>
  </div>
  <br>
</block>

<place-info [parkingInfo]="parking"></place-info>
<br>

<ng-container *ngIf="showPaymentInfo">
  <block title="お支払い情報">
    <p-pay-method [passcardCancelDocumentUrl]="passcardCancelDocumentUrl" [payInfo]="payInfo"></p-pay-method>
  </block>
  <br>

  <div *ngIf="!IsComplete" class="button-wrapper">
    <button type="button" class="cmn-btn bck-btn" (click)="onReInput(2)">お支払い情報入力に戻る</button>
  </div>
  <br>
</ng-container>

<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button class="six" label="申請"
    (apply)="onApply()"
    [disabled]="api.Disabled"
    [loading]="api.Disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn dcd-btn six'" />
</div>