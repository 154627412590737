import { Component, OnDestroy, inject } from '@angular/core';
import { NavigateService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { ReceiptIssueModel } from '../receipt-issue.model';
import { ReceiptIssueComponent } from '../receipt-issue.component';

@Component({
  selector: 'receipt-change-tip',
  templateUrl: './change-tip.component.html',
  providers: [ReceiptIssueModel]
})
export class ReceiptChangeTipComponent implements OnDestroy {
  private readonly _nav = inject(NavigateService);
  private readonly _parent = inject(ReceiptIssueComponent);
  
  readonly api = inject(PasscardApiProxy);
  readonly model = inject(ReceiptIssueModel);

  ngOnDestroy(): void {
    this.api.error = '';
  }

  onBack(){
    const { url, state } = this.model.src;
    this._nav.navigate(url, false, state);
  }

  onIssue() {
    this.model.issue();
  }

  onChange() {
    this.model.nameWorking = this.model.nameFurigana;
    this.model.saveModel();
    this._parent.navigate('changing');
  }
}
