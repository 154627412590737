<h2>証明書等再提出</h2>

<p class="complete-text">証明書等再提出が完了しました</p>
<div class="text-center-list">
  <p>係員が申請内容を確認致します。</p>
  <p>申請内容の確認完了までお待ちください。</p>
  <br>
  <p>受付No.{{receiptNo}}</p>
</div>

<div class="buttonBox">
  <button (click)="onApply()" class="cmn-btn bck-btn">定期情報に戻る</button>
</div>