<!-- <div>
  <h2>SUCCESS</h2>
  <h2>receipt loading...</h2>
  <pre>{{query}}</pre>
</div> -->
<div class="container">
  <div class="main-wrap">
    <div class="main-contents">
      <div class="text-complete">しばらくお待ちください。</div>
    </div>
  </div>
</div>
