<p-info kind="danger">
  <p>以下の定期券の解約を行います。</p>
  <p>内容を確認し、「解約」を押してください。</p>
</p-info>
<h2>定期解約</h2>

<contract-detail [contract]="contract"></contract-detail>

<h3>注意事項</h3>
<div class="comment-list">
  <div>定期券の解約を行うと、有効期限にかかわらず、即時解約となります</div>
  <div>解約に伴う返金は行っておりません</div>
  <div><p>返金については<a class="passcard-link" href="{{app.publishedDocumentInfo.termsServiceURL}}" target="_terms">利用規約</a>ご確認ください</p></div>
</div>
<ng-container *ngIf="!IsComplete">
  <br>
  <section class="checkWrapper">
    <label [ngClass]="{'checkBoxArea':isConfirmed,'checkBoxAreaNot':!isConfirmed}">
      <input [(ngModel)]="isConfirmed" type="checkbox" class="unsubscribeConfirm_check">
      <div class="checkboxMeg">注意事項と利用規約について確認しました</div>
    </label>
  </section>
  <br>
  <div *ngIf="!IsComplete" class="buttonBoxVertical">
    <button type="button" (click)="onBack()" class="cmn-btn mr15 bck-btn">戻る</button>
    <loading-button label="解約"
      (apply)="onCancelApply()"
      [disabled]="!isConfirmed ||api.Disabled "
      [loading]="api.Disabled"
      [lClass]="'button-box-loading'"
      [class]="'cmn-btn cncl-btn'" />
  </div>
</ng-container>

<popup-modal #cancelConfirm>
  <div class="cancel-constract-modal">
    <div><i class="icon exclamation-circle"></i></div>
    <div>解約を実行します。</div>
    <div class="comment">
      <div>この操作は取消ができません。</div>
      <div>解約を実行すると定期券は利用できなくなります。</div>
    </div>
    <div>
      <div *ngIf="!api.Disabled">
        <button type="button" class="cmn-btn bck-btn" (click)="onDlgClose()">キャンセル</button>
      </div>
      <div>
        <loading-button label="解約"
          (apply)="onDlgCancel()"
          [disabled]="api.Disabled"
          [loading]="api.Disabled"
          [lClass]="'button-box-loading'"
          [class]="'cmn-btn cncl-btn'" />
      </div>
    </div>
  </div>
</popup-modal>