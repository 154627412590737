import { Component, Input, inject } from '@angular/core';
import { PaymentDivision } from '../../models/passcard';
import { CurrencyPipe } from '@angular/common';
import { formatPeriod } from '../../passcard.model';

export interface PasscardValidTerm {
  /**
   * 定期契約月数(int)
   */
  passPaymentDivision: PaymentDivision;

  /**
   * 選択対象月(int)
   * 0：選択なし、1：当月、2：翌月　
   */
  selectTargetMonth: number;

  /**
   * 定期有効開始(long)
   */
  passcardValidFrom: number;

  /**
   * 定期有効終了(long)
   */
  passcardValidTo: number;

  /**
   * 定期更新可能開始日(long)
   */
  passcardUpdateFrom: number;

  /**
   * 定期更新可能終了日(long)
   */
  passcardUpdateTo: number;
}

@Component({
  selector: 'p-passcard-valid-term',
  templateUrl: './p-passcard-valid-term.component.html',
  providers: [
    CurrencyPipe
  ]
})
export class PPasscardValidTermComponent {
  private readonly _currencyPipe = inject(CurrencyPipe);
  @Input() model!: PasscardValidTerm;

  get PasscardMonth() {
    if (!this.model.passPaymentDivision) return null;
    const { monthNum, unitPrice } = this.model.passPaymentDivision;
    return `${monthNum}ヵ月(${this._currencyPipe.transform(unitPrice, 'JPY')})`
  }

  get FixedTerm() {
    return formatPeriod([this.model.passcardValidFrom, this.model.passcardValidTo]);
  }
  get UpdatableTerm() {
    return formatPeriod([this.model.passcardUpdateFrom, this.model.passcardUpdateTo]);
  }
}
