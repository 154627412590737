<div class="tableCtn">
    <ng-container [ngSwitch]="payInfo.method">
        <ng-template [ngSwitchCase]="0">
            <p-single label="お支払い方法" value="定期更新機で支払う" />
            <p-single label="お支払い期限" [value]="paymentTerm" />
        </ng-template>
        <ng-template [ngSwitchCase]="1">
            <p-single label="お支払い方法" value="クレジットカードで支払う" />
            <p-single label="クレジットカード番号" [value]="creditNo" />
        </ng-template>
        <ng-template [ngSwitchCase]="2">
            <p-single label="お支払い方法" value="PayPayで支払う" />
            <p-single label="お支払い期限" [value]="paymentTerm" />
        </ng-template>
        <ng-template [ngSwitchCase]="3">
            <p-single label="お支払い方法" value="コンビニで支払う" />
            <p-single label="お支払い先コンビニ" [value]="payInfo.cvsPay.cvsName" />
            <p-single label="コンビニ支払い手数料" value="{{payInfo.cvsPay.payCommission | currency:'JPY'}}" />
            <p-single label="お支払い期限" [value]="paymentTerm" />
        </ng-template>
    </ng-container>
</div>
<br>
<div class="comment-list">
    <ng-container [ngSwitch]="payInfo.method">
        <ng-template [ngSwitchCase]="0">
            <div>お支払い期限を過ぎますと、定期券の申請は自動的に削除されます</div>
        </ng-template>
        <ng-template [ngSwitchCase]="1">
            <div>申請と同時に決済が完了します</div>
        </ng-template>
        <ng-template [ngSwitchCase]="2">
            <div>お支払い期限を過ぎますと、定期券の申請は自動的に削除されます</div>
            <div class="error-text">PayPayでお支払い完了後、本サイトの決済完了ページに自動的に移動します。PayPayお支払い完了後は、アプリや画面を閉じずにお待ちください</div>
            <div>PayPayでお支払い完了後、本サイトに移動できない場合、10分程度でお支払いが完了しますので、時間をおいて定期情報ページをご確認ください</div>
        </ng-template>
        <ng-template [ngSwitchCase]="3">
            <div>お支払い期限を過ぎますと、定期券の申請は自動的に削除されます</div>
            <div>コンビニでお支払い後、お支払いの確認が取れ次第「決済確認メール」を送信いたします(メールはお支払いから2時間以内に送信します)</div>
        </ng-template>
    </ng-container>
    <div><p>キャンセルや解約については、「<a href="{{app.publishedDocumentInfo.specifiedCommercialTransactionsURL}}" target="specified-commercial-transactions" class="passcard-link">特定商取引法に基づく表記</a>」をご確認ください</p></div>
</div>
