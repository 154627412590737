import { Component, OnInit, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { ApiService, NavigateService } from 'src/app/services';
import { SealReissueService } from '../../seal-reissue.service';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'seal-reissue01',
  templateUrl: './seal-reissue01.component.html'
})
export class SealReissue01Component implements OnInit {
  buyKind = -1;
  private readonly _route = inject(ActivatedRoute);

  constructor(public app: AppModel,
    public api: ApiService,
    private _navi: NavigateService,
    private _sealService: SealReissueService
  ) {
    this._sealService.processing = 0;
  }

  ngOnInit(): void {
    this._route.data.pipe(map(({ data }) => data)).subscribe(x => {
      this._sealService.applyParams = x;
      this._sealService.reissueFee = this._sealService.applyParams.applySealIssueCommission;
    });
  }
  
  get IsComplete() {
    return this._sealService.complete;
  }

  get contract() {
    return this._sealService.passcardApply;
  }

  get Charge() {
    return this._sealService.reissueFee;
  }

  onNext() {
    this._navi.navigateByUrl('/passcard/seal-reissue/step/2');
  }
}
