import { Component, DestroyRef, inject } from '@angular/core';
import { PasscardStatus, ReceptionMode } from '../passcard.model';
import { AppModel } from 'src/app/app.model';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { NavigateService, StorageService } from 'src/app/services';
import { PaymentType } from '../pay/pay.model';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { PasscardChangeService } from './passcard-change.service'

const STEPS = ['定期選択', 'お支払い方法', '確認', '完了'];

@Component({
  selector: 'passcard-change',
  templateUrl: './passcard-change.component.html',
  providers: [ PasscardChangeService ]
})
export class PasscardChangeComponent {
  TS = PasscardStatus;
  private _storage = inject(StorageService);
  service = inject(PasscardChangeService);
  paymentType: number | null = null;

  steps = [...STEPS];

  receptionNo: string | null = null;
  btnPhoneMode = false;
  currentProc: number = 0;
  app = inject(AppModel);

  paymethodLable: string = '';
  
  constructor(
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    l: Location) {
    const state = l.getState();
    this.service.passcardApply ??= state['passcardApply'];
    this.service.applyParams ??= state['applyParams'];
    this.service.status ??= state['status'] ?? 6;
    this.service.saveState();

    const cache02Key = `payment-method`;
    this.paymentType = this._storage.load(cache02Key, null)?.method ?? null;

    const subs = inject(Router).events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { url } = <NavigationEnd>event;
        if (url.includes('/passcard/change/step/4')) {
          this.service.processing = 3;
        }
        else if (url.includes('/passcard/change/step/3')) {
          this.service.processing = 2;
        }
        else if (url.includes('/passcard/change/step/2')) {
          this.service.processing = 1;
        }
        else {
          this.service.processing = 0;
        }
        if (url.includes('/passcard/change')) {
          this.service.saveState();
        }
      }
    });

    inject(DestroyRef).onDestroy(() => subs.unsubscribe());
  }

  get isNeedConfirm() {
    return this.status != 11 && this.service.Cach01.passcard.receptionMode == ReceptionMode.Manual;
  }
  
  get PayMethodLabel() {
    return PaymentType[this.paymentType];
  }

  get passcardApply() {
    return this.service.passcardApply;
  }

  get complete() {
    return this.service.complete;
  }

  get status() {
    return this.service.status;
  }

  async onToPayDetail() {
    const passcardApplyNo = this.passcardApply.passcardApplyNo;
    let to = 'ic-register';
    switch (this.paymentType) {
      case PaymentType.Terminal:
        to = 'terminal-pay';
        break;
      case PaymentType.CreditCard:
        to = `ic-register/${passcardApplyNo}`;
        break;
      case PaymentType.PayPay:
        to = '2pay-pay';
        break;
      case PaymentType.Cvs:
        to = 'conveni-pay';
        break;
    }

    let paymentInfo = null;
    if (this.paymentType != 1) {
      paymentInfo = await this.api.getPaymentInfo(this.paymentType, passcardApplyNo);
    }

    this._navi.navigate(`/passcard/${to}`, false, { passcardApplyNo: passcardApplyNo, paymentInfo });
  }

  /**
  * 申請状況を確認する
  */
  onToConfirm() {
    this._navi.navigateByUrl('/passcard/list');
  }
}
