<h2>コンビニ支払い情報</h2>
<div class="tableCtn">
  <div class="tableLine">
    <div class="tableCell_l">お支払い先コンビニ</div>
    <div class="tableCell_r">
      <div class="tdCtn">
        <div class="conveni single" [ngClass]="CvsClass">{{paymentInfo.cvsName}}</div>
      </div>
    </div>
  </div>
  <ng-container [ngSwitch]="paymentInfo.cvsCode">
    <ng-template [ngSwitchCase]="'00007'">
      <p-single label="払込番号" [value]="paymentInfo.cvsReceiptNo" />
      <div class="tableLine">
        <div class="tableCell_l">払込票</div>
        <div class="tableCell_r">
          <div class="tdCtn"><a href="{{paymentInfo.cvsReceiptUrl}}" target="cvs-receipt" class="passcard-link">コチラからご確認ください</a></div>
        </div>
      </div>  
    </ng-template>
    <ng-template [ngSwitchCase]="'10002'">
      <p-single label="第1番号" [value]="paymentInfo.cvsConfNo" />
      <p-single label="第2番号" [value]="paymentInfo.cvsReceiptNo" />
      <div class="tableLine">
        <div class="tableCell_l">バーコード取得用URL</div>
        <div class="tableCell_r">
          <div class="tdCtn"><a href="{{paymentInfo.cvsReceiptUrl}}" target="cvs-barcode" class="passcard-link">コチラからご確認ください</a></div>
        </div>
      </div>  
    </ng-template>
    <ng-template [ngSwitchCase]="'10008'">
      <p-single label="受付番号" [value]="paymentInfo.cvsReceiptNo" />
      <p-single label="確認番号" [value]="paymentInfo.cvsConfNo" />
    </ng-template>
    <ng-template [ngSwitchDefault]>
      <p-single label="お客様番号" [value]="paymentInfo.cvsReceiptNo" />
      <p-single label="確認番号" [value]="paymentInfo.cvsConfNo" />
    </ng-template>
  </ng-container>
  <p-single label="お支払い期限" [value]="paymentTerm" />
  <div class="tableLine">
    <div class="tableCell_l">ご請求額</div>
    <div class="tableCell_r">
      <div class="tdCtn">{{payRequest.payDetail.paymentAmount| currency:'JPY'}}</div>
    </div>
  </div>
</div>
<br>
<div>
  <p><a href="{{paymentInfo.paymentCvsDocumentUrl}}" target="cvs-pay-method" class="passcard-link">※お支払い方法の詳細はコチラ</a></p>
  <br>
  <div class="comment-list">
    <div>お支払い期限を過ぎますと、定期券の申請は自動的に削除されます</div>
    <div>コンビニでお支払い後、お支払いの確認が取れ次第「決済確認メール」を送信いたします(メールはお支払いから2時間以内に送信します)    </div>
  </div>
</div>
<br>
<pay-request [model]="payRequest"></pay-request>
<br>
<div class="buttonBoxVertical">
  <loading-button label="定期券情報に戻る"
    (apply)="onBack()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn bck-btn six'" />
</div>