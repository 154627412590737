<h2>新規会員登録</h2>
<!--
<reg-stepbar [step]="4"></reg-stepbar>
-->
<flow-box-registration [steps]="['アドレス入力', 'お客様情報入力', '確認', '完了']" [processing]="2" [processed]="2" />

<t-error [message]="error"></t-error>

<h3>お客様情報</h3>
<section>
  <div class="tableCtn tableCtn-confirm">
    <div *ngIf="model.byUserName!=='0'">
      <p-kana [label]="'氏名'" [value]="model.fullname"></p-kana>
    </div>
    <div *ngIf="model.byUserFurigana!=='0'">
      <p-kana [label]="'氏名（カナ）'" [value]="model.kana"></p-kana>
    </div>
    <div *ngIf="model.byUserBirth!=='0'">
      <p-birthdate [label]="'生年月日'" [value]="model.birthdate"></p-birthdate>
    </div>
    <p-single [label]="'メールアドレス'" [value]="model.mailAddr"></p-single>
    <div *ngIf="model.byUserTel!=='0'">
      <p-single [label]="'電話番号'" [value]="model.phone"></p-single>
    </div>
    <div *ngIf="model.bySecurityNumber!=='0'">
      <p-single [label]="'防犯登録番号'" [value]="model.securityNumber"></p-single>
    </div>
  </div>
</section>
<div *ngIf="model.byUserZip!=='0' || model.byUserAddress1!=='0' ||  model.byUserAddress2!=='0'">
  <h3 class="undr-tble">住所</h3>
  <section>
    <span *ngIf="model.byUserZip!=='0' || model.byUserAddress1!=='0' ||  model.byUserAddress2!=='0'">
      <div class="tableCtn tableCtn-confirm">
        <div *ngIf="model.byUserZip!=='0'">
          <p-postcode [label]="'郵便番号'" [value]="model.postcode"></p-postcode>
        </div>
        <div *ngIf="model.byUserAddress1!=='0'">
          <p-single [label]="'住所1'" [value]="model.address1"></p-single>
        </div>
        <div *ngIf="model.byUserAddress2!=='0'">
          <p-single [label]="'住所2'" [value]="model.address2"></p-single>
        </div>
      </div>
    </span>
  </section>
</div>
<div *ngIf="model.byCompanyName!=='0' || model.byCompanyAddress2!=='0'">
  <h3 class="undr-tble">通学/勤務先</h3>
</div>
<section>
  <span *ngIf="model.byCompanyName!=='0' || model.byCompanyAddress2!=='0'">
    <div class="tableCtn tableCtn-confirm">
      <div *ngIf="model.byCompanyName!=='0'">
        <p-single [label]="'通学/勤務先名'" [value]="model.wsName"></p-single>
      </div>
      <div *ngIf="model.byCompanyName!=='0'">
        <p-single [label]="'通学/勤務先名（カナ）'" [value]="model.wsKana"></p-single>
      </div>
      <div *ngIf="model.byCompanyName!=='0'">
        <p-single [label]="'電話番号'" [value]="model.wsPhone"></p-single>
      </div>
      <div *ngIf="model.byCompanyName!=='0'">
        <p-postcode [label]="'郵便番号'" [value]="model.wsPostcode"></p-postcode>
      </div>
      <div *ngIf="model.byCompanyName!=='0'">
        <p-single [label]="'住所1'" [value]="model.wsAddress1"></p-single>
      </div>
      <div *ngIf="model.byCompanyAddress2!=='0'">
        <p-single [label]="'住所2'" [value]="model.wsAddress2"></p-single>
      </div>
    </div>
  </span>
</section>
<div *ngIf="model.byCarInfo!=='0'">
  <h3 class="undr-tble">車両情報(クルマ)</h3>
</div>
<section>
  <span *ngIf="model.byCarInfo!=='0'">
    <div class="tableCtn tableCtn-confirm">
      <div *ngIf="model.byCarInfo!=='0'">
        <p-carinfo [label]="'ナンバープレート'" [np]="model.numberPlate"></p-carinfo>
      </div>
    </div>
  </span>
</section>
<div *ngIf="model.byApplyMotorcycleNumber!=='0'">
  <h3 class="undr-tble">車両情報(バイク)</h3>
</div>
<section>
  <span *ngIf="model.byApplyMotorcycleNumber!=='0'">
    <div class="tableCtn tableCtn-confirm">
      <div *ngIf="model.byApplyMotorcycleNumber!=='0'">
        <p-single [label]="'ナンバープレート'" [value]="model.motorcycleNumberPlate"></p-single>
      </div>
    </div>
  </span>
</section>
<h3 class="undr-tble">パスワード</h3>
<section>
  <div class="tableCtn tableCtn-confirm">
    <p-single [label]="'パスワード'" [value]="'************'"></p-single>
  </div>
</section>
<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="navi.goBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [label]="'登録'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>