<p-info>
    <p>以下の内容で設定します。一度表示した利用明細書の宛名は変更できません。</p>
    <p>内容確認後、「利用明細表示」を押してください。</p>
</p-info>
<br>
<div class="tableCtn tableCtn-confirm">
    <p-single label="宛名" [value]="name + '　様'" />
</div>
<br>
<div class="buttonBoxVertical">
    <button type="button" class="cmn-btn mr15 bck-btn" (click)="onBack()">戻る</button>
    <loading-button label="利用明細表示"
        (apply)="onIssue()"
        [disabled]="api.Disabled"
        [loading]="api.Disabled"
        [class]="'cmn-btn mr15'"
        [lClass]="'button-box-loading'" />
</div>