import { Component, inject, ViewChild } from '@angular/core';
import { addDay, PasscardStatus, formatPeriod, calcUpdatablePeriod, getPasscardValidEndDay, getInputImageUploadModel, ReceptionMode } from '../../../passcard.model';
import { Observable } from 'rxjs';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { Passcard, PaymentDivision } from 'src/app/passcard/models/passcard';
import { PasscardChangeComponent } from '../../passcard-change.component';
import { Location } from '@angular/common';
import { CertificateType, ImageCheckReq } from 'src/app/shared/certificate-file';
import { IMyFilesComponent, MyFilesModel } from 'src/app/shared/i-my-files/i-my-files.component';

@Component({
  selector: 'passcard-change01',
  templateUrl: './passcard-change01.component.html',
})
export class PasscardChange01Component {
  private _storage = inject(StorageService);
  PasscardStatus = PasscardStatus;

  fixedPeriods$!: Observable<string[]>;
  fixedPeriod: string = '';
  updatablePeriod = '';
  passcards: Passcard[];
  passcardValidFrom = '';
  paymentDivision: PaymentDivision;
  certificateTypeList: CertificateType[] = [];
  myFilesModel: MyFilesModel = {
    certificateType: 0,
    images: []
  };

  model: {
    passcard?: Passcard,
    passcardMonth: number,
    passcardValidFrom?: number,
    passcardValidTo?: number,
    passcardUpdateFrom?: number,
    passcardUpdateTo?: number,
  } = {
    passcardMonth: 0,
  };
  
  @ViewChild(IMyFilesComponent) fileUploader: IMyFilesComponent;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    public wiz: PasscardChangeComponent,
    private _navi: NavigateService,
    l: Location
  ) {
    this.wiz.service.processing = 0;
    this.wiz.service.saveState();
    this.restoreModel();
  }

  get applyParams() {
    return this.wiz.service.applyParams;
  }

  get passcardSelected() {
    return this.model.passcard;
  }

  get IsComplete() {
    return this.wiz.service.complete;
  }

  get showCertificateUpload() {
    return this.wiz.status != 11 && !!this.certificateTypeList?.length;
  }

  get NextDisabled() {
    const certificateUploadError = this.fileUploader?.error ?? false;
    return this.api.error
      || this.api.Disabled
      || !this.passcardSelected
      || this.model.passcardMonth == 0
      || certificateUploadError;
  }

  onPasscardChange(passcard: Passcard) {
    if (!passcard || passcard.passcardType == this.model.passcard?.passcardType) return;

    this.model.passcard = passcard;
    this.model.passcardMonth = 0;
    this.fixedPeriod = '';
    this.updatablePeriod = '';
    this.certificateTypeList = [];
    
    this.wiz.service.status = passcard.receptionMode == ReceptionMode.Manual ? 10 : 6;

    if (passcard?.certificateTypeNoList) {
      this.certificateTypeList = getInputImageUploadModel(passcard.certificateTypeNoList ,this.applyParams.certificateTypeList);
      setTimeout(() => this.clearImageUploadModel(), 0);
    }
  }

  onSetPasscardMonth(paymentDivision: PaymentDivision) {
    if (this.model.passcardMonth != paymentDivision.monthNum) {
      this.model.passcardMonth = paymentDivision.monthNum;
      this.paymentDivision = paymentDivision;
      this.calcPeriod();
    }
  }

  formatDate(dt: number, sep?: string): string {
    sep ??= '/';
    const dtDate = new Date(dt);
    const month = `${dtDate.getMonth() + 1}`.padStart(2, '0');
    const date = `${dtDate.getDate()}`.padStart(2, '0');
    return `${dtDate.getFullYear()}${sep}${month}${sep}${date}`
  }

  async onNext() {
    this.saveModel();
    const { passcardType, extended, receptionMode }= this.model.passcard;
    if (this.showCertificateUpload) {
      // if (!this.fileUploader.validate()) return;

      const { passcardValidFrom, passcardValidTo, passcardMonth } = this.model;
      const { certificateType, images } = this.myFilesModel;
      const certificateImageList: ImageCheckReq[] = images.filter(x => x.fileSize).map(x => {
        delete x.validatorMessage;
        return ({...x, certificateImageData: x.certificateImageData.substring(x.certificateImageData.indexOf(',') + 1)})
      });
    }

    if (this.wiz.status == 6 && this.model.passcard.receptionMode == ReceptionMode.Manual) {
      this.wiz.service.status = 10;
      this.wiz.service.saveState();
    }

    if (this.wiz.status != 10) {
      let payCommission = this.model.passcard
        .paymentDivisionList.find(x => x.monthNum == this.model.passcardMonth).unitPrice;
      const { paymentTerm, terminalPaymentTerm } = await this.api.getPaymentTerm(this.wiz.status, this.model.passcardMonth);
      const {
        paymentMethodPaidMachineQR,
        paymentMethodCreditCard,
        paymentMethodPayPay,
        paymentMethodConvenienceStore,
      } = this.applyParams;
      this._storage.save('payment-params', {
        paymentTerm, terminalPaymentTerm,
        paymentMethodPaidMachineQR, paymentMethodCreditCard, paymentMethodPayPay, paymentMethodConvenienceStore,
        commission: payCommission
      });
    }

    const next = receptionMode == ReceptionMode.Manual && this.wiz.status != 11 ? 3 : 2;
    this._navi.navigateByUrl(`/passcard/change/step/${next}`);
  }

  saveModel() {
    const { passcardValidFrom, passcardValidTo, passcardUpdateFrom, passcardUpdateTo } = this.model;
    const myFilesModel: any = {};
    if (this.showCertificateUpload) {
      myFilesModel.myFilesModel = this.myFilesModel;
      myFilesModel.certificateTypeSetting = this.certificateTypeList.find(x => x.certificateType == this.myFilesModel.certificateType);
    }

    this.wiz.service.Cach01 = {
      passcard: this.model.passcard,
      paymentDivision: this.paymentDivision,
      passcardMonth: this.model.passcardMonth,
      passcardValidFrom,
      passcardValidTo,
      passcardUpdateFrom,
      passcardUpdateTo,
      beforePasscardType: this.applyParams.currentPasscardType ?? this.wiz.passcardApply.passcardType,
      beforePasscardTypeName: this.applyParams.currentPasscardTypeName ?? this.wiz.passcardApply.name,
      beforePasscardExtended: this.applyParams.currentPasscardExtended ?? this.wiz.passcardApply.passcardExtended,
      ...myFilesModel
    };
    this.wiz.service.saveState();
  }

  restoreModel() {
    this.passcards =this.applyParams.passcardTypeList;
    const cache = this.wiz.service.Cach01;
    if (cache) {
      this.model.passcard = this.passcards.find(x => x.passcardType == cache.passcard.passcardType);
      this.model.passcardMonth = cache.passcardMonth;
      this.model.passcardValidTo = cache.passcardValidTo;
      this.paymentDivision = cache.paymentDivision;
      this.myFilesModel = cache.myFilesModel;
      if (this.model.passcard?.certificateTypeNoList?.length) {
        this.certificateTypeList = getInputImageUploadModel(this.model.passcard.certificateTypeNoList ,this.applyParams.certificateTypeList);
      }

      this.myFilesModel ??= {
        certificateType: this.certificateTypeList?.[0]?.certificateType,
        images: []
      }
    } else if (this.wiz.status == 11) {
      this.model.passcard = this.passcards[0];
      this.model.passcardMonth = this.applyParams.selectedMonthNum;
      this.paymentDivision = this.model.passcard.paymentDivisionList.find(x => x.monthNum == this.model.passcardMonth);
    }

    this.model.passcardValidFrom = new Date(addDay(new Date(this.wiz.service.passcardApply.passcardValidTo), 1)).getTime();

    this.calcPeriod();
  }

  private clearImageUploadModel() {
    this.myFilesModel.certificateType = this.certificateTypeList?.[0]?.certificateType;
    this.myFilesModel.month = this.myFilesModel.year = undefined;
    this.myFilesModel.images = [];
  }

  private calcPeriod() {
    const { passcardMonth } = this.model;
    if (passcardMonth <= 0) return;

    const base = new Date(this.model.passcardValidFrom);
    this.model.passcardValidTo = getPasscardValidEndDay(base, passcardMonth);
    this.fixedPeriod = formatPeriod([this.model.passcardValidFrom, this.model.passcardValidTo]);
    const { passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay } = this.applyParams;
    const updatablePeiod = calcUpdatablePeriod(new Date(this.model.passcardValidTo), passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay);
    this.model.passcardUpdateFrom = updatablePeiod[0]
    this.model.passcardUpdateTo = updatablePeiod[1];
    this.updatablePeriod = formatPeriod(updatablePeiod);
  }
}
