import { Component, ViewChild } from '@angular/core';
import { NavigateService } from 'src/app/services';
import { SealReissueService } from '../../seal-reissue.service';
import { CeditDefault, PayInfomation } from 'src/app/passcard/pay/pay.model';
import { PaymethodSelectComponent } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';

@Component({
  selector: 'seal-reissue02',
  templateUrl: './seal-reissue02.component.html'
})
export class SealReissue02Component {
  payMethod: PayInfomation;
  payMethodUsableFlgs: number[];
  
  @ViewChild(PaymethodSelectComponent, { static: true }) payMethodSelect!: PaymethodSelectComponent;

  constructor(private _navi: NavigateService,
    private _sealService: SealReissueService) {
    this._sealService.processing = 1;
    this.payMethod = _sealService.applyModel.payMethod;
    this.payMethod.creditPay ??=  { cardUse: 0, "0": { ...CeditDefault }, "1": { ...CeditDefault } };
    this.payMethod.cvsPay ??= {};
    this.payMethodUsableFlgs = [
      _sealService.applyParams.paymentMethodPaidMachineQR,
      _sealService.applyParams.paymentMethodCreditCard,
      _sealService.applyParams.paymentMethodPayPay,
      _sealService.applyParams.paymentMethodConvenienceStore,
    ]
  }

  get IsComplete() {
    return this._sealService.complete;
  }

  get dcParkingNo() {
    return this._sealService.applyModel.dcParkingNo;
  }

  onBack() {
    this._navi.navigateByUrl('/passcard/seal-reissue/step/1');
  }

  async onNext() {
    if (!await this.payMethodSelect.validate()) return;

    this._sealService.applyModel.payMethod = this.payMethod;
    this._sealService.maxProcessed = Math.max(this._sealService.maxProcessed, 1);
    this._navi.navigateByUrl('/passcard/seal-reissue/step/3');
  }

}
