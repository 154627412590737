<h2>お支払い一覧</h2>
<div class="row">
  <div class="selectBox">
    <select title="期間" [(ngModel)]="conditionsPeriod" (change)="onChangePeriod()">
      <option *ngFor="let item of conditionsPeriods" value="{{item.code}}">{{item.name}}</option>
    </select>
  </div>
</div>
<div class="pay-list" *ngIf="paymentList.length; else none">
  <div class="pay-item" *ngFor="let paymentItem of paymentList" (click)="onToDetail(paymentItem)">
    <div class="status">
      <i class="icon"
      [class.certi-card]="paymentItem.paymentContentsCode == 1"
      [class.refresh]="paymentItem.paymentContentsCode == 2"
      [class.lost]="paymentItem.paymentContentsCode == 3"></i>
    </div>
    <div>
      <div>{{paymentItem.paymentDate | date: 'yyyy/MM/dd HH:mm'}}</div>
      <div>{{paymentItem.parkingAreaName}}</div>
      <div>{{PCN[paymentItem.paymentContentsCode]}}</div>
    </div>
    <div>{{ paymentItem.paymentFee | currency:'JPY' }}</div>
  </div>
</div>
<br>
<app-pagination
  *ngIf="1 < pageModel.pages"
  [pages]="pageModel.pages"
  [page]="pageModel.page"
  [popup]="!api.Disabled"
  (onPage)="onPage($event)"
  ></app-pagination>

<ng-template #none>
  <div>お支払いなし</div>
</ng-template>