import { Component, inject } from '@angular/core';
import { PasscardStatus, PeriodKind, formatPeriod, getStorageKey } from '../../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { Passcard } from 'src/app/passcard/models/passcard';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { PayFeeDetail } from 'src/app/passcard/common/p-pay-fees/p-pay-fees.component';
import { PaymentType } from 'src/app/passcard/pay/pay.model';
import { ParkingInfo } from 'src/app/passcard/models/apply-info';
import { ApplyComponent } from '../../apply.component';
import { getApplyPaymentItems } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';
import { Location } from '@angular/common';

const buycardStrs = Object.freeze({
  '0': 'お手持ちの交通系ICカードを定期券として利用する',
  '1': '駐輪場専用のICカードを購入して定期券として利用する'
});

@Component({
  selector: 'apply03',
  templateUrl: './apply03.component.html'
})
export class Apply03Component {
  private _storage = inject(StorageService);
  private _cach01: any;
  private _cach02: any;
  private _cach03: any;

  TS = PasscardStatus;
  passcardCancelDocumentUrl = '';

  status: number;
  dcParkingNo: number | null;
  /**
   * 定期代
   */
  passcardFee: number;

  buycardFee: number = 0;

  buycardStr: string;
  usedStart: string;
  fixedPeriod: string = '';
  updatablePeriod = '';
  paidDetail: PayFeeDetail;

  parking: ParkingInfo;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _wiz: ApplyComponent,
    private _navi: NavigateService,
    l: Location
    ) {
    const state = l.getState();
    this.status = state['status'];
    this.dcParkingNo = state['dcParkingNo'];

    this._wiz.processing = 2;
    this._wiz.saveState();
    this.parking = {
      dcParkingNo: 0,
      parkingName: _wiz.parkingName.getValue(),
      parkingAddress: _wiz.parkingAddress
    };

    const { selectTargetMonth, passcardMonth, buyICCard } = this.Cach01;
    this.buycardStr = buycardStrs[buyICCard];
    this.buycardFee = this.Cach01.applyNewIssueCommission??0;
    if (buyICCard == 1) {
      this.buycardFee += this.Cach01.applyCardIssueCommission??0;
    }

    this.passcardFee = this.passcard.paymentDivisionList!.find(x => x.monthNum == passcardMonth)?.unitPrice;    
    this.setPaymentDetails();
    this.passcardCancelDocumentUrl = this.Cach01.passcardCancelDocumentUrl;
    this.usedStart =PeriodKind[selectTargetMonth];
    if (selectTargetMonth) {
      const { passcardUpdateFrom, passcardUpdateTo, passcardValidFrom, passcardValidTo  } = this.Cach01;
      this.fixedPeriod = formatPeriod([passcardValidFrom, passcardValidTo]);
      this.updatablePeriod = formatPeriod([passcardUpdateFrom, passcardUpdateTo]);
    }
  }

  get Cach01() {
    const key = getStorageKey('passcard-apply-1', this.status, this.dcParkingNo);
    this._cach01 ??= this._storage.load(key, null);
    return this._cach01;
  }

  get Cach02() {
    const key = getStorageKey('payment-method', this.status, this.dcParkingNo);
    this._cach02 ??= this._storage.load(key, null);
    return this._cach02;
  }

  get Cach03() {
    const key = getStorageKey('passcard-apply-3', this.status, this.dcParkingNo);
    this._cach03 ??= this._storage.load(key, null);
    return this._cach03;
  }

  get passcard(): Passcard {
    return this.Cach01.passcard;
  }

  get payInfo() {
    return this.Cach02;
  }

  get ShowPasscardStatus() {
    const status = this.status;
    return status == 1 || status == 2  || status == 7 || status == 9;
  }

  get IsComplete() {
    return this._wiz.complete;
  }

  get btnPasscardSelectVisible() {
    return this.status != 9;
  }

  get payInfoVisible() {
    const status = this.status;
    return status == 1 || status == 3 || status == 8;
  }

  get isReApply(): boolean {
    return !!this._wiz.paramsReApply;
  }

  onReInput(kb: number) {
    const nextArgs: any = { status: this.status } 
    if (this._wiz.paramsReApply) {
      Object.assign(nextArgs, { dcParkingNo: this._wiz.paramsReApply.dcParkingNo })
    }

    if (kb == 1) {
      if (this._wiz.paramsReApply?.dcParkingNo != null) {
        this._navi.navigate(`/passcard/new/reApply/${this._wiz.paramsReApply?.dcParkingNo}`, false, nextArgs);
        return;
      }  
    }

    this._navi.navigate(`/passcard/new/step/${kb}`, false, nextArgs);
  }

  /**
   * 申請を行う
   */
  async onApply() {
    this._wiz.maxProcessed = 2;
    const { no, passcardType, extended, receptionMode } = this.passcard;

    const { buyICCard, passcardMonth, selectTargetMonth } = this.Cach01;
    const dcParkingNo = this._wiz.paramsReApply?.dcParkingNo ?? null;
    const status = this.status;
    const passPaymentDivision = this.passcard.paymentDivisionList?.find(x => x.monthNum == passcardMonth)?.passPaymentDivision ?? 0;
    const bookingInfoRequired = {
      passcardApplyNo: this._wiz.paramsReApply?.passcardApplyNo ?? null,
      dcParkingNo,
      status,
      no,
      passcardType,
      extended,
      passPaymentDivision,
      receptionMode,
    }

    const applyInfo: any = {};
    if (status == 8 || status == 1 || status == 3) {
      const { passcardValidFrom, passcardValidTo } = this.Cach01;
      Object.assign(applyInfo, getApplyPaymentItems(this._storage, status, dcParkingNo));
      const { paymentTerm, terminalPaymentTerm } = this.payInfo;
      switch (this.payInfo.method) {
        case PaymentType.Terminal:
          applyInfo.terminalPaymentTerm = terminalPaymentTerm;
          break;
        case PaymentType.PayPay:
        case PaymentType.Cvs:
            applyInfo.paymentTerm = paymentTerm;
          break;
      }

      applyInfo.paymentAmount = this.passcardFee;
      applyInfo.passcardValidFrom = passcardValidFrom;
      applyInfo.passcardValidTo = passcardValidTo;
      applyInfo.buyICCard = buyICCard;
      applyInfo.passcardMonth = passcardMonth;
      applyInfo.selectTargetMonth = selectTargetMonth;
      applyInfo.applyNewIssueCommission = this.Cach01.applyNewIssueCommission ?? 0;
      applyInfo.applyCardIssueCommission = this.Cach01.applyCardIssueCommission ?? 0;
    }

    const reservation = {
      ...bookingInfoRequired,
      ...applyInfo,
    };

    const applyResult = await this.api.passcardApply(reservation);
    let message2 = '';
    let message3 = '';

    if (status == 2) {
      message2 = '順番となるまでお待ちください。';
    }
    else if (status == 7 || status == 9) {
      message2 = '申請内容の確認完了までお待ちください。';
    }
    else {
      message2 = '続けてICカードの登録を定期更新機にて';
      switch (this.payInfo.method) {
        case PaymentType.Terminal:
          message2 = '続けて駐車場の定期更新機にてお支払いをお願い致します。';
          break;
        case PaymentType.PayPay:
          message2 = '続けてPayPay支払いのお手続きをお願い致します。';
          break;
        case PaymentType.Cvs:
          message2 = '続けてコンビニで支払いのお手続きをお願い致します。';
          break;
        default:
          message3 = 'お手続きをお願い致します。';
          break;
      }
    }

    this._wiz.maxProcessed = Math.max(this._wiz.maxProcessed, 3);
    this._wiz.complete = true;

    this._wiz.passcardApplyNo = applyResult.passcardApplyNo ?? null;
    const key = getStorageKey(`passcard-apply-complete`, this.status, this.dcParkingNo);
    this._storage.save(key, { passcardApplyNo: this._wiz.passcardApplyNo });

    const receiptNo = this.isReApply || (this.passcard.receptionMode == 1 && this.passcard.status == PasscardStatus.Free) ? applyResult.receiptNo : null;
    this._navi.navigate('/passcard/new/step/4', true, {
      message1: '定期券の新規申請が完了しました。',
      message2,
      message3,
      receiptNo,
      dcParkingNo
    });
  }

  private setPaymentDetails() {
    this.paidDetail = {
      paymentFeeDetails: [],
      paymentAmount: 0
    };

    if (!this.payInfoVisible) return;

    this.paidDetail.paymentAmount = this.passcardFee;
    this.paidDetail.paymentFeeDetails.push({ contents: '定期代', Fee: this.passcardFee });
    if (this.payInfo.method == PaymentType.Cvs) {
      const { payCommission } = this.payInfo.cvsPay;
      this.paidDetail.paymentFeeDetails.push({ contents: 'コンビニ支払い手数料', Fee: payCommission });
      this.paidDetail.paymentAmount += payCommission;
    }

    if (this.Cach01.applyNewIssueCommission) {
      const applyNewIssueCommission = this.Cach01.applyNewIssueCommission;
      this.paidDetail.paymentFeeDetails.push({ contents: '新規発行手数料', Fee: applyNewIssueCommission });
      this.paidDetail.paymentAmount += applyNewIssueCommission;
    }
    if (this.Cach01.applyCardIssueCommission) {
      const buyCardCommission = this.Cach01.applyCardIssueCommission;
      this.paidDetail.paymentFeeDetails.push({ contents: 'ICカード購入手数料', Fee: buyCardCommission });
      this.paidDetail.paymentAmount += buyCardCommission;
    }
  }
}
