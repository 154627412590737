import { Component, inject } from '@angular/core';
import { ValidateService } from 'src/app/services';
import { ReceiptIssueModel } from '../receipt-issue.model';
import { ReceiptIssueComponent } from '../receipt-issue.component';

const nameCaption = '宛名';

@Component({
  selector: 'receipt-changing',
  templateUrl: './changing.component.html',
  providers: [ReceiptIssueModel]
})
export class ReceiptChangingComponent {
  private readonly _parent = inject(ReceiptIssueComponent);
  readonly model = inject(ReceiptIssueModel);
  valid = inject(ValidateService);

  message: string[] = [];
  messages = [];

  ngDoCheck() {
    this.validation();
  }

  get error() {
    return !!this.message.length
  }

  validation() {
    const msg = this.valid;
    this.message = msg.validRequired(this.model.nameWorking, nameCaption);
    if (this.message.length == 0) {
      msg.pushSetname(this.message, this.model.nameWorking, nameCaption);
    }

    if (this.message.length == 0) {
      msg.pushSurrogate(this.message, this.model.nameWorking, nameCaption);     
    }
    
    return this.message && !this.message.length;
  }

  onChange = () => this.messages = null;

  onConfirm() {
    this.model.saveModel();
    this._parent.navigate('confirm');
  }

  onBack() {
    this._parent.navigate('tip');
  }
}
