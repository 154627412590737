import { Component, ViewChild, inject } from '@angular/core';
import { Location } from '@angular/common';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { LostService } from '../lost/lost.service';
import { ApplyStatus, formatPeriod, getStorageKey } from '../passcard.model';
import { SealReissueService } from '../seal-reissue/seal-reissue.service';
import { RefreshService } from '../refresh/refresh.service';
import { PasscardApply } from '../models/apply-info';
import { PaymentType } from '../pay/pay.model';
import { ModalComponent } from '../common/modal/modal.component';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { StatusTipComponent, TipKind } from '../common/status-tip/status-tip.component';

/**
 * 定期契約情報
 */
@Component({
  selector: 'contract',
  templateUrl: './contract.component.html'
})
export class ContractComponent {
  private readonly _storage = inject(StorageService);

  ApplyStatus = ApplyStatus;
  confirmedClass: string | null = null;
  changePeriod = '';

  contract: PasscardApply | null = null;
  passcardApplyNo: number;
  refreshRedDotShow = false;
  
  @ViewChild('dlg') dlg!: ModalComponent
  @ViewChild(StatusTipComponent) statusTip: StatusTipComponent;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private _lostService: LostService,
    private _sealService: SealReissueService,
    private _refreshService: RefreshService,
    location: Location) {
    this.contract = location.getState()['contract'];
    this.passcardApplyNo = this.contract.passcardApplyNo;
    const { systemDate, passcardUpdateFrom, passcardUpdateTo, passcardValidFrom, passcardValidTo } = this.contract;
    this.refreshRedDotShow = this.contract.passcardAvailable == 1
    && !this.PayButtonVisible
    && this.contract.status != ApplyStatus.WaitingICRegistForLost
    && passcardUpdateFrom <= systemDate && systemDate <= passcardUpdateTo
      && passcardValidFrom <= systemDate && systemDate <= passcardValidTo;
  }

  get tipVisible() {
    if (this.contract.passcardAvailable > 1) return false;
    const { status, systemDate, passcardUpdateFrom, passcardUpdateTo, passcardValidFrom, passcardValidTo } = this.contract;
    if (systemDate >= passcardUpdateFrom && systemDate <= passcardUpdateTo) {
      if (systemDate > passcardValidTo) return true;  // 利用可(更新猶予期間)
      if (passcardValidFrom <= systemDate && systemDate <= passcardValidTo) return true;  // 定期更新受付中
    }

    return status == ApplyStatus.WaitingPayAfterIssued
    || status == ApplyStatus.WaitingICRegistForLost
    || this.PayButtonVisible;
  }

  get isPast() {
    return this.contract.passcardAvailable > 1;
  }

  get PayButtonVisible() {
    const status = this.contract['status'];
    return status == ApplyStatus.WaitingPayForRefresh
      || status == ApplyStatus.WaitingPayForLost;
  }

  get RefreshButtonVisible() {
    return this.contract.menuSettingUpdate && (this.contract.status == ApplyStatus.Avaliable || this.contract.passcardAvailable);
  }

  get LostButtonVisible() {
    return this.contract.menuSettingLostReissue && (this.contract.status == ApplyStatus.Avaliable || this.contract.passcardAvailable);
  }

  get SealIssueButtonVisible() {
    return this.contract.menuSettingStickerReissue && (this.contract.status == ApplyStatus.Avaliable || this.contract.passcardAvailable);
  }

  get CancelButtonVisible() {
    return this.contract.menuSettingCancel && (this.contract.status == ApplyStatus.Avaliable || this.contract.passcardAvailable);
  }

  onTipClick() {
    if (this.PayButtonVisible) {
      this.onPay();
      return;
    }

    const applyingStatus = this.statusTip.applying.getValue();
    if (applyingStatus == TipKind.ChangeReceipting
      || applyingStatus == TipKind.Expired) {
      this.onRefresh();
      return;
    }

    switch (this.contract.status) {
      case ApplyStatus.AutoCancellation:
      case ApplyStatus.WaitingRefresh:
        this.onRefresh();
        break;
      case ApplyStatus.WaitingICRegistForLost:
        this.onICRegister();
        break;
    }
  }

  onICRegister() {
    this._navi.navigate(`/passcard/ic-register/${this.contract.passcardApplyNo}`, false, this.contract);
  }

  /**
   * お支払い処理
   */
  async onPay() {
    let payMethod = 0;
    let urlNav = '/passcard/terminal-pay';
    switch (this.contract.passcardPaymentType) {
      case PaymentType.Terminal:
        urlNav = `/passcard/terminal-pay`;
        payMethod = 0;
        break;
      case PaymentType.PayPay:
        urlNav = `/passcard/2pay-pay`;
        payMethod = 2;
        break;
      case PaymentType.Cvs:
        urlNav = `/passcard/conveni-pay`;
        payMethod = 3;
        break;
    }

    const paymentInfo = await this.api.getPaymentInfo(payMethod, this.contract.passcardApplyNo);
    this._navi.navigate(urlNav, false, { passcardApplyNo: this.contract.passcardApplyNo, reservation: this.contract, paymentInfo });
  }

  /**
   * 紛失・破損処理
   */
  onLost() {
    this._lostService.reset();
    this._storage.remove(`payment-params`);
    this._storage.remove('lost-wiz');
    this._storage.remove(getStorageKey('lost-step-1', 4, this.contract.dcParkingNo));
    this._storage.remove(getStorageKey('payment-method', 4, this.contract.dcParkingNo));

    this._lostService.applyModel.dcParkingNo = this.contract.dcParkingNo;
    this._navi.navigate('/passcard/lost',
      false,
      {
        contract: this.contract
      });
  }

  /**
   * 更新処理
   */
  onRefresh() {
    const { systemDate, passcardUpdateFrom, passcardUpdateTo } = this.contract;
    const sysDate = new Date(systemDate);
    const updateFrom = new Date(passcardUpdateFrom);
    const udateTo = new Date(passcardUpdateTo);
    sysDate.setHours(0, 0, 0, 0);
    updateFrom.setHours(0, 0, 0, 0);
    udateTo.setHours(0, 0, 0, 0);
    if (sysDate < updateFrom || sysDate > udateTo) {
      this.changePeriod = formatPeriod([passcardUpdateFrom, passcardUpdateTo]);
      this.dlg.showModal();
      return;
    }

    this._refreshService.reset();
    this._storage.remove(`payment-params`);
    this._storage.remove('refresh-wiz');
    this._storage.remove(getStorageKey('refresh-step-1', 6, this.contract.dcParkingNo));
    this._storage.remove(getStorageKey('payment-method', 6, this.contract.dcParkingNo));
    this._refreshService.applyModel.dcParkingNo = this.contract.dcParkingNo;
    this._navi.navigate('/passcard/refresh',
      false,
      {
        contract: this.contract
      });
  }

  /**
   * シール再発行処理
   */
  onSealReissue() {
    this._sealService.reset();
    this._navi.navigate('/passcard/seal-reissue',
      false,
      {
        contract: this.contract
      });
  }

  /**
   * 定期券の解約
   */
  onCancelConfirm() {
    this._lostService.cancelComplete = false;
    this._navi.navigate('/passcard/passcard/cancel',
      false,
      {
        contract: this.contract
      });
  }

  onDlgClose() {
    this.dlg.closeModal();
  }

  /**
   * お知らせ情報の取得
   */
  get noticeInfo(){
    return this.contract['noticeInfoList'];
  }
}
