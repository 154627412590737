import { Component, inject } from '@angular/core';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { Location } from '@angular/common';
import { NavigateService } from 'src/app/services';

@Component({
  selector: 'change-complete',
  templateUrl: './complete.component.html'
})
export class ChangeCompleteComponent {
  private readonly _navi = inject(NavigateService);
  
  receiptNo: string;
  constructor(public api: PasscardApiProxy,
    location: Location) {
    this.receiptNo = location.getState()?.['receiptNo'];
  }

  /**
   * 申請状況を確認する
   */
  onConfirmApply() {
    this._navi.navigateByUrl('/passcard/list');
  }
}
