import { Component, ViewChild, inject } from '@angular/core';
import { NavigateService } from 'src/app/services';
import { AppModel } from 'src/app/app.model';
import { PaymethodSelectComponent } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { PasscardChangeComponent } from '../../passcard-change.component';

@Component({
  selector: 'passcard-change02',
  templateUrl: './passcard-change02.component.html'
})
export class PasscardChange02Component {
  api = inject(PasscardApiProxy);
  status: number;
  
  @ViewChild(PaymethodSelectComponent) payMethodSelect!: PaymethodSelectComponent;

  constructor(public app: AppModel,
    private _navi: NavigateService,
    private _wiz: PasscardChangeComponent) {
      this.status = this._wiz.status;
  }

  get IsComplete() {
    return this._wiz.service.complete;
  }

  get nextDisabled() {
    const payMethod = this.payMethodSelect?.model;
    return this.api.Disabled || !payMethod || payMethod.method == -1 || !!payMethod.error;
  }

  get dcParkingNo() {
    return this._wiz.passcardApply.dcParkingNo;
  }
  
  onBack() {
    this._navi.navigateByUrl(`/passcard/change/step/1`);
  }

  async onNext() {
    if (!await this.payMethodSelect.validate()) return;

    this._wiz.service.maxProcessed = Math.max(this._wiz.service.maxProcessed, 2);
    this._wiz.paymentType = this.payMethodSelect.model.method;
    this._wiz.service.saveState();
    this.payMethodSelect.saveModel();

    this._navi.navigateByUrl('/passcard/change/step/3');
  }
}
