import { Component } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService } from 'src/app/services';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { PaidDetail, PaymentContentName } from '../../pay/pay.model';
import { PayHistroyService } from './pay-histroy.service';
import { Location } from '@angular/common';

@Component({
  selector: 'pay-list',
  templateUrl: './pay-list.component.html'
})
export class PayListComponent {
  PCN = PaymentContentName;
  pageModel = {
    pages: 0,
    countPerPage: 20,
    page: 1,
  }

  paymentList: PaidDetail[] = [];
  conditionsPeriod: number;
  conditionsPeriods: {code: number, name: string}[] = [];

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private _paymentService: PayHistroyService,
    location: Location) {
    let nowYear = new Date().getFullYear();
    this.conditionsPeriods.push({ code: nowYear, name: '過去1年間' });
    for (let i = 1; i < 4; ++i) {
      this.conditionsPeriods.push({ code: nowYear - i, name: `${nowYear - i}年` });
    }

    const state = <any>location.getState();
    if (state?.conditionsPeriod) {
      this.pageModel.page = state.currentPage;
      nowYear = state.conditionsPeriod;
    }

    this.conditionsPeriod = nowYear;
    this.api.getPaymentList(this.pageModel, nowYear).then(x => this.paymentList = x);
  }

  onChangePeriod() {
    this.pageModel.page = 1;
    this.api.getPaymentList(this.pageModel, +this.conditionsPeriod).then(x => this.paymentList = x);
  }

  /**
   *　お支払い詳細へ
   * @param paidDetail
   */
  async onToDetail(paidDetail: PaidDetail) {
    const paymentDetail = await this.api.getPaymentDetails(paidDetail.processDt, paidDetail.processMs);
    paymentDetail.processDt = paidDetail.processDt;
    paymentDetail.processMs = paidDetail.processMs;
    this._paymentService.conditionsPeriod = this.conditionsPeriod;
    this._paymentService.currentPage = this.pageModel.page;
    this._navi.navigate(`/payment/detail`, false, paymentDetail);
  }

  async onPage(page: number) {
    const pageModel = { ...this.pageModel, page };
    this.paymentList = await this.api.getPaymentList(pageModel, +this.conditionsPeriod);
    this.pageModel.page = page;
  }
}
