import { Component, Input, inject } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { ApplyStatus, formatDate, formatPeriod } from '../../passcard.model';
import { AppliedDetailDispMeta, AppliedDetailItem, AppliedDetailItemType, PasscardApplyDetail } from '../../models/apply-details';
import { PaymentContentName } from '../../pay/pay.model';

const invalidApplies = Object.freeze({
  "申請取消": '申請取消', "期限切れ": '期限切れ', "完了": '完了'
});

@Component({
  selector: 'apply-details',
  templateUrl: './apply-details.component.html',
  providers: [ CurrencyPipe ]
})
export class ApplyDetailsComponent {
  PT = Object.freeze({
    "0": '定期更新機',
    "2": 'PayPay',
    "3": 'コンビニ'
  });
  private readonly _currencyPipe = inject(CurrencyPipe);
  private _detail: PasscardApplyDetail;
  private _applyContentCode = 1;

  @Input() passcardApplyResult: string;
  @Input() parkingAreaName: string;
  @Input() set detail(val: PasscardApplyDetail) {
    this._detail = val;
    this.getApplyState();
    this.mapToDisplay(this.detail);
  }

  get detail() {
    return this._detail;
  }

  passcardNameCaption = '定期の種類';
  applyState: string;
  /**
   * 申請内容
   * status:
   *  1～29→1:新規定期契約
   *  31～39→2:定期更新
   *  41～49→3:定期紛失
   *  51～59→証明書等再提出
   */
  applyContent: string;
  appliedDetails: AppliedDetailItem[];

  private mapToDisplay(detail: PasscardApplyDetail) {
    const keys = Object.keys(AppliedDetailDispMeta);
    this.appliedDetails = keys
      .filter(x => !!AppliedDetailDispMeta[x].belong.find((y: number) => y == this._applyContentCode))
      .filter(x => {
        if ((this.detail.status == 1 || this.detail.status == 2) && x != 'receiptNo') return false;  //1:順番待ち 2:順番になりました

        const val = detail[x]
        if (val != undefined) return true;
        const to = AppliedDetailDispMeta[x];
        if (to != undefined && detail[x] != undefined) return true;
        return false;
    }
    ).map(x => this.getDisplayItem(detail, x));
}

  private getApplyState() {
    this.applyContent = '新規定期契約';
    const { status, denialReason } = this.detail;
    if (status >= 1 && status <= 29) this._applyContentCode = 1;
    else if (status >= 31 && status <= 39) this._applyContentCode = 2;  // 定期更新
    else if (status >= 41 && status <= 49) this._applyContentCode = 3;
    else if (status >= 51 && status <= 59) this._applyContentCode = 5;

    this.applyContent = PaymentContentName[this._applyContentCode];
    if (this._applyContentCode == 2 && this.detail.changedPasscardType != undefined) {
      this.passcardNameCaption = '現在の定期の種類';
    }

    this.applyState = (() => {
      if (invalidApplies[this.passcardApplyResult]) return invalidApplies[this.passcardApplyResult];
      const passcardPaymentType = this.detail.passcardPaymentType ?? 99;
      switch (status) {
        case ApplyStatus.WaitingFree: return '順番待ち';
        case ApplyStatus.WaitingReApply: return '申請待ち';
        case ApplyStatus.WaitingModify: return '差し戻し';
        case ApplyStatus.WaitingPay:
          return passcardPaymentType != 99 ? 'お支払い待ち' : '申請待ち';
        case ApplyStatus.WaitingPayForRefresh:
        case ApplyStatus.WaitingPayForLost:
          return passcardPaymentType != 99 ? 'お支払い待ち' : '申請中';
        case ApplyStatus.WaitingICRegist:
        case ApplyStatus.WaitingICRegistForLost:  return 'ICカード登録待ち';
        default: return '申請中';
      }
    })();
  }

  private getDisplayItem(detail: PasscardApplyDetail, key: string): AppliedDetailItem {
    const val = detail[key];
    const meta: AppliedDetailItem = AppliedDetailDispMeta[key];
    let caption = meta.caption;
    if (this._applyContentCode == 2) {  // 定期更新
      if (key == 'passcardMonth') caption = '更新月数';
      else if (key == 'passcardValidFrom') caption = '更新後の定期期間';
      else if (key == 'passcardUpdateFrom') caption = '更新後の更新可能期間';
    }
    
    let dispStr = meta.type == AppliedDetailItemType.String ? val : '';
    let dispStr2 = '';
    switch (meta.type) {
      case AppliedDetailItemType.PasscardMonth:
        dispStr = `${val}ヵ月(${this._currencyPipe.transform(detail.passcardAmount, 'JPY')})`;
        break;
      case AppliedDetailItemType.ValidFrom:
        if (val) { // 月末方式
          dispStr = `${['選択なし', '当月から', '翌月から'][detail.selectTargetMonth]}`;
        } else {  // JR
          dispStr = formatDate(detail.passcardValidFrom);
        }
        break;
      case AppliedDetailItemType.Date:
        if (meta.to) {
          dispStr = formatPeriod([val, detail[meta.to]])
        } else {
          dispStr = formatDate(val);
        }
        break;
      case AppliedDetailItemType.DateTime:
        const datetime = new Date(val);
        const hour = `${datetime.getHours()}`.padStart(2, '0');
        const minutes = `${datetime.getMinutes()}`.padStart(2, '0');
        dispStr = `${formatDate(val)} ${hour}:${minutes}`;
        break;
      case AppliedDetailItemType.BuyICCard:
        dispStr = val == 0 ? 'お手持ちの交通系ICカードを定期券として利用する': '駐輪場専用のICカードを購入して定期券として利用する';
        const addtional = this._applyContentCode == 3 ? detail.reIssueCommission : detail.newIssueCommission;
        const issueFee = val == 0 ? 0 : detail.buyICCardCommission;
        dispStr2 = `(発行手数料：${this._currencyPipe.transform(issueFee + addtional, 'JPY')})`;
        break;
      default:
        dispStr = val;
    }

    return {
      caption,
      dispStr,
      dispStr2
    }
  }

  getStatusClass(status: string) {
    switch (status) {
      case '完了': return 'status-idle';
      case '申請中': return 'status-busy';
      case '申請取消': return 'status-stop';
      case '期限切れ': return 'status-stop';
    }
  }
}
