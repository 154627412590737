import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, LOCALE_ID, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'i-date-select',
  templateUrl: './i-dateselect.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => IDateSelectComponent),
    multi: true
}]
})
export class IDateSelectComponent implements ControlValueAccessor {
  today: Date = new Date(new Date().getTime());
  _disabled = false;
  _value: any;
  get value() {
    return this._value;
  }

  set value(val: any) {
    this._onChange(val);
  }

  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() message1: string = '';
  @ViewChild('selector') datepicker: ElementRef<HTMLInputElement>;

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    private readonly _changeDetectorRef: ChangeDetectorRef
  ) {}

  writeValue(value: any): void {
    if (value !== this.value) {
      this.value = value; // this is my internal value model
    }
  }
  _onChange: (value: any) => void = () => {};
  registerOnChange(fn: (value: any) => void) {
      this._onChange = fn;
  }
  
  _onTouched: () => any = () => {};
  registerOnTouched(fn: any) {
      this._onTouched = fn;
  }
  
  // if you implement disabled functionality you need this function
  setDisabledState(value: boolean): void {
      this._disabled = value; // this is my internal disabled model
  }

  
  onDateChanged(date: Date) {
    this.today = date;
    this._onChange(formatDate(date, 'yyyy/MM/dd', this._locale));
    this._changeDetectorRef.markForCheck();
  }

  onPickDate() {
    this.datepicker.nativeElement.showPicker();
  }
}
