import { Component } from '@angular/core';
import { PasscardApiProxy } from './passcard.api.proxy';

@Component({
  selector: 'app-passcard',
  templateUrl: './passcard.component.html',
})
export class PassCardComponent {
  constructor(private _api: PasscardApiProxy) {
  }
  get error() {
    return this._api.error;
  }
}
