<div class="card" (click)="onCardClick()" [ngClass]="{ clickable: clickable }">
  <div>
    <div>
      <div>
        <i class="bike"></i>
      </div>
      <div>
        <i class="bike"></i>
      </div>
    </div>
    <div>
      <div>
        <div>
          <div class="parking-status status" [ngClass]="statusClass">{{statusText}}</div>
        </div>
        <div>
          <div class="logo">&nbsp;</div>
        </div>
      </div>
      <div>
        <div class="card-normal">
          <div>
            <p>{{reservation.parkingName}}</p>
            <p>{{reservation.name}}</p>
          </div>
          <div>
            <span *ngIf="PasscardAvailable; else other">{{TermYear}}年<span
                class="font-xlarge"><b>{{TermMMDD}}</b></span><b>({{TermWeekDay}})</b>まで</span>
            <ng-template #other>
              <ng-container *ngIf="reservation.status == ApplyStatus.WaitingFree; else normal">
                <span class="font-xlarge">順番待ちです</span>
              </ng-container>
              <ng-template #normal>
                <span class="font-xlarge">{{tips}}</span>
              </ng-template>
            </ng-template>
          </div>
          <div>
            <ng-container *ngIf="PasscardAvailable">
              <div *ngIf="status2" [ngSwitch]="status2">
                <ng-template [ngSwitchCase]="1">
                  <div class="parking-status status2 status-idle">
                    更新<br>受付中
                  </div>
                </ng-template>
                <ng-template [ngSwitchCase]="2">
                  <div class="parking-status status2 status-idle">
                    更新<br>受付中
                  </div>
                </ng-template>
                <ng-template [ngSwitchCase]="3">
                  <div class="parking-status status2 status-disabled">
                    更新<br>期間外
                  </div>
                </ng-template>
                <ng-template [ngSwitchCase]="4">
                  <div class="parking-status status2 status-disabled2">
                    更新<br>不可
                  </div>
                </ng-template>
              </div>
              <div>
                <div>
                  <p class="font-small">No.</p>
                  <p>{{contract.passcardNo}}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>