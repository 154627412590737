import { Component, Input, OnInit } from '@angular/core';
import { PeriodKind, formatPeriod, ApplyStatus } from '../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { ApiService } from 'src/app/services';
import { PasscardApply } from '../../models/apply-info';
import { Passcard } from '../../models/passcard';
import { PaymentType } from '../../pay/pay.model';
import { TipKind } from '../../common/status-tip/status-tip.component';
import { Router } from '@angular/router';

@Component({
  selector: 'apply-info',
  templateUrl: './apply-info.component.html'
})
export class ApplyInfoComponent implements OnInit{
  private _passcardInfo: Passcard;
  PERIODKIND = PeriodKind;
  ApplyStatus = ApplyStatus;
  confirmedClass: string | null = null;
  viewProc = 0;

  @Input() reservation: PasscardApply | null = null;
  @Input() tipKind = -1;

  // お知らせ表示フラグ
  noticeInfoShow = false;

  constructor(
    public app: AppModel,
    public api: ApiService,
    private router: Router) {
  }

  ngOnInit(): void {
    // お知らせを表示するか判定
    if (this.router.url.startsWith('/passcard/applyInfo')) {
      this.noticeInfoShow = true;
    } else {
      this.noticeInfoShow = false;
    }
  }

  get passcardInfo() {
    if (this.reservation && this._passcardInfo?.passcardType != this.reservation.passcardType) {
      this._passcardInfo = {
        passcardType: this.reservation.passcardType,
        name: this.reservation.name,
        waitNum: this.reservation.waitNum,
        no: this.reservation.no,
        status: this.reservation.requestStatus,
        vacantWaitOverFlg: this.reservation.vacantWaitOverFlg,
        unitPrice: this.reservation.unitPrice,
        annotation: this.reservation.annotation
      }
    }

    return this._passcardInfo ?? this.reservation;
  }

  get periodVisible() {
    const { status, passcardPaymentType } = this.reservation;
    if (this.tipKind == TipKind.Applying || (status == ApplyStatus.WaitingPay && passcardPaymentType == PaymentType.UnSelect)) return false;

    return status != ApplyStatus.WaitingModify && status > 2;
  }

  get ValidPeriod() {
    const { passcardValidFrom, passcardValidTo } = this.reservation!;
    return formatPeriod([passcardValidFrom, passcardValidTo]);
  }

  get usingStart() {
    if (this.reservation.applyUseStartMethod == 1) {
      return this.ValidPeriod.split('～')[0];
    }

    return PeriodKind[this.reservation.selectTargetMonth] ?? '選択なし'
  }

  get UpdatablePeriod() {
    const { passcardUpdateFrom, passcardUpdateTo } = this.reservation!;
    return formatPeriod([passcardUpdateFrom, passcardUpdateTo]);
  }

  /**
   * お知らせ情報取得
   */
  get noticeInfo(){
    return this.reservation?.noticeInfoList;
  }
}