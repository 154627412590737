<div class="tableCtn">
  <p-single label="シール再発行手数料" value="{{Charge | currency:'JPY'}}" />
</div>
<br>

<div class="card-container">
  <card *ngIf="contract" [reservation]="contract"></card>
</div>
<br>

<div *ngIf="!IsComplete" class="buttonBox">
  <loading-button class="six" label="次へ"
    (apply)="onNext()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [lClass]="'button-box-loading'"
    [class]="'cmn-btn six'" />
</div>