import { Component, Input, OnInit } from '@angular/core';
import { PeriodKind, ApplyStatus } from '../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { ApiService } from 'src/app/services';
import { PasscardApply } from '../../models/apply-info';
import { CurrencyPipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'apply-info',
  templateUrl: './apply-info.component.html',
  providers: [ CurrencyPipe ]
})
export class ApplyInfoComponent implements OnInit {
  PERIODKIND = PeriodKind;
  ApplyStatus = ApplyStatus;
  confirmedClass: string | null = null;
  viewProc = 0;

  @Input() reservation: PasscardApply | null = null;
  @Input() tipKind = -1;

  // お知らせ表示フラグ
  noticeInfoShow = false;

  constructor(
    public app: AppModel,
    public api: ApiService,
    private router: Router) {
  }

  ngOnInit(): void {
    // お知らせを表示するか判定
    if (this.router.url.startsWith('/passcard/applyInfo')) {
      this.noticeInfoShow = true;
    } else {
      this.noticeInfoShow = false;
    }
  }
  /**
 * お知らせ情報取得
 */
  get noticeInfo(){
    return this.reservation?.noticeInfoList;
  }
}




