<block title="お支払い方法選択">
  <div class="passcard-list">
    <div *ngIf="usableFlags[0]" class="passcard passcard-normal full-width"
      [class.passcard-selected]="model.method == PayMethod.Terminal"
      (click)="onMethodSelected(0)">
      <div class="input">
        <div class="radio-wrapper"><input type="radio" name="model.method" [checked]="model.method == PayMethod.Terminal" value="0"
            id="method1" /></div>
        <div><label class="radio-caption" for="method1">定期更新機で支払う</label></div>
      </div>
      <div class="pay-machine" *ngIf="model.method == 0">
        <ng-container *ngIf="status == 6; else notRefresh">
          <p class="pay-machine-refresh">Webでのお手続きは不要です。定期券をご持参の上、駐車場にてお支払いください。</p>
        </ng-container>
        <ng-template #notRefresh>
          <p>現地の定期更新機にて直接お支払いをお願いします。</p>
          <br>
          <div class="tableCtn">
            <p-single label="お支払い期限" [value]="terminalPaymentTerm" />
          </div>
       </ng-template>
      </div>
    </div>
    <div *ngIf="usableFlags[1]" class="passcard passcard-normal full-width"
      [class.selected-border]="model.method == PayMethod.CreditCard"
      (click)="onMethodSelected(1)">
      <div class="input">
        <div class="radio-wrapper">
          <input type="radio" name="model.method" [checked]="model.method == PayMethod.CreditCard" (change)="onCreditSelected()" value="1" id="method2" /></div>
        <div><label class="radio-caption" for="method2">クレジットカードで支払う</label></div>
      </div>
      <pay-cc *ngIf="model.method == 1" [status]="status" [model]="model" #pcc></pay-cc>
    </div>
    <div class="passcard passcard-normal full-width" *ngIf="usableFlags[2]"
      [class.passcard-selected]="model.method == PayMethod.PayPay"
      (click)="onMethodSelected(2)">
      <div class="input">
        <div class="radio-wrapper"><input type="radio" name="model.method" [checked]="model.method == PayMethod.PayPay" value="2"
            id="method3" /></div>
        <div><label class="radio-caption" for="method3">PayPayで支払う</label></div>
      </div>
      <div class="paypay" *ngIf="model.method == 2">
        <div class="logo-paypay"><i></i></div>
        <div class="tableCtn">
          <p-single label="お支払い期限" [value]="paymentTerm" />
        </div>
        <br>
        <p>※シークレットブラウザ、プライベートモード等でのご利用はできません。</p>
        <p>　通常のブラウザモードで決済をお願いします。</p>
      </div>
    </div>
    <div class="passcard passcard-normal full-width"
      *ngIf="usableFlags[3]"
      [class.selected-border]="model.method == PayMethod.Cvs"
      (click)="onMethodSelected(3)">
      <div class="input">
        <div class="radio-wrapper"><input type="radio" name="model.method" [checked]="model.method == PayMethod.Cvs" value="2"
            id="method4" /></div>
        <div><label class="radio-caption" for="method4">コンビニで支払う</label></div>
      </div>
      <pay-cvs *ngIf="model.method == 3" [cvsInfo]="model.cvsPay.cvsInfo" [model]="model"></pay-cvs>
    </div>
  </div>
</block>