import { Component, Input } from '@angular/core';
import { ApiService, focusNext, ValidateService } from '../../services';
import { HttpClient } from '@angular/common/http';
import { REGION, YUBINBANGO_DATA_URL, YubinbangoData } from 'src/app/YubinbangoJsonpClientBackend';

//
interface AddressReq {
  postCode: string;
}

type YubinbangoDataResponse = Record<string, YubinbangoData>;

//
interface Res {
  resultCode: string;
  address1: string;
  address2: string;
}

@Component({
  selector: 'i-ws-address',
  templateUrl: './i-ws-address.component.html',
})
export class IWsAddressComponent {
  private _wsAddressDatas: YubinbangoDataResponse | null = null;
  @Input() model: { byCompanyName: string, string, byCompanyAddress2: string, wsPostcode: string[], wsAddress1: string, wsAddress2: string };
  @Input() spanRequire: boolean;
  @Input() messages: string[];
  @Input() messages1: string[];
  @Input() messages2: string[];
  @Input() label: string;
  @Input() label1: string;
  @Input() label2: string;
  @Input() label3: string;

  message: string[];
  message1: string[];
  message2: string[];
  message3: string[];
  message4: string[];

  //
  constructor(
    public api: ApiService,
    public valid: ValidateService,
    private _http: HttpClient
  ) { }

  ngOnInit() {
    //this.model.postcode = ['',''];
  }

  //
  validation = () => {
    const msg = this.valid;
    const postcode1 = this.model.wsPostcode[0];
    const postcode2 = this.model.wsPostcode[1];
    const address1 = this.model.wsAddress1;
    const address2 = this.model.wsAddress2;

    this.message = [];
    this.message1 = [];
    this.message2 = [];
    this.message3 = [];
    this.message4 = [];
    const fn = msg.validRequired;
    if (this.model.byCompanyName === '2') {
      // required ?
      const fn = msg.validRequired;
      this.message1 = fn(postcode1, this.label1);
      this.message2 = fn(postcode2, this.label1);
      this.message = this.valid.merge(this.message1, this.message2);
      this.message3 = fn(address1, this.label2);
    }
    if (this.model.byCompanyAddress2 === '2') {
      this.message4 = fn(address2, this.label3);
    }
    msg.pushPostcode(this.message, postcode1, postcode2, this.label);
    msg.pushAddress(this.message3, address1, this.label2);
    msg.pushProhibit(this.message3, address1, this.label2);
    msg.pushAddress(this.message4, address2, this.label3);
    msg.pushProhibit(this.message4, address2, this.label3);
    return !this.message.length && !this.message3.length && !this.message4.length;
  }

  //
  validationIsInput = (isInput: boolean) => {
    if (isInput) {
      this.model.byCompanyName = '2';
      this.model.byCompanyAddress2 = '2';
    } else {
      this.model.byCompanyName = '1';
      this.model.byCompanyAddress2 = '1';
    }

    const msg = this.valid;
    const postcode1 = this.model.wsPostcode[0];
    const postcode2 = this.model.wsPostcode[1];
    const address1 = this.model.wsAddress1;
    const address2 = this.model.wsAddress2;

    this.message = [];
    this.message1 = [];
    this.message2 = [];
    this.message3 = [];
    this.message4 = [];
    const fn = msg.validRequired;
    if (this.model.byCompanyName === '2') {
      // required ?
      const fn = msg.validRequired;
      this.message1 = fn(postcode1, this.label1);
      this.message2 = fn(postcode2, this.label1);
      this.message = this.valid.merge(this.message1, this.message2);
      this.message3 = fn(address1, this.label2);
    }
    if (this.model.byCompanyAddress2 === '2' || isInput) {
      this.message4 = fn(address2, this.label3);
    }
    msg.pushPostcode(this.message, postcode1, postcode2, this.label);
    msg.pushAddress(this.message3, address1, this.label2);
    msg.pushProhibit(this.message3, address1, this.label2);
    msg.pushAddress(this.message4, address2, this.label3);
    msg.pushProhibit(this.message4, address2, this.label3);
    return !this.message.length && !this.message3.length && !this.message4.length;
  }

  //onChange = () => this.messages = null;
  onChange = (inputkb?: number) => {
    this.messages = null;
    this.messages1 = null;
    this.messages2 = null;
    if (inputkb === 0 || inputkb === 1) {
      this.model.wsAddress1 = "";
      this.model.wsAddress2 = "";
      if (this.model.wsPostcode[0].length == 3) {
        const cachedZip0 = Object.keys(this._wsAddressDatas ?? {})[0];
        if (!cachedZip0?.startsWith(this.model.wsPostcode[0])) {
          const url = `${YUBINBANGO_DATA_URL}/${this.model.wsPostcode[0]}.js`;
          this._http.jsonp<YubinbangoDataResponse>(url, '$yubin').subscribe(data => {
            this._wsAddressDatas = data;
            this.afterPut();
          });
        }
        else {
          this.afterPut();
        }
      }
    }
  }

  private afterPut = () => {
    if (this._wsAddressDatas && this.model.wsPostcode[1].length === 4) {
      const array = this._wsAddressDatas[this.model.wsPostcode.join('')];
      if (array) {
        const [regionId, locality, street, extended] = array;
        if (regionId && REGION[regionId]) {
          this.model.wsAddress1 = `${REGION[regionId]}${locality ?? ''}`;
          this.model.wsAddress2 = `${street ?? ''}${extended ?? ''}`;
        }
        return;
      }
    }
  }

  //
  onKeyup = (evt: KeyboardEvent) => {
    if ('0' <= evt.key && evt.key <= '9') {
      const elm = <HTMLInputElement>evt.target;
      if (+elm.maxLength - 1 < elm.value.length)
        focusNext(elm.nextElementSibling);
    }
  }

  //
  isInput = () => {
    let postcode1 = this.model.wsPostcode[0];
    let postcode2 = this.model.wsPostcode[1];
    if (postcode1 === undefined) {
      postcode1 = '';
    }
    if (postcode2 === undefined) {
      postcode2 = '';
    }
    let address1 = this.model.wsAddress1;
    let address2 = this.model.wsAddress2;
    const len1 = String(postcode1).length;
    const len2 = String(postcode2).length;
    const len3 = String(address1).length;
    const len4 = String(address2).length;
    if (len1 || len2 || len3 || len4) {
      return true;
    } else {
      return false;
    }
  }

}