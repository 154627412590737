import { Component, Input } from '@angular/core';
import { PayRequestInfo } from '../pay.model';

@Component({
  selector: 'pay-request',
  templateUrl: './pay-request.component.html'
})
export class PayRequestComponent {
  @Input() model!: PayRequestInfo;
}
