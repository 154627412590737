import { Component, Input } from '@angular/core';
import { KeyService, ValidateService } from '../../services';

//
@Component({
  selector: 'i-ws-phone',
  templateUrl: './i-ws-phone.component.html',
})
export class IWsPhoneComponent {
  @Input() model: { byCompanyName: string, wsPhone: string };
  @Input() spanRequire: boolean;
  @Input() messages: string[];
  @Input() label: string;

  message: string[];

  constructor(
    public key: KeyService,
    public valid: ValidateService
  ) { }

  //
  validation = () => {
    const msg = this.valid;
    this.message = [];
    if (this.model.byCompanyName === '2') {
      const fn = msg.validRequired;
      this.message = fn(this.model.wsPhone, this.label);
    }
    msg.pushPhone(this.message, this.model.wsPhone, this.label);

    return this.message && !this.message.length;
  }

  //
  validationIsInput = (isInput: boolean) => {
    if (isInput) {
      this.model.byCompanyName = '2';
    } else {
      this.model.byCompanyName = '1';
    }
    const msg = this.valid;
    this.message = [];
    if (this.model.byCompanyName === '2') {
      const fn = msg.validRequired;
      this.message = fn(this.model.wsPhone, this.label);
    }
    msg.pushPhone(this.message, this.model.wsPhone, this.label);

    return this.message && !this.message.length;
  }
  
  onChange = () => this.messages = null;

  //
  isInput = () => {
    const wsPhone = this.model.wsPhone;
    const len = String(wsPhone).length;
    if (len) {
      return true;
    } else {
      return false;
    }
  }

}