<div class="tableCtn passcard-pay">
    <div class="tableLine">
      <div class="tableCell_l">内訳</div>
      <div class="tableCell_r pay-items">
        <ng-container *ngFor="let payItem of paidDetail.paymentFeeDetails">
          <div *ngIf="payItem.Fee == 0 || payItem.Fee">
            <div>{{payItem.contents}}</div>
            <div>{{payItem.Fee | currency:'JPY'}}</div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="tableLine">
      <div class="tableCell_l">合計</div>
      <div class="tableCell_r">
        <div class="sum">
          <div><b>{{totalLbl}}</b></div>
          <div><b><span class="total-symbol">¥</span><span class="total-value">{{PaymentAmount}}</span></b></div>
        </div>
      </div>
    </div>
  </div>