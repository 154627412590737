import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'popup-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent implements OnDestroy {
  private _scrollbarWidth: number = 0;
  private _callbackClose: () => void = () => {};
  showPop = false;

  onTouchMove(ev: Event) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
  }

  showModal(): Promise<void> {
    this._scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.overflow = 'hidden';
    if (this._scrollbarWidth > 0) {
      document.body.style.touchAction = 'none';
      document.body.style.paddingRight = `${this._scrollbarWidth}px`;
    }

    window.addEventListener('touchmove', this.onTouchMove, { passive: false });
    window.addEventListener('wheel', this.onTouchMove, { passive: false });

    this.showPop = true;

    return new Promise(resolve => {
      this._callbackClose = () => resolve();
    });
  }

  nop(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }
  closeModal() {
    document.body.style.overflow = '';
    if (this._scrollbarWidth > 0) {
      document.body.style.touchAction = '';
      document.body.style.paddingRight = `0`;
    }

    window.removeEventListener('touchmove', this.onTouchMove)
    window.removeEventListener('wheel', this.onTouchMove);

    this.showPop = false;
    this._callbackClose();
  }

  ngOnDestroy(): void {
    if (this.showPop) {
      this.closeModal();
    }
  }
}
