import { Injectable, inject } from "@angular/core";
import { StorageService } from "src/app/services";
import { ApiReturn, ApplyStatus } from "../passcard.model";

@Injectable()
export class ApplyListModel {
    private readonly _storage = inject(StorageService);
    conditionsPeriod: number;
    currentPage = 1;
    constructor() {
        this._storage.load('ApplyListModel', this);
        this.conditionsPeriod ??= new Date().getFullYear();
    }

    saveState() {
        this._storage.save('ApplyListModel', this)
    }
}

export interface PasscardApplyList extends ApiReturn {
    totalNumber: number;
    passcardApplyList: ApplyListItem[];
}

export interface ApplyListItem {
    /**
     * 定期申請管理番号(int)
     */
    passcardApplyNo: number;

    /**
     * 処理日時(long)
     */
    processDt: number;

    /**
     * 処理日時のミリ秒(int)
     */
    processMs: number;

    /**
     * 申請日時(long)
     */
    passcardApplyDate: number;

    /**
     * 申請処理結果(String)
     */
    passcardApplyResult: string;

    /**
     * 申請内容コード(int)
     */
    passcardApplyContentsCode: number;

    /**
     * 駐車エリア名称(String)
     */
    parkingAreaName: string;

    /**
     * 支払い料金(int)
     */
    paymentFee: number;
}


export const AppliedState = Object.freeze({
    Applying: 0, "0": { content: '申請中' },
    Canceled: 1, "1": { content: '申請取消' },
    Expired: 2, "2": { content: '期限切れ' },
    Completed: 4, "4": { content: '完了' },
});

export function getAppliedState(applyInfo: any): number {
    const { status } = applyInfo;
    switch (status) {
        case ApplyStatus.WaitingReApply:
        case ApplyStatus.WaitingModify:
        case ApplyStatus.WaitingPay:
        case ApplyStatus.WaitingPayForRefresh:
            return AppliedState.Applying;
    }
}