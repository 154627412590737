import { Component, inject } from '@angular/core';
import { PeriodKind, PasscardStatus, formatPeriod, addMonth, addDay, calcUpdatablePeriod, ReceptionMode, getPeridStart, getStorageKey } from '../../../passcard.model';
import { Observable } from 'rxjs';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { Passcard, PasscardApplyApi } from 'src/app/passcard/models/passcard';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { ApplyComponent } from '../../apply.component';
import { Location } from '@angular/common';

@Component({
  selector: 'apply01',
  templateUrl: './apply01.component.html'
})
export class Apply01Component {
  private _storage = inject(StorageService);
  private _thisStorageKey = '';
  applyParams: PasscardApplyApi;
  PasscardStatus = PasscardStatus;
  PERIODKIND = PeriodKind;
  passcardApplyPossible = 0;

  applyNewIssueCommission = 0; // 新規発行手数料(int),
  issueFee: number = 0;
  fixedPeriods$!: Observable<string[]>;
  fixedPeriod: string = '';
  updatablePeriod = '';
  passcards: Passcard[];
  passcardValidFrom = '';

  model: {
    passcard?: Passcard,
    passcardMonth: number,
    selectTargetMonth: number,
    buyICCard: number,
    passcardValidFrom?: number,
    passcardValidTo?: number,
    passcardUpdateFrom?: number,
    passcardUpdateTo?: number,
    passcardCancelDocumentUrl?: string;
  } = {
    passcardMonth: 0,
    buyICCard: -1,   // -1:選択されない 0:購入しない、1:購入する
    selectTargetMonth: 0,           
  };

  private readonly _route: ActivatedRoute = inject(ActivatedRoute);

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _wiz: ApplyComponent,
    private _navi: NavigateService,
    l: Location
  ) {
    this._wiz.processing = 0;
    this._wiz.saveState;
    this.restoreModel();
  }

  get passcardSelected() {
    return this.model.passcard;
  }

  get IsComplete() {
    return this._wiz.complete;
  }

  get showPeriod() {
    const status = this._wiz.status;
    return !(status == 2 || status == 7 || status == 9) && this.passcardSelected;
  }

  get cardPurchaseChoicesBuy() {
    return this.applyParams.cardPurchaseChoicesBuy;
  }

  get cardPurchaseChoicesMyCardUse() {
    return this.applyParams.cardPurchaseChoicesMyCardUse;
  }

  get applyUseStartMethod() {
    return this.applyParams.applyUseStartMethod;
  }

  get applySelectMonthType() {
    return this.applyParams.applySelectMonthType;
  }
  
  get isReApply() {
    return !!this._wiz.paramsReApply;
  }

  get NextDisabled() {
    const status = this._wiz.status;
    return this.api.error
      || this.api.Disabled
      || this.passcardApplyPossible == 0
      || !this.passcardSelected
      || ((status == 1 || status == 3 || status == 8)
        && (this.model.passcardMonth == 0
          || this.model.selectTargetMonth < 1
          || ((this.cardPurchaseChoicesBuy || this.cardPurchaseChoicesMyCardUse) && this.model.buyICCard == -1)
        ));
  }

  onPasscardChange(passcard: Passcard) {
    if (!passcard || (passcard.status == 2 && passcard.vacantWaitOverFlg == 1)) return;

    this._wiz.passcard = passcard;
    this.model.passcard = passcard;
    this.model.passcardMonth = 0;
    this.model.selectTargetMonth = 0;
    this.model.buyICCard = -1;
    this.fixedPeriod = '';
    this.updatablePeriod = '';
  }

  onPeriodChange(periodKind: number) {
    this.model.selectTargetMonth = periodKind;
    this.calcPeriod();
  }

  onSetPasscardMonth(passcardMonth: number) {
    if (this.model.passcardMonth != passcardMonth) {
      this.model.passcardMonth = passcardMonth;
      this.calcPeriod();
    }
  }

  async onNext() {   
    this.saveModel();
    const status = this._wiz.status;
    const nextArgs: any = { status } 
    if (this.isReApply) {
      Object.assign(nextArgs, { dcParkingNo: this._wiz.paramsReApply.dcParkingNo })
    }

    if (this.isReApply || (this.passcardSelected.receptionMode == 1
        && this.passcardSelected.status == PasscardStatus.Free)) {
      let payCommission = this.model.passcard
        .paymentDivisionList.find(x => x.monthNum == this.model.passcardMonth).unitPrice;
      if (this.model.buyICCard == 1) payCommission += this.applyParams.applyCardIssueCommission;
      const { paymentTerm, terminalPaymentTerm } = await this.api.getPaymentTerm(status, this.model.selectTargetMonth);
      const {
        paymentMethodPaidMachineQR,
        paymentMethodCreditCard,
        paymentMethodPayPay,
        paymentMethodConvenienceStore,
      } = this.applyParams;
      this._storage.save('payment-params', {
        paymentTerm, terminalPaymentTerm,
        paymentMethodPaidMachineQR, paymentMethodCreditCard, paymentMethodPayPay, paymentMethodConvenienceStore,
        commission: payCommission
      });

      this._wiz.passcard = this.passcardSelected; 
      this._wiz.saveState();

      this._navi.navigate('/passcard/new/step/2', false, nextArgs);
    }
    else {
      this._navi.navigate('/passcard/new/step/3', false, nextArgs);
    }
  }

  saveModel() {
    const { passcardValidFrom, passcardValidTo, passcardUpdateFrom, passcardUpdateTo } = this.model;
    const { parkingName, parkingAddress } = this._wiz;
    this._wiz.passcard = this.passcardSelected;
    this._storage.save(this._thisStorageKey, {
      passcard: this.model.passcard,
      passcardMonth: this.model.passcardMonth,
      selectTargetMonth: this.model.selectTargetMonth,
      buyICCard: this.model.buyICCard,
      applyNewIssueCommission: this.applyNewIssueCommission,
      applyCardIssueCommission: this.model.buyICCard == 1 ? this.applyParams.applyCardIssueCommission : 0,
      passcardValidFrom,
      passcardValidTo,
      passcardUpdateFrom,
      passcardUpdateTo,
      parkingName: parkingName.getValue(),
      parkingAddress,
      passcardCancelDocumentUrl: this.applyParams.passcardCancelDocumentUrl
    });
  }

  restoreModel() {
    this._route.data.pipe(map(({ data }) => data)).subscribe(x => {
      this.applyParams = x;
      this.passcardApplyPossible = x.passcardApplyPossible;
      this.passcards = x.passcardTypeList;
      this.applyNewIssueCommission  = x.applyNewIssueCommission??0;
      this.issueFee = x.applyCardIssueCommission + this.applyNewIssueCommission;

      if (!this.isReApply) {
        const { parkingName, address1, address2 } = x;
        setTimeout(() => this._wiz.parkingName.next(parkingName));
        this._wiz.parkingAddress = `${address1 ?? ''}${address2 ?? ''}`;
      }

      const passcard = this.passcards[0];
      const status = !this._wiz.paramsReApply ? 1
        : (this._wiz.paramsReApply.applyStatus == 8 ? 8 : passcard.receptionMode == ReceptionMode.Auto ? 3 : 9);
      this._thisStorageKey = getStorageKey('passcard-apply-1', status, this._wiz.paramsReApply?.dcParkingNo ?? null);
      const cache = this._storage.load(this._thisStorageKey, null);
      if (cache) {
        this.model.passcard = cache.passcard;
        this.model.passcardMonth = cache.passcardMonth;
        this.model.buyICCard = cache.buyICCard;
        this.model.selectTargetMonth = cache.selectTargetMonth;
        this._wiz.passcard = cache.passcard;
      }
      else {
        if (this._wiz.paramsReApply) {
          this.model.passcard = passcard;
          this._wiz.passcard = passcard;
        }  
      }

      this.calcPeriod();
    });
  }

  private calcPeriod() {
    const { selectTargetMonth, passcardMonth } = this.model;
    if (selectTargetMonth < 1 || passcardMonth == 0) return;

    this.model.passcardValidFrom = getPeridStart(this.applyParams.systemDate)[selectTargetMonth - 1];
    this.model.passcardValidTo = addDay(new Date(addMonth(new Date(this.model.passcardValidFrom), passcardMonth)), -1);
    this.fixedPeriod = formatPeriod([this.model.passcardValidFrom, this.model.passcardValidTo]);
    const { passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay } = this.applyParams;
    const updatablePeiod = calcUpdatablePeriod(new Date(this.model.passcardValidTo), passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay);
    this.model.passcardUpdateFrom = updatablePeiod[0]
    this.model.passcardUpdateTo = updatablePeiod[1];
    this.updatablePeriod = formatPeriod(updatablePeiod);
  }
}
