<block title="ご請求情報">
  <div class="tableCtn">
    <p-single label="ご請求内容" [value]="model.paymentContentsName" />
    <p-single label="駐車場" [value]="model.parkingAreaName" />
    <p-single label="受付No." [value]="model.receptionNo" />
  </div>
</block>
<br>
<block title="ご請求明細">
  <p-pay-fees [paidDetail]="model.payDetail" ></p-pay-fees>
</block>