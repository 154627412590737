import { Component, inject } from '@angular/core';
import { PasscardStatus, ReceptionMode, formatPeriod, getStorageKey } from '../../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { PayFeeDetail } from 'src/app/passcard/common/p-pay-fees/p-pay-fees.component';
import { PaymentType } from 'src/app/passcard/pay/pay.model';
import { PasscardChangeComponent } from '../../passcard-change.component';
import { getApplyPaymentItems } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';
import { ParkingInfo } from 'src/app/passcard/models/apply-info';

@Component({
  selector: 'passcard-change03',
  templateUrl: './passcard-change03.component.html'
})
export class PasscardChange03Component {
  private _storage = inject(StorageService);
  private _cach02: any;
  private _cach03: any;

  TS = PasscardStatus;

  parking: ParkingInfo;
  
  /**
   * 定期代
   */
  passcardFee: number;

  fixedPeriod: string = '';
  updatablePeriod = '';
  paidDetail: PayFeeDetail;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    public wiz: PasscardChangeComponent,
    private _navi: NavigateService,
    ) {
    
    this.wiz.service.processing = 2;
    this.wiz.service.saveState();
    const { parkingName, parkingAddress } = this.wiz.passcardApply;
    this.parking = {
      dcParkingNo: 0,
      parkingName: parkingName,
      parkingAddress: parkingAddress
    };

    this.setPaymentDetails();

    const { passcardUpdateFrom, passcardUpdateTo, passcardValidFrom, passcardValidTo, paymentDivision } = this.Cach01;
    this.passcardFee = paymentDivision.unitPrice;
    this.fixedPeriod = formatPeriod([passcardValidFrom, passcardValidTo]);
    this.updatablePeriod = formatPeriod([passcardUpdateFrom, passcardUpdateTo]);
  }

  get Cach01() {
    return this.wiz.service.Cach01;
  }

  get Cach02() {
    if (this.wiz.status == 10) return null;

    this._cach02 ??= this._storage.load(getStorageKey('payment-method', this.wiz.status, this.dcParkingNo), null);
    return this._cach02;
  }

  get Cach03() {
    this._cach03 ??= this._storage.load('passcard-change-3', null);
    return this._cach03;
  }

  get dcParkingNo() {
    return this.wiz.passcardApply.dcParkingNo;
  }

  get showCertificateUpload() {
    return !!this.Cach01.certificateTypeSetting;
  }

  get certificateTypeSetting() {
    return this.Cach01.certificateTypeSetting;
  }

  get myFilesModel() {
    return this.Cach01.myFilesModel;
  }

  onReInput(kb: number) {
    this._navi.navigateByUrl(`/passcard/change/step/${kb}`);
  }

  /**
   * 申請を行う
   */
  async onApply() {
    this.wiz.service.maxProcessed = 2;
    const { no, passcardType, extended, receptionMode } = this.Cach01.passcard;
    const { dcParkingNo, passcardApplyNo } = this.wiz.passcardApply;
    const bookingInfoRequired = {
      passcardApplyNo,
      dcParkingNo,
      status: this.wiz.status,
      no,
      passcardType,
      extended,
      passPaymentDivision: 0,
      receptionMode,
    }

    const {
      passcardMonth,
      passcardValidFrom,
      passcardValidTo,
      paymentDivision,
      beforePasscardType,
      beforePasscardExtended
    } = this.Cach01;

    bookingInfoRequired.passPaymentDivision = paymentDivision.passPaymentDivision;
    const applyInfo: any = {
      passcardValidFrom,
      passcardValidTo,
      passcardMonth,
      beforePasscardType,
      beforePasscardExtended
    };

    if (this.Cach02) {
      Object.assign(applyInfo, getApplyPaymentItems(this._storage, this.wiz.status, dcParkingNo));
      const { paymentTerm, terminalPaymentTerm } = this.Cach02;
      switch (applyInfo.paymentMethod) {
        case PaymentType.Terminal:
          applyInfo.terminalPaymentTerm = terminalPaymentTerm;
          break;
        case PaymentType.PayPay:
        case PaymentType.Cvs:
            applyInfo.paymentTerm = paymentTerm;
          break;
      }
  
      applyInfo.paymentAmount = this.passcardFee;  
      if (this.Cach01.applyCardIssueCommission) {
        applyInfo.applyCardIssueCommission = this.Cach01.applyCardIssueCommission;
      }  
    }

    const certificateUploadInfo: any = {};
    if (this.showCertificateUpload) {
      const { certificateType, images } = this.myFilesModel;
      certificateUploadInfo.certificateType = certificateType;
      certificateUploadInfo.certificateImageList = images.filter(x => x.fileSize).map(x => {
        const { certificateImageNum, imageSetting, certificateImageName, certificateImageData, fileName, fileSize } = x;
        return {
          certificateImageNum,
          imageSetting,
          certificateImageName,
          certificateImageData: certificateImageData.substring(certificateImageData.indexOf(',') + 1),
          fileName,
          fileSize
        }
      });
    }

    const reservation = {
      ...bookingInfoRequired,
      ...applyInfo,
      ...certificateUploadInfo
    };

    const applyResult = await this.api.passcardApply(reservation);
    const message1 = '定期券の更新申請が完了しました。'
    let message2 = '';
    let message3 = '';
    if (receptionMode == ReceptionMode.Manual) {
      message2 = '申請内容の確認完了までお待ちください。';
    } else {
      switch (applyInfo.paymentMethod) {
        case PaymentType.PayPay:
          message2 = '続けてPayPay支払いのお手続きをお願い致します。';
          break;
        case PaymentType.Cvs:
          message2 = '続けてコンビニで支払いのお手続きをお願い致します。';
          break;
        default:
          message2 = '続けてICカードの登録を定期更新機にて';
          message3 = 'お手続きをお願い致します。';
          break;
      }
    }

    this.wiz.service.complete = true;
    this.wiz.service.saveState();

    const receiptNo = applyResult.receiptNo;
    this._navi.navigate('/passcard/change/step/4', true, {
      message1,
      message2,
      message3,
      receiptNo,
      dcParkingNo
    });
  }

  private setPaymentDetails() {
    if (!this.Cach02) return;

    const { passcardMonth, passcard } = this.Cach01;
    this.passcardFee = passcard.paymentDivisionList!.find(x => x.monthNum == passcardMonth)?.unitPrice;    

    this.paidDetail = {
      paymentFeeDetails: [],
      paymentAmount: 0
    };

    this.paidDetail.paymentAmount = this.passcardFee;
    this.paidDetail.paymentFeeDetails.push({ contents: '定期代', Fee: this.passcardFee });
    const payInfo = this.Cach02
    if (payInfo.method == PaymentType.Cvs) {
      const { payCommission } = payInfo.cvsPay;
      this.paidDetail.paymentFeeDetails.push({ contents: 'コンビニ支払い手数料', Fee: payCommission });
      this.paidDetail.paymentAmount += payCommission;
    }
  }
}
