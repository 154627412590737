import { Pipe, PipeTransform } from '@angular/core';
import { CertificateImage } from '../certificate-file';

@Pipe({
  name: 'filterImage'
})
export class FilterImagePipe implements PipeTransform {

  transform(values: CertificateImage[], ...args: unknown[]): any[] {
    return values.filter(v => v.fileSize > 0);
  }

}