import { ApiReturn } from "../../passcard.model";

export const PaymentApplyStatus = Object.freeze({
    Apply: 1, '1': '定期申請',
    Refresh: 2, '2': '定期更新',
    Lost: 3, '3': '定期紛失',
});

export function checkValidFeeItem(paymentInfo: PayinfoBase, item: string): number | null {
    const fee = paymentInfo[item] ?? null;
    if (fee == null) return null;
    if (fee == 0 && (paymentInfo.applyStatus != PaymentApplyStatus.Lost || item != 'reIssueCommission')) return null;

    return fee;
}

export interface PayinfoBase extends ApiReturn {
    /**
     * 申請ステータス(int)
     * 1：定期申請、2：定期更新、3：定期紛失
     */
    applyStatus: number;

    paymentDetail: string;  //"定期申請料金",
    dcParkingNo: string;    //123,
    dcParkingName: string;  //"アマノ第一駐輪場",
    receiptNo: string;      //"37-12346579",
    paymentTerm: number;    //16960320000
    passcardAmount: number;
    buyICCardCommission: number;
    reIssueCommission: number;
    passcardCancelDocumentUrl: string;
}

export interface PaymentTerminalInfo extends PayinfoBase {
    paymentTerminalDocumentUrl: string;
    qrCode: string;
    authKey: string; // 認証番号(String)
}

export interface PaymentPayPayInfo extends PayinfoBase {
    settlementStatus: string;
    paymentPaypayDocumentUrl: string;
    paypayCallCountLimit: number;
    paypayCallInvalidTime: number;
}

export interface PaymentCvsInfo extends PayinfoBase {
    cvsCode: string; // コンビニコード(String),
    cvsName: string; // コンビニ名(String),
    cvsConfNo: string; // 確認番号(String),
    cvsReceiptNo: string; // コンビニ決済受付番号(String),
    cvsReceiptUrl: string; // 払込票URL(String),
    convenienceCommission: number; // コンビニ決済手数料 (int),
    paymentCvsStatus: string;
    paymentCvsDocumentUrl: string; 
}

export type PaymentInfo = PaymentTerminalInfo | PaymentPayPayInfo | PaymentCvsInfo;