declare var LOOPBACK: boolean;

import { Observable, Subject, } from 'rxjs';
import { ApiService,UrlCategory, } from './api.service';
import { NavigateService } from './navigate.service'
import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Inject, LOCALE_ID } from '@angular/core';
import imageCompression  from "browser-image-compression";

// 署名付きURL取得リクエスト
export interface PreSignedUrlRequest {
    certificateType: number,
    certificateImageNum: number,
    contentType: string,
    contentLength: number,
}

// 署名付きURL取得レスポンス
export interface PreSignedUrlResponse {
    resultCode: number,
    mainMessage: string,
    presignedUrl: string,
    objectName: string,
}

// 画像アップロード完了チェックリクエスト
export interface CompleteCheckRequest {
    certificateImageNum: number,
    objectName: string,
    fileName : string , 
}

// 画像アップロード完了チェックレスポンス
export interface CompleteCheckResponse {
    resultCode: number,
    mainMessage: string,
    checkResult: number,
}

// 一時画像ダウンロードリクエスト
export interface DownloadTemporaryImageFileRequest {
    certificateImageNum: number,
    fileName: string,
    objectName: string,
}

// 一時画像ダウンロードレスポンス
export interface DownloadTemporaryImageFileResponse {
    resultCode: number,
    mainMessage: string,
    certificateImageData: string,
}

// 登録済み画像ダウンロードリクエスト
export interface DownloadImageFileRequest {
    certificateImageNum: number,
    passcardApplyNo: number,
}

// 登録済み画像ダウンロードレスポンス
export interface DownloadImageFileResponse {
    resultCode: number,
    mainMessage: string,
    certificateImageData: string,
}

@Injectable({
    providedIn: 'root'
})
export class CertificateImageService {

    // API呼び出しパス
    // 署名付きURL取得
    private readonly API_PATH_PRE_SIGND_URL_GET = '/upload/getPresignedUrl';
    // 画像アップロード完了チェック
    private readonly API_PATH_CHECK_UPLOAD_COMPLETE = '/upload/completeCheck';
    // 一時画像ダウンロード
    private readonly API_PATH_TEMPORARYFILE_DOWNLOAD = '/certificate/temporaryDownload';
    // 申請時登録済み画像ダウンロード
    private readonly API_PATH_DOWNLOAD = '/certificate/download';
    // 画像データに付与するヘッダー
    public static readonly IMAGE_HEADER = 'data:image/png;base64,';
    constructor(
        private api: ApiService,
        private http: HttpClient,
        private _navi: NavigateService,
        @Inject(LOCALE_ID) private locale: string,
    ) {
    }

    /**
     * 署名付きURLを取得する
     * @returns 署名付きURL
     */
    getPreSignedUrl(request: PreSignedUrlRequest): Observable<PreSignedUrlResponse> {
        const httpresponse$: Subject<HttpResponse<PreSignedUrlResponse>> =
            this.api.post<PreSignedUrlResponse>(this.API_PATH_PRE_SIGND_URL_GET, request, UrlCategory.Base);
        return httpresponse$.pipe(map(response => {
            if (response.body) {
                return response.body;
            } else {
                return null;
            }
        }));
    }

    /**
     * 一時画像ファイルをアップロードする
     * @param request API呼び出し用リクエストパラメータ
     * @returns 処理結果
     */
    uploadTemporaryImageFile(uploadUrl: string, uploadfile: any): Observable<any> {
        if (LOOPBACK) {
            // ローカル起動時
            const subj = new Subject<HttpResponse<any>>();
            const url = './assets/api/upload/uploadTemporaryImageFile.GET.json' + '?t=' + new Date().getTime();

            this.http.request<HttpResponse<any>>('get', url).pipe()
                .subscribe(
                    res => {
                        subj.next(res);
                        subj.complete();
                    },
                    error => {
                        subj.next(error);
                        subj.complete();
                    }
                );
            return subj
        } else {
            // サーバー接続時
            const fd = new FormData();
            fd.append("upfile", uploadfile);
            // ファイルをアップロード
            return this.http.put<any>(uploadUrl, uploadfile, { observe: "events" });
        }
    }

    /**
     * アップロードした証明書画像のチェックをおこなう
     * @returns 
     */
    checkUploadFile(request: CompleteCheckRequest): Promise<CompleteCheckResponse> {
        return new Promise<CompleteCheckResponse>((resolve, reject) => {
            this.api.disabled = true;
            this.api.post<CompleteCheckResponse>(this.API_PATH_CHECK_UPLOAD_COMPLETE, request, 1).subscribe(
                res => {
                    this.api.disabled = false;
                    const result = <CompleteCheckResponse>res.body;
                    if (result.resultCode == 0) {
                        resolve(res.body);
                        return;
                    } else {
                        reject(result.mainMessage)
                        this._navi.navigateByUrl('/error', true);
                        return;
                    }
                },
                _err =>{
                    resolve(_err.body);
                    this._navi.navigateByUrl('/error', true);
                    return;
                }
            );
        });
    }

    /**
     * 一時画像ファイルをダウンロードする
     * @returns 一時画像情報
     */
    downLoadTemporaryImageFile(request: DownloadTemporaryImageFileRequest): Observable<DownloadTemporaryImageFileResponse> {
        const httpresponse$: Subject<HttpResponse<DownloadTemporaryImageFileResponse>> =
            this.api.post<DownloadTemporaryImageFileResponse>(this.API_PATH_TEMPORARYFILE_DOWNLOAD, request, 1);
        return httpresponse$.pipe(map(response => {
            if (response.body) {
                return response.body;
            } else {
                return null;
            }
        }));
    }

    /**
     * 証明書画像ファイル（登録用）をダウンロードする
     */
    downLoadImageFile(request: DownloadImageFileRequest): Observable<DownloadImageFileResponse> {
        const httpresponse$: Subject<HttpResponse<DownloadImageFileResponse>> =
            this.api.post<DownloadImageFileResponse>(this.API_PATH_DOWNLOAD, request, 1);
        return httpresponse$.pipe(map(response => {
            if (response.body) {
                return response.body;
            } else {
                return null;
            }
        }));
    }

    /**
     * 画像を圧縮する
     * @param file 圧縮するファイル
     * @returns 
     */
    async getCompressImageFileAsync(file: File) {
        const options = {
            maxSizeMB: 1, // 最大ファイルサイズ
            maxWidthOrHeight: 700 // 最大画像幅もしくは高さ
        };
        try {
            // 圧縮画像の生成
            return await imageCompression(file, options);
        } catch (error) {
            throw error;
        }
    }
}