<h2>証明書等再提出</h2>
<t-error [message]="error"></t-error>

<t-info value1="以下の内容で証明書等を再提出します。" value2="内容を確認し、「確定」を押してください。"></t-info>

<p-my-files
  [certificateNextCheckDate]="certificateNextCheckDate"
  [certificateType]="certificateTypeSetting"
  [model]="myFilesModel"></p-my-files>
<div *ngIf="!model.completed" class="buttonBoxVertical">
  <button (click)="onBack()" class="cmn-btn mr15 bck-btn">戻る</button>
  <loading-button
    (apply)="onApply()"
    [disabled]="api.disabled"
    [loading]="api.disabled"
    [label]="'確定'"
    [class]="'cmn-btn'"
    [lClass]="'button-box-loading'">
  </loading-button>
</div>