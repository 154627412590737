import { Component } from '@angular/core';
import { NavigateService } from '../../../services';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'file-info-complete',
  templateUrl: './complete.component.html',
})
export class CompleteComponent {
  proc: number;
  receiptNo = '';
  mainMessage = '証明書等変更が完了しました';

  constructor(
    private navi: NavigateService,
    route: ActivatedRoute,
    l: Location
  ) {
    route.params.subscribe(x => this.proc = +x['proc']);
    this.receiptNo = l.getState()?.['receiptNo'];
  }

  onApply() {
    this.navi.navigateByUrl('/passcard/list');
  }
}
