import { Component, Input } from '@angular/core';
import { PasscardStatus, PasscardStatusText } from '../../passcard.model';
import { Passcard } from '../../models/passcard';

@Component({
  selector: 'passcard-status',
  template: `
    <div class="parking-status"
      [ngClass]="{
        'status-stop': passcard.status == PasscardStatus.Busy && passcard.vacantWaitOverFlg == 1,
        'status-busy': passcard.status == PasscardStatus.Busy && passcard.vacantWaitOverFlg != 1,
        'status-idle': passcard.status == PasscardStatus.Free
        }">{{StatusText}}</div>
    <span class="font-small" *ngIf="showWaitNum">&nbsp;&nbsp;{{StausTextExtra}}</span>
  `})
export class PasscardStatusComponent {
  PasscardStatus = PasscardStatus;
  @Input() passcard: Passcard;
  @Input() showWaitNum = false;

  get StatusText() {
    const status = this.passcard.status == PasscardStatus.Free ? 1 : this.passcard.vacantWaitOverFlg == 1 ? -1 : 2;
    return PasscardStatusText[status];
  }

  get StausTextExtra() {
    if (this.passcard.vacantWaitOverFlg != 1 && this.passcard.status == PasscardStatus.Busy) {
      return `現在${this.passcard.waitNum}人待ちです`;
    }

    return '';
  }
}
