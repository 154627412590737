import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { ApiService, NavigateService } from 'src/app/services';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { ApiResultGetPasscardApply, PasscardApply } from '../models/apply-info';
import { ActivatedRoute, ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApplyStatus } from '../passcard.model';
import { PaymentType } from '../pay/pay.model';
import { AppModel } from 'src/app/app.model';

@Component({
  selector: 'reservations',
  templateUrl: './reservations.component.html'
})
export class ReservationsComponent implements OnInit {
  private readonly _route = inject(ActivatedRoute);
  private _navi = inject(NavigateService);
  app = inject(AppModel);
  
  api = inject(PasscardApiProxy);
  reservations: PasscardApply[]

  @ViewChild('toplink') toplink: ElementRef<HTMLAnchorElement>;

  ngOnInit(): void {
    this._route.data.pipe(map(({ data }) => data))
      .subscribe(x => this.reservations = x);
  }

  /**
   *　カードクリック
   * @param reservation 申請情報
   */
  onCardClick(reservation: PasscardApply) {
    if (reservation.status >= 30 || reservation.passcardAvailable == 1) {
      this._navi.navigate(`/passcard/contract`, false, { contract: reservation });
      return;
    }

    this._navi.navigate(`/passcard/applyInfo`, false, { reservation });
  }
}

export const AppliesResolver: ResolveFn<PasscardApply[]> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  api = inject(ApiService),
  navi = inject(NavigateService),
  apiProxy = inject(PasscardApiProxy)
): Observable<PasscardApply[]> => {
  const auto = route.data?.auto;
  return new Observable<PasscardApply[]>(observer => {
    const subscription = api.get<ApiResultGetPasscardApply>('/passcardApplyInfo', 1)
      .pipe(
        catchError(() => {
          navi.navigateByUrl('/error', true);
          return EMPTY;
        }),
        map(({ body }) => body))
      .subscribe(value => {
        if (value.resultCode != 0 && value.resultCode != 4) {
          navi.navigateByUrl('/error', true);
          return;
        }

        apiProxy.error = value.mainMessage;
        if (value.mainMessage) {
          observer.next([]);
          return;
        }
        else {
          if (value.passcardApplyList.length == 1) {
            const reservation = value.passcardApplyList[0];
            if (auto) {
              if ((reservation.status == ApplyStatus.WaitingPay
                || reservation.status == ApplyStatus.WaitingPayForRefresh
                || reservation.status == ApplyStatus.WaitingPayForLost)
                && reservation.passcardPaymentType != PaymentType.UnSelect) {
                  let payMethod = 0;
                  let urlNav = '/passcard/terminal-pay';
                  switch (reservation.passcardPaymentType) {
                    case PaymentType.Terminal:
                      urlNav = `/passcard/terminal-pay`;
                      payMethod = 0;
                      break;
                    case PaymentType.PayPay:
                      urlNav = `/passcard/2pay-pay`;
                      payMethod = 2;
                      break;
                    case PaymentType.Cvs:
                      urlNav = `/passcard/conveni-pay`;
                      payMethod = 3;
                      break;
                  }
              
                  apiProxy.getPaymentInfo(payMethod, reservation.passcardApplyNo)
                    .then(paymentInfo => navi.navigate(urlNav, false, { passcardApplyNo: reservation.passcardApplyNo, reservation, paymentInfo }));
                  return;
              }
              else if (reservation.status == ApplyStatus.WaitingICRegist
                || reservation.status == ApplyStatus.WaitingICRegistForLost) {
                  navi.navigate(`/passcard/ic-register/${reservation.passcardApplyNo}`, false, reservation);
                  return;
                }
            }

            if (reservation.status < 30 && reservation.passcardAvailable != 1) {
              navi.navigate('/passcard/applyInfo', true, { reservation: reservation });
            }
            else {
              if (reservation.passcardAvailable == 1
                && reservation.status == 0) {
                reservation.status = 30;
              }
              navi.navigate(`/passcard/contract`, false, { contract: reservation });
            }
          }
          else {
            observer.next(value.passcardApplyList);
          }
        }
      });

    return () => subscription.unsubscribe();
  });
}
