import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';

import { ApiService, ConfigService, NavigateService } from '../../../services';
import { WsInfoModel } from '../ws-info.model';
import { MypageModel } from '../../mypage.model';

import { IWsNameComponent } from '../../../shared/i-ws-name/i-ws-name.component';
import { IWsKanaComponent } from '../../../shared/i-ws-kana/i-ws-kana.component';
import { IWsAddressComponent } from '../../../shared/i-ws-address/i-ws-address.component';
import { IWsPhoneComponent } from '../../../shared/i-ws-phone/i-ws-phone.component';

//
interface Req {
  companyName: string;
  companyNameFurigana: string;
  companyTel: string;
  companyZipCodeList: string[];
  companyAddress1: string;
  companyAddress2: string;
}

interface Res {
  resultCode: number;
  mainMessage: string;
  validatorMessageList: string[];
  companyInfoValidator: {
    validatorMessageCompanyNameList: string[];
    validatorMessageCompanyNameFuriganaList: string[];
    validatorMessageCompanyTelList: string[];
    validatorMessageCompanyZipCodeList: string[];
    validatorMessageCompanyAddress1List: string[];
    validatorMessageCompanyAddress2List: string[];
  }

}

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, DoCheck {
  @ViewChild(IWsNameComponent, { static: false }) wsName: IWsNameComponent;
  @ViewChild(IWsKanaComponent, { static: false }) wsKana: IWsKanaComponent;
  @ViewChild(IWsAddressComponent, { static: false }) wsAddress: IWsAddressComponent;
  @ViewChild(IWsPhoneComponent, { static: false }) wsPhone: IWsPhoneComponent;

  // for Server
  error: string;
  errors: string[];
  messages: { wsName: string[];wsKana:string[];wsTelephoneNumber:string[];wsPostcodeList:string[];wsAddress1:string[];wsAddress2:string[]};

  //
  disabled: boolean;

  //
  applyCompanySetting: string;
  applyCompanyAddress2Setting: string;
  
  //
  constructor(
    public model: WsInfoModel,
    public mypage: MypageModel,
    public api: ApiService,
    private conf: ConfigService,
    public navi: NavigateService,
  ) { }

  ngOnInit() {
    this.disabled = true;
    this.model.dispWsName = true;
    this.model.dispWsKana = true;
    this.model.dispWsPhone = true; 
    this.model.dispWsAddress = true; 
    this.messages = {
      wsName:null,
      wsKana:null,
      wsTelephoneNumber:null,
      wsPostcodeList:null,
      wsAddress1:null,
      wsAddress2:null
    };

    this.applyCompanySetting = this.model.byCompanyName;
    this.applyCompanyAddress2Setting = this.model.byCompanyAddress2; 
  }

  ngDoCheck() {
    let isInput= false;
    let b1 = true;
    let b2 = true;
    let b3 = true;
    let b4 = true;
    if (this.applyCompanySetting === '1'
      && this.applyCompanyAddress2Setting === '1') {
      const b1IsInput = this.wsName ? this.wsName.isInput() : false;
      const b2IsInput = this.wsKana ?  this.wsKana.isInput(): false;
      const b3IsInput = this.wsPhone ? this.wsPhone.isInput(): false;
      const b4IsInput = this.wsAddress ? this.wsAddress.isInput(): false;
      if (b1IsInput || b2IsInput || b3IsInput || b4IsInput) {
        isInput = true;
      }
      b1 = this.wsName ? this.wsName.validationIsInput(isInput): false;
      b2 = this.wsKana ? this.wsKana.validationIsInput(isInput) :false;
      b3 = this.wsPhone ? this.wsPhone.validationIsInput(isInput): false;
      b4 = this.wsAddress ? this.wsAddress.validationIsInput(isInput): false;
    } else {
      b1 = this.wsName ? this.wsName.validation(): false;
      b2 = this.wsKana ? this.wsKana.validation() :false;
      b3 = this.wsPhone ? this.wsPhone.validation(): false;
      b4 = this.wsAddress ? this.wsAddress.validation(): false;
    }

    var b5 = true;
    if (this.model.byCompanyName === '0' && this.model.byCompanyFurigana === '0'
    && this.model.byCompanyTel === '0' && this.model.byCompanyZip === '0'
    && this.model.byCompanyAddress1 === '0' && this.model.byCompanyAddress2 === '0') {
      b5 = false;
    }
    this.disabled = !(b1 && b2 && b3 && b4 && b5);
  }

  onKeyup = () => {
    this.error = null;
    this.errors = null;
  }

  //
  private afterPost = (body: Res) => {
    if (body.resultCode === 0) {
      this.model.saveStorage();
      this.navi.navigateByUrl('/mypage/ws-info/confirm');
    } else {
      this.api.enabled();

      this.error = body.mainMessage;
      this.errors = body.validatorMessageList;
      this.messages.wsName = body.companyInfoValidator.validatorMessageCompanyNameList;
      this.messages.wsKana = body.companyInfoValidator.validatorMessageCompanyNameFuriganaList;
      this.messages.wsTelephoneNumber = body.companyInfoValidator.validatorMessageCompanyTelList;
      this.messages.wsPostcodeList = body.companyInfoValidator.validatorMessageCompanyZipCodeList;
      this.messages.wsAddress1 = body.companyInfoValidator.validatorMessageCompanyAddress1List;
      this.messages.wsAddress2 = body.companyInfoValidator.validatorMessageCompanyAddress2List;
    }
  }

  //
  onApply = () => {
    this.api.disabled = true;

    const req: Req = {
      companyName: this.model.wsName,
      companyNameFurigana: this.model.wsKana,
      companyTel: this.model.wsPhone,
      companyZipCodeList: this.model.wsPostcode,
      companyAddress1: this.model.wsAddress1,
      companyAddress2: this.model.wsAddress2
    };

    this.api.post<Res>('/companyInfo/check', req, 2).subscribe(
      res => this.afterPost(res.body)
    );
  }

}
