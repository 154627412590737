import { Component, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { NavigateService } from 'src/app/services';
import { Location } from '@angular/common';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { PasscardApply } from '../../models/apply-info';
import { ApplyCancelModel } from './apply-cancel.model';
import { PasscardApplyDetail } from '../../models/apply-details';

@Component({
  selector: 'apply-cancel',
  templateUrl: './apply-cancel.component.html',
  providers: [ ApplyCancelModel ]
})
export class ApplyCancelComponent {
  private _isConfirmed = false;
  readonly model = inject(ApplyCancelModel);

  proc: number;
  passcardApplyNo: number;
  reservation: PasscardApply | null = null;
  detail: PasscardApplyDetail;
  parkingAreaName: string;

  constructor(
    public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    location: Location) {
    const { passcardApplyNo, parkingAreaName, reservation, applyDetail, proc } = <any>location.getState();
    this.reservation = reservation;
    this.passcardApplyNo = passcardApplyNo;
    this.parkingAreaName = parkingAreaName;
    this.proc = proc ?? 0;
    if (this.proc == 1) {
      this.detail = applyDetail;
    }
  }

  get isConfirmed() {
    return this._isConfirmed;
  }

  set isConfirmed(val: boolean) {
    this._isConfirmed = val;
  }

  onBack() {
    this._navi.goBack();
  }

  onCancel() {
    this.api.passcardApplyCancel(this.passcardApplyNo).then(x => {
      this.model.complete = true;
      this.model.save();
      const showBackToApplyList = this.proc == 1;
      this._navi.navigate('passcard/apply/canceled', true, {
        showBackToApplyList,
        title: this.proc == 0 ? '定期情報' : '取消',
        message1: '申請を取り消しました。',
        message2: 'またのご利用お待ちしております。'
      });
    });
  }
}
