import { Injectable } from "@angular/core";

/**
 * 定期券予約サービス
 */
@Injectable({
  providedIn: 'root'
})
export class PasscardBookingService {
  changeComplete = false;
  cancelComplete = false;
}
