import { Component, OnInit } from '@angular/core';
import { ApiService, NavigateService } from 'src/app/services';
import { PaypayStateReq, PaypayStateRes } from '../paypay.model';
import { Location } from '@angular/common';

declare var LOOPBACK: boolean;

@Component({
  selector: 'app-paypay',
  templateUrl: './paypay.component.html',
})
export class Paypaycomponent implements OnInit {
  paymentFee: number;
  dealId: string;
  settlementUrl: string;

  count = 0;
  isCompleted: boolean = false;

  constructor(
    private api: ApiService,
    private navi: NavigateService,
    location: Location
  ) {
    const { paymentFee, settlementUrl, transactionId } = <any>location.getState();
    this.dealId = transactionId;
    this.paymentFee = paymentFee;
    this.settlementUrl = settlementUrl;
  }

  ngOnInit() {
    this.openPaypay();
  }

  onToPasscards() {
    this.navi.navigateByUrl('/passcard/list');
  }

  // API call
  openPaypay() {
    if (!LOOPBACK) {
      window.open(this.settlementUrl, '_exePaypay');
    }

    this.polling(this.dealId);
  }

  async polling(transactionId: string) {
    const req: PaypayStateReq = {
      transactionId: transactionId,
    };
    this.count = 0;
    // const bak = LOOPBACK
    // LOOPBACK = false;
    // const url = this.api.getUrl();
    // this.api.setUrl('http://localhost:5000');
    while (!this.isCompleted && this.count < 30 && transactionId) {
      this.api.post<PaypayStateRes>('/paymentStatusConfirmation', req, 1).subscribe(res => {
        if (res.body.resultCode != 0 && res.body.resultCode != 4) {
          this.navi.navigate('/error');
          return;
        }

        if (res.body.resultCode === 0 && res.body.paymentStatus === 'COMPLETED') {
          this.isCompleted = true;
          this.count += 60;
        }
      });

      await this.api.sleep(2000);
      this.count++;
    }

    // this.api.setUrl(url);
    // LOOPBACK = bak;
  }
}
