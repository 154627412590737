import { ChangeDetectorRef, Component, ViewChild, inject , OnInit } from '@angular/core';
import { PeriodKind, PasscardStatus, formatPeriod, calcUpdatablePeriod, getPeridStart, getPasscardValidEndDay, ReceptionMode, getInputImageUploadModel } from '../../../passcard.model';
import { Observable } from 'rxjs';
import { AppModel } from 'src/app/app.model';
import { StorageService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { Passcard, PasscardApplyApi } from 'src/app/passcard/models/passcard';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { ApplyComponent } from '../../apply.component';
import { CurrencyPipe } from '@angular/common';
import { CertificateType } from 'src/app/shared/certificate-file';
import { EmptyImageUpload, IMyFilesComponent, MyFilesModel } from 'src/app/shared/i-my-files/i-my-files.component';
import { CertificateImageService, 
  DownloadTemporaryImageFileRequest , 
  DownloadTemporaryImageFileResponse, 
  DownloadImageFileRequest,
  DownloadImageFileResponse,
} from '../../../../services/certificateImage.service';
import { ApiService } from '../../../../services/api.service';
import { Subject } from 'rxjs';
import { CertificateImage } from '../../../../shared/certificate-file';


@Component({
  selector: 'apply01',
  templateUrl: './apply01.component.html',
  providers: [ CurrencyPipe ]
})
export class Apply01Component implements OnInit{
  private _storage = inject(StorageService);
  private _cdRef = inject(ChangeDetectorRef);

  applyParams: PasscardApplyApi;
  PasscardStatus = PasscardStatus;
  PERIODKIND = PeriodKind;
  passcardApplyPossible = 0;
  certificateURL: string;
  certificateTypeList: CertificateType[];
  
// 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応Start
  msgDateSelect = [];
  dateSelectModel: { min: number, max: number, current: string; }
// 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応End

  applyNewIssueCommission = 0; // 新規発行手数料(int),
  issueFee: number = 0;
  fixedPeriods$!: Observable<string[]>;
  fixedPeriod: string = '';
  updatablePeriod = '';
  passcards: Passcard[];
  passcardValidFrom = '';
  selectedInfo: any;
  initCertificate: MyFilesModel;
  imageDownloadFinish: Subject<number> = new Subject<number>();

  myFilesModel: MyFilesModel = {
    certificateType: undefined,
    year: undefined,
    month: undefined,
    images: []
  };

  model: {
    passcard?: Passcard,
    passcardMonth: number,
    selectTargetMonth: number,
    buyICCard: number,
    passcardValidFrom?: number,
    passcardValidTo?: number,
    passcardUpdateFrom?: number,
    passcardUpdateTo?: number,
  } = {
    passcardMonth: 0,
    buyICCard: -1,   // -1:選択されない 0:購入しない、1:購入する
    selectTargetMonth: 0,           
  };

  private readonly _route: ActivatedRoute = inject(ActivatedRoute);

  @ViewChild(IMyFilesComponent) fileUploader: IMyFilesComponent;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _wiz: ApplyComponent,
    private _currencyPipe: CurrencyPipe,
    private certificateImageService: CertificateImageService,
    private apiService: ApiService,
  ) {
    this._wiz.processing = 0;
    this._wiz.saveState();
    this.restoreModel();
  }

  ngOnInit() {
    // 画像一時ファイルがある場合は取得
    if (this.myFilesModel && this.myFilesModel.images.length > 0) {
      // ダウンロード終了監視用データ作成
      const imageList: { imageNum: number; finish: boolean }[] = [];
      this.myFilesModel.images.forEach((image) => {
        imageList.push({ imageNum: image.certificateImageNum, finish: false });
      });

      // 画像ダウンロード終了監視
      const subscripttion = this.imageDownloadFinish.subscribe((imageNumber) => {
        if (imageNumber === -1) {
          // エラー発生
          this.apiService.loadingOff();
          subscripttion.unsubscribe();
        } else {
          // 終わったものを終了に
          const image = imageList.find((image) => image.imageNum === imageNumber);
          if (image) {
            // 完了を設定
            image.finish = true;
          }
          // 全部終わったか(未完了のものがないか)
          if (!imageList.some((image) => image.finish === false)) {
            this.apiService.loadingOff();
            subscripttion.unsubscribe();
          }
        }
      });
      // 画像ダウンロード実施
      this.apiService.loadingOn();
      this.downLoadImage(this.myFilesModel.images);
    }
  }

  /**
   * 画像データをダウンロードする
   * @param certicateImages ダウンロードするイメージデータ
   */
  private downLoadImage(certicateImages: CertificateImage[]) {
    certicateImages.forEach((certicateImage) => {
      if (certicateImage.fileSize > 0) {
        // 画像がアップロードされている場合
        // 一時画像の場合
        if (certicateImage.fileName !== '') {
          const request: DownloadTemporaryImageFileRequest = {
            certificateImageNum: certicateImage.certificateImageNum,
            fileName: certicateImage.fileName,
            objectName: certicateImage.objectName,
          };
          // アップロード済みの画像データがある場合はダウンロードする
          this.certificateImageService.downLoadTemporaryImageFile(request).subscribe(
            (response: DownloadTemporaryImageFileResponse) => {
              if (response.resultCode === 0) {
                // 正常終了
                certicateImage.certificateImageData = CertificateImageService.IMAGE_HEADER + response.certificateImageData;
                this.imageDownloadFinish.next(certicateImage.certificateImageNum);
              } else {
                // 異常終了
                this.imageDownloadFinish.next(-1);
              }
            });
        } else {
          // 登録済み画像の場合
          const request: DownloadImageFileRequest = {
            certificateImageNum: certicateImage.certificateImageNum,
            passcardApplyNo: this._wiz.paramsReApply.passcardApplyNo,
          };
          // アップロード済みの画像データがある場合はダウンロードする
          this.certificateImageService.downLoadImageFile(request).subscribe(
            (response: DownloadImageFileResponse) => {
              if (response.resultCode === 0) {
                // 正常終了
                certicateImage.certificateImageData = CertificateImageService.IMAGE_HEADER + response.certificateImageData;
                this.imageDownloadFinish.next(certicateImage.certificateImageNum);
              } else {
                // 異常終了
                this.imageDownloadFinish.next(-1);
              }
            });
        }
      } else {
        // 画像がアップロードされていない場合
        this.imageDownloadFinish.next(certicateImage.certificateImageNum);
      }
    });
  }

  get passcardSelected() {
    return this.model.passcard;
  }

  get IsComplete() {
    return this._wiz.complete;
  }

  get showSelect() {
    const status = this._wiz.status;
    return this.passcardSelected
      && (status == 3 || status == 1 || status == 7 || status == 9 || this._wiz.isCorrect);
  }

  get showCertificateUpload() {
    return this.status != 2 && (this.status != 8 || this._wiz.isCorrect)
      && this.passcardSelected?.certificateTypeNoList?.length > 0;
  }

  get cardPurchaseChoicesBuy() {
    return this.applyParams.cardPurchaseChoicesBuy;
  }

  get cardPurchaseChoicesMyCardUse() {
    return this.applyParams.cardPurchaseChoicesMyCardUse;
  }

  get applyUseStartMethod() {
    return this.applyParams.applyUseStartMethod;
  }

  get applySelectMonthType() {
    return this.applyParams.applySelectMonthType;
  }
  
  get isReApply() {
    return !!this._wiz.paramsReApply;
  }

  get status() {
    return this._wiz.status;
  }

  get NextDisabled() {
    const status = this._wiz.status;
    const certificateUploadError = this.fileUploader?.error ?? false;
    return this.api.error
      || this.api.Disabled
      || this.passcardApplyPossible == 0
      || !this.passcardSelected
      || certificateUploadError
      || (this.showSelect && (this.model.passcardMonth == 0 || !this.model.passcardValidFrom)
      || (status != 2 && (this.cardPurchaseChoicesBuy || this.cardPurchaseChoicesMyCardUse) && this.model.buyICCard == -1)
        );
  }

  onPasscardChange(passcard: Passcard) {
    if (!passcard || (passcard.status == PasscardStatus.NoVacancies || passcard.status == PasscardStatus.StopApply)) return;

    this._wiz.passcard = passcard;
    this.model.passcard = passcard;
    this.model.passcardMonth = 0;
    this.model.selectTargetMonth = 0;
    this.model.buyICCard = -1;
    if(this.applyUseStartMethod === 0){
      this.model.passcardValidFrom = 0;
    }
    this.fixedPeriod = '';
    this.updatablePeriod = '';
    if (this.passcardSelected?.certificateTypeNoList && passcard.status != 2) {
      this.certificateTypeList = getInputImageUploadModel(this.passcardSelected?.certificateTypeNoList, this.applyParams.certificateTypeList);
      setTimeout(() => this.myFilesModel = {
        certificateType: this.certificateTypeList[0]?.certificateType,
        year: undefined,
        month: undefined,
        images: [] 
      }, 0);
    }

    this._wiz.status = 1;
    if (passcard.status == 2) {
      this._wiz.status = 2;
    } else if (passcard.receptionMode == ReceptionMode.Manual) {
      this._wiz.status = 7;
    }

    this._cdRef.detectChanges();
}

  onPeriodChange(periodKind: number) {
    this.model.selectTargetMonth = periodKind;
    this.calcPeriod();
  }

  onSetPasscardMonth(passcardMonth: number) {
    if (this.model.passcardMonth != passcardMonth) {
      this.model.passcardMonth = passcardMonth;
      this.calcPeriod();
    }
  }

  // 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応Start
  onStartDateChanged(e: Event) {
    const newVal = e.target['value'];
    this.fixedPeriod = '';
    this.updatablePeriod = '';
    this.model.passcardValidFrom = 0;
    this.msgDateSelect = [];
    if (newVal) {
      const val = new Date(newVal).getTime();
      if (val >= this.applyParams.applySelectableStartDay && val <= this.applyParams.applySelectableEndDay) {
        this.model.passcardValidFrom = val;
      }
    }

    if (!this.model.passcardValidFrom) {
      const formatDate = (dt: number): string => {
        const dtDate = new Date(dt);
        const month = `${dtDate.getMonth() + 1}`.padStart(2, '0');
        const date = `${dtDate.getDate()}`.padStart(2, '0');
        return `${dtDate.getFullYear()}/${month}/${date}`
      }

      this.msgDateSelect = [`使用開始日には${formatDate(this.applyParams.applySelectableStartDay)}～${formatDate(this.applyParams.applySelectableEndDay)}の日付を入力してください。`];
      return;
    }

    this.calcPeriod();
  }

  formatDate(dt: number, sep?: string): string {
    sep ??= '/';
    const dtDate = new Date(dt);
    const month = `${dtDate.getMonth() + 1}`.padStart(2, '0');
    const date = `${dtDate.getDate()}`.padStart(2, '0');
    return `${dtDate.getFullYear()}${sep}${month}${sep}${date}`
  }
// 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応End

  async onNext() {

    this.saveModel();
    const status = this._wiz.status;
    if ((this.isReApply && !this._wiz.isCorrect && status != 9) || (this.passcardSelected.receptionMode == 1
        && this.passcardSelected.status == PasscardStatus.Available)) {
      let payCommission = this.model.passcard
        .paymentDivisionList.find(x => x.monthNum == this.model.passcardMonth).unitPrice;
      if (this.model.buyICCard == 1) payCommission += this.applyParams.applyCardIssueCommission;
      payCommission += this.applyParams.applyNewIssueCommission;
      const { paymentTerm, terminalPaymentTerm } = await this.api.getPaymentTerm(status, this.model.selectTargetMonth, this.model.passcardValidFrom);
      const {
        paymentMethodPaidMachineQR,
        paymentMethodCreditCard,
        paymentMethodPayPay,
        paymentMethodConvenienceStore,
      } = this.applyParams;
      this._storage.save('payment-params', {
        paymentTerm, terminalPaymentTerm,
        paymentMethodPaidMachineQR, paymentMethodCreditCard, paymentMethodPayPay, paymentMethodConvenienceStore,
        commission: payCommission
      });

      this._wiz.passcard = this.passcardSelected; 
      this._wiz.saveState();

      this._wiz.navigate('step/2');
    }
    else {
      this._wiz.navigate('step/3');
    }
  }

  saveModel() {
    const { passcardValidFrom, passcardValidTo, passcardUpdateFrom, passcardUpdateTo } = this.model;
    this._wiz.passcard = this.passcardSelected;
    const myFilesModel: { myFilesModel: MyFilesModel, certificateTypeSetting: CertificateType, } = { myFilesModel: null, certificateTypeSetting: null };

    if (this.showCertificateUpload) {
      myFilesModel.myFilesModel = this.myFilesModel;
      myFilesModel.certificateTypeSetting = this.certificateTypeList.find(x => x.certificateType == this.myFilesModel.certificateType);
      // 画像の実データはクリア(容量の問題)
      if (myFilesModel.myFilesModel && myFilesModel.myFilesModel.images) {
        myFilesModel.myFilesModel.images.forEach((image) => {
          image.certificateImageData = '';
        });
      }
    }

    this._wiz.saveState();
    this._storage.save(this._wiz.getStorageKey('passcard-apply-1'), {
      passcard: this.model.passcard,
      passcardMonth: this.model.passcardMonth,
      selectTargetMonth: this.model.selectTargetMonth,
      buyICCard: this.model.buyICCard,
      applyNewIssueCommission: this.applyNewIssueCommission,
      applyCardIssueCommission: this.model.buyICCard == 1 ? this.applyParams.applyCardIssueCommission : 0,
      passcardValidFrom,
      passcardValidTo,
      passcardUpdateFrom,
      passcardUpdateTo,
      ...myFilesModel
    });
  }

  restoreModel() {
    this._route.data.pipe(map(({ data }) => data)).subscribe(x => {
      this.applyParams = x;
      this.passcardApplyPossible = x.passcardApplyPossible;
      this.passcards = x.passcardTypeList;
      this.applyNewIssueCommission  = x.applyNewIssueCommission??0;
      this.issueFee = x.applyCardIssueCommission + this.applyNewIssueCommission;
      this.certificateURL = this.applyParams.certificateURL;
      const cache = this._wiz.status ? this._storage.load(this._wiz.getStorageKey('passcard-apply-1'), null) : null;
      this.model.passcard = this._wiz.passcard;
      if (cache) {
        this.model.passcardMonth = cache.passcardMonth;
        this.model.buyICCard = cache.buyICCard;
        this.model.selectTargetMonth = cache.selectTargetMonth;
// 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応Start
        this.model.passcardValidFrom = cache.passcardValidFrom;
        this.model.passcardValidTo = cache.passcardValidTo;
// 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応End
        this._wiz.passcard = cache.passcard;
        this.myFilesModel = cache.myFilesModel ?? {...EmptyImageUpload, images: []};
      }
      this.setImageUploadModel();

      if (this.isReApply && !this.showSelect) {
        this.setSelectInfo();
        return;
      }

      this.calcPeriod();
      // 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応Start
      if (this.applyParams.applyUseStartMethod == 1) {
        this.dateSelectModel = {
          min: this.applyParams.applySelectableStartDay,
          max: this.applyParams.applySelectableEndDay,
          current: ''
        };

        if (this.model.passcardValidFrom) {
          this.dateSelectModel.current = this.formatDate(this.model.passcardValidFrom, '-');
        } else if (this.dateSelectModel.min) {
          this.model.passcardValidFrom = this.dateSelectModel.min;
          this.dateSelectModel.current = this.formatDate(this.model.passcardValidFrom, '-');
        }
        if (!this.dateSelectModel.current) {  
          this.msgDateSelect = [`使用開始日には${this.formatDate(this.applyParams.applySelectableStartDay)}～${this.formatDate(this.applyParams.applySelectableEndDay)}の日付を入力してください。`];
        }
      }
// 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応End
    });
    
  }

  private setImageUploadModel() {
    if (!this.showCertificateUpload) return;
    this.certificateTypeList = getInputImageUploadModel(this.passcardSelected?.certificateTypeNoList, this.applyParams.certificateTypeList);
    if (this._wiz.status != 8 || (!this._wiz.isCorrect || !this.applyParams.uploadedCertificateImageList || this.applyParams.uploadedCertificateImageList.length === 0)) {
      if (this.myFilesModel?.certificateType == undefined) {
        setTimeout(() =>
        this.myFilesModel = {
          certificateType: this.certificateTypeList[0]?.certificateType,
          year: undefined,
          month: undefined,
          images: []
        }, 0);
      }

      return;
    }
   
    const { uploadedCertificateType, uploadedCertificateImageList } = this.applyParams;
    if (!this.myFilesModel.images || this.myFilesModel.images.length === 0) {
      // 画像情報の枠を作る
      this.myFilesModel = {
        certificateType: uploadedCertificateType,
        year: undefined,
        month: undefined,
        images: this.certificateTypeList.find(x => x.certificateType == uploadedCertificateType).certificateImageSettingList
          .filter(x => x.imageSetting)
          .map(x => ({ ...x, validatorMessage: x.imageSetting == 1 ? [`画像「${x.certificateImageName}」を選択してください。`] : [] }))
      };

      // 申請情報の画像情報を復元
      if (this.myFilesModel.images && this.myFilesModel.images.length > 0) {
        for (let index = 0; index < this.myFilesModel.images.length; index++) {
          const imagedata = uploadedCertificateImageList.find((uploadData) => {
            return uploadData.certificateImageNum === index + 1;
          });
          if (imagedata) {
            this.myFilesModel.images[index].certificateImageData = CertificateImageService.IMAGE_HEADER + imagedata.certificateImageData;
            this.myFilesModel.images[index].fileName = '';
            this.myFilesModel.images[index].fileSize = 100;
            this.myFilesModel.images[index].validatorMessage = [];
          }
        }
      }
    }
    this.initCertificate = {
      certificateType: uploadedCertificateType,
      year: undefined,
      month: undefined,
      images: this.myFilesModel.images.map(x => ({...x}))
    };
  }

  private setSelectInfo() {
    const {
      selectedMonthNum,
      selectedTargetMonth,
      selectedBuyCard,
      selectedPasscardValidFrom,
    } = this.applyParams; 

    const passcardValidTo = getPasscardValidEndDay(new Date(selectedPasscardValidFrom), selectedMonthNum);
    this.model.passcardMonth = selectedMonthNum;
    this.model.passcardValidFrom = selectedPasscardValidFrom;
    this.model.passcardValidTo = passcardValidTo;
    this.model.selectTargetMonth = selectedTargetMonth;
    const { passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay } = this.applyParams;
    const price = this.passcardSelected.paymentDivisionList.find(x => x.monthNum == selectedMonthNum)?.unitPrice;
    const updatablePeiod = calcUpdatablePeriod(new Date(passcardValidTo), passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay);
    this.model.passcardUpdateFrom = updatablePeiod[0];
    this.model.passcardUpdateTo = updatablePeiod[1];
    if (!(this.model.buyICCard >= 0)) {
      this.model.buyICCard = selectedBuyCard;      
    }
    const targetMonths = ['選択なし', '当月から', '翌月から'];
    const validPeriod = formatPeriod([selectedPasscardValidFrom, passcardValidTo]);
    this.selectedInfo = {
      passcardMonth: `${selectedMonthNum}ヵ月(${this._currencyPipe.transform(price, 'JPY')})`,
      applyUseStart: this.applyParams.applyUseStartMethod == 0 ? targetMonths[selectedTargetMonth] : validPeriod.split('～')[0],
      validPeriod,
      updatablePeriod: formatPeriod(updatablePeiod)
    }
  }

  private calcPeriod() {
    const { selectTargetMonth, passcardMonth } = this.model;
    if (passcardMonth <= 0) return;

// 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応Start
//    this.model.passcardValidFrom = getPeridStart(this.applyParams.systemDate)[selectTargetMonth - 1];
    if (this.applyParams.applyUseStartMethod == 0 && selectTargetMonth < 1) return;
    if (this.applyParams.applyUseStartMethod == 1 && !this.model.passcardValidFrom) return;

    if (this.applyParams.applyUseStartMethod == 0) {
      this.model.passcardValidFrom = getPeridStart(this.applyParams.systemDate)[selectTargetMonth - 1];
    }
// 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応End

    this.model.passcardValidTo = getPasscardValidEndDay(new Date(this.model.passcardValidFrom), passcardMonth);
    this.fixedPeriod = formatPeriod([this.model.passcardValidFrom, this.model.passcardValidTo]);
    const { passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay } = this.applyParams;
    const updatablePeiod = calcUpdatablePeriod(new Date(this.model.passcardValidTo), passcardUpdatePeriod, passcardUpdateStartDay, passcardUpdateEndDay);
    this.model.passcardUpdateFrom = updatablePeiod[0]
    this.model.passcardUpdateTo = updatablePeiod[1];
    this.updatablePeriod = formatPeriod(updatablePeiod);
  }
}
