import { Component, ViewChild, inject } from '@angular/core';
import { NavigateService } from 'src/app/services';
import { AppModel } from 'src/app/app.model';
import { PaymethodSelectComponent } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { ApplyComponent } from '../../apply.component';
import { Location } from '@angular/common';

@Component({
  selector: 'apply02',
  templateUrl: './apply02.component.html'
})
export class Apply02Component {
  dcParkingNo: number | null;
  api = inject(PasscardApiProxy);
  status: number;
  
  @ViewChild(PaymethodSelectComponent) payMethodSelect!: PaymethodSelectComponent;

  constructor(public app: AppModel,
    private _navi: NavigateService,
    private _wiz: ApplyComponent,
    l: Location) {
    const state = l.getState();
    this.dcParkingNo = state['dcParkingNo'] ?? null;
    this.status = state['status'];
  }

  get IsComplete() {
    return this._wiz.complete;
  }

  get nextDisabled() {
    const payMethod = this.payMethodSelect?.model;
    return this.api.Disabled || !payMethod || payMethod.method == -1 || !!payMethod.error;
  }
  
  onBack() {
    const nextArgs: any = { status: this.status } 
    if (this._wiz.paramsReApply) {
      Object.assign(nextArgs, { dcParkingNo: this._wiz.paramsReApply.dcParkingNo })
      this._navi.navigate(`/passcard/new/reApply/${this.dcParkingNo}`, false, nextArgs);
      return;
    }

    this._navi.navigate('/passcard/new/step/1', false, nextArgs);
  }

  async onNext() {
    if (!await this.payMethodSelect.validate()) return;

    this._wiz.maxProcessed = Math.max(this._wiz.maxProcessed, 2);
    this._wiz.paymentType = this.payMethodSelect.model.method;
    this._wiz.saveState();
    this.payMethodSelect.saveModel();
    const nextArgs: any = { status: this.status } 
    if (this._wiz.paramsReApply) {
      Object.assign(nextArgs, { dcParkingNo: this._wiz.paramsReApply.dcParkingNo })
    }

    this._navi.navigate('/passcard/new/step/3', false, nextArgs);
  }
}
