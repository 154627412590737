import { Component, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { Location } from '@angular/common';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { NavigateService, StorageService } from 'src/app/services';
import { PaymentType } from '../pay/pay.model';
import { PasscardApply } from '../models/apply-info';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'lost',
  templateUrl: './lost.component.html'
})
export class LostComponent {
  private _storage = inject(StorageService);
  passcardApply: PasscardApply;

  maxProcessed = 0;
  processing = 0;
  paymentType: number = PaymentType.UnSelect;
  complete = false;
  passcardApplyNo: number | null = null;

  constructor(
    public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    router: Router,
    location: Location) {
    const navigateState = location.getState();
    this.passcardApply = navigateState['contract'];
    this._storage.load(`lost-wiz`, this);
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { url } = <NavigationEnd>event;
        if (url.includes('/passcard/lost/step/4')) {
          this.processing = 3;
        }
        else if (url.includes('/passcard/lost/step/3')) {
          this.processing = 2;
        }
        else if (url.includes('/passcard/lost/step/2')) {
          this.processing = 1;
        }
        else {
          this.processing = 0;
        }

        if (url.includes('/passcard/lost/')) {
          this.saveState();
        }
      }
    });
  }

  get PayMethodLabel() {
    return PaymentType[this.paymentType];
  }

  saveState(): void {
    const { maxProcessed, processing, complete } = this;
    this._storage.save(`lost-wiz`, {
      processing,
      maxProcessed,
      complete,
      paymentType: this.paymentType,
      passcardApplyNo: this.passcardApplyNo,
      passcardApply: this.passcardApply
    });
  }


  async onToPayDetail() {
    const passcardApplyNo = this.passcardApplyNo;
    let to = 'ic-register';
    const payMethod = this.paymentType;
    switch (payMethod) {
      case PaymentType.Terminal:
        to = 'terminal-pay';
        break;
      case PaymentType.PayPay:
        to = '2pay-pay';
        break;
      case PaymentType.Cvs:
        to = 'conveni-pay';
        break;
      default:
        to = `ic-register/${passcardApplyNo}`;
        break;
    }

    let paymentInfo = null;
    if (payMethod != 1) {
      paymentInfo = await this.api.getPaymentInfo(payMethod, passcardApplyNo);
    }

    this._navi.navigate(`/passcard/${to}`, false, { passcardApplyNo, paymentInfo });
  }

  onToRegistIc() {
    this._navi.navigate(`/passcard/ic-register/${this.passcardApplyNo}`, false, { });
  }

  /**
  * 定期情報ページに戻る
  */
  onBackToContract() {
    this._navi.navigateByUrl('/passcard/list');
  }

}
