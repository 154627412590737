import { Component, Input, QueryList, ViewChildren, ElementRef, inject } from '@angular/core';
import { CertificateType, CertificateImage, ImageCheckResult } from '../certificate-file';
import { ValidateService } from 'src/app/services';
import { CompleteCheckRequest, PreSignedUrlRequest, CertificateImageService } from '../../services/certificateImage.service';
import { ApiService } from '../../services/api.service';

export interface MyFilesModel {
  certificateType: number;
  year?: number;
  month?: number;

  images: CertificateImage[];
}

export const EmptyImageUpload = Object.freeze({
  certificateType: undefined,
  year: undefined,
  month: undefined,
  images: undefined
});

//
@Component({
  selector: 'i-my-files',
  templateUrl: './i-my-files.component.html',
})
export class IMyFilesComponent {
  private readonly _valid = inject(ValidateService);
  private imageService = inject(CertificateImageService);
  private _certificateTypeList: CertificateType[] = [];
  private uploadFile: File;
  private selectFileFlg = false;

  @ViewChildren('upload') upload: QueryList<ElementRef<HTMLInputElement>>;

  @Input() set certificateTypeList(val: CertificateType[]) {
    this._certificateTypeList = val;
    this.certificateType = val?.[0];
  }

  get certificateTypeList() {
    return this._certificateTypeList;
  }

  @Input() certificateNextCheckDate?: number;
  @Input() initValue: MyFilesModel;
  @Input() model: MyFilesModel;

  @Input() set message(msg: ImageCheckResult) {
    this.expireMessageOuter = [];
    this.images.forEach(x => {
      if (!x.certificateImageData || x.certificateImageData === '') {
        x.validatorMessage = x.imageSetting == 1 && !x.fileSize ? [`画像「${x.certificateImageName}」を選択してください。`] : []
      }
    }
    );
    const msgs = msg?.certificateValidator;
    if (msgs) {
      msgs.validatorCertificateImageList.forEach(({ certificateImageNum, validatorMessageCertificateImageList }) => {
        const certificateImage = this.images.find(x => x.certificateImageNum == certificateImageNum)
        certificateImage.validatorMessage = certificateImage.validatorMessage.concat(validatorMessageCertificateImageList);
      })
      return;
    }
  }

  certificateType: CertificateType;
  msgYear: string[];
  msgMonth: string[];
  expireMessage: string[];
  expireMessageOuter: string[];

  

  constructor(
    private apiService: ApiService,
  ) { }

  get error() {
    return !!this.model.images.find(x => x.imageSetting == 1 && (!x.certificateImageData || x.certificateImageData === ''));
  }

  get images() {
    if (!this.model) return [];
    if (!this.model.images?.length) {
      this.initiaImages(this.certificateType);
    }
    return this.model.images;
  }

  /**
   * ファイル選択時の処理
   * @param item 
   * @param event 
   */
  async onSelectFile(item: CertificateImage, event: any) {
    if (!event.target.files.length || !event.target.files[0]) return;

    const reader = new FileReader();
    reader.onload = (e: any) => {
      // 画像データ表示
      this.selectFileFlg = true;
      item.certificateImageData = e.target.result;
    };
    // バリデーション
    item.validatorMessage = [];
    let originalFile: File = event.target.files[0];
    item.fileSize = originalFile.size;
    if (!this.validate(item)) {
      item.certificateImageData = "";
      return;
    }
    // ファイルサイズが1Mを超える場合、１Mに圧縮
    if (originalFile.size > 1024 * 1024) {
      this.uploadFile = await this.imageService.getCompressImageFileAsync(originalFile);
    } else {
      this.uploadFile = originalFile;
    }
    reader.readAsDataURL(this.uploadFile);
  }

  /**
   * 画像読み込み失敗時イベント
   * @param item 対象の証明書画像
   */
  onImageError(item: CertificateImage) {
    if (!this.selectFileFlg) {
      return;
    }else{
      this.selectFileFlg = false;
    }
    this.apiService.loadingOff();
    item.certificateImageData = '';
    item.fileName = '';
    item.fileSize = 0;
    item.validatorMessage.push('画像アップロードが失敗しました。もう一度「画像を選択する」からやり直してください。');
  }

  /**
   * 画像読み込み終了時イベント
   * @param item 対象の証明書画像
   */
  onloadImage(item: CertificateImage) {
    // エラーを消去
    item.validatorMessage = [];
    if (!this.selectFileFlg) {
      return;
    } else {
      this.apiService.loadingOn();
      this.selectFileFlg = false;
    }
    // 署名付きURLの取得
    const preSignedUrlReq: PreSignedUrlRequest = {
      certificateType: this.model.certificateType,
      certificateImageNum: item.certificateImageNum,
      contentType: this.uploadFile.type,
      contentLength: this.uploadFile.size
    }

    this.imageService.getPreSignedUrl(preSignedUrlReq).subscribe((preSignedUrlRes) => {
      if (preSignedUrlRes.resultCode !== 0) {
        // 失敗の場合
        this.onFileUploadError(item, preSignedUrlRes.mainMessage);
        return;
      }
      const index1 = preSignedUrlRes.presignedUrl.indexOf('image_');
      const index2 = preSignedUrlRes.presignedUrl.indexOf('?', index1);
      const fileName = preSignedUrlRes.presignedUrl.substring(index1, index2);
      item.objectName = preSignedUrlRes.objectName;
      item.fileName = fileName;
      // ファイルのアップロード
      this.imageService.uploadTemporaryImageFile(preSignedUrlRes.presignedUrl, this.uploadFile).subscribe({
        next: () => {
          // nextは2回発生するのでここでは処理しない
        },
        error: () => {
          // エラー発生時
          this.onFileUploadError(item);
        },
        complete: async () => {
          // ファイルチェック結果取得
          const request: CompleteCheckRequest = {
            certificateImageNum: item.certificateImageNum,
            objectName: item.objectName,
            fileName: item.fileName
          }
          // 失敗した場合は初回を含め最大10回リトライ
          let loopcount = 0;
          const id = setInterval(async () => {
            loopcount = loopcount + 1;
            if (loopcount > 10) {
              // リトライ上限
              this.onFileUploadError(item);
              clearInterval(id);
              return;
            }

            const result = await this.imageService.checkUploadFile(request);
            if (result && result.resultCode === 0) {
              if (result.checkResult === 0) {
                // 成功の場合
                item.fileName = fileName;
                item.fileSize = this.uploadFile.size;
                this.apiService.loadingOff();
                clearInterval(id);
              }
            } else {
              // エラー発生
              clearInterval(id);
              this.onFileUploadError(item, result.mainMessage);
              return;
            }
          }, 1000);
        }
      });
    });
  }

  /**
   * ファイルアップロード時エラー処理
   * @param item 
   */
  private onFileUploadError(item: CertificateImage, message = '') {
    this.apiService.loadingOff();
    if (message && message !== '') {
      item.validatorMessage = [message];
    } else {
      item.validatorMessage = ['ファイルのアップロードに失敗しました。'];
    }
    item.certificateImageData = '';
    item.fileName = '';
    item.fileSize = 0;
  }


  onFileCancel(item: CertificateImage) {
    item.certificateImageData = '';
    item.fileName = null;
    item.fileSize = null;
    item.validatorMessage = [];
    if (item.imageSetting == 1) {
      item.validatorMessage = [`画像「${item.certificateImageName}」を選択してください。`];
    }

    const element = this.upload.find(x => +x.nativeElement.id == item.certificateImageNum);
    if (element?.nativeElement) element.nativeElement.value = '';
  }

  onFilesKindChange() {
    this.clear(this.model.certificateType);
  }

  private initiaImages(certificateType: CertificateType) {
    this.model.images = certificateType?.certificateImageSettingList
      .map(x => ({ ...x, validatorMessage: x.imageSetting == 1 ? [`画像「${x.certificateImageName}」を選択してください。`] : [] }));
  }

  validate(item: CertificateImage): boolean {
    item.validatorMessage = [];

    if (item.fileName && item.fileName.length > 256) {
      item.validatorMessage.push(this._valid.get(this._valid.string.maxLength, 'ファイル名', '256'));
    }

    this._valid.pushProhibit(item.validatorMessage, item.fileName, 'ファイル名');

    if (item.fileSize > 1024 * 1024 * 5) {
      item.validatorMessage.push('ファイルサイズが5[MB]を超えています。');
    }

    if (item.fileSize === 0) {
      item.validatorMessage.push('画像「' + item.certificateImageName + '」を選択してください。');
    }

    if (item.fileName && /\.(jpg|png|jpeg)$/i.test(item.fileName) != true) {
      item.validatorMessage.push('png、jpeg形式の画像ファイルを選択してください。');
    }

    if (item.validatorMessage.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  clear(certificateType?: number) {
    this.upload.forEach(x => x.nativeElement.value = '');
    certificateType ??= this.certificateTypeList[0]?.certificateType;
    this.model.certificateType = certificateType;
    this.certificateType = this.certificateTypeList.find(x => x.certificateType == certificateType);
    this.model.year = undefined;
    this.model.month = undefined;

    this.initiaImages(this.certificateType);
  }
}