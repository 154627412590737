import { ChangeDetectorRef, Component, OnInit, ViewChild, inject } from '@angular/core';

import { ApiService, NavigateService, StorageService } from '../../../services';
import { IMyFilesComponent, MyFilesModel } from 'src/app/shared/i-my-files/i-my-files.component';
import { CertificateType, CertificateUploadSettings, CheckRes, ImageCheckResult } from '../../../shared/certificate-file';
import { Location } from '@angular/common';
import { CertificateUploadModel } from '../certificate-upload.model';
import { CertificateImageService, DownloadTemporaryImageFileRequest, DownloadTemporaryImageFileResponse } from '../../../services/certificateImage.service';
import { Subject } from 'rxjs';
import { CertificateImage } from '../../../shared/certificate-file';
//
@Component({
  selector: 'fi-config',
  templateUrl: './config.component.html',
  providers: [CertificateUploadModel]
})
export class ConfigComponent implements OnInit {
  private readonly _storage = inject(StorageService);
  private _cdRef = inject(ChangeDetectorRef);
  certificateNextCheckDate: number;
  certificateURL: string;
  certificateTypeList: CertificateType[];
  myFilesModel: MyFilesModel;
  error: string;
  imageCheckResult: ImageCheckResult;
  imageDownloadFinish: Subject<number> = new Subject<number>();

  @ViewChild(IMyFilesComponent) myFiles!: IMyFilesComponent;
  //
  constructor(
    public model: CertificateUploadModel,
    public api: ApiService,
    public navi: NavigateService,
    private certificateImageService: CertificateImageService,
    l: Location
  ) {
    const state = <CertificateUploadSettings>l.getState();
    this.certificateTypeList = [...state.certificateTypeList];
    this.certificateNextCheckDate = state.certificateNextCheckDate;
    this.certificateURL = state.certificateURL;
    this.certificateTypeList.forEach(x => {
      x.certificateImageSettingList = x.certificateImageSettingList.filter((z) => z.imageSetting && z.imageSetting !== 0);
      x.certificateImageSettingList = x.certificateImageSettingList.map((y, i) => ({ ...y, certificateImageNum: i + 1 })).filter(x => x.imageSetting);
    });
  }

  get images() {
    return this.myFilesModel.images;
  }

  ngOnInit() {
    this.setFileModel(this.model.certificateType);

    // 画像一時ファイルがある場合は取得
    if (this.myFilesModel && this.myFilesModel.images.length > 0) {
      // ダウンロード終了監視用データ作成
      const imageList: { imageNum: number; finish: boolean }[] = [];
      this.myFilesModel.images.forEach((image) => {
        imageList.push({ imageNum: image.certificateImageNum, finish: false });
      });

      // 画像ダウンロード終了監視
      const subscripttion = this.imageDownloadFinish.subscribe((imageNumber) => {
        if (imageNumber === -1) {
          // エラー発生
          this.api.loadingOff();
          subscripttion.unsubscribe();
        } else {
          // 終わったものを終了に
          const image = imageList.find((image) => image.imageNum === imageNumber);
          if (image) {
            // 完了を設定
            image.finish = true;
          }
          // 全部終わったか(未完了のものがないか)
          if (!imageList.some((image) => image.finish === false)) {
            this.api.loadingOff();
            subscripttion.unsubscribe();
          }
        }
      });
      // 画像ダウンロード実施
      this.api.loadingOn();
      this.downLoadImage(this.myFilesModel.images);
    }
  }

  /**
 * 画像データをダウンロードする
 * @param certicateImages ダウンロードするイメージデータ
 */
  private downLoadImage(certicateImages: CertificateImage[]) {
    certicateImages.forEach((certicateImage) => {
      if (certicateImage.fileSize > 0) {
        // 画像がアップロードされている場合
        const request: DownloadTemporaryImageFileRequest = {
          certificateImageNum: certicateImage.certificateImageNum,
          fileName: certicateImage.fileName,
          objectName: certicateImage.objectName,
        };
        // アップロード済みの画像データがある場合はダウンロードする
        this.certificateImageService.downLoadTemporaryImageFile(request).subscribe(
          (response: DownloadTemporaryImageFileResponse) => {
            if (response.resultCode === 0) {
              // 正常終了
              certicateImage.certificateImageData = CertificateImageService.IMAGE_HEADER + response.certificateImageData;
              this.imageDownloadFinish.next(certicateImage.certificateImageNum);
            } else {
              // 異常終了
              this.imageDownloadFinish.next(-1);
            }
          });
      } else {
        // 画像がアップロードされていない場合
        this.imageDownloadFinish.next(certicateImage.certificateImageNum);
      }
    });
  }

  setFileModel(certificateType: number) {
    if (!this.certificateTypeList.find(x => x.certificateType == certificateType)) {
      certificateType = this.certificateTypeList[0]?.certificateType ?? 0;
    }

    this.myFilesModel = {
      certificateType: certificateType,
      year: this.model.year,
      month: this.model.month,
      images: this.model.images,
    };
    setTimeout(() => {
      this.myFilesModel.images = [...this.model.images];
      this._cdRef.detectChanges();
    }, 0);
  }
  onClear() {
    this.imageCheckResult = null;
    this.error = '';
    this.myFiles.clear();
    this._cdRef.detectChanges();
  }

  private afterPost(body: CheckRes) {
    if (body.resultCode === 0) {
      this.model.images = this.images;
      this.model.certificateType = this.myFilesModel.certificateType;
      // 画像データを削除
      if (this.model.images && this.model.images.length > 0) {
        this.model.images.forEach((image) => {
          image.certificateImageData = '';
        })
      }
      this.model.saveStorage();
      this.navi.navigate(`passcard/certificate-upload/confirm`, false, {
        certificateNextCheckDate: this.certificateNextCheckDate,
        certificateURL: this.certificateURL,
        certificateTypeList: this.certificateTypeList
      });

    } else if (body.resultCode === 1 || body.resultCode === 2 || body.resultCode === 3) {
      this.navi.navigateByUrl('/error', true);

    } else {
      this.api.enabled();
      this.error = body.mainMessage;
      this.imageCheckResult = body;
    }
  }

  // 戻るボタン押下時処理
  onBack() {
    const contract = this._storage.load('reupload-settings', null)?.state;
    this.navi.navigate('/passcard/contract', false, { contract });
  }

  // 確認ボタン押下時処理
  onApply() {
    this.error = null;
    const checkres: CheckRes = {
      resultCode: 0,
      mainMessage: '',
      certificateValidator: null,
    }
    this.afterPost(checkres);
  }
}
