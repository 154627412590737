import { Location } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'proc-succ',
  templateUrl: './proc-success.component.html'
})
export class ProcSuccessComponent {
  message1: string;
  message2: string;
  message3: string;
  receiptNo: string;
  title: string;
  constructor(location: Location) {
    const state = location.getState();
    this.message1 = state['message1'];
    this.message2 = state['message2'];
    this.message3 = state['message3'];
    this.receiptNo = state['receiptNo'];
    this.title = state['title'];
  }
}
