import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, NavigateService } from 'src/app/services';
import { PayModel } from '../../models/pay.model';

@Component({
  selector: 'redirect-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {
  query: string;

  constructor(
    private route: ActivatedRoute,
    private pay: PayModel,
    private navi: NavigateService,
    public api: ApiService
  ) { }

  ngOnInit() {
    this.pay.resetAll();
    this.route.queryParams.subscribe(
      params => this.query = JSON.stringify(params, null, ' '));
  }

  onTop() {
    this.navi.navigateByUrl('');
  }

  // todo 最低2パターン必要
  // 1. PayPay内部の処理が終わっていない
  // 2. レシート情報が取得できない
  //  どっちもresultCode===7説あり
}
