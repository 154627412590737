import { Component, OnInit } from '@angular/core';
import { ApiService, NavigateService, StorageService } from '../../services';
import { NoticeInfoService, NoticeInfo } from '../../services/notice-info.service';


@Component({
  selector: 'app-info-list',
  templateUrl: './list.component.html',
})
export class ListComponent implements OnInit {

  // ���m�点�\���ő匏��
  readonly NOTICE_MAX_COUNT = 20;

  noticeInfoList: NoticeInfo[] = [];
  allNoticeInfoList: NoticeInfo[] = [];

  pageModel = {
    pages: 0,
    countPerPage: this.NOTICE_MAX_COUNT,
    page: 1,
  }

  constructor(
    public api: ApiService,
    public navi: NavigateService,
    private noticeService: NoticeInfoService,
    private storageService : StorageService,
  ) { }

  ngOnInit() {
    // ���m�点���擾
    this.allNoticeInfoList = this.storageService.load(NoticeInfoService.LOCAL_STORAGE_KEY_NORMAL_NOTICEINFO, this.allNoticeInfoList);
    this.pageModel.page = 1;
    if (this.allNoticeInfoList && this.allNoticeInfoList.length > 0) {
      this.pageModel.pages = Math.ceil(this.allNoticeInfoList.length / this.pageModel.countPerPage);
      this.setPageData();
    }
  }

  /**
   * �e���m�点�^�C�g���������C�x���g
   * @param id ���m�点No
   */
  onDetail = (id: number) => {
   // �I�����ꂽ���m�点�����[�J���X�g���[�W�֕ۑ�
    this.storageService.save(NoticeInfoService.LOCAL_STORAGE_KEY_NOTICEINFO , this.noticeInfoList[id]);
    window.open(this.noticeService.getNoticeDetailPath(), '_blank');
  }

  /**
   * �y�[�W�N���b�N���C�x���g
   * @param $event 
   */
  onPage($event) {
    this.pageModel.page = $event;
    this.setPageData();
  }

  /**
   * �\���f�[�^�ݒ�
   */
  private setPageData() {
    // �\���J�n�ʒu�E�I���ʒu�̌v�Z
    const startPos = (this.pageModel.page - 1) * this.pageModel.countPerPage;
    let endPos = startPos + this.pageModel.countPerPage - 1;
    // �I���ʒu����
    if (this.allNoticeInfoList.length - 1 < endPos) {
      endPos = this.allNoticeInfoList.length - 1;
    }
    // �\���f�[�^�ݒ�
    this.noticeInfoList = [];
    if (this.allNoticeInfoList.length >= startPos) {
      for (let i = startPos; i <= endPos; i++) {
        this.noticeInfoList.push(this.allNoticeInfoList[i]);
      }
    }
   }

}

