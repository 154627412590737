<div class="passcard-app-title"><h2>定期情報</h2><div>
  <a class="passcard-link" *ngIf="isPast; else current" routerLink="/passcard/list">定期情報></a>
  <ng-template #current>
    <a class="passcard-link" routerLink="/passcard/past-passcards">過去の定期</a>
  </ng-template>
</div></div>
<ng-container *ngIf="tipVisible">
  <status-tip [applyInfo]="contract" (click)="onTipClick()" #tipStatus/>
  <br>
</ng-container>
<contract-detail [contract]="contract">
    <ng-container *ngIf="!isPast">
      
      <ng-container *ngIf="detailBtnVisible">
        <div class="buttonBoxVertical">
          <loading-button class="six" label="申請中の情報を表示する"
              (apply)="onApplyDetail()"
              [icon]="true"
              [disabled]="NextDisabled"
              [loading]="api.Disabled"
              [class]="api.Disabled ? 'cmn-btn' : 'cmn-btn icon'"
              [lClass]="'button-box-loading'"><i class="icon left sign"></i><span>申請中の情報を表示する</span></loading-button>
        </div>      
        <br>    
    </ng-container>
    <div class="buttons">
      <div class="large-button apply-button" *ngIf="contract.status == ApplyStatus.WaitingICRegistForLost" (click)="onICRegister()">
        <label title="ICカード登録">
            <div><i class="icon Suica"></i></div>
          <div>ICカード登録</div>
        </label>
    </div>
    <div class="large-button apply-button" *ngIf="PayButtonVisible" (click)="onPay()">
        <label title="お支払い">
          <div><i class="icon money-circle"></i></div>
          <div>お支払い</div>
        </label>
      </div>
      <div class="large-button apply-button" *ngIf="ReapplyButtonVisible" (click)="onReApply()">
        <label title="定期申請">
            <div><i class="icon note-pen"></i></div>
            <div>定期申請</div>
        </label>
      </div>
      <div class="large-button" *ngIf="RefreshButtonVisible" [class.apply-button]="refreshRedDotShow" (click)="onRefresh()">
        <label title="更新">
          <div><i class="icon refresh"></i></div>
          <div>更新</div>
        </label>
      </div>
      <div class="large-button" *ngIf="LostButtonVisible" (click)="onLost()">
        <label title="紛失">
          <div><i class="lost"></i></div>
          <div>紛失</div>
        </label>
      </div>
      <div class="large-button" *ngIf="SealIssueButtonVisible" (click)="onSealReissue()">
        <label title="シール発行">
          <div><i class="icon ticket"></i></div>
          <div class="label-two"><div>シール</div><div>発行</div></div>
        </label>
      </div>
      <div class="large-button" *ngIf="CancelButtonVisible" (click)="onCancelConfirm()">
        <label title="解約">
          <div><i class="icon cancel"></i></div>
          <div>解約</div>
        </label>
      </div>
      <div class="large-button"  *ngIf="CertificateUploadButtonVisible" (click)="onReUploadFiles()">
        <label title="証明書等再提出">
          <div><i class="icon resume"></i></div>
          <div class="label-two"><div>証明書等</div><div>再提出</div></div>
        </label>
      </div>
    </div>
    <br>
  </ng-container>
</contract-detail>
<br>
<notice-info [parkingNoticeInformation]="noticeInfo"></notice-info>

<popup-modal #dlg>
  <div class="cancel-constract-modal">
    <div><i class="icon info"></i></div>
    <div>更新期間外です。</div>
    <div class="comment">
      <div>更新可能期間({{changePeriod}})にお手続きをお願いいたします。</div>
    </div>
    <div>
        <button type="button" class="cmn-btn bck-btn six" (click)="onDlgClose()">閉じる</button>
    </div>
  </div>
</popup-modal>