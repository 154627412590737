import { Component, DestroyRef, inject } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { Location } from '@angular/common';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { NavigateService, StorageService } from 'src/app/services';
import { PaymentType } from '../pay/pay.model';
import { PasscardApply } from '../models/apply-info';
import { NavigationEnd, Router } from '@angular/router';
import { Passcard } from '../models/passcard';
import { ReceptionMode } from '../passcard.model';

/**
 * 定期更新
 */
@Component({
  selector: 'refresh',
  templateUrl: './refresh.component.html'
})
export class RefreshComponent {
  private readonly _storage = inject(StorageService);
  passcardApply: PasscardApply;
  passcard: Passcard;

  /**
   *  6：(自動承認)定期更新申請
   * 10：(手動承認)承認前の定期更新申請を行う場合
   * 11：(手動承認)承認後の定期更新申請を行う場合
   */
  status = 6;
  dcParkingNo: number;
  processing = 0;
  paymentType: number = PaymentType.UnSelect;
  complete = false;
  passcardApplyNo: number | null = null;
  
  constructor(
    public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    location: Location) {

    const navigateState = location.getState();
    
    this.passcardApply = navigateState?.['contract'];
    this._storage.load(`refresh-wiz`, this);
    const subs = inject(Router).events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const { url } = <NavigationEnd>event;
        if (url.includes('/passcard/refresh/step/4')) {
          this.processing = 3;
        }
        else if (url.includes('/passcard/refresh/step/3')) {
          this.processing = 2;
        }
        else if (url.includes('/passcard/refresh/step/2')) {
          this.processing = 1;
        }
        else {
          this.processing = 0;
        }

        if (url.includes('/passcard/refresh')) {
          this.saveState();
        }
      }
    });
    
    inject(DestroyRef).onDestroy(() => subs.unsubscribe());
  }

  get PayMethodLabel() {
    return PaymentType[this.paymentType];
  }

  async onToPayDetail() {
    const passcardApplyNo = this.passcardApplyNo;
    let to = 'ic-register';
    switch (this.paymentType) {
      case PaymentType.CreditCard:
        to = `ic-register/${passcardApplyNo}`;
        break;
      case PaymentType.PayPay:
        to = '2pay-pay';
        break;
      case PaymentType.Cvs:
        to = 'conveni-pay';
        break;
    }

    let paymentInfo = null;
    if (this.paymentType != 1) {
      paymentInfo = await this.api.getPaymentInfo(this.paymentType, passcardApplyNo);
    }

    this._navi.navigate(`/passcard/${to}`, false, { passcardApplyNo, paymentInfo });
  }

  /**
  * 定期情報ページに戻る
  */
  onBackToContract() {
    this._navi.navigateByUrl('/passcard/list');
  }

  saveState(): void {
    const { status, dcParkingNo, passcard, processing, complete } = this;
    this._storage.save(`refresh-wiz`, {
      status,
      dcParkingNo,
      processing,
      complete,
      passcard,
      paymentType: this.paymentType,
      passcardApplyNo: this.passcardApplyNo,
      passcardApply: this.passcardApply
    });
  }
}
