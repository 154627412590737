import { Injectable, inject } from "@angular/core";
import { NavigateService, StorageService } from "src/app/services";
import { PasscardApply } from "../models/apply-info";
import { getStorageKey } from "../passcard.model";

@Injectable()
export class ChangeConfirmService {
    private readonly _navi = inject(NavigateService);
    private readonly _storage = inject(StorageService);

    private _dcParkingNo: number;
    private _applyInfo: PasscardApply;
    

    prepare(applyInfo: PasscardApply) {
        this._applyInfo = applyInfo;
        const {
            passcardApplyNo,
            passcardType,
            passcardMonth,
            selectTargetMonth,
            passcardValidFrom,
            passcardValidTo,
            buyICCard,
            dcParkingNo,
            parkingName,
            parkingAddress,
          } = applyInfo;
        this._storage.save(`passcard-applyinfo`, {
            applyStatus: 8,
            action: 'correct',
            passcardApplyNo,
            passcardType,
            passcardMonth,
            selectTargetMonth,
            passcardValidFrom,
            passcardValidTo,
            buyICCard,
            dcParkingNo,
            parkingName,
            parkingAddress,
           });

        this._dcParkingNo = dcParkingNo;
    }

    doCorrect() {
        const {
            passcardMonth,
            selectTargetMonth,
            passcardValidFrom,
            passcardValidTo,
            buyICCard,
            selectedBuyCard,
          } = this._applyInfo;
        const key = getStorageKey('passcard-apply-1', 8, this._dcParkingNo);
        this._storage.save(key, {
            passcardMonth,
            selectTargetMonth,
            passcardValidFrom,
            passcardValidTo,
            buyICCard: buyICCard ?? selectedBuyCard,
        });
        this._storage.remove('apply-wiz');
        this._navi.navigateByUrl('/passcard/correct');
    }
}