import { Component, OnDestroy } from '@angular/core';
import { AppModel } from 'src/app/app.model';
import { DeviceService, NavigateService } from 'src/app/services';
import { Location } from '@angular/common';
import { PasscardApiProxy } from '../../passcard.api.proxy';
import { PayFeeDetail } from '../../common/p-pay-fees/p-pay-fees.component';
import { createReceiptPdf, downloadDetailPdf } from '../receipt-issue/createpdf';
import { PaymentDetail } from '../models/PaymentDetail.model';
import { PaymentType, PaymentContentName } from '../pay.model';
import { PayHistroyService } from '../pay-list/pay-histroy.service';

@Component({
  selector: 'pay-detail',
  templateUrl: './pay-detail.component.html'
})
export class PayDetailComponent implements OnDestroy {
  PCN = PaymentContentName;
  private _objUrl = '';
  disPC: boolean;
  paidDetail: PaymentDetail;
  feeDetail: PayFeeDetail;
  paymentMethodsDetails: { contentsName: string, contents: string }[];

  constructor(public app: AppModel,
    private _navi: NavigateService,
    private _paymentService: PayHistroyService,
    public api: PasscardApiProxy,
    device: DeviceService,
    location: Location) {
    this.paidDetail = <PaymentDetail>location.getState();
    this.disPC = device.getDevice() == 'other';
    this._objUrl = this.paidDetail.objUrl;
    this.initialize();
  }

  ngOnDestroy(): void {
    this.clearPdfResource();
  }

  async onReceiptIssue() {
    const { processDt, processMs } = this.paidDetail;
    if (this.paidDetail.receiptAddressNameFlg !== 0) {
      downloadDetailPdf(async() => {
        const receipt = await this.api.getReceipt(processDt, processMs);
        this.clearPdfResource();
        this.api.Disabled = true;
        this._objUrl = await createReceiptPdf(receipt);
        this.api.Disabled = false;
        return this._objUrl;  
      });
    }

    const cacheState = <any>this.paidDetail;
    this._navi.navigate('/payment/receipt', false, { processDt, processMs, cacheState });
  }

  onBack() {
    const { conditionsPeriod, currentPage } = this._paymentService;
    this._navi.navigate('payment/list', false, { conditionsPeriod, currentPage });
  }

  private clearPdfResource() {
    if (this._objUrl) {
      window.URL.revokeObjectURL(this._objUrl)
    }
  }

  private initialize() {
    const meta = [
      { item: 'newIssueCommission', title: '新規発行手数料' },
      { item: 'reIssueCommission', title: '再発行手数料' },
      { item: 'buyICCardCommission', title: 'ICカード購入手数料' },
      { item: 'convenienceCommission', title: 'コンビニ支払い手数料' },
    ];

    this.feeDetail = {
      paymentFeeDetails: [],
      paymentAmount: this.paidDetail.paymentFee
    }

    if (this.paidDetail.paymentContentsCode <= 2
      && !!this.paidDetail.passcardAmount) {
      const content1 = PaymentContentName[this.paidDetail.paymentContentsCode];
      this.feeDetail.paymentFeeDetails.push({
        contents: `${content1}(${this.paidDetail.passcardMonth}か月)`,
        Fee: this.paidDetail.passcardAmount
      });
    }

    meta.forEach(item => {
      const fee = this.paidDetail[item.item];
      if (!!fee) {
        this.feeDetail.paymentFeeDetails.push({ contents: item.title, Fee: fee });
      }
    });

    switch (this.paidDetail.passcardPaymentType) {
      case PaymentType.CreditCard:
        const { creditCardNo, creditExpirationDate } = this.paidDetail;
        const expire = new Date(creditExpirationDate);
        const m = `${expire.getMonth()+1}`.padStart(2, '0');
        const year = `${expire.getFullYear()}`.substring(2);
        this.paymentMethodsDetails = [
          { contentsName: 'お支払い方法', contents: 'クレジットカード' },
          { contentsName: 'クレジットカード番号', contents: creditCardNo },
          { contentsName: '有効期限', contents: `${m}/${year}` }
        ]
        break;
      case PaymentType.PayPay:
        this.paymentMethodsDetails = [
          { contentsName: 'お支払い方法', contents: 'PayPay' }
        ]
        break;
      case PaymentType.Cvs:
        this.paymentMethodsDetails = [
          { contentsName: 'お支払い方法', contents: 'コンビニ' }
        ]
        break;
    }
  }
}
