import { Component, OnDestroy, inject } from '@angular/core';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { ReceiptIssueModel } from '../receipt-issue.model';
import { ReceiptIssueComponent } from '../receipt-issue.component';

@Component({
  selector: 'receipt-change-confirm',
  templateUrl: './change-confirm.component.html',
  providers: [ReceiptIssueModel]
})
export class ReceiptChangeConfirmComponent implements OnDestroy {
  private readonly _parent = inject(ReceiptIssueComponent);
  readonly api = inject(PasscardApiProxy);
  readonly model = inject(ReceiptIssueModel);

  ngOnDestroy(): void {
    this.api.error = '';
  }

  onBack(){
    this._parent.navigate('changing');
  }

  onIssue() {
    this.model.issue(true);
  }
}
