import { Routes } from "@angular/router";
import { ReceiptIssueComponent } from "./receipt-issue.component";
import { ReceiptChangeTipComponent } from "./change-tip/change-tip.component";
import { ReceiptChangingComponent } from "./changing/changing.component";
import { ReceiptChangeConfirmComponent } from "./change-confirm/change-confirm.component";

export const receiptIssueRoutes: Routes = [
    {
        path: 'passcard/apply-history/receipt', component: ReceiptIssueComponent, children: [
            { path: '', component: ReceiptChangeTipComponent },
            { path: 'tip', component: ReceiptChangeTipComponent },
            { path: 'changing', component: ReceiptChangingComponent },
            { path: 'confirm', component: ReceiptChangeConfirmComponent },
          ]
    }
  ];
  
  //
  export const receiptIssueComponents = [
    ReceiptIssueComponent,
    ReceiptChangeTipComponent,
    ReceiptChangingComponent,
    ReceiptChangeConfirmComponent
  ]
  