<block *ngIf="payInfoVisible" title="ご請求明細">
  <p-pay-fees [paidDetail]="paidDetail"></p-pay-fees>
  <br>
</block>
<block title="定期情報">
  <passcard-info [model]="wiz.passcard" [showStatus]="ShowPasscardStatus"></passcard-info>
  <ng-container *ngIf="Cach01.passcardMonth">
    <br>
    <div class="tableCtn">
      <p-single label="契約月数" value="{{Cach01.passcardMonth}}ヵ月({{passcardFee | currency:'JPY'}})" />
      <p-single label="定期期間" [value]="fixedPeriod" />
      <p-single label="更新可能期間" [value]="updatablePeriod" />
      <div class="tableLine">
        <div class="tableCell_l">ICカード購入</div>
        <div class="tableCell_r">
          <div class="tdCtn">
            <p>{{buycardStr}}</p>
            <p>(発行手数料：{{ buycardFee | currency:'JPY'}})</p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</block>
<div class="button-wrapper">
  <button type="button" class="cmn-btn bck-btn" (click)="onReInput(1)">定期情報を再選択する</button>
</div>
<place-info [parkingInfo]="parking"></place-info>
<br>
<block title="証明書等登録" *ngIf="showCertificateUpload">
  <p-my-files [certificateType]="certificateTypeSetting" [model]="myFilesModel"></p-my-files>
</block>
<br>
<ng-container *ngIf="payInfoVisible">
  <block title="お支払い情報">
    <p-pay-method [payInfo]="Cach02"></p-pay-method>
  </block>
  <br>
  <div *ngIf="!wiz.complete" class="button-wrapper">
    <button type="button" class="cmn-btn bck-btn" (click)="onReInput(2)">お支払い情報入力に戻る</button>
  </div>
  <br>
</ng-container>
<div *ngIf="!wiz.complete" class="buttonBox">
  <loading-button class="six"
    [label]="wiz.applyBtnCaption"
    (apply)="onApply()"
    [disabled]="api.Disabled"
    [loading]="api.Disabled"
    [class]="'cmn-btn dcd-btn six'"
    [lClass]="'button-box-loading'" />
</div>