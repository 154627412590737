<p-info *ngIf="service.processing == 2 && !isNeedConfirm">
    <p>以下の内容で定期申請を行います。</p>
    <p>内容を確認し、「申請」を押してください。</p>
</p-info>
<p-info kind="warning" *ngIf="service.processing == 2 && isNeedConfirm">
    <p>内容を確認し、「申請」を押してください。</p>
    <p>申請後、係員が申請内容の確認を行います。</p>
    <p>申請内容が確認出来次第、支払いのお手続きをお願い致します。</p>
</p-info>
<h2>定期更新</h2>
<flow-box *ngIf="service.processing < 2 || !isNeedConfirm"
    [steps]="steps"
    [processing]="service.processing" />
<router-outlet></router-outlet>

<ng-container *ngIf="service.processing == 3">
    <div class="buttonBoxVertical complete-btns">
        <loading-button *ngIf="!isNeedConfirm"
          label="{{PayMethodLabel}}情報を表示する"
          (apply)="onToPayDetail()"
          [disabled]="api.Disabled"
          [loading]="api.Disabled"
          [lClass]="'button-box-loading'"
          [class]="'cmn-btn six'" />
        <loading-button label="申請状況を確認する"
          (apply)="onToConfirm()"
          [disabled]="api.Disabled"
          [loading]="api.Disabled"
          [lClass]="'button-box-loading'"
          [class]="'cmn-btn bck-btn2 six'" />
      </div>    
</ng-container>
  