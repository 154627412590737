import { Component, OnInit, inject } from '@angular/core';

import { NavigateService, StorageService } from '../../services';
import { ConfigService } from '../../services/config.service';

import { AppModel } from '../../app.model';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-navi',
  templateUrl: './navi.component.html',
})
export class NaviComponent implements OnInit {
  active = -1;
  get btn1Caption() {
    return !this.app.login ? '会員登録' : '定期券';
  };
  get btn2Caption() {
    return !this.app.login ? 'ログイン' : 'マイページ';
  };

  get btn1Icon() {
    if (this.active == 0) {
      return !this.app.login ? 'navi-register-sel' : 'navi-passcard';
    }

    return !this.app.login ? 'navi-register' : 'certi-card';
  }

  get btn2Icon() {
    if (this.active == 2) {
      return !this.app.login ? 'navi-login' : 'user';
    }

    return !this.app.login ? 'navi-login' : 'navi-mypage';
  }

  constructor(
    public app: AppModel,
    public api: PasscardApiProxy,
    public conf: ConfigService,
    private _navi: NavigateService,
    private _storage: StorageService,
    private _router: Router
  ) {
    this.setActive(this._router.url);
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.setActive(event.url);
      }
    });
  }

  setActive(url: string){
    this.active = -1;
    if ((url.startsWith('/passcard/') && !url.startsWith('/passcard/apply-history'))
    || url.startsWith('/registration/')) {
      this.active = 0;
    }
    else if (url.startsWith('/passcard/apply-history')) {
      this.active = 1;
    }
    else if (url.startsWith('/mypage/')
    || url.startsWith('/login')) {
      this.active = 2;
    }
  }

  //
  ngOnInit() { }
  onTopBtn1Click() {
    if (this.app.login) {
      this._navi.navigateByUrl('/passcard/list');
    } else {
      // 会員登録完了後の画面遷移先切替対応
      const url_path = location.pathname;
      this._storage._save('transitionSourceURL', url_path);
      this._navi.navigateByUrl('/registration/mail');
    }
  }

  //
  onTopBtn2Click() {
    if (this.app.login) {
      this._navi.navigateByUrl('/mypage/top');
    } else {
      this._navi.navigateByUrl('/login');
    }
  }

  onApplyInfoClick() {
    this._storage.remove('ApplyListModel');
    this._navi.navigateByUrl('/passcard/apply-history/list');
  }
  
  //
  onNavigate = (e: Event, url: string) => {
    e.preventDefault();
    this._navi.navigateByUrl(url);
  }

  onReserve = (e: Event) => {
    e.preventDefault();
  }

  onCurrent = (e: Event) => {
    e.preventDefault();
  }

  onPast = (e: Event) => {
    e.preventDefault();
  }

  onQR = (e: Event) => {
    e.preventDefault();
  }

  onOpenUrl = (e: Event, url: string, name: string) => {
    e.preventDefault();
  }

}
