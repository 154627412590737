import { Component, inject, OnInit , OnDestroy } from '@angular/core';
import { PasscardStatus, PeriodKind, formatPeriod, getStorageKey , StorageKey3dsecure , ApplyType} from '../../../passcard.model';
import { AppModel } from 'src/app/app.model';
import { StorageService } from 'src/app/services';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { PayFeeDetail } from 'src/app/passcard/common/p-pay-fees/p-pay-fees.component';
import { PaymentType } from 'src/app/passcard/pay/pay.model';
import { ParkingInfo } from 'src/app/passcard/models/apply-info';
import { ApplyComponent } from '../../apply.component';
import { getApplyPaymentItems } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';
import { CertificateType } from 'src/app/shared/certificate-file';
import { MyFilesModel } from 'src/app/shared/i-my-files/i-my-files.component';
import { CertificateImageService, 
  DownloadTemporaryImageFileRequest , 
  DownloadTemporaryImageFileResponse,
  DownloadImageFileRequest,
  DownloadImageFileResponse
} from '../../../../services/certificateImage.service';
import { CertificateImage } from '../../../../shared/certificate-file';
import { Subject } from 'rxjs';
import { ApiService } from '../../../../services/api.service';

const buycardStrs = Object.freeze({
  '0': 'お手持ちの交通系ICカードを定期券として利用する',
  '1': '駐輪場専用のICカードを購入して定期券として利用する'
});

@Component({
  selector: 'apply03',
  templateUrl: './apply03.component.html'
})
export class Apply03Component implements OnInit{
  private _storage = inject(StorageService);
  private _cach01: any;
  private _cach02: any;
  private _cach03: any;

  TS = PasscardStatus;

  /**
   * 定期代
   */
  passcardFee: number;

  buycardFee: number = 0;

  buycardStr: string;
  usedStart: string;
  fixedPeriod: string = '';
  updatablePeriod = '';
  paidDetail: PayFeeDetail;

  parking: ParkingInfo;

  showCertificateUpload = false;
  certificateTypeSetting: CertificateType;
  myFilesModel: MyFilesModel;

  imageDownloadFinish: Subject<number> = new Subject<number>();

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    public wiz: ApplyComponent,
    private certificateImageService: CertificateImageService,
    private apiService: ApiService
    ) {
    this.wiz.processing = 2;
    this.wiz.saveState();
    this.parking = {
      dcParkingNo: 0,
      parkingName: wiz.parkingName,
      parkingAddress: wiz.parkingAddress,
      };

    if (!this.Cach01) return;

    const { selectTargetMonth, passcardMonth, buyICCard } = this.Cach01;
    this.buycardStr = buycardStrs[buyICCard];
    this.buycardFee = this.Cach01.applyNewIssueCommission??0;
    if (buyICCard == 1) {
      this.buycardFee += this.Cach01.applyCardIssueCommission??0;
    }

    this.passcardFee = this.wiz.passcard.paymentDivisionList!.find(x => x.monthNum == passcardMonth)?.unitPrice;    
    this.setPaymentDetails();
    this.usedStart =PeriodKind[selectTargetMonth];
// 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応Start
//    if (selectTargetMonth) {
    if (this.Cach01.passcardValidFrom) {
// 2024.04.01 【JR方式(カレンダーから使用開始日を選択する)】対応End
      const { passcardUpdateFrom, passcardUpdateTo, passcardValidFrom, passcardValidTo  } = this.Cach01;
      this.fixedPeriod = formatPeriod([passcardValidFrom, passcardValidTo]);
      this.updatablePeriod = formatPeriod([passcardUpdateFrom, passcardUpdateTo]);
    }


    this.showCertificateUpload = !!this.Cach01.myFilesModel;

    if (this.showCertificateUpload) {
      this.myFilesModel = this.Cach01.myFilesModel;
      this.certificateTypeSetting = this.Cach01.certificateTypeSetting;  
    }
  }

  ngOnInit(){
    // 画像一時ファイルがある場合は取得
    if (this.myFilesModel && this.myFilesModel.images.length > 0) {
      // ダウンロード終了監視用データ作成
      const imageList : {imageNum: number; finish: boolean}[] = [];
      this.myFilesModel.images.forEach((image)=>{
        imageList.push({imageNum: image.certificateImageNum, finish: false});
      });
    
      // 画像ダウンロード終了監視
      const subscripttion = this.imageDownloadFinish.subscribe((imageNumber)=>{
        if(imageNumber === -1){
          // エラー発生
          this.apiService.loadingOff();
          subscripttion.unsubscribe();
        }else{
          // 終わったものを終了に
          const image = imageList.find((image) => image.imageNum === imageNumber);
          if(image){
            // 完了を設定
            image.finish = true;
          }
          // 全部終わったか(未完了のものがないか)
          if(!imageList.some((image) => image.finish === false)){
            this.apiService.loadingOff();
            subscripttion.unsubscribe();
          }
        }
      });
      // 画像ダウンロード実施
      this.apiService.loadingOn();
      this.downLoadImage(this.myFilesModel.images);
    }
  }

  
  /**
   * 画像データをダウンロードする
   * @param certicateImages ダウンロードするイメージデータ
   */
  private downLoadImage(certicateImages: CertificateImage[]) {
    certicateImages.forEach((certicateImage) => {
      if (certicateImage.fileSize > 0) {
        // 画像がアップロードされている場合
        // 一時画像の場合
        if (certicateImage.fileName !== '') {
          const request: DownloadTemporaryImageFileRequest = {
            certificateImageNum: certicateImage.certificateImageNum,
            fileName: certicateImage.fileName,
            objectName: certicateImage.objectName,
          };
          // アップロード済みの画像データがある場合はダウンロードする
          this.certificateImageService.downLoadTemporaryImageFile(request).subscribe(
            (response: DownloadTemporaryImageFileResponse) => {
              if (response.resultCode === 0) {
                // 正常終了
                certicateImage.certificateImageData = CertificateImageService.IMAGE_HEADER + response.certificateImageData;
                this.imageDownloadFinish.next(certicateImage.certificateImageNum);
              } else {
                // 異常終了
                this.imageDownloadFinish.next(-1);
              }
            });
        }else{
          // 登録済み画像の場合
          const request: DownloadImageFileRequest = {
            certificateImageNum: certicateImage.certificateImageNum,
            passcardApplyNo: this.wiz.paramsReApply.passcardApplyNo,
          };
          // アップロード済みの画像データがある場合はダウンロードする
          this.certificateImageService.downLoadImageFile(request).subscribe(
            (response: DownloadImageFileResponse) => {
              if (response.resultCode === 0) {
                // 正常終了
                certicateImage.certificateImageData = CertificateImageService.IMAGE_HEADER + response.certificateImageData;
                this.imageDownloadFinish.next(certicateImage.certificateImageNum);
              } else {
                // 異常終了
                this.imageDownloadFinish.next(-1);
              }
            });
        }
      } else {
        // 画像がアップロードされていない場合
        this.imageDownloadFinish.next(certicateImage.certificateImageNum);
      }
    });
  }

  get Cach01() {
    this._cach01 ??= this._storage.load(this.wiz.getStorageKey('passcard-apply-1'), null);
    return this._cach01;
  }

  get Cach02() {
    this._cach02 ??= this._storage.load(this.wiz.getStorageKey('payment-method'), null);
    return this._cach02;
  }

  get Cach03() {
    this._cach03 ??= this._storage.load(this.wiz.getStorageKey('passcard-apply-3'), null);
    return this._cach03;
  }

  get ShowPasscardStatus() {
    const status = this.wiz.status;
    return status == 1 || status == 2  || status == 7;
  }

  get payInfoVisible() {
    const status = this.wiz.status;
    return status == 1 || status == 3 || (status == 8 && !this.wiz.isCorrect);
  }

  get isReApply(): boolean {
    return !!this.wiz.paramsReApply;
  }

  onReInput(kb: number) {
    this.wiz.navigate(`step/${kb}`);
  }

  /**
   * 申請を行う
   */
  async onApply() {
    const { no, passcardType, extended, receptionMode, paymentDivisionList } = this.wiz.passcard;

    const dcParkingNo = this.wiz.paramsReApply?.dcParkingNo ?? null;
    const status = this.wiz.isCorrect ? {}: { status: this.wiz.status };
    const bookingInfoRequired = {
      passcardApplyNo: this.wiz.paramsReApply?.passcardApplyNo ?? null,
      dcParkingNo,
      ...status,
      no,
      passcardType,
      extended,
      passPaymentDivision: 0,
      receptionMode,
    }

    const applyInfo: any = {};
    if (this.wiz.status != 2) {
      const {
        buyICCard,
        passcardMonth,
        selectTargetMonth,
        passcardValidFrom,
        passcardValidTo,
        passcardUpdateFrom,
        passcardUpdateTo
      } = this.Cach01;

      if (passcardMonth) {
        bookingInfoRequired.passPaymentDivision = paymentDivisionList.find(x => x.monthNum == passcardMonth).passPaymentDivision;
        applyInfo.passcardValidFrom = passcardValidFrom;
        applyInfo.passcardValidTo = passcardValidTo;
        applyInfo.buyICCard = buyICCard;
        applyInfo.passcardMonth = passcardMonth;
        applyInfo.selectTargetMonth = selectTargetMonth;  
        applyInfo.passcardUpdateFrom = passcardUpdateFrom;
        applyInfo.passcardUpdateTo = passcardUpdateTo;
      }

      if (this.payInfoVisible) {
        Object.assign(applyInfo, getApplyPaymentItems(this._storage, this.wiz.status, dcParkingNo));
        const { paymentTerm, terminalPaymentTerm } = this.Cach02;
        switch (applyInfo.paymentMethod) {
          case PaymentType.Terminal:
            applyInfo.terminalPaymentTerm = terminalPaymentTerm;
            break;
          case PaymentType.PayPay:
          case PaymentType.Cvs:
              applyInfo.paymentTerm = paymentTerm;
            break;
        }  
      }

      applyInfo.paymentAmount = this.passcardFee;  
      applyInfo.applyNewIssueCommission = this.Cach01.applyNewIssueCommission ?? 0;
      applyInfo.applyCardIssueCommission = this.Cach01.applyCardIssueCommission ?? 0;
    }

    const certificateUploadInfo: any = {};
   
    if (this.showCertificateUpload) {
      const { certificateType, images } = this.myFilesModel;

      certificateUploadInfo.certificateType = certificateType;
      certificateUploadInfo.certificateImageList = images.filter(x => x.fileSize && x.fileSize !== 0).map(x => {
        const { certificateImageNum, fileName , objectName} = x;
        return {
          certificateImageNum,
          fileName,
          objectName,
        }
      });
    }

    const reservation = {
      ...bookingInfoRequired,
      ...applyInfo,
      ...certificateUploadInfo,
    };
    if (reservation.paymentAmount + reservation.applyNewIssueCommission > 0 && applyInfo.paymentMethod === PaymentType.CreditCard) {
      // 支払いが発生し、クレジットカード払いの場合 
      let redirectUrl = '';
      if(location.href.startsWith(location.origin + '/web-passcard-ec/')){
        // Expressで起動時
        redirectUrl = `${location.origin}/web-passcard-ec/passcard/emv3dsecure`;
      }else{
        // 通常時
        redirectUrl = `${location.origin}/passcard/emv3dsecure`;
      }
      const request = {
        tokenId: reservation.tokenId,
        applyCardIssueCommission: reservation.applyCardIssueCommission,
        applyNewIssueCommission: reservation.applyNewIssueCommission,
        applyLostIssueCommission: reservation.applyLostIssueCommission,
        status: reservation.status,
        paymentAmount: reservation.paymentAmount,
        redirectionUri: redirectUrl,
      }
      const applyResult = await this.api.passcardApply3Dsecure(request);
      if (applyResult.resultCode === 0) {
        if (!applyResult.authStartUrl) {
          this.wiz.navigate('/error');
        } else {
          // 申請情報をストレージに保存
          const strageInfo = {
            applyType: ApplyType.New,
            apiRequest: reservation,
          }
          this._storage.save(StorageKey3dsecure, strageInfo)
          this.wiz.saveState();
          location.href = applyResult.authStartUrl;
        }
      }
    } else {
      const applyResult = await this.api.passcardApply(reservation, this.wiz.isCorrect);
      let message1 = this.wiz.isCorrect ? '定期券の修正申請が完了しました。' : '定期券の新規申請が完了しました。';
      let message2 = '';
      let message3 = '';

      if (this.wiz.status == 2) {
        message2 = '順番となるまでお待ちください。';
      }
      else if (this.wiz.status == 7 || this.wiz.status == 9 || this.wiz.isCorrect) {
        message2 = '申請内容の確認完了までお待ちください。';
      }
      else {
        message2 = '続けてICカードの登録を定期更新機にて';
        switch (applyInfo.paymentMethod) {
          case PaymentType.Terminal:
            message2 = '続けて駐車場の定期更新機にてお支払いをお願い致します。';
            break;
          case PaymentType.PayPay:
            message2 = '続けてPayPay支払いのお手続きをお願い致します。';
            break;
          case PaymentType.Cvs:
            message2 = '続けてコンビニで支払いのお手続きをお願い致します。';
            break;
          default:
            message3 = 'お手続きをお願い致します。';
            break;
        }
      }

      this.wiz.complete = true;
      this.wiz.passcardApplyNo = applyResult.passcardApplyNo ?? null;
      this.wiz.saveState();

      this.wiz.navigate('step/4', {
        message1,
        message2,
        message3,
        receiptNo: applyResult.receiptNo,
        dcParkingNo
      });
    }
  }

  private setPaymentDetails() {
    this.paidDetail = {
      paymentFeeDetails: [],
      paymentAmount: 0
    };

    if (!this.payInfoVisible) return;

    this.paidDetail.paymentAmount = this.passcardFee;
    this.paidDetail.paymentFeeDetails.push({ contents: '定期代', Fee: this.passcardFee });
    const payInfo = this.Cach02;
    if (payInfo.method == PaymentType.Cvs) {
      const { payCommission } = payInfo.cvsPay;
      this.paidDetail.paymentFeeDetails.push({ contents: 'コンビニ支払い手数料', Fee: payCommission });
      this.paidDetail.paymentAmount += payCommission;
    }

    if (this.Cach01.applyNewIssueCommission) {
      const applyNewIssueCommission = this.Cach01.applyNewIssueCommission;
      this.paidDetail.paymentFeeDetails.push({ contents: '新規発行手数料', Fee: applyNewIssueCommission });
      this.paidDetail.paymentAmount += applyNewIssueCommission;
    }
    if (this.Cach01.applyCardIssueCommission) {
      const buyCardCommission = this.Cach01.applyCardIssueCommission;
      this.paidDetail.paymentFeeDetails.push({ contents: 'ICカード購入手数料', Fee: buyCardCommission });
      this.paidDetail.paymentAmount += buyCardCommission;
    }
  }
}
