import { PasscardApiProxy } from './../../passcard.api.proxy';
import { Injectable, inject } from "@angular/core";
import { NavigateService, StorageService } from "src/app/services";
import { createReceiptPdf, downloadDetailPdf } from "./createpdf";
import { PaymentReceipt } from "../models/payment-receipt.model";

const key = 'receipt-issue-model'

@Injectable({providedIn: 'root'})
class ReceiptIssueService {
    api = inject(PasscardApiProxy);
}

async function refreshAfterIssued(api: PasscardApiProxy, navi: NavigateService) {
    const { passcardApplyNo, processDt, processMs } = this.state;
    const state = {...this.state};    
    state[this.refreshField] = await api.getPasscardApplyDetail({passcardApplyNo, processDt, processMs});
    navi.navigate(this.url, false, state);
}

@Injectable()
export class ReceiptIssueModel {
    private readonly _navi = inject(NavigateService);
    private readonly _storage = inject(StorageService);
    private readonly _service = inject(ReceiptIssueService);

    nameFurigana: string;
    nameWorking: string;
    processDt: number;
    processMs: number;
    objUrl: string;
    src: { url: string, state: any, refreshField: string }

    constructor() {
        this._storage.load(key, this);
    }

    issue(cofirmed?: boolean) {
        const api = this._service.api;
        downloadDetailPdf(async () => {
            const { processDt, processMs, nameWorking, nameFurigana } = this;
            const name = cofirmed ? nameWorking : nameFurigana;
            await api.setReceiptName(processDt, processMs, name);
            const receipt = await api.getReceipt(processDt, processMs);
            await this.doIssue(api, receipt);
            this.nameFurigana = name;
            refreshAfterIssued.call(this.src, api, this._navi);
            return this.objUrl;
          });
    }

    issueOnly() {
        const api = this._service.api;
        downloadDetailPdf(async() => {
            const { processDt, processMs } = this;
            const receipt = await api.getReceipt(processDt, processMs);
            await this.doIssue(api, receipt);
            return this.objUrl;  
          });
    }

    clear() {
        if (this.objUrl) {
          window.URL.revokeObjectURL(this.objUrl);
          this.objUrl = '';
          this.saveModel();
        }
    }

    private async doIssue(api: PasscardApiProxy, receipt: ReadonlyArray<PaymentReceipt>) {
        this.clear();
        api.Disabled = true;  // simulate api call
        this.objUrl = await createReceiptPdf(receipt);
        api.Disabled = false;
        this.saveModel();
    }
    
    saveModel() {
        this._storage.save(key, this);
    }
}
