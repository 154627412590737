import { PDFDocument, PDFFont, PDFPage, grayscale, rgb } from 'pdf-lib'
import * as fontkit from '@btielen/pdf-lib-fontkit';
import { formatDate } from 'src/app/passcard/passcard.model';
import { PaymentReceipt } from '../models/payment-receipt.model';

const fontSizeNormal = 10;
const fontSizeBig = 16;
const url = '/assets/font/BIZUDGothic_Regular.ttf'
const urlBold = '/assets/font/BIZUDGothic-Bold.ttf'

const fnFormatCurrency = (val: number) => '¥' + `${val}`.replace(/\B(?=(\d{3}){0,3}(?!\d))/g, ',');

const getDevice = () => {
  const ua = navigator.userAgent;

  if (ua.includes('Mobile') && (ua.includes('iPhone') || ua.includes('iPod') || ua.includes('Android')))
    return 'sp';

  if (ua.includes('iPad') || ua.includes('Android'))
    return 'tab';

  return 'other';
}
export function downloadDetailPdf(callback: () => Promise<string>) {
  const disPC = getDevice() == 'other';
  let win: Window | null = null;
  if (!disPC) {
    win = open('', '_blank');
    win.document.title = '利用明細書';  
  }
  callback().then(x => {
    if (disPC) {
      win = open('', '_blank');
      win.document.title = '利用明細書';  
      const iframe = document.createElement('iframe');
      iframe.style.width = '100%';
      iframe.style.height = '100vh';
      iframe.style.margin = '0';
      iframe.style.padding = '0';
      iframe.style.overflow = 'hidden';
      iframe.style.border = 'none';
      win.document.body.style.margin = '0';
      win.document.body.appendChild(iframe);
      iframe.src = x;
    }
    else {
      if (navigator.userAgent.includes('Android')) {
        const a = win.document.createElement('a');
        a.href = x;
        const filename = x.substring(x.lastIndexOf('/')+ 1) ;
        a.download = filename + '.pdf';
        a.click();
        window.URL.revokeObjectURL(x);
      } else {
        win.location = x;
      }
    }
  })
  .catch(_ => {
    win?.close();
  });
}

export async function createReceiptPdf(paymentReceipts: ReadonlyArray<PaymentReceipt>) {
  const fontBytes = await fetch(url).then(res => res.arrayBuffer());
  const fontBoldBytes = await fetch(urlBold).then(res => res.arrayBuffer());
  const pdfDoc = await PDFDocument.create();
  pdfDoc.registerFontkit(fontkit);
  const customBoldFont = await pdfDoc.embedFont(fontBoldBytes);
  const customFont = await pdfDoc.embedFont(fontBytes);

  for (let i = 0; i < paymentReceipts.length; ++i) {
    const page = pdfDoc.addPage()
    await issuePaymentDetail(page, customFont, customBoldFont, paymentReceipts[i]);
  }

  const pdfBytes = await pdfDoc.save()
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const objUrl = window.URL.createObjectURL(blob);
  return objUrl;
}

async function issuePaymentDetail(page: PDFPage,
  customFont: PDFFont,
  customBoldFont: PDFFont,
  paymentReceipt: PaymentReceipt) {
  const paymentFee = fnFormatCurrency(paymentReceipt.paymentFee);
  const consumptionTax = fnFormatCurrency(paymentReceipt.consumptionTax);
  const date = new Date(paymentReceipt.issueDate);
  const hour = `${date.getHours()}`.padStart(2, '0');
  const minute = `${date.getMinutes()}`.padStart(2, '0');
  const issueDate = `${formatDate(paymentReceipt.issueDate)} ${hour}:${minute}`;
  
  const footerDisplayContents = [];
  for (let i = 1; i < 11; ++i) {
    const content = paymentReceipt[`footerDisplayContents${i}`];
    if (content) footerDisplayContents.push(content);
  }

  const centerX = 298;
  let x = centerX - customBoldFont.widthOfTextAtSize(paymentReceipt.title, 20) / 2 | 0;
  page.drawText(paymentReceipt.title, {
    x,
    y: 750,
    size: 20,
    font: customBoldFont
  })

  let y = 670;
  x = 168
  // 宛名情報
  let addressArray = [];
  // 宛名を一定の横幅で分割
  let add = '';
  // ベースとなる横幅
  const baseWidth = 160;
  if (paymentReceipt.receiptAddressName.length === 0) {
    addressArray.push('');
  } else {
    for (let i = 0; i < paymentReceipt.receiptAddressName.length; i++) {
      add = add + paymentReceipt.receiptAddressName.substring(i, i + 1);
      let contentsWidth = customFont.widthOfTextAtSize(add, 16);
      if (baseWidth < contentsWidth) {
        addressArray.push(add.substring(0, add.length - 1));
        add = paymentReceipt.receiptAddressName.substring(i, i + 1);
      }
      if (i === paymentReceipt.receiptAddressName.length - 1) {
        addressArray.push(add);
      }
    }
  }
  let w1 = 0;
  // 一番長いものを基準に座標を計算
  for (let i = 0; i < addressArray.length; i++) {
    if (w1 < customFont.widthOfTextAtSize(addressArray[i], 16)) {
      w1 = customFont.widthOfTextAtSize(addressArray[i], 16)
    }
  }

  // 宛名が１行・２行の場合(位置をずらさず上に１行乗せる)
  const addFontHeight = 20;
  if (addressArray.length <= 2) {
    for (let i = 0; i < addressArray.length; i++) {
      page.drawText(addressArray[i], {
        x: centerX - w1 / 2 | 0,
        y: y + 6 + (addressArray.length - i - 1) * addFontHeight,
        size: fontSizeBig,
        font: customFont
      })
    }
  } else {
    // 宛名が3行以上の場合  順にずらす
    for (let i = 0; i < addressArray.length; i++) {
      page.drawText(addressArray[i], {
        x: centerX - w1 / 2 | 0,
        y: y + 6 + addFontHeight - (i * addFontHeight),
        size: fontSizeBig,
        font: customFont
      })
    }
    // ずらした分の座標を加算
    y = y - (addressArray.length - 2) * addFontHeight;
  }

  page.drawLine({
    start: { x, y },
    end: { x: x + 260, y },
    thickness: 0.5,
    opacity: 0.75
  })
  page.drawLine({
    start: { x, y: y - 2 },
    end: { x: x + 260, y: y - 2 },
    thickness: 0.5,
    opacity: 0.75,
  })

  // 宛名が複数行ある場合は真ん中に来るように
  page.drawText('宛名', {
    x: x + 6,
    y: y + 6 + (addressArray.length - 1) * addFontHeight / 2,
    size: fontSizeBig,
    font: customFont,
    color: rgb(0, 0, 0),
  })

  page.drawText('様', {
    x: x + 260 - 30,
    y: y + 6 + (addressArray.length - 1) * addFontHeight / 2,
    size: fontSizeBig,
    font: customFont
  })

  y -= 60;

  page.drawLine({
    start: { x, y },
    end: { x: x + 260, y },
    thickness: 0.5,
    opacity: 0.75
  })
  page.drawLine({
    start: { x, y: y - 2 },
    end: { x: x + 260, y: y - 2 },
    thickness: 0.5,
    opacity: 0.75
  })

  page.drawText('金額', {
    x: x + 6,
    y: y + 6,
    size: fontSizeBig,
    font: customFont
  })

  const size = customFont.widthOfTextAtSize(paymentFee, 16);
  page.drawText(paymentFee, {
    x: x + 260 - 10 - size,
    y: y + 6,
    size: fontSizeBig,
    font: customFont
  })

  page.drawText('-', {
    x: x + 260 - 10,
    y: y + 6,
    size: fontSizeBig,
    font: customFont
  })

  y -= 20;
  page.drawText(`消費税${paymentReceipt.rateValue}%対象額(税込み)`, {
    x: x + 6,
    y: y + 6,
    size: fontSizeNormal,
    font: customFont
  })

  page.drawText('-', {
    x: x + 166,
    y: y + 6,
    size: fontSizeNormal,
    font: customFont
  })
  const size2 = customFont.widthOfTextAtSize(paymentFee, 10);
  page.drawText(paymentFee, {
    x: x + 166 - size2,
    y: y + 6,
    size: fontSizeNormal,
    font: customFont
  })

  y -= 12;
  page.drawText('消費税額', {
    x: x + 6,
    y: y + 6,
    size: fontSizeNormal,
    font: customFont
  })
  page.drawText('-', {
    x: x + 166,
    y: y + 6,
    size: fontSizeNormal,
    font: customFont
  })
  const size3 = customFont.widthOfTextAtSize(consumptionTax, 10);
  page.drawText(consumptionTax, {
    x: x + 166 - size3,
    y: y + 6,
    size: fontSizeNormal,
    font: customFont
  })

  y -= 150;
  x = 50
  const x_v = x + 150;
  const width = 497;
  page.drawRectangle({
    x,
    y: y,
    width: 150,
    height: 120,
    color: rgb(0.95, 0.95, 0.95)
  })

  page.drawRectangle({
    x,
    y: y,
    width,
    height: 120,
    borderWidth: .5,
    borderColor: grayscale(0.5),
    opacity: 0.5,
    borderOpacity: 0.75,
  })

  y += 120;
  page.drawLine({
    start: { x: x_v, y: y },
    end: { x: x_v, y: y - 120 },
    thickness: 0.5,
    color: grayscale(0.5),
    opacity: 0.75,
  })

  const captions = ['但し書き', 'お支払い情報', '日時', '駐車場'];
  const paymentDate = new Date(paymentReceipt.paymentDate);
  const hours = `${paymentDate.getHours()}`.padStart(2, '0');
  const minutes = `${paymentDate.getMinutes()}`.padStart(2, '0');
  const strPaymentDate = `${formatDate(paymentReceipt.paymentDate)} ${hours}:${minutes}`;

  const vals = [paymentReceipt.provision, paymentReceipt.payment, strPaymentDate, paymentReceipt.parkingAreaName];
  for (let i = 0; i < 3; ++i) {
    y -= 30
    page.drawLine({
      start: { x, y: y },
      end: { x: x + width, y: y },
      thickness: 0.5,
      color: grayscale(0.5),
      opacity: 0.75,
    })
    page.drawText(captions[i], {
      x: x + 6,
      y: y + 10,
      size: fontSizeNormal,
      font: customFont
    })
    page.drawText(vals[i], {
      x: x_v + 6,
      y: y + 10,
      size: fontSizeNormal,
      font: customFont
    })
  }

  y -= 30
  page.drawText(captions[3], {
    x: x + 6,
    y: y + 10,
    size: fontSizeNormal,
    font: customFont
  })
  page.drawText(vals[3], {
    x: x_v + 6,
    y: y + 10,
    size: fontSizeNormal,
    font: customFont
  })

  y -= 30
  page.drawText(`利用明細書発行日時:${issueDate}`, {
    x,
    y: y + 10,
    size: fontSizeNormal,
    font: customFont
  })

  y -= 12
  page.drawText(`No.`, {
    x,
    y: y + 10,
    size: fontSizeNormal,
    font: customFont
  });

  const regex = /([a-zA-Z]+)/g;
  const result = paymentReceipt.parkingFeeReceiptNoBranch.split(regex);
  let xx = x + 16;
  result.forEach(str => {
    page.drawText(str, {
      x: xx,
      y: y + 10,
      size: fontSizeNormal,
      font: customFont
    });

    xx += customFont.widthOfTextAtSize(str, fontSizeNormal);
  })

  y -= 30
  page.drawText('本紙は、電子的に保持している利用明細データーを画面表示したものです。', {
    x,
    y: y + 10,
    size: 8,
    font: customFont
  })

  x = 400
  y -= 18
  footerDisplayContents.forEach((f: string) => {
    y -= 12;
    const strs = f.split(regex);
    xx = x;
    strs.forEach((str: string) => {
      page.drawText(str, {
        x: xx,
        y: y + 10,
        size: fontSizeNormal,
        font: customFont
      });

      xx += customFont.widthOfTextAtSize(str, fontSizeNormal);
    })
  });
}