<div class="credit-cards">
  <div><img class="logo-ccard" src="/assets/img/credit-card.png" alt="" /></div>
  <div>
    <ng-container *ngIf="PayModel[0].no">
      <div class="passcard-list">
        <div class="passcard passcard-normal" [ngClass]="{ 'passcard-selected':  PayModel.cardUse == 0 }"  (click)="setCardUse(0)">
          <div class="input">
            <div class="radio-wrapper"><input type="radio" name="ticketinfo" [checked]="PayModel.cardUse == 0" (change)="setCardUse(0)" value="0"
                id="ticket1" /></div>
            <div><label class="radio-caption" for="ticket1">
                <p>登録したカード情報を使用する</p>
                <p>{{PayModel[0].no}}</p>
              </label></div>
          </div>
        </div>
        <div class="passcard passcard-normal" [ngClass]="{ 'passcard-selected':  PayModel.cardUse == 1 }"
          (click)="setCardUse(1)">
          <div class="input">
            <div class="radio-wrapper">
              <input type="radio" name="ticketinfo" [checked]="PayModel.cardUse == 1" (change)="setCardUse(1)" value="1"
                id="ticket2" /></div>
            <div><label class="radio-caption" for="ticket2">カード情報を入力する</label></div>
          </div>
        </div>
      </div>
      <br>
    </ng-container>
    <ng-container *ngIf="PayModel.cardUse == 1; else inputedCreditcard">
      <div class="tableCtn">
        <div class="tableLine">
          <div class="tableCell_l">クレジットカード番号</div>
          <div class="tableCell_r">
            <input placeholder="1234567890123456" type="text" type="tel" maxlength="16"
              [(ngModel)]="PayModel[1].no" [ngClass]="{'error_border_r':msgCard.length}">
            <p-error [messages]="msgCard"></p-error>
            </div>
        </div>
        <div class="tableLine">
          <div class="tableCell_l">有効期限</div>
          <div class="tableCell_r">
            <div class="selectTime">
              <div class="selectBox">
                <select title="月" [(ngModel)]="cardExpiresMonth" [ngClass]="{'error_border_r':msgMonth.length}">
                  <option value="">--</option>
                  <option *ngFor="let op of option_m" [value]="op.value">{{op.text}}</option>
                </select>
              </div>
              <span>月/</span>
              <div class="selectBox">
                <select title="年" [(ngModel)]="cardExpiresYear" [ngClass]="{'error_border_r':msgYear.length}">
                  <option value="">--</option>
                  <option *ngFor="let op of option_y" [value]="op.value">{{op.text}}</option>
                </select>
              </div>
              <span>年</span>
            </div>
            <p-error [messages]="msgExpired"></p-error>
          </div>
        </div>
        <div class="tableLine">
          <div class="tableCell_l"><div class="lable-with-help">セキュリティコード<i class="icon help" (click)="help = !help"></i></div></div>
          <div class="tableCell_r">
            <input type="tel" maxlength="4" placeholder="123" class="frm_pay"
              [(ngModel)]="PayModel[1].securityCode" [ngClass]="{'error_border_r':msgSecurity.length}">
            <p-error [messages]="msgSecurity"></p-error>
          </div>
        </div>
      </div>
      <section *ngIf="help" class="box-inner mt15">
        <div class="balloon">
          <h3>セキュリティコードとは</h3>
          <p>セキュリティコードは、クレジットカードの裏面または表面に記載された3桁もしくは4桁の数字です。</p>
          <div class="creditcard-box">
            <div class="cards"><em>【Visa・MasterCard・JCB・Diners Club】<br>カード裏面の署名欄に記載された末尾3桁の数字</em><div class="card-image credit-card"></div></div>
            <div class="amex"><em>【American Express】<br>カード表面のクレジットカード番号の右上に記載された4桁の数字</em><div class="card-image amex-card"></div></div>
          </div>
        </div>
      </section>
      <br>
      <section class="checkWrapper">
        <label [ngClass]="{'checkBoxArea':regitFlag,'checkBoxAreaNot':!regitFlag}">
          <input [(ngModel)]="regitFlag" type="checkbox" class="unsubscribeConfirm_check">
          <div class="checkboxMeg">このクレジットカードを登録する</div>
        </label>
      </section>
    </ng-container>
    <ng-template #inputedCreditcard>
      <div class="tableCtn">
        <p-single label="クレジットカード番号" [value]="PayModel[0].no" />
        <p-single label="有効期限" [value]="PayModel[0].expire" />
      </div>
    </ng-template>
  </div>
</div>