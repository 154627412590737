import { Component, Input, OnInit } from '@angular/core';
import { NavigateService, RouterService } from '../../services';
import { AppModel } from '../../app.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input()
  showLink: boolean = false;
  
  constructor(
    public app: AppModel,
    private navi: NavigateService,
    private routerWatch: RouterService
  ) { }

  ngOnInit() {
  }

  //
  isUnexpected = () => this.routerWatch.mode === 'unexpected';

  //
  onTop = (evt: Event) => {
    evt.preventDefault();
    this.navi.navigateByUrl('/');
  }

  onLogin = (evt: Event) => {
    evt.preventDefault();
    this.navi.navigateByUrl('/login');
  }

  onMessage = (evt: Event) => {
    evt.preventDefault();
    this.navi.navigateByUrl('/message/list');
  }

  onMypage = (evt: Event) => {
    evt.preventDefault();
    this.navi.navigateByUrl('/mypage/top');
  }

  onParking = (evt: Event) => {
    evt.preventDefault();
    this.navi.navigateByUrl('/');
  }
}
