import { Component, ViewChild, inject } from '@angular/core';
import { NavigateService } from 'src/app/services';
import { PaymethodSelectComponent } from 'src/app/passcard/common/paymethod-select/paymethod-select.component';
import { PasscardApiProxy } from 'src/app/passcard/passcard.api.proxy';
import { RefreshComponent } from '../../refresh.component';

@Component({
  selector: 'refresh02',
  templateUrl: './refresh02.component.html'
})
export class Refresh02Component {
  private readonly _wiz = inject(RefreshComponent);

  dcParkingNo: number | null;
  api = inject(PasscardApiProxy);

  @ViewChild(PaymethodSelectComponent, { static: true }) payMethodSelect!: PaymethodSelectComponent;

  constructor(private _navi: NavigateService) {
    this._wiz.processing = 1;
    this._wiz.saveState();
    this.dcParkingNo = this._wiz.passcardApply.dcParkingNo;
  }

  get IsComplete() {
    return this._wiz.complete;
  }

  get nextDisabled() {
    const payMethod = this.payMethodSelect?.model;
    return this.api.Disabled || !payMethod || payMethod.method <= 0 || !!payMethod.error;
  }
 
  onBack() {
    this._navi.navigateByUrl('/passcard/refresh/step/1');
  }

  async onNext() {
    if (!await this.payMethodSelect.validate()) return;

    this._wiz.maxProcessed = Math.max(this._wiz.maxProcessed, 2);
    this._wiz.paymentType = this.payMethodSelect.model.method;
    this._wiz.saveState();
    this.payMethodSelect.saveModel();
    this._navi.navigateByUrl('/passcard/refresh/step/3');
  }

}
