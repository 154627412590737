import { Component, OnInit, DoCheck, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService, ConfigService, NavigateService, StorageService } from '../../services';
import { RegistrationModel } from '../registration.model';
import { IFullnameComponent } from '../../shared/i-fullname/i-fullname.component';
import { IKanaComponent } from '../../shared/i-kana/i-kana.component';
import { IBirthdateComponent } from '../../shared/i-birthdate/i-birthdate.component';
import { IPhoneComponent } from '../../shared/i-phone/i-phone.component';
import { ISecurityNumberComponent } from '../../shared/i-securitynumber/i-securitynumber.component';
import { IAddressComponent } from '../../shared/i-address/i-address.component';
import { IWsNameComponent } from '../../shared/i-ws-name/i-ws-name.component';
import { IWsKanaComponent } from '../../shared/i-ws-kana/i-ws-kana.component';
import { IWsAddressComponent } from '../../shared/i-ws-address/i-ws-address.component';
import { IWsPhoneComponent } from '../../shared/i-ws-phone/i-ws-phone.component';
import { ICarInfoComponent } from '../../shared/i-carinfo/i-carinfo.component';
import { IMotorcycleNumberComponent } from '../../shared/i-motorcyclenumber/i-motorcyclenumber.component';
import { IPasswd2Component } from '../../shared/i-passwd2/i-passwd2.component';
import { Time9 } from '../../shared/time9';

//
interface ConfirmReq {
  parkingCode: number;         // 駐車場コード
  mailSendToken: string;
  mailSendDate: string; //1970/01/01_09:00:00
};

interface ConfirmRes {
  resultCode: number;
  mainMessage: string;
  linkStatusCode: string;
  mailAddress: string;
  transitionSourceURL: string;
  itemDisplaySetting: {
    applyNameSetting: number;
    applyFuriganaSetting: number;
    applyBirthSetting: number;
    applyTelSetting: number;
    applyRegistrationNoSetting: number;
    applyZipSetting: number;
    applyAddress1Setting: number;
    applyAddress2Setting: number;
    applyCompanySetting: number;
    applyCompanyAddress2Setting: number;
    applyCarNumberSetting: number;
    applyMotorcycleNumberSetting: number;
  }
  agreementStatus: number;
}

interface CheckReq {
  parkingCode: number;      // 駐車場コード
  fullNameList: string[];   // 氏名
  furiganaList: string[];   // 氏名（カタカナ）
  birthList: string[];  // 生年月日
  telephoneNumber: string;  // 電話番号
  bicycleRegistrationNo: string;   // 防犯登録番号
  zipCodeList: string[];   // 郵便番号
  address1: string;         // 住所1
  address2: string;         // 住所2
  companyName: string;           // 勤務先名/通学先名
  companyNameFurigana: string;           // 勤務先名/通学先名（カナ）
  companyTel: string;// （勤務先/通学先）電話番号
  companyZipCodeList: string[]; // （勤務先/通学先）郵便番号
  companyAddress1: string;       // （勤務先/通学先）住所1 
  companyAddress2: string;       // （勤務先/通学先）住所2
  numberPlateList: string[];// ナンバープレート（クルマ）
  motorcycleNumberPlate: string;  // ナンバープレート（バイク）
  password: string;         // パスワード
};

interface CheckRes {
  resultCode: number;
  mainMessage: string;
  userInfoValidator: {
    // お客様情報
    validatorMessageFullNameList: string[];
    validatorMessageNameFuriganaList: string[];
    validatorMessageBirthList: string[];
    validatorMessageTelephoneNumberList: string[];
    validatorMessageBicycleRegistrationNoList: string[];
    // 住所
    validatorMessageZipCodeList: string[];
    validatorMessageAddress1List: string[];
    validatorMessageAddress2List: string[];
    // 勤務先/通学先
    validatorMessageCompanyNameList: string[];
    validatorMessageCompanyNameFuriganaList: string[];
    validatorMessageCompanyTelList: string[];
    validatorMessageCompanyZipCodeList: string[];
    validatorMessageCompanyAddress1List: string[];
    validatorMessageCompanyAddress2List: string[];
    // 車両情報（クルマ）
    validatorMessageNumberPlateList: string[];
    // 車両情報（バイク）
    validatorMessageMotorcycleNumberPlateList: string[];
    // パスワード
    validatorMessagePasswordList: string[];
  }
}

interface Messages {
  // お客様情報
  fullname: string[];
  kana: string[];
  birthdate: string[];
  phone: string[];
  securityNunber: string[];
  // 住所
  //address: string[];
  postcode1: string[];
  zipCode: string[];
  address1: string[];
  address2: string[];
  // 勤務先/通学先
  wsName: string[];
  wsKana: string[];
  wsPhone: string[];
  //wsAddress: string[];
  companyZipCode: string[];
  companyAddress1: string[];
  companyAddress2: string[];
  // 車両情報（クルマ）
  okNg: string[][];
  NP: string[];
  // 車両情報（バイク）
  motorcycleNumber: string[];
  // パスワード
  passwd: string[];
  // エラー
  error: string;
}

//
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
})
export class ConfigComponent implements OnInit, DoCheck {
  @ViewChild(IFullnameComponent, {static: false}) fullname: IFullnameComponent;
  @ViewChild(IKanaComponent, { static: false }) kana: IKanaComponent;
  @ViewChild(IBirthdateComponent, { static: false }) birthdate: IBirthdateComponent;
  @ViewChild(IPhoneComponent, { static: false }) phone: IPhoneComponent;
  @ViewChild(ISecurityNumberComponent, { static: false }) securityNunber: IPhoneComponent;
  @ViewChild(IAddressComponent, { static: false }) address: IAddressComponent;
  @ViewChild(IWsNameComponent, { static: false }) wsName: IWsNameComponent;
  @ViewChild(IWsKanaComponent, { static: false }) wsKana: IWsKanaComponent;
  @ViewChild(IWsAddressComponent, { static: false }) wsAddress: IWsAddressComponent;
  @ViewChild(IWsPhoneComponent, { static: false }) wsPhone: IWsPhoneComponent;
  @ViewChild(ICarInfoComponent, { static: false }) carInfo: ICarInfoComponent;
  @ViewChild(IMotorcycleNumberComponent, { static: false }) motorcycleNumber: IMotorcycleNumberComponent;
  @ViewChild(IPasswd2Component, { static: false }) passwd: IPasswd2Component;

  // for Server
  messages: Messages;

  //
  loading: boolean;
  disabled: boolean;

  dispFlg: {
    //dispWsName : boolean;
  }

  //
  parkingCode : number;

  //
  applyCompanySetting: string;
  applyCompanyAddress2Setting: string;

  //
  constructor(
    public model: RegistrationModel,
    public api: ApiService,
    private conf: ConfigService,
    private navi: NavigateService,
    private route: ActivatedRoute,
    private storage: StorageService
  ) { }

  ngOnInit() {
    this.model.passwd = null;
    this.model.byUserName = '';
    this.model.byUserFurigana = '';
    this.model.byUserBirth = '';
    this.model.byUserMail = '';
    this.model.byUserTel = '';
    this.model.bySecurityNumber = '';
    this.model.byUserZip = '';
    this.model.byCompanyName = '';
    this.model.byCompanyFurigana = '';
    this.model.byCompanyTel = '';
    this.model.byCompanyZip = '';
    this.model.byCarInfo = '';
    this.model.byApplyMotorcycleNumber = '';
    if (!this.model.mail) {
      this.model.mail = {
        token: '',
        date: ''
      };
    }
    this.messages = {
      fullname: null,
      kana: null,
      birthdate: null,
      phone: null,
      securityNunber: null,
      postcode1: null,
      zipCode: null,
      address1: null,
      address2: null,
      wsPhone: null,
      wsName: null,
      wsKana: null,
      companyZipCode: null,
      companyAddress1: null,
      companyAddress2: null,
      okNg: [null, null, null, null],
      NP: null,
      motorcycleNumber: null,
      passwd: null,
      error: null,
    };

    // トークン、トークン作成日時、駐車場コードを取得する
    const map = this.route.snapshot.paramMap;
    const p3 = map.get('p3')
    if (p3) {
      this.parkingCode = Number(p3);
    } else {
      // 連絡票Sheet.52対応
      this.parkingCode = 0;
    }
    this.postParams(map.get('p1'), map.get('p2'));
    this.model.parkingCode = this.parkingCode;
    this.storage._save('parkingCode',  this.parkingCode.toString());
  }

  ngDoCheck() {
    this.validation();
  }

  private postParams = (p1: string, p2: string) => {
    const c = this.conf;
    this.api.disabled = true;
    this.loading = true;

    if (p1 !== this.model.mail.token || p2 !== this.model.mail.date) {
      this.model.reset();
      this.model.mail = {
        token: p1,
        date: p2
      };
    }

    //
    const req: ConfirmReq = {
      parkingCode: this.parkingCode,
      mailSendDate: Time9.toString(+p2, '_'),
      mailSendToken: p1
    }

    this.api.post<ConfirmRes>('/registration/confirm', req, 2).subscribe(
      res => {
        if (res.body.resultCode === 0) {
          this.api.enabled(0);
          this.loading = false;
          this.model.mailAddr = res.body.mailAddress;
          // 会員登録完了後の画面遷移先切替対応
          this.model.transitionSourceURL = res.body.transitionSourceURL;
          // 項目表示非表示制御（0:非表示、1:表示、2:表示必須入力）
          const itemDisplaySetting = res.body.itemDisplaySetting;
          this.model.byUserName = (itemDisplaySetting.applyNameSetting).toString();
          this.model.byUserFurigana = (itemDisplaySetting.applyFuriganaSetting).toString();
          this.model.byUserBirth = (itemDisplaySetting.applyBirthSetting).toString();
          this.model.byUserTel = (itemDisplaySetting.applyTelSetting).toString();
          this.model.bySecurityNumber =  (itemDisplaySetting.applyRegistrationNoSetting).toString();
          this.model.byUserZip = (itemDisplaySetting.applyZipSetting).toString();
          this.model.byUserAddress1 = (itemDisplaySetting.applyAddress1Setting).toString();
          this.model.byUserAddress2 = (itemDisplaySetting.applyAddress2Setting).toString();
          this.model.byCompanyName = (itemDisplaySetting.applyCompanySetting).toString();
          this.model.byCompanyAddress2  = (itemDisplaySetting.applyCompanyAddress2Setting).toString();
          this.model.byCarInfo = (itemDisplaySetting.applyCarNumberSetting).toString();
          this.model.byApplyMotorcycleNumber = (itemDisplaySetting.applyMotorcycleNumberSetting).toString();

          this.applyCompanySetting = (itemDisplaySetting.applyCompanySetting).toString();
          this.applyCompanyAddress2Setting = (itemDisplaySetting.applyCompanyAddress2Setting).toString();

          // 利用規約同意の不具合対応
          if (res.body.agreementStatus != null && res.body.agreementStatus != undefined) {
            this.storage._save('agreementStatus', String(res.body.agreementStatus));
          } else {
            this.storage._save('agreementStatus', '0');
          }

          this.model.saveStorage();
        } else {
          this.navi.navigateByUrl('/error', true);
        }
      }
    );
  }

  //
  private validation = () => {
    // お客様情報
    const b1 = this.fullname ? this.fullname.validation() : true;
    const b2 = this.kana ? this.kana.validation() : true;
    const b3 = this.birthdate ? this.birthdate.validation() : true;
    const b4 = this.phone ? this.phone.validation() : true;
    const b5 = this.securityNunber ? this.securityNunber.validation() : true;
    // 住所
    const b6 = this.address ? this.address.validation() : true;

    // 勤務先/通学先
    // 蔦井版対応
    let isInput= false;
    let b7 = true;
    let b8 = true;
    let b9 = true;
    let b10 = true;
    if (this.applyCompanySetting === '1' && this.applyCompanyAddress2Setting === '1') {
      const b7IsInput = this.wsName ? this.wsName.isInput() : false;
      const b8IsInput = this.wsKana ?  this.wsKana.isInput(): false;
      const b9IsInput = this.wsPhone ? this.wsPhone.isInput(): false;
      const b10IsInput = this.wsAddress ? this.wsAddress.isInput(): false;
      if (b7IsInput || b8IsInput || b9IsInput || b10IsInput) {
        isInput = true;
      }
      b7 =  this.wsName ? this.wsName.validationIsInput(isInput): true;
      b8 = this.wsKana ? this.wsKana.validationIsInput(isInput) :true;
      b9 = this.wsPhone ? this.wsPhone.validationIsInput(isInput): true;
      b10 = this.wsAddress ? this.wsAddress.validationIsInput(isInput): true;
    } else {
      b7 = this.wsName ? this.wsName.validation(): true;
      b8 = this.wsKana ? this.wsKana.validation() :true;
      b9 = this.wsPhone ? this.wsPhone.validation(): true;
      b10 = this.wsAddress ? this.wsAddress.validation(): true;
    }

    // 車両情報（クルマ）
    const b11 = this.carInfo ? this.carInfo.validation() : true;
    // 車両情報（バイク）
    const b12 = this.motorcycleNumber ? this.motorcycleNumber.validation() : true;
    // パスワード
    const b13 = this.passwd ? this.passwd.validation() : true;

    this.disabled = !(b1 && b2 && b3 && b4 && b5 && b6 && b7 && b8 && b9 && b10 && b11 && b12 && b13) ;
    // 定期Web申請_デバッグ用
    //this.disabled = !(b1) ;

  }

  //
  onChange = () => {
    this.messages.error = null;
  }

  //
  private afterPost = (body: CheckRes) => {
    if (body.resultCode === 0) {
      this.model.saveStorage();
      this.navi.navigateByUrl('/registration/confirm2');

    } else if (body.resultCode === 1 || body.resultCode === 2 || body.resultCode === 3) {
      this.navi.navigateByUrl('/error', true);

    } else {
      this.api.enabled();
      const v = body.userInfoValidator;
      const ng = v.validatorMessageNumberPlateList
      ? v.validatorMessageNumberPlateList.map(s => s === 'OK' ? null : ['NG'])
      : [null, null, null, null];

      this.messages = {
        fullname: v.validatorMessageFullNameList,
        kana: v.validatorMessageNameFuriganaList,
        birthdate: v.validatorMessageBirthList,
        phone: v.validatorMessageTelephoneNumberList,
        securityNunber: v.validatorMessageBicycleRegistrationNoList,
        postcode1: v.validatorMessageZipCodeList,
        zipCode: v.validatorMessageZipCodeList,
        address1: v.validatorMessageAddress1List,
        address2: v.validatorMessageAddress2List,
        wsName: v.validatorMessageCompanyNameList,
        wsKana: v.validatorMessageCompanyNameFuriganaList,
        wsPhone: v.validatorMessageCompanyTelList,
        companyZipCode: v.validatorMessageCompanyZipCodeList,
        companyAddress1: v.validatorMessageCompanyAddress1List,
        companyAddress2: v.validatorMessageCompanyAddress2List,
        okNg: ng,
        NP: v.validatorMessageNumberPlateList,
        motorcycleNumber: v.validatorMessageMotorcycleNumberPlateList,
        passwd: v.validatorMessagePasswordList,
        error: body.mainMessage,
      };
    }
  }

  onApply = () => {
    this.api.disabled = true;
    const m = this.model;
    const c = this.conf;
    const req: CheckReq = {
      // 駐車場コード
      parkingCode: m.parkingCode,  // 駐車場コード
      // お客様情報
      fullNameList: m.fullname,   // 氏名
      furiganaList: m.kana,       // 氏名（カタカナ）
      birthList: m.birthdate, // 生年月日
      telephoneNumber: m.phone,   // 電話番号
      bicycleRegistrationNo: m.securityNumber,   // 防犯登録番号
      // 住所
      zipCodeList: m.postcode,   // 郵便番号
      address1: m.address1,       // 住所1
      address2: m.address2,       // 住所2
      // 勤務先/通学先
      companyName: m.wsName,           // 勤務先名/通学先名
      companyNameFurigana: m.wsKana,           // 勤務先名/通学先名（カタカナ）
      companyTel: m.wsPhone,   // （勤務先/通学先）電話番号
      companyZipCodeList: m.wsPostcode,   // （勤務先/通学先）郵便番号
      companyAddress1: m.wsAddress1,       // （勤務先/通学先）住所1 
      companyAddress2: m.wsAddress2,       // （勤務先/通学先）住所2
      // 車両情報（クルマ）
      numberPlateList: m.numberPlate, // 車両情報
      // 車両情報（バイク）
      motorcycleNumberPlate: m.motorcycleNumberPlate,
      // パスワード
      password: m.passwd,              // パスワード
    };
    this.api.post<CheckRes>('/registration/check', req, 2).subscribe(
      res => this.afterPost(res.body)
    );
  }

}
