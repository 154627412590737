import { Component, ViewChild, inject } from '@angular/core';
import { Location } from '@angular/common';
import { AppModel } from 'src/app/app.model';
import { NavigateService, StorageService } from 'src/app/services';
import { LostService } from '../lost/lost.service';
import { ApplyStatus, formatPeriod, getStorageKey } from '../passcard.model';
import { SealReissueService } from '../seal-reissue/seal-reissue.service';
import { RefreshService } from '../refresh/refresh.service';
import { PasscardApply } from '../models/apply-info';
import { PaymentType } from '../pay/pay.model';
import { ModalComponent } from '../common/modal/modal.component';
import { PasscardApiProxy } from '../passcard.api.proxy';
import { StatusTipComponent, TipKind } from '../common/status-tip/status-tip.component';
import { CertificateUploadModel } from '../certificate-upload/certificate-upload.model';

/**
 * 定期契約情報
 */
@Component({
  selector: 'contract',
  templateUrl: './contract.component.html'
})
export class ContractComponent {
  private readonly _storage = inject(StorageService);

  ApplyStatus = ApplyStatus;
  confirmedClass: string | null = null;
  changePeriod = '';

  contract: PasscardApply | null = null;
  passcardApplyNo: number;
  refreshRedDotShow = false;
  
  @ViewChild('dlg') dlg!: ModalComponent
  @ViewChild(StatusTipComponent) statusTip: StatusTipComponent;

  constructor(public app: AppModel,
    public api: PasscardApiProxy,
    private _navi: NavigateService,
    private _lostService: LostService,
    private _sealService: SealReissueService,
    private _refreshService: RefreshService,
    location: Location) {
    this.contract = location.getState()['contract'];
    this.passcardApplyNo = this.contract.passcardApplyNo;
    const { systemDate, passcardUpdateFrom, passcardUpdateTo, passcardValidFrom, passcardValidTo } = this.contract;
    this.refreshRedDotShow = this.contract.passcardAvailable == 1
    && !this.PayButtonVisible
    && this.contract.status != ApplyStatus.WaitingICRegistForLost
    && passcardUpdateFrom <= systemDate && systemDate <= passcardUpdateTo
      && passcardValidFrom <= systemDate && systemDate <= passcardValidTo;
  }

  get tipVisible() {
    if (this.contract.passcardAvailable > 1) return false;
    const { status, systemDate, passcardUpdateFrom, passcardUpdateTo, passcardValidFrom, passcardValidTo } = this.contract;

    if (status == 32 || status == 51) return true;  // 申請中
    if (systemDate >= passcardUpdateFrom && systemDate <= passcardUpdateTo) {
      if (systemDate > passcardValidTo) return true;  // 利用可(更新猶予期間)
      if (passcardValidFrom <= systemDate && systemDate <= passcardValidTo) return true;  // 定期更新受付中
    }

    return status == ApplyStatus.WaitingPayAfterIssued
    || status == ApplyStatus.WaitingICRegistForLost
    || this.PayButtonVisible;
  }

  get isPast() {
    return this.contract.passcardAvailable > 1;
  }

  get PayButtonVisible() {
    const status = this.contract['status'];
    return this.contract.passcardPaymentType != 99
      && (status == ApplyStatus.WaitingPayForRefresh
      || status == ApplyStatus.WaitingPayForLost);
  }

  get RefreshButtonVisible() {
    return this.contract.menuSettingUpdate && (this.contract.status == ApplyStatus.Avaliable || this.contract.passcardAvailable);
  }

  get LostButtonVisible() {
    return this.contract.menuSettingLostReissue && (this.contract.status == ApplyStatus.Avaliable || this.contract.passcardAvailable);
  }

  get SealIssueButtonVisible() {
    return this.contract.menuSettingStickerReissue && (this.contract.status == ApplyStatus.Avaliable || this.contract.passcardAvailable);
  }

  get CancelButtonVisible() {
    return this.contract.menuSettingCancel && (this.contract.status == ApplyStatus.Avaliable || this.contract.passcardAvailable);
  }

  get CertificateUploadButtonVisible() {
    const {menuSettingCertificateReSubmit, passcardAvailable, certificateTypeList } = this.contract;
    return menuSettingCertificateReSubmit && passcardAvailable && certificateTypeList?.length;
  }

  get ReapplyButtonVisible() {
    return this.contract.passcardAvailable
      && this.contract.status == ApplyStatus.WaitingPayForRefresh && this.contract.passcardPaymentType == 99;
  }
  
  get detailBtnVisible() {
    const { status } = this.contract ?? {};
    return status == 31 || status == 32
      || status == 41 || status == 44 || status == 51;
  }

  onTipClick() {
    if (this.PayButtonVisible) {
      this.onPay();
      return;
    }

    if (this.statusTip?.applying.getValue() == 0) {
      this.onApplyDetail();
      return;
    }

    const applyingStatus = this.statusTip.applying.getValue();
    if (applyingStatus == TipKind.ChangeReceipting
      || applyingStatus == TipKind.Expired) {
      this.onRefresh();
      return;
    }

    switch (this.contract.status) {
      case ApplyStatus.AutoCancellation:
      case ApplyStatus.WaitingRefresh:
        this.onRefresh();
        break;
      case ApplyStatus.WaitingPayForRefresh:
        this.onReApply();
        break;
      case ApplyStatus.WaitingICRegistForLost:
        this.onICRegister();
        break;
    }
  }

  onICRegister() {
    this._navi.navigate(`/passcard/ic-register/${this.contract.passcardApplyNo}`, false, this.contract);
  }

  /**
   * お支払い処理
   */
  async onPay() {
    let payMethod = 0;
    let urlNav = '/passcard/terminal-pay';
    switch (this.contract.passcardPaymentType) {
      case PaymentType.Terminal:
        urlNav = `/passcard/terminal-pay`;
        payMethod = 0;
        break;
      case PaymentType.PayPay:
        urlNav = `/passcard/2pay-pay`;
        payMethod = 2;
        break;
      case PaymentType.Cvs:
        urlNav = `/passcard/conveni-pay`;
        payMethod = 3;
        break;
    }

    const paymentInfo = await this.api.getPaymentInfo(payMethod, this.contract.passcardApplyNo);
    this._navi.navigate(urlNav, false, { passcardApplyNo: this.contract.passcardApplyNo, reservation: this.contract, paymentInfo });
  }

  /**
   * 紛失・破損処理
   */
  onLost() {
    this._lostService.reset();
    this._storage.remove(`payment-params`);
    this._storage.remove('lost-wiz');
    this._storage.remove(getStorageKey('lost-step-1', 4, this.contract.dcParkingNo));
    this._storage.remove(getStorageKey('payment-method', 4, this.contract.dcParkingNo));

    this._lostService.applyModel.dcParkingNo = this.contract.dcParkingNo;
    this._navi.navigate('/passcard/lost',
      false,
      {
        contract: this.contract
      });
  }

  /**
   * 更新処理
   */
  onRefresh() {
    const { systemDate, passcardUpdateFrom, passcardUpdateTo } = this.contract;
    const sysDate = new Date(systemDate);
    const updateFrom = new Date(passcardUpdateFrom);
    const udateTo = new Date(passcardUpdateTo);
    sysDate.setHours(0, 0, 0, 0);
    updateFrom.setHours(0, 0, 0, 0);
    udateTo.setHours(0, 0, 0, 0);
    if (sysDate < updateFrom || sysDate > udateTo) {
      this.changePeriod = formatPeriod([passcardUpdateFrom, passcardUpdateTo]);
      this.dlg.showModal();
      return;
    }

    this._refreshService.reset();
    this.prepareRefresh(6);
    this._navi.navigate('/passcard/refresh', false, { contract: this.contract});
  }

  /**
   * 更新処理(証明書アプロード後)
   */
  onReApply() {
    this._refreshService.reset();
    this.prepareRefresh(11);
    this._navi.navigate('/passcard/refresh',
      false, {
        contract: this.contract
      });  
  }

  /**
   * シール再発行処理
   */
  onSealReissue() {
    this._sealService.reset();
    this._navi.navigate('/passcard/seal-reissue',
      false,
      {
        contract: this.contract
      });
  }

  /**
   * 定期券の解約
   */
  onCancelConfirm() {
    this._lostService.cancelComplete = false;
    this._navi.navigate('/passcard/passcard/cancel',
      false,
      {
        contract: this.contract
      });
  }

  /**
   * 証明書等再提出
   */
  onReUploadFiles() {
    CertificateUploadModel.reset(this._storage);
    const {
      passcardType,
      passcardExtended,
      passcardValidFrom,
      passcardValidTo,
      certificateURL,
      certificateTypeList,
      certificateNextCheckDate,
      no,
    } = this.contract;

    this._storage.save('reupload-settings', {
      passcardType,
      passcardExtended,
      passcardValidFrom,
      passcardValidTo,
      no,
      state: this.contract
    });
    
    this._navi.navigate('passcard/certificate-upload/config', false, { certificateNextCheckDate, certificateURL, certificateTypeList });
  }

  /**
   *　申請詳細へ
  */
  async onApplyDetail() {
    const { passcardApplyNo, parkingName } = this.contract;
    const applyDetail = await this.api.getPasscardApplyDetail({ passcardApplyNo, processDt: 0, processMs: 0 });

    this._navi.navigate(`passcard/apply-history/detail`, false, {
      passcardApplyNo,
      passcardApplyResult: '申請中',
      parkingAreaName: parkingName,
      reservation: this.contract,
      applyDetail
    });
  }

  onDlgClose() {
    this.dlg.closeModal();
  }

  /**
   * 
   * @param status 
   *   6: 更新処理（通常）
   *  11: 更新処理(証明書アプロード後)
   */
  private prepareRefresh(status: number) {
    const { passcardType, passcardApplyNo, dcParkingNo, parkingName, parkingAddress, passcardExtended, name,
      passcardValidFrom, passcardValidTo, no 
     } = this.contract;
    this._storage.remove(`payment-params`);
    this._storage.remove('refresh-wiz');
    this._storage.save('passcard-applyinfo', {
      status,
      passcardApplyNo,
      passcardType,
      parkingName,
      parkingAddress,
      name,
      passcardExtended,
      dcParkingNo,
      passcardValidFrom,
      passcardValidTo,
      no,
    })
  }
  /**
   * お知らせ情報の取得
   */
  get noticeInfo() {
    return this.contract['noticeInfoList'];
  }
}
