import { Component, inject } from '@angular/core';
import { ApiService, NavigateService, StorageService, UrlCategory } from '../../../services';
import { MyFilesModel } from 'src/app/shared/i-my-files/i-my-files.component';
import { CertificateType, CertificateUploadSettings } from 'src/app/shared/certificate-file';
import { Location } from '@angular/common';
import { CertificateUploadModel } from '../certificate-upload.model';
import { Subject } from 'rxjs';
import { CertificateImageService, 
  DownloadTemporaryImageFileRequest , 
  DownloadTemporaryImageFileResponse,
} from '../../../services/certificateImage.service';
import { CertificateImage } from '../../../shared/certificate-file';

interface Res {
  resultCode: number;
  mainMessage: string;
  receiptNo?: number;
}

//
@Component({
  selector: 'fi-confirm',
  templateUrl: './confirm.component.html',
  providers: [ CertificateUploadModel ]
})
export class ConfirmComponent {
  private readonly _storage = inject(StorageService);
  private _state: CertificateUploadSettings;

  previews: string[] = [];
  certificateNextCheckDate: number;
  certificateTypeSetting: CertificateType

  myFilesModel: MyFilesModel;
  error: string;
  imageDownloadFinish: Subject<number> = new Subject<number>();

  constructor(
    public model: CertificateUploadModel,
    public api: ApiService,
    public navi: NavigateService,
    l: Location,
    private certificateImageService: CertificateImageService,
  ) {
    this._state = <CertificateUploadSettings>l.getState();
    this.certificateNextCheckDate = this._state.certificateNextCheckDate;
    this.certificateTypeSetting = this._state.certificateTypeList.find(x=> x.certificateType == model.certificateType);
    model.loadStorage();
    this.myFilesModel = {
      certificateType: model.certificateType,
      images: model.images,
      year: model.year,
      month: model.month,
    }

    // 一時ファイルをダウンロード
     // 画像一時ファイルがある場合は取得
     if (this.myFilesModel && this.myFilesModel.images.length > 0) {
      // ダウンロード終了監視用データ作成
      const imageList : {imageNum: number; finish: boolean}[] = [];
      this.myFilesModel.images.forEach((image)=>{
        imageList.push({imageNum: image.certificateImageNum, finish: false});
      });
    
      // 画像ダウンロード終了監視
      const subscripttion = this.imageDownloadFinish.subscribe((imageNumber)=>{
        if(imageNumber === -1){
          // エラー発生
          this.api.loadingOff();
          subscripttion.unsubscribe();
        }else{
          // 終わったものを終了に
          const image = imageList.find((image) => image.imageNum === imageNumber);
          if(image){
            // 完了を設定
            image.finish = true;
          }
          // 全部終わったか(未完了のものがないか)
          if(!imageList.some((image) => image.finish === false)){
            this.api.loadingOff();
            subscripttion.unsubscribe();
          }
        }
      });
      // 画像ダウンロード実施
      this.api.loadingOn();
      this.downLoadImage(this.myFilesModel.images);
    }
  }

  /**
   * 画像データをダウンロードする
   * @param certicateImages ダウンロードするイメージデータ
   */
  private downLoadImage(certicateImages: CertificateImage[]) {
    certicateImages.forEach((certicateImage) => {
      if (certicateImage.fileSize > 0) {
        // 画像がアップロードされている場合
        const request: DownloadTemporaryImageFileRequest = {
          certificateImageNum: certicateImage.certificateImageNum,
          fileName: certicateImage.fileName,
          objectName: certicateImage.objectName,
        };
        // アップロード済みの画像データがある場合はダウンロードする
        this.certificateImageService.downLoadTemporaryImageFile(request).subscribe(
          (response: DownloadTemporaryImageFileResponse) => {
            if (response.resultCode === 0) {
              // 正常終了
              certicateImage.certificateImageData = CertificateImageService.IMAGE_HEADER + response.certificateImageData;
              this.imageDownloadFinish.next(certicateImage.certificateImageNum);
            } else {
              // 異常終了
              this.imageDownloadFinish.next(-1);
            }
          });
      } else {
        // 画像がアップロードされていない場合
        this.imageDownloadFinish.next(certicateImage.certificateImageNum);
      }
    });
  }

  //
  private afterPut(body: Res) {
    if (body.resultCode === 0) {
      this.model.apply();
      this._storage.remove('reupload-settings');
      this.navi.navigate(`passcard/certificate-upload/complete`, false, { receiptNo: body.receiptNo });
    } else {
      this.api.enabled();

      this.error = body.mainMessage;
    }
  }

  onBack() {
    this.navi.navigate(`passcard/certificate-upload/config`, false, this._state);
  }

  //
  onApply() {
    this.api.disabled = true;
    const { certificateType, images } = this.model;

    const certificateImageList = images.filter(x => x.fileSize).map(x => ({
      certificateImageNum: x.certificateImageNum,
      fileName: x.fileName,
      objectName: x.objectName,
    }));

    const settings = this._storage.load('reupload-settings', null);
    delete settings['state'];
    this.api.post<any>('/certificateReSubmit', {
      ...settings,
      certificateType,
      certificateImageList,
    }, UrlCategory.Base).subscribe(res => this.afterPut(res.body));
  }
}
