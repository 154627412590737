import { StatusTipComponent } from './status-tip/status-tip.component';
import { CardComponent } from './card/card.component';
import { ContractInfoComponent } from './contract-info/contract-info.component';
import { PPasscardValidTermComponent } from "./p-passcard-valid-term/p-passcard-valid-term.component";
import { PaginationComponent } from './pagination/pagination.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { DeliveryInputComponent } from './delivery-input/delivery-input.component';
import { IUsernameComponent } from './i-username/i-username.component';
import { PayByCreditcardComponent } from './pay-creditcard/pay-creditcard.component';
import { PayByCvsComponent } from './pay-cvs/pay-cvs.component';
import { BlockComponent } from './block/block.component';
import { PPayMethodComponent } from './p-pay-method/p-pay-method.component';
import { PPayFeesComponent } from './p-pay-fees/p-pay-fees.component';
import { PaymethodSelectComponent } from './paymethod-select/paymethod-select.component';
import { PasscardStatusComponent } from './passcard-info/passcard-status.component';
import { IDateSelectComponent } from './i-dateselect/i-date-select.component';
import { InfomationComponent } from './infomation/infomation.component';
import { PlaceInfoComponent } from './place-info/place-info.component';
import { ModalComponent } from './modal/modal.component';
import { FlowBoxComponent } from './flow-box/flow-box.component';
import { PasscardInfoComponent } from './passcard-info/passcard-info.component';
import { ApplyDetailsComponent } from './apply-details/apply-details.component';
import { NoticeInfoComponent } from './notice-info/notice-info.component';

export const passcardCommonComponents = [
    StatusTipComponent,
    CardComponent,
    ContractInfoComponent,
    PPasscardValidTermComponent,
    PaginationComponent,
    DeliveryComponent,
    DeliveryInputComponent,
    IUsernameComponent,
    PayByCreditcardComponent,
    PayByCvsComponent,
    BlockComponent,
    PPayMethodComponent,
    PPayFeesComponent,
    PaymethodSelectComponent,
    PasscardStatusComponent,
    IDateSelectComponent,
    InfomationComponent,
    PlaceInfoComponent,
    ModalComponent,
    FlowBoxComponent,
    PasscardInfoComponent,
    ApplyDetailsComponent,
    NoticeInfoComponent,
];